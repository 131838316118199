import { xor } from "lodash";

/**
 * Removes `element` if it's already a member of `array` or adds `element`
 * if it's not a member of `array`. Returns a new array without modifying
 * the original
 */
export function toggleArrayElement<TElement>(
  array: readonly TElement[],
  element: TElement
): TElement[] {
  return xor(array, [element]);
}
