import React from "react";
import { Skeleton, Stack, Typography } from "@mui/material";
import { useGroup } from "../../domain/crud";
import { makeGroupLocation } from "../../paths";
import type { Group } from "../../services/datastore";
import Link from "../Link";

export interface GroupInfoProps {
  groupId: Group["id"];
  chip?: React.ReactNode;
}

export default function GroupInfo({ groupId, chip }: GroupInfoProps) {
  const groupQuery = useGroup(groupId, {
    select(response) {
      return response.data.name;
    },
  });

  return (
    <Stack>
      {groupQuery.isSuccess ? (
        <Stack direction="row" spacing={1} alignItems="center">
          <span>{groupQuery.data}</span>
          {chip}
        </Stack>
      ) : (
        <Skeleton />
      )}
      <Link to={makeGroupLocation(groupId)}>
        <Typography variant="body2">{groupId}</Typography>
      </Link>
    </Stack>
  );
}
