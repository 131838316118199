import type { TimeRange } from "../types";

export function calculateWindowTicks(
  recordWindow: TimeRange,
  intervalMs: number
): number[] {
  // Floor to a multiple of the interval size
  let firstTickMs =
    Math.floor(recordWindow.startTimeMs / intervalMs) * intervalMs;
  if (firstTickMs < recordWindow.startTimeMs) {
    firstTickMs += intervalMs;
  }

  const ticks = [firstTickMs];
  for (
    let tick = firstTickMs;
    tick < recordWindow.endTimeMs;
    tick += intervalMs
  ) {
    ticks.push(tick);
  }

  return ticks;
}
