import { Skeleton, Typography } from "@mui/material";
import { useIngestion } from "../../domain/crud";
import { makeIngestionLocation } from "../../paths";
import type { Ingestion } from "../../services/datastore";
import Link from "../Link";

export interface IngestionInfoProps {
  ingestionId: Ingestion["id"];
}

export default function IngestionInfo({ ingestionId }: IngestionInfoProps) {
  const ingestionQuery = useIngestion(ingestionId, {
    select(response) {
      return response.data.name;
    },
  });

  return (
    <>
      {ingestionQuery.data ?? <Skeleton />}
      <Link to={makeIngestionLocation(ingestionId)}>
        <Typography variant="body2">{ingestionId}</Typography>
      </Link>
    </>
  );
}
