import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { DatabaseAlert } from "mdi-material-ui";
import type { Location } from "react-router-dom";
import type { ListResponse } from "../../domain/crud";
import type { UseDataStoreQueryResult } from "../../domain/datastores";
import { useIsConnected } from "../../domain/datastores";
import Center from "../Center";
import Error from "../Error";
import Loading from "../Loading";
import {
  makeInternalColumn,
  SortDirection,
  TableHeader,
  TableFooter,
  useColumnVisibility,
} from "./";
import type { Column, InternalColumn } from "./types";
import type { ResourceTableModel } from "./validation";

export interface ResourceTableProps<TResource> {
  resourceName: string;
  resourceCreateLocation?: Partial<Location>;
  getRowKey: (resource: TResource) => React.Key;
  columns: ReadonlyArray<Column<TResource>>;
  searchQuery: UseDataStoreQueryResult<ListResponse<TResource>>;
  tableModel: ResourceTableModel;
  onTableModelChange: (changes: Partial<ResourceTableModel>) => void;
  filterSection?: React.ReactNode;
  activeFilterCount?: number;
}

export default function ResourceTable<TResource>({
  resourceCreateLocation,
  resourceName,
  getRowKey,
  columns,
  searchQuery,
  tableModel,
  onTableModelChange,
  filterSection,
  activeFilterCount,
}: ResourceTableProps<TResource>) {
  const isConnected = useIsConnected();

  const internalColumns = columns.map(makeInternalColumn);

  const { visibleColumns, toggleColumnVisibility } = useColumnVisibility(
    resourceName,
    internalColumns
  );

  function makeSortChangeHandler(order: string) {
    const isActiveSort = order === tableModel.order;
    const newSort =
      !isActiveSort || tableModel.sort === SortDirection.Desc
        ? SortDirection.Asc
        : SortDirection.Desc;

    return function handleSortChange() {
      onTableModelChange({ sort: newSort, order, offset: 0 });
    };
  }

  function renderHeaderCell(column: InternalColumn<TResource>) {
    const { sortKey } = column;

    let children: React.ReactNode = column.header;
    if (sortKey !== undefined) {
      const isActiveSort = sortKey === tableModel.order;

      children = (
        <TableSortLabel
          active={isActiveSort}
          direction={isActiveSort ? tableModel.sort : SortDirection.Asc}
          onClick={makeSortChangeHandler(sortKey)}
        >
          {children}
        </TableSortLabel>
      );
    }

    return (
      <TableCell key={column.header} align={column.align}>
        {children}
      </TableCell>
    );
  }

  let body: React.ReactNode = null;
  if (!isConnected) {
    body = (
      <Center sx={{ py: 5 }}>
        <DatabaseAlert fontSize="large" />
        <Typography variant="h4" component="p">
          Connect to a DataStore to see its {resourceName}s
        </Typography>
      </Center>
    );
  } else if (searchQuery.isInitialLoading) {
    body = (
      <Box sx={{ py: 5 }}>
        <Loading type="circular" />
      </Box>
    );
  } else if (searchQuery.isError) {
    body = (
      <Box sx={{ py: 5 }}>
        <Error>
          <Typography variant="h4" component="p">
            An error occurred searching for {resourceName}s
          </Typography>
        </Error>
      </Box>
    );
  } else if (searchQuery.isSuccess && searchQuery.data.count === 0) {
    body = (
      <Center sx={{ py: 5 }}>
        <Typography variant="h4" component="p">
          The search returned 0 results
        </Typography>
      </Center>
    );
  } else if (searchQuery.isSuccess) {
    body = (
      <TableContainer sx={{ overflowX: "auto", whiteSpace: "nowrap" }}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                  borderBottom: "unset",
                },
              }}
            >
              {visibleColumns.map(renderHeaderCell)}
            </TableRow>
          </TableHead>
          <TableBody>
            {searchQuery.data.data.map((resource) => (
              <TableRow
                key={getRowKey(resource)}
                sx={{
                  // Remove bottom border for table cells in last row
                  [`&:last-of-type .${tableCellClasses.root}`]: {
                    borderBottom: "unset",
                  },
                }}
              >
                {visibleColumns.map((column) => (
                  <React.Fragment key={column.header}>
                    {column.renderCell(resource, { align: column.align })}
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      <TableHeader
        resourceCreateLocation={resourceCreateLocation}
        resource={resourceName}
        internalColumns={internalColumns}
        visibleColumns={visibleColumns}
        toggleColumnVisibility={toggleColumnVisibility}
        searchQuery={searchQuery}
        filterSection={filterSection}
        activeFilterCount={activeFilterCount}
      />
      {body}
      <TableFooter
        count={searchQuery.data?.count}
        paginationModel={tableModel}
        onPaginationModelChange={onTableModelChange}
      />
    </>
  );
}
