import React from "react";
import { Error } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import type { QueryParamConfig } from "use-query-params";
import {
  BooleanParam,
  createEnumParam,
  decodeNumber,
  encodeNumber,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import Pagination from "../../../components/Pagination";
import { useLogs } from "../../../domain/crud";
import { useIsConnected } from "../../../domain/datastores";
import type { Maybe } from "../../../types";
import { pluralize } from "../../../utils";
import SearchResults from "./SearchResults";

interface FormValues {
  search: string;
  playable: boolean;
}

const limitOptions = [15, 25, 50];

const LimitParam: QueryParamConfig<number, Maybe<number>> = {
  encode: encodeNumber,
  decode(value) {
    const numValue = decodeNumber(value);

    if (numValue == null) {
      return null;
    }

    if (!limitOptions.includes(numValue)) {
      return null;
    }

    return numValue;
  },
};

const OffsetParam: QueryParamConfig<number, Maybe<number>> = {
  encode: encodeNumber,
  decode(value) {
    const numValue = decodeNumber(value);

    const result = z.number().int().nonnegative().nullish().safeParse(numValue);

    if (result.success) {
      return result.data;
    } else {
      return null;
    }
  },
};

const SortOptions = {
  Newest: "newest",
  Oldest: "oldest",
} as const;

const SortParam = createEnumParam([SortOptions.Newest, SortOptions.Oldest]);

export default function Thumbnail() {
  const isConnected = useIsConnected();

  const [params, setParams] = useQueryParams({
    search: withDefault(StringParam, ""),
    playable: withDefault(BooleanParam, true),
    limit: withDefault(LimitParam, limitOptions[0]),
    offset: withDefault(OffsetParam, 0),
    sort: withDefault(SortParam, SortOptions.Newest, true),
  });

  const sortOptions =
    params.sort === "newest"
      ? { sort: "desc", order: "start_time" }
      : { sort: "asc", order: "start_time" };

  const logsQuery = useLogs(
    {
      nameLike: params.search,
      startTimeNull: params.playable ? false : undefined,
      limit: params.limit,
      offset: params.offset,
      ...sortOptions,
    },
    {
      keepPreviousData: true,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  const { control, handleSubmit } = useForm<FormValues>({
    values: {
      search: params.search,
      playable: params.playable,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = function onSubmit(values) {
    setParams({ ...values, offset: 0 });
  };

  return (
    <Stack spacing={4}>
      <Card>
        <CardContent>
          <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="search"
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth label="Log name" />
              )}
            />
            <Controller
              name="playable"
              control={control}
              render={({ field: { value, ...field } }) => (
                <FormControlLabel
                  control={<Checkbox checked={value} {...field} />}
                  label="Playable logs only"
                />
              )}
            />
            <Button
              sx={{ alignSelf: "start" }}
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isConnected}
            >
              Search
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Stack direction="row" alignItems="center">
            {!isConnected ? null : logsQuery.isLoading ? (
              <Typography>Fetching logs...</Typography>
            ) : logsQuery.isError ? (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Error color="error" />
                <Typography>Unable to perform search</Typography>
              </Stack>
            ) : logsQuery.isRefetching ? (
              <Typography>Searching...</Typography>
            ) : (
              <Typography>{pluralize(logsQuery.data.count, "log")}</Typography>
            )}
            <TextField
              select
              size="small"
              label="Sort by"
              value={params.sort}
              onChange={(e) =>
                setParams({ sort: e.target.value as any, offset: 0 })
              }
              sx={{ ml: "auto", width: "18ch" }}
            >
              <MenuItem value={SortOptions.Newest}>Newest logs</MenuItem>
              <MenuItem value={SortOptions.Oldest}>Oldest logs</MenuItem>
            </TextField>
            <TextField
              select
              size="small"
              label="Results per page"
              value={params.limit}
              onChange={(e) =>
                setParams({
                  limit: Number(e.target.value),
                  offset: 0,
                })
              }
              sx={{ ml: 2, width: "15ch" }}
            >
              {limitOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Box position="relative" mt={2}>
            <Divider />
            {logsQuery.isFetching && (
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                }}
              />
            )}
          </Box>
          <SearchResults searchQuery={logsQuery} />
          {logsQuery.isSuccess && (
            <>
              <Divider sx={{ mb: 2 }} />
              <Pagination
                count={logsQuery.data.count}
                limit={params.limit}
                offset={params.offset}
                onChange={(offset) => {
                  setParams({ offset });
                }}
              />
            </>
          )}
        </CardContent>
      </Card>
    </Stack>
  );
}
