import { Settings } from "@mui/icons-material";
import { Box, Container } from "@mui/material";
import GlobalNavigation from "../../components/GlobalNavigation";
import Header from "../../components/Header";
import Helmet from "../../components/Helmet";
import Layout, {
  LayoutStateProvider,
  SidebarSwitch,
  SideSheetTrigger,
} from "../../components/Layout";
import Loading from "../../components/Loading";
import NoDataStoreAlert from "../../components/NoDataStoreAlert";
import SettingsDrawer from "../../components/SettingsDrawer";
import { useIngestion } from "../../domain/crud";
import { useIngestionParams } from "../../paths";
import { selectData } from "../../utils";
import IngestionForm from "./components/EditForm";

export default function EditIngestion() {
  const { ingestionId } = useIngestionParams();
  const ingestionQuery = useIngestion(ingestionId, { select: selectData });
  const ingestion = ingestionQuery.data;
  return (
    <>
      <Helmet>
        <title>Edit Ingestion</title>
      </Helmet>
      <LayoutStateProvider>
        <Layout
          header={
            <Header
              title="Edit Ingestion"
              actions={
                <SideSheetTrigger
                  title="Settings"
                  sidebarId="settings"
                  icon={<Settings />}
                />
              }
            />
          }
          globalNavigation={<GlobalNavigation />}
          sideSheet={
            <SidebarSwitch
              config={[
                {
                  id: "settings",
                  element: <SettingsDrawer />,
                },
              ]}
            />
          }
        >
          <Box sx={{ flexGrow: 1, minWidth: 0, overflowY: "auto", py: 4 }}>
            <Container fixed>
              <NoDataStoreAlert sx={{ mb: 4 }} />
              {ingestion ? (
                <IngestionForm ingestion={ingestion} />
              ) : (
                <Loading type="circular" />
              )}
            </Container>
          </Box>
        </Layout>
      </LayoutStateProvider>
    </>
  );
}
