import type { QueryKey } from "@tanstack/react-query";
import { useDataStoreClients } from "./hooks";
import makeCacheKeyPrefix from "./makeCacheKeyPrefix";
import type { DataStoreQueryKey } from "./types";

/**
 * Given a standard array query key, return a shallow copy prefixed with
 * a DataStore cache prefix corresponding to the connected DataStore.
 *
 * Useful for making the base key for a DataStore query key factory.
 */
export default function useDataStoreQueryKey<TBaseQueryKey extends QueryKey>(
  queryKey: TBaseQueryKey
): DataStoreQueryKey<TBaseQueryKey> {
  const clients = useDataStoreClients();

  const basePath = clients?.basePath ?? null;

  return [makeCacheKeyPrefix(basePath), ...queryKey] as const;
}
