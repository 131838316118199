import React, { useCallback } from "react";
import { Close, CloudUpload, Settings } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import type { BoxProps } from "@mui/material";
import {
  Alert,
  AlertTitle,
  alpha,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormHelperText,
  IconButton,
  LinearProgress,
  Link,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import invariant from "invariant";
import { isEmpty } from "lodash";
import { FolderOpen } from "mdi-material-ui";
import prettyBytes from "pretty-bytes";
import type { FileError, FileRejection } from "react-dropzone";
import Dropzone, { ErrorCode } from "react-dropzone";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import Details from "../../components/Details";
import GlobalNavigation from "../../components/GlobalNavigation";
import Header from "../../components/Header";
import Helmet from "../../components/Helmet";
import Layout, {
  LayoutStateProvider,
  SidebarSwitch,
  SideSheetTrigger,
} from "../../components/Layout";
import NoDataStoreAlert from "../../components/NoDataStoreAlert";
import SettingsDrawer from "../../components/SettingsDrawer";
import {
  useCurrentUser,
  useGroupAssociations,
  useGroups,
} from "../../domain/crud";
import { useCurrentDataStore, useIsConnected } from "../../domain/datastores";
import { NOTEBOOK_HELP_LINK } from "../../links";
import type { DataStore } from "../../models";
import { makePlayerLocation, useMakeStudioLocation } from "../../paths";
import type { Group, GroupAssociation, Log } from "../../services/datastore";
import { UserRole } from "../../services/datastore";
import { selectData } from "../../utils";
import MultipartUpload from "./MultipartUpload";
import { UploadResponseError, useUploadLog } from "./queries";

interface FormValues {
  name: Log["name"];
  groupId: Group["id"];
  file: File | null;
}

export default function Upload() {
  const dataStore = useCurrentDataStore();
  const isConnected = useIsConnected();

  const allowedGroups = useGroupsWithUploadPermissions();

  const theme = useTheme();

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      groupId: "",
      file: null,
    },
  });

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      clearErrors("file");

      const errors = new Set<FileError["code"]>();
      rejectedFiles.forEach((file) =>
        file.errors.forEach((error) => errors.add(error.code))
      );

      if (errors.size > 0) {
        setValue("file", null);
      }

      if (errors.has(ErrorCode.TooManyFiles)) {
        setError("file", { message: "Only one log file can be uploaded" });
        return;
      }

      if (errors.has(ErrorCode.FileInvalidType)) {
        setError("file", {
          message: "Only ROSBAG files (.bag) can be uploaded",
        });
        return;
      }

      if (errors.has(ErrorCode.FileTooLarge)) {
        setError("file", { message: "Log files must be less than 5 GB" });
        return;
      }

      setValue("file", acceptedFiles[0], { shouldValidate: true });
    },
    [clearErrors, setError, setValue]
  );

  const uploadLog = useUploadLog();

  const onSubmit: SubmitHandler<FormValues> = function onSubmit({
    name,
    groupId,
    file,
  }) {
    invariant(file !== null, "File should not be null in submit handler");

    uploadLog.mutate(
      { name, groupId, file },
      {
        onError(error) {
          if (error instanceof UploadResponseError) {
            if (error.type === "name:duplicate") {
              setError("name", {
                message: "A log with that name already exists",
              });
            }
          }
        },
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>Upload a Log</title>
      </Helmet>
      <LayoutStateProvider>
        <Layout
          header={
            <Header
              title="Upload"
              actions={
                <SideSheetTrigger
                  title="Settings"
                  sidebarId="settings"
                  icon={<Settings />}
                />
              }
            />
          }
          globalNavigation={<GlobalNavigation />}
          sideSheet={
            <SidebarSwitch
              config={[
                {
                  id: "settings",
                  element: <SettingsDrawer />,
                },
              ]}
            />
          }
        >
          <Box sx={{ flexGrow: 1, minWidth: 0, overflowY: "auto", py: 4 }}>
            <Container fixed>
              <NoDataStoreAlert sx={{ mb: 4 }} />
              <Stack spacing={4}>
                <Card>
                  <CardHeader
                    title="New Log"
                    titleTypographyProps={{ component: "h2" }}
                  />
                  <CardContent>
                    <Stack
                      spacing={2}
                      alignItems="baseline"
                      component="form"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Controller
                        name="name"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Log name is required",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            label="Name"
                            sx={{ width: "40ch" }}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error?.message || " "}
                          />
                        )}
                      />
                      <Controller
                        name="groupId"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Group is required",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            select
                            disabled={allowedGroups == null}
                            label="Group"
                            error={Boolean(fieldState.error)}
                            helperText={
                              isConnected && allowedGroups === undefined
                                ? "Loading groups..."
                                : allowedGroups === null
                                ? "An error occurred loading groups"
                                : fieldState.error?.message ?? " "
                            }
                            sx={{ width: "25ch" }}
                          >
                            <MenuItem value="">--</MenuItem>
                            {allowedGroups?.map((group) => (
                              <MenuItem key={group.id} value={group.id}>
                                {group.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      <Controller
                        name="file"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Log file is required",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <Dropzone
                            noClick
                            noKeyboard
                            onDrop={onDrop}
                            multiple={false}
                            maxSize={MultipartUpload.MAX_FILE_SIZE_BYTES}
                            accept={{
                              "application/octet-stream": [".bag"],
                            }}
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                              open,
                            }) => {
                              const dashColor =
                                fieldState.error !== undefined
                                  ? theme.palette.error.main
                                  : theme.palette.text.primary;

                              const gradientStops = `${dashColor}, ${dashColor} 8px, transparent 8px, transparent 16px`;

                              return (
                                <Box width={1}>
                                  <Stack
                                    {...getRootProps({
                                      sx: {
                                        width: 1,
                                        pt: 4,
                                        color: "inherit",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        border: "1px transparent",
                                        backgroundImage: [
                                          `repeating-linear-gradient(to top, ${gradientStops})`,
                                          `repeating-linear-gradient(to right, ${gradientStops})`,
                                          `repeating-linear-gradient(to bottom, ${gradientStops})`,
                                          `repeating-linear-gradient(to left, ${gradientStops})`,
                                        ].join(", "),
                                        backgroundSize: [
                                          "1px 100%",
                                          "100% 1px",
                                          "1px 100%",
                                          "100% 1px",
                                        ].join(", "),
                                        backgroundPosition: [
                                          "bottom left",
                                          "top left",
                                          "top right",
                                          "bottom right",
                                        ].join(", "),
                                        backgroundRepeat: "no-repeat",
                                        bgcolor: (theme) =>
                                          isDragActive
                                            ? alpha(
                                                theme.palette.text.primary,
                                                0.05
                                              )
                                            : undefined,
                                      } as BoxProps["sx"],
                                    })}
                                  >
                                    <FolderOpen
                                      sx={{ mb: 2, ...theme.typography.h1 }}
                                    />
                                    <span>Drag and drop a log</span>
                                    <Box sx={{ width: 100, my: 1 }}>
                                      <Divider role="presentation">or</Divider>
                                    </Box>
                                    <Button
                                      type="button"
                                      aria-describedby="max-size"
                                      color="primary"
                                      variant="outlined"
                                      onClick={open}
                                    >
                                      Browse files
                                    </Button>
                                    <Typography id="max-size" mt={2}>
                                      Maximum file size:{" "}
                                      {prettyBytes(
                                        MultipartUpload.MAX_FILE_SIZE_BYTES
                                      )}
                                      , Accepted file types: .bag
                                    </Typography>
                                    <input {...getInputProps()} />
                                    <Box
                                      sx={{
                                        width: 1,
                                        borderTop: "1px transparent",
                                        backgroundImage: `repeating-linear-gradient(to right, ${gradientStops})`,
                                        backgroundSize: "100% 1px",
                                        backgroundPosition: "top left",
                                        backgroundRepeat: "no-repeat",
                                        p: 2,
                                        "&&": {
                                          // Increases specificity to override <Stack />'s
                                          // top margin
                                          mt: 4,
                                        },
                                      }}
                                    >
                                      {field.value === null ? (
                                        <Typography fontStyle="italic">
                                          No file selected
                                        </Typography>
                                      ) : (
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          spacing={2}
                                        >
                                          <Typography>
                                            {field.value.name} (
                                            {prettyBytes(field.value.size)})
                                          </Typography>
                                          <Tooltip title="Clear selected file">
                                            <IconButton
                                              size="small"
                                              color="error"
                                              onClick={() =>
                                                setValue("file", null)
                                              }
                                            >
                                              <Close />
                                            </IconButton>
                                          </Tooltip>
                                        </Stack>
                                      )}
                                    </Box>
                                  </Stack>
                                  <FormHelperText
                                    error={Boolean(fieldState.error)}
                                    variant="outlined"
                                    sx={{ ml: 2 }}
                                  >
                                    {fieldState.error?.message ?? " "}
                                  </FormHelperText>
                                </Box>
                              );
                            }}
                          </Dropzone>
                        )}
                      />
                      <Details>
                        <Details.Summary>
                          How can I upload logs larger than{" "}
                          {prettyBytes(MultipartUpload.MAX_FILE_SIZE_BYTES)}?
                        </Details.Summary>
                        <Details.Content paragraph>
                          To upload a log larger than{" "}
                          {prettyBytes(MultipartUpload.MAX_FILE_SIZE_BYTES)}{" "}
                          you'll need to use your{" "}
                          {dataStore === null ? (
                            "DataStore's REST API"
                          ) : (
                            <Link
                              color="inherit"
                              href={generateLinkToRestApiDocs(dataStore.origin)}
                            >
                              DataStore's REST API
                            </Link>
                          )}{" "}
                          directly. Using the API, you can upload logs up to 5
                          TiB.
                        </Details.Content>
                        <Details.Content>
                          Follow this detailed guide on{" "}
                          <Link color="inherit" href={NOTEBOOK_HELP_LINK}>
                            using the API to upload large files
                          </Link>
                          .
                        </Details.Content>
                      </Details>
                      <Divider flexItem />
                      <Typography id="upload-warning">
                        <Typography component="span" fontWeight="bold">
                          Important:
                        </Typography>{" "}
                        Once you start uploading your log, leave this page open
                        until the upload is complete
                      </Typography>
                      <LoadingButton
                        aria-describedby="upload-warning"
                        loading={uploadLog.isLoading}
                        disabled={
                          !isConnected ||
                          allowedGroups == null ||
                          uploadLog.isSuccess
                        }
                        type="submit"
                        color="primary"
                        variant="contained"
                        startIcon={<CloudUpload />}
                      >
                        Upload Log
                      </LoadingButton>
                      {uploadLog.isLoading && (
                        <Stack sx={{ width: 1, pt: 2 }}>
                          <Typography paragraph id="progress-label">
                            {uploadLog.uploadStatus.isComplete
                              ? "Finishing up..."
                              : uploadLog.uploadStatus.isUploading
                              ? `Upload in progress: ${new Intl.NumberFormat(
                                  undefined,
                                  {
                                    style: "percent",
                                  }
                                ).format(uploadLog.uploadStatus.progress)}`
                              : "Preparing to upload..."}
                          </Typography>
                          <LinearProgress
                            aria-labelledby="progress-label"
                            variant="determinate"
                            value={uploadLog.uploadStatus.progress * 100}
                          />
                        </Stack>
                      )}
                      {uploadLog.isSuccess && (
                        <SuccessAlert logId={uploadLog.data.data.id} />
                      )}
                      {/* If `errors` is empty but the upload failed, it means
                     some otherwise-unknown error occurred that isn't directly
                     related to any of the form inputs. */}
                      {uploadLog.isError && isEmpty(errors) && (
                        <Alert
                          severity="error"
                          variant="filled"
                          sx={{ width: 1 }}
                        >
                          <AlertTitle>Error</AlertTitle>
                          An unknown error occurred trying to upload the log
                        </Alert>
                      )}
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>
            </Container>
          </Box>
        </Layout>
      </LayoutStateProvider>
    </>
  );
}

interface SuccessAlertProps {
  logId: Log["id"];
}

function SuccessAlert({ logId }: SuccessAlertProps) {
  const makeStudioLocation = useMakeStudioLocation();

  return (
    <Alert severity="success" variant="filled" sx={{ width: 1 }}>
      <AlertTitle>Log Uploaded</AlertTitle>
      Your log has been uploaded and is processing.{" "}
      <Link
        component={RouterLink}
        to={makeStudioLocation(makePlayerLocation({ logId }))}
        color="inherit"
      >
        View it in the player
      </Link>
      .
    </Alert>
  );
}

function useGroupsWithUploadPermissions() {
  const currentUserQuery = useCurrentUser({ select: selectData });

  const isAdmin = currentUserQuery.data?.isAdmin;

  const groupsQuery = useGroups({ limit: 100, sort: "asc", order: "name" });
  const groupAssociationsQuery = useGroupAssociations(
    { limit: 100, userId: currentUserQuery.data?.id },
    {
      enabled:
        // Admins can upload to any group so no need to waste time fetching associations
        isAdmin === false && currentUserQuery.data?.id !== undefined,
      select({ data }) {
        return new Map<GroupAssociation["groupId"], UserRole>(
          data.map(({ groupId, role }) => [groupId, role])
        );
      },
    }
  );

  if (groupsQuery.isSuccess && isAdmin) {
    // Admins can upload to any group
    return groupsQuery.data.data;
  }

  if (groupsQuery.isSuccess && groupAssociationsQuery.isSuccess) {
    return groupsQuery.data.data.filter((group) => {
      const role = groupAssociationsQuery.data.get(group.id);

      if (role === undefined) {
        return false;
      }

      // Non-admins can only upload to groups in which they have the editor
      // role or higher
      return role === UserRole.Owner || role === UserRole.Editor;
    });
  }

  if (groupsQuery.isError || groupAssociationsQuery.isError) {
    return null;
  }

  return undefined;
}

function generateLinkToRestApiDocs(
  dataStoreOrigin: DataStore["origin"]
): URL["href"] {
  return new URL("/redoc", dataStoreOrigin).href;
}
