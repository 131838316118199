import type React from "react";
import { areIntervalsOverlapping } from "date-fns";
import type { Draft } from "immer";
import invariant from "invariant";
import {
  filter,
  findIndex,
  isEqual,
  map,
  maxBy,
  minBy,
  pullAllWith,
  pullAt,
} from "lodash";
import type { Reducer as ImmerReducer } from "use-immer";
import { useImmerReducer } from "use-immer";
import type { Topic } from "../../../../../services/datastore";
import type { LayoutNode } from "../../../panels";
import { iteratePanels } from "../../../panels";
import type { PlaybackSource } from "../../../playbackReducer";
import type { DraftExtractionTopic, TimeRange } from "../../../types";

export type SetSelectedTopicsAction = {
  type: "set-selected-topics";
  payload: {
    topics: Array<Topic>;
  };
};

export function setSelectedTopics(
  topics: Array<Topic>
): SetSelectedTopicsAction {
  return {
    type: "set-selected-topics",
    payload: {
      topics,
    },
  };
}

export type SelectLayoutTopicsAction = {
  type: "select-layout-topics";
};

export function selectLayoutTopics(): SelectLayoutTopicsAction {
  return {
    type: "select-layout-topics",
  };
}

export type DraftSelectedTopicsAction = {
  type: "draft-selected-topics";
};

export function draftSelectedTopics(): DraftSelectedTopicsAction {
  return {
    type: "draft-selected-topics",
  };
}

export type DraftTopicAction = {
  type: "draft-topic";
  payload: {
    topic: Topic;
    range: TimeRange;
  };
};

export function draftTopic(topic: Topic, range: TimeRange): DraftTopicAction {
  return {
    type: "draft-topic",
    payload: {
      topic,
      range,
    },
  };
}

export type RemoveDraftTopicAction = {
  type: "remove-draft-topic";
  payload: {
    topic: DraftExtractionTopic;
  };
};

export function removeDraftTopic(
  topic: DraftExtractionTopic
): RemoveDraftTopicAction {
  return {
    type: "remove-draft-topic",
    payload: {
      topic,
    },
  };
}

export type StartFinalizingAction = {
  type: "start-finalizing";
};

export function startFinalizing(): StartFinalizingAction {
  return {
    type: "start-finalizing",
  };
}

export type AbortFinalizingAction = {
  type: "abort-finalizing";
};

export function abortFinalizing(): AbortFinalizingAction {
  return {
    type: "abort-finalizing",
  };
}

export type ResetDraftAction = {
  type: "reset-draft";
};

export function resetDraft(): ResetDraftAction {
  return {
    type: "reset-draft",
  };
}

export type DraftExtractionActions =
  | SetSelectedTopicsAction
  | SelectLayoutTopicsAction
  | DraftSelectedTopicsAction
  | DraftTopicAction
  | RemoveDraftTopicAction
  | StartFinalizingAction
  | AbortFinalizingAction
  | ResetDraftAction;

type DraftExtractionReducerState = {
  isFinalizing: boolean;
  topics: Array<DraftExtractionTopic>;
  selectedTopicIds: Array<Topic["id"]>;
};

export type DraftExtraction = DraftExtractionReducerState & {
  canSelectLayoutTopics: boolean;
  dispatch: React.Dispatch<DraftExtractionActions>;
};

const initialState: DraftExtractionReducerState = {
  isFinalizing: false,
  topics: [],
  selectedTopicIds: [],
};

export type UseDraftExtractionArgs = {
  playerTopics: Array<Topic> | undefined;
  playerRange: PlaybackSource["range"];
  layout: LayoutNode;
};

export default function useDraftExtraction({
  playerTopics,
  playerRange,
  layout,
}: UseDraftExtractionArgs): DraftExtraction {
  const layoutTopicNames: MakeReducerArgs["layoutTopicNames"] = [];
  iteratePanels(layout, (panel) => {
    if (panel.isInitialized) {
      layoutTopicNames.push(panel.topicName);
    }
  });

  const [draftExtractionState, dispatch] = useImmerReducer(
    makeReducer({
      playerTopics,
      playerRange,
      layoutTopicNames,
    }),
    initialState
  );

  return {
    ...draftExtractionState,
    canSelectLayoutTopics: layoutTopicNames.length > 0,
    dispatch,
  };
}

type MakeReducerArgs = Pick<
  UseDraftExtractionArgs,
  "playerTopics" | "playerRange"
> & {
  layoutTopicNames: Array<Topic["name"]>;
};

function makeReducer({
  playerTopics,
  playerRange,
  layoutTopicNames,
}: MakeReducerArgs): ImmerReducer<
  DraftExtractionReducerState,
  DraftExtractionActions
> {
  return function reducer(draftState, action) {
    invariant(
      playerTopics !== undefined && playerRange !== undefined,
      "Topics and/or range not defined"
    );

    switch (action.type) {
      case "set-selected-topics": {
        draftState.selectedTopicIds = map(action.payload.topics, "id");

        return;
      }
      case "select-layout-topics": {
        invariant(layoutTopicNames.length > 0, "No layout topics to select");

        const layoutTopics = playerTopics.filter(({ name }) =>
          layoutTopicNames.includes(name)
        );

        draftState.selectedTopicIds = map(layoutTopics, "id");

        return;
      }
      case "draft-selected-topics": {
        invariant(
          draftState.selectedTopicIds.length > 0,
          "Must have at least one topic selected"
        );

        for (const topicId of draftState.selectedTopicIds) {
          addTopicToDraft(draftState, topicId, playerRange);
        }

        return;
      }
      case "draft-topic": {
        addTopicToDraft(
          draftState,
          action.payload.topic.id,
          action.payload.range
        );

        return;
      }
      case "remove-draft-topic": {
        const topicIndex = findIndex(draftState.topics, action.payload.topic);

        invariant(topicIndex !== -1, "Topic not found");

        pullAt(draftState.topics, topicIndex);

        return;
      }
      case "start-finalizing": {
        invariant(
          draftState.topics.length > 0,
          "Must have at least one topic drafted to finalize"
        );
        invariant(!draftState.isFinalizing, "Already finalizing");

        draftState.isFinalizing = true;

        return;
      }
      case "abort-finalizing": {
        invariant(draftState.isFinalizing, "Not currently finalizing");

        draftState.isFinalizing = false;

        return;
      }
      case "reset-draft": {
        return initialState;
      }
      default: {
        const _exhaustiveCheck: never = action;
        throw new Error(`Unknown action: ${_exhaustiveCheck}`);
      }
    }
  };
}

function addTopicToDraft(
  draftState: Draft<DraftExtractionReducerState>,
  topicId: Topic["id"],
  playerBounds: NonNullable<MakeReducerArgs["playerRange"]>
): void {
  const newDraftTopic: DraftExtractionTopic = {
    topicId,
    ...playerBounds,
  };

  // If the new draft topic's time range overlaps with any existing
  // draft topics with a matching topic ID, those need to be merged
  // into a single draft
  const overlappingTopics = filter(draftState.topics, (topic) => {
    if (topic.topicId !== topicId) {
      return false;
    }

    return areIntervalsOverlapping(
      { start: topic.startTimeMs, end: topic.endTimeMs },
      {
        start: newDraftTopic.startTimeMs,
        end: newDraftTopic.endTimeMs,
      },
      { inclusive: true }
    );
  });

  if (overlappingTopics.length === 0) {
    // New draft didn't overlap with anything so just push it
    // and continue
    draftState.topics.push(newDraftTopic);

    return;
  }

  // New draft topic and all drafts it overlapped with need to be
  // merged into a single draft whose time range spans them all.
  const { startTimeMs: minOverlapTimeMs } = minBy(
    [...overlappingTopics, newDraftTopic],
    "startTimeMs"
  )!;
  const { endTimeMs: maxOverlapTimeMs } = maxBy(
    [...overlappingTopics, newDraftTopic],
    "endTimeMs"
  )!;

  // Remove existing overlapped drafts which will be represented by
  // the merged draft
  pullAllWith(draftState.topics, overlappingTopics, isEqual);

  // Push the merged draft in place of new draft and overlapped drafts
  draftState.topics.push({
    topicId,
    startTimeMs: minOverlapTimeMs,
    endTimeMs: maxOverlapTimeMs,
  });
}
