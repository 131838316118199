import { z } from "zod";
import { getAppConfig } from "../config";

const DOMAIN_NAME_LABEL_REGEXP = /^[a-z\d][a-z\d-]+[a-z\d]$/;

export const DataStoreName = z
  .string()
  .min(3, "Must be at least 3 characters long")
  .regex(
    DOMAIN_NAME_LABEL_REGEXP,
    "Only lowercase letters, numbers, and dashes allowed. Cannot start or end with dashes"
  );
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DataStoreName = z.infer<typeof DataStoreName>;

function addUrlIssueToCtx(ctx: z.RefinementCtx, message: string) {
  ctx.addIssue({
    validation: "url",
    code: z.ZodIssueCode.invalid_string,
    message,
    fatal: true,
  });
}

const BaseDataStoreOrigin = z.string().transform((input, ctx) => {
  let url;
  try {
    url = new URL(input);
  } catch {
    addUrlIssueToCtx(ctx, "Invalid URL");

    return z.NEVER;
  }

  if (!["https:", "http:"].includes(url.protocol)) {
    addUrlIssueToCtx(ctx, "DataStore URL can only use HTTP(s) protocol");

    return z.NEVER;
  }

  if (!(url.username === "" && url.password === "")) {
    addUrlIssueToCtx(ctx, "DataStore URL cannot contain credentials");

    return z.NEVER;
  }

  if (url.search !== "") {
    addUrlIssueToCtx(ctx, "DataStore URL cannot contain query parameters");

    return z.NEVER;
  }

  if (url.hash !== "") {
    addUrlIssueToCtx(ctx, "DataStore URL cannot contain hash");

    return z.NEVER;
  }

  // A generic DataStore URL is allowed to contain a path. However, the output
  // URL shouldn't end with a trailing slash. The generated SDK naively appends
  // its path strings - which always start with "/" - to the base URL, so
  // that could result in two "/"s in the final path. According to the URL
  // spec, the only time a URL's `pathname` will end with "/" is when it doesn't
  // actually have a path, i.e. it's `pathname === "/"`
  let origin = url.origin;
  if (url.pathname !== "/") {
    origin += url.pathname;
  }

  // Technically this is no longer an origin since it can now contain a path
  return origin;
});

function isPlatformOrigin(url: URL): boolean {
  const { apiDomainSuffix } = getAppConfig();

  return url.origin.endsWith(apiDomainSuffix);
}

export const PlatformDataStoreOrigin = BaseDataStoreOrigin.superRefine(
  (input, ctx) => {
    const url = new URL(input);

    if (!isPlatformOrigin(url)) {
      addUrlIssueToCtx(ctx, "Not a Platform URL");

      return z.NEVER;
    }

    if (url.protocol !== "https:") {
      addUrlIssueToCtx(ctx, "Platform URLs must use HTTPS protocol");

      return z.NEVER;
    }

    if (url.pathname !== "/") {
      addUrlIssueToCtx(ctx, "Platform URLs cannot contain a path");

      return z.NEVER;
    }
  }
);

export const CustomDataStoreOrigin = BaseDataStoreOrigin.refine(
  (origin) => !isPlatformOrigin(new URL(origin)),
  { message: "URL is a Platform URL" }
);

const BaseDataStore = z.object({
  name: DataStoreName,
});

export const PlatformDataStore = BaseDataStore.extend({
  type: z.literal("platform"),
  origin: PlatformDataStoreOrigin,
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PlatformDataStore = z.infer<typeof PlatformDataStore>;

export const CustomDataStore = BaseDataStore.extend({
  type: z.literal("custom"),
  origin: CustomDataStoreOrigin,
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CustomDataStore = z.infer<typeof CustomDataStore>;

const DataStore = z.discriminatedUnion("type", [
  PlatformDataStore,
  CustomDataStore,
]);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DataStore = z.infer<typeof DataStore>;
