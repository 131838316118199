import { useQueryClient } from "@tanstack/react-query";
import invariant from "invariant";
import type { StrictOmit } from "ts-essentials";
import type {
  Group,
  GroupCreateRequest,
  GroupFetchResponse,
  GroupListResponse,
  GroupUpdateRequest,
  ListGroupsRequest,
} from "../../services/datastore";
import type { ResolvedKeyFactory } from "../../types";
import type { UseDataStoreQueryOptions } from "../datastores";
import {
  useDataStoreMutation,
  useDataStoreQuery,
  useDataStoreQueryKey,
} from "../datastores";
import { getInitialDetailsData, mergeEnabledOption } from "./utils";

export function useGroupKeys() {
  const baseKey = useDataStoreQueryKey(["groups"] as const);

  const factory = {
    all: baseKey,
    lists: () => [...factory.all, "list"] as const,
    list: (request: ListGroupsRequest) =>
      [...factory.lists(), request] as const,
    details: () => [...factory.all, "details"] as const,
    detail: (groupId: Group["id"] | null) =>
      [...factory.details(), groupId] as const,
  } as const;

  return factory;
}

export type GroupKeys = ResolvedKeyFactory<typeof useGroupKeys>;

export function useGroups<TData = GroupListResponse>(
  request: ListGroupsRequest,
  options?: UseDataStoreQueryOptions<
    GroupListResponse,
    unknown,
    TData,
    GroupKeys["list"]
  >
) {
  return useDataStoreQuery({
    queryKey: useGroupKeys().list(request),
    queryFn(context, { groupApi }) {
      return groupApi.listGroups(request, context);
    },
    ...options,
  });
}

export function useGroup<TData = GroupFetchResponse>(
  groupId: Group["id"] | null,
  options?: StrictOmit<
    UseDataStoreQueryOptions<
      GroupFetchResponse,
      unknown,
      TData,
      GroupKeys["detail"]
    >,
    "initialData"
  >
) {
  const queryClient = useQueryClient();

  const groupKeys = useGroupKeys();

  const isGroupIdValid = groupId !== null;

  return useDataStoreQuery({
    queryKey: groupKeys.detail(groupId),
    queryFn(context, { groupApi }) {
      invariant(isGroupIdValid, "Group ID cannot be null");

      return groupApi.getGroup({ groupId }, context);
    },
    ...options,
    enabled: mergeEnabledOption(options, isGroupIdValid),
    initialData() {
      if (!isGroupIdValid) {
        return;
      }

      return getInitialDetailsData(
        queryClient,
        groupKeys.lists(),
        (group: Group) => group.id === groupId
      );
    },
  });
}

export function useCreateGroup() {
  const groupKeys = useGroupKeys();

  const queryClient = useQueryClient();

  return useDataStoreMutation({
    mutationFn(request: GroupCreateRequest, { groupApi }) {
      return groupApi.createGroup({ groupCreateRequest: request });
    },
    onSuccess(response) {
      return queryClient.setQueryData<GroupFetchResponse>(
        groupKeys.detail(response.data.id),
        response
      );
    },
  });
}

export function useUpdateGroup(groupId: Group["id"]) {
  const queryClient = useQueryClient();
  const groupKeys = useGroupKeys();

  return useDataStoreMutation({
    mutationFn(request: GroupUpdateRequest, { groupApi }) {
      return groupApi.updateGroup({ groupId, groupUpdateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData<GroupFetchResponse>(
        groupKeys.detail(response.data.id),
        response
      );
    },
  });
}

export function useDeleteGroup(groupId: Group["id"]) {
  return useDataStoreMutation({
    mutationFn(_, { groupApi }) {
      return groupApi.deleteGroup({ groupId });
    },
  });
}
