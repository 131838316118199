import { Settings } from "@mui/icons-material";
import { Box, Container } from "@mui/material";
import GlobalNavigation from "../../components/GlobalNavigation";
import Header from "../../components/Header";
import Helmet from "../../components/Helmet";
import Layout, {
  LayoutStateProvider,
  SidebarSwitch,
  SideSheetTrigger,
} from "../../components/Layout";
import Loading from "../../components/Loading";
import NoDataStoreAlert from "../../components/NoDataStoreAlert";
import SettingsDrawer from "../../components/SettingsDrawer";
import { useUser } from "../../domain/crud";
import { useUserParams } from "../../paths";
import { selectData } from "../../utils";
import UserForm from "./components/EditForm";

export default function EditUser() {
  const { userId } = useUserParams();
  const userQuery = useUser(userId, { select: selectData });
  const user = userQuery.data;
  return (
    <>
      <Helmet>
        <title>Edit User</title>
      </Helmet>
      <LayoutStateProvider>
        <Layout
          header={
            <Header
              title="Edit User"
              actions={
                <SideSheetTrigger
                  title="Settings"
                  sidebarId="settings"
                  icon={<Settings />}
                />
              }
            />
          }
          globalNavigation={<GlobalNavigation />}
          sideSheet={
            <SidebarSwitch
              config={[
                {
                  id: "settings",
                  element: <SettingsDrawer />,
                },
              ]}
            />
          }
        >
          <Box sx={{ flexGrow: 1, minWidth: 0, overflowY: "auto", py: 4 }}>
            <Container fixed>
              <NoDataStoreAlert sx={{ mb: 4 }} />
              {user ? <UserForm user={user} /> : <Loading type="circular" />}
            </Container>
          </Box>
        </Layout>
      </LayoutStateProvider>
    </>
  );
}
