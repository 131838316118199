import { zodResolver } from "@hookform/resolvers/zod";
import type {
  FieldValues,
  UseFormHandleSubmit,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";
import { useForm } from "react-hook-form";
import type { StrictOmit } from "ts-essentials";
import type { z } from "zod";

type NullableFields<TFieldValues extends FieldValues> = {
  [Key in keyof TFieldValues]: TFieldValues[Key] | null;
};

export type UseStudioFormProps<TSchema extends z.ZodType<FieldValues>> =
  StrictOmit<
    UseFormProps<NullableFields<z.infer<TSchema>>, void>,
    "resolver" | "defaultValues"
  > & {
    schema: TSchema;
    defaultValues?: NullableFields<z.infer<TSchema>>;
    onSubmit: Parameters<
      UseFormHandleSubmit<NullableFields<z.infer<TSchema>>, z.infer<TSchema>>
    >[0];
  };

export type UseStudioFormReturn<TSchemaOutput extends FieldValues> = StrictOmit<
  UseFormReturn<NullableFields<TSchemaOutput>, void, TSchemaOutput>,
  "handleSubmit"
> & {
  handleSubmit: ReturnType<
    UseFormHandleSubmit<NullableFields<TSchemaOutput>, TSchemaOutput>
  >;
};

export function useStudioForm<TSchema extends z.ZodType<FieldValues>>({
  schema,
  onSubmit,
  ...props
}: UseStudioFormProps<TSchema>): UseStudioFormReturn<z.infer<TSchema>> {
  const { handleSubmit, ...form } = useForm<
    NullableFields<z.infer<TSchema>>,
    void,
    z.infer<TSchema>
  >({
    ...(props as UseFormProps<NullableFields<z.infer<TSchema>>>),
    // TODO: Defaulting to sync parsing may improve validation time
    //       for large forms (assuming there are no async validations)
    resolver: zodResolver(schema),
  });

  return {
    ...form,
    handleSubmit: handleSubmit(onSubmit),
  };
}
