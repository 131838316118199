import React from "react";
import {
  Album,
  CloudDownload,
  CloudUpload,
  Settings,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { Database, FileCloud } from "mdi-material-ui";
import type { Location } from "react-router-dom";
import { Link as RouterLink, useLocation } from "react-router-dom";
import GlobalNavigation from "../components/GlobalNavigation";
import Header from "../components/Header";
import Helmet from "../components/Helmet";
import Layout, {
  LayoutStateProvider,
  SidebarSwitch,
  SideSheetTrigger,
} from "../components/Layout";
import LogQSLink from "../components/LogQSLink";
import SettingsDrawer from "../components/SettingsDrawer";
import * as paths from "../paths";
import { useMakeStudioLocation } from "../paths";
import type { Maybe } from "../types";

export default function Home() {
  const makeStudioLocation = useMakeStudioLocation();

  const theme = useTheme();

  const location = useLocation();

  function renderRedirectStateAlert() {
    const locationState = location.state as Maybe<paths.IndexState>;

    if (locationState == null) {
      return null;
    }

    let children = undefined;

    if (locationState.unmatchedPath !== undefined) {
      children = (
        <>
          <AlertTitle>Unknown Path</AlertTitle>
          <Typography fontSize="inherit" paragraph>
            Studio doesn't recognize the following path:
          </Typography>
          <pre>{locationState.unmatchedPath}</pre>
          <Typography fontSize="inherit">
            You might have mistyped the URL into your address bar or followed an
            old link that no longer works.
          </Typography>
        </>
      );
    }

    if (locationState.invalidOrigin !== undefined) {
      children = (
        <>
          <AlertTitle>Error Connecting to DataStore</AlertTitle>
          <Typography fontSize="inherit" paragraph>
            The <code>url</code> query parameter contained the following
            malformed value:
          </Typography>
          <pre>{locationState.invalidOrigin}</pre>
          <Typography fontSize="inherit">
            This could happen if you incorrectly copied a link to Studio and
            pasted it into your browser's address bar.
          </Typography>
        </>
      );
    }

    if (locationState.unknownPlatformOrigin !== undefined) {
      children = (
        <>
          <AlertTitle>Error Connecting to DataStore</AlertTitle>
          <Typography fontSize="inherit" paragraph>
            Studio encountered the following DataStore URL:
          </Typography>
          <pre>{locationState.unknownPlatformOrigin}</pre>
          <Typography fontSize="inherit">
            This URL is to a Platform DataStore to which you do not have access.
            If you expect to have access, you will need to contact an
            administrator.
          </Typography>
        </>
      );
    }

    return (
      <Grid item xs={12}>
        <Alert
          severity="error"
          variant="filled"
          sx={{
            "& .MuiAlert-message": {
              flexGrow: 1,
            },
            "& pre": {
              borderRadius: 1,
              bgcolor: "grey.400",
              p: 0.75,
            },
          }}
        >
          {children}
        </Alert>
      </Grid>
    );
  }

  function renderCard(
    icon: React.ReactNode,
    title: string,
    text: string,
    to: Partial<Location>
  ) {
    return (
      <Grid item xs={12} md={6} sx={{ mx: "auto" }}>
        <Card
          sx={{
            height: 1,
            width: 1,
            "& .MuiCardContent-root": {
              height: 1,
              display: "flex",
            },
            "& .MuiSvgIcon-root": {
              mr: 2,
              ...theme.typography.h1,
            },
          }}
        >
          <CardContent>
            {icon}
            <div>
              <Typography
                component={RouterLink}
                to={makeStudioLocation(to)}
                variant="h4"
                color="primary"
                gutterBottom
                display="block"
              >
                {title}
              </Typography>
              <Typography variant="h6" component="p">
                {text}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <LayoutStateProvider>
        <Layout
          header={
            <Header
              title="Home"
              actions={
                <SideSheetTrigger
                  title="Settings"
                  sidebarId="settings"
                  icon={<Settings />}
                />
              }
            />
          }
          globalNavigation={<GlobalNavigation />}
          sideSheet={
            <SidebarSwitch
              config={[
                {
                  id: "settings",
                  element: <SettingsDrawer />,
                },
              ]}
            />
          }
        >
          <Box sx={{ flexGrow: 1, minWidth: 0, overflowY: "auto", py: 4 }}>
            <Container fixed>
              <Grid container spacing={4}>
                {renderRedirectStateAlert()}
                <Grid item xs={12}>
                  <Typography variant="h2" component="h1" align="center">
                    Welcome to Studio!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" component="p" align="center">
                    What would you like to do?
                  </Typography>
                </Grid>
                {renderCard(
                  <FileCloud />,
                  "Search Logs",
                  "Search for logs in your DataStore to find one worth exploring",
                  paths.makeLogsLocation()
                )}
                {renderCard(
                  <CloudUpload />,
                  "Upload a Log",
                  "Upload a ROSBAG from your device",
                  paths.makeUploadLocation()
                )}
                {renderCard(
                  <Album />,
                  "Play Records",
                  "Visually explore your logs and extract subsets of data for local analysis",
                  paths.makePlayerLocation()
                )}
                {renderCard(
                  <CloudDownload />,
                  "Find Extractions",
                  "Find curated ROSBAGs of your DataStore's logs",
                  paths.makeExtractionsLocation()
                )}
                {renderCard(
                  <Database />,
                  "Manage DataStores",
                  "Create, delete, and connect to DataStores",
                  paths.makeDataStoresLocation()
                )}
                <Grid item xs={12}>
                  <Divider sx={{ width: 50, m: "auto" }} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    "& .LogQSLink-image": {
                      width: 150,
                      height: "auto",
                    },
                  }}
                >
                  <LogQSLink />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Layout>
      </LayoutStateProvider>
    </>
  );
}
