import React, { useCallback, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import invariant from "invariant";
import { Navigate, useNavigate } from "react-router-dom";
import CustomConnectionForm from "../../../components/CustomConnectionForm";
import GlobalLoadingFallback from "../../../components/GlobalLoadingFallback";
import { createSafeContext } from "../../../contexts";
import type { DataStore } from "../../../models";
import * as paths from "../../../paths";
import { selectData } from "../../../utils";
import {
  useCreateCustomDataStore,
  useCustomDataStores,
  usePlatformDataStores,
} from "../api";
import { removeDataStoreQueries } from "../services";
import { useOriginParam } from "./hooks";
import type { ProcessOriginParamResult } from "./utils";
import { processOriginParam } from "./utils";

export interface DataStoreProviderProps {
  children: React.ReactNode;
}

export interface DataStoreContextValue {
  current: DataStore | null;
  connect: (dataStore: DataStore) => void;
  disconnect: () => void;
}

export const [useDataStoreContext, DataStoreContext] =
  createSafeContext<DataStoreContextValue>("DataStore");

export function useCurrentDataStore() {
  return useDataStoreContext().current;
}

export function useEnsuredCurrentDataStore() {
  const maybeDataStore = useCurrentDataStore();

  invariant(maybeDataStore !== null, "Expected a DataStore");

  return maybeDataStore;
}

export function useIsConnected() {
  return useCurrentDataStore() !== null;
}

interface DataStoreProviderInnerProps extends DataStoreProviderProps {
  processResult: Extract<
    ProcessOriginParamResult,
    {
      status:
        | "loaded:no-connection"
        | "loaded:connection-found"
        | "unknown:custom";
    }
  >;
}

function DataStoreProviderInner({
  processResult,
  children,
}: DataStoreProviderInnerProps) {
  const [, setOrigin] = useOriginParam();

  const queryClient = useQueryClient();

  const connect = useCallback(
    (dataStore: DataStore) => {
      setOrigin(dataStore.origin);
      removeDataStoreQueries(queryClient);
    },
    [setOrigin, queryClient]
  );

  const disconnect = useCallback(() => {
    setOrigin(null);
    removeDataStoreQueries(queryClient);
  }, [setOrigin, queryClient]);

  const dataStore =
    processResult.status === "loaded:connection-found"
      ? processResult.dataStore
      : null;

  const value = useMemo(
    () => ({ current: dataStore, connect, disconnect }),
    [dataStore, connect, disconnect]
  );

  return (
    <DataStoreContext.Provider value={value}>
      {children}
      {processResult.status === "unknown:custom" && (
        <UnknownDataStoreDialog origin={processResult.origin} />
      )}
    </DataStoreContext.Provider>
  );
}

export default function DataStoreProvider({
  children,
}: DataStoreProviderProps) {
  const [originParam] = useOriginParam();

  const platformDataStoresQuery = usePlatformDataStores();
  const customDataStoresQuery = useCustomDataStores({ select: selectData });

  const processResult = processOriginParam({
    originParam,
    platformDataStoresQuery,
    customDataStoresQuery,
  });

  if (processResult.status === "invalid:malformed") {
    const { state, ...to } = paths.makeIndexLocation({
      invalidOrigin: processResult.value,
    });

    return <Navigate replace to={to} state={state} />;
  }

  if (processResult.status === "unknown:platform") {
    const { state, ...to } = paths.makeIndexLocation({
      unknownPlatformOrigin: processResult.origin,
    });

    return <Navigate replace to={to} state={state} />;
  }

  if (processResult.status === "loading") {
    return <GlobalLoadingFallback />;
  }

  return (
    <DataStoreProviderInner processResult={processResult}>
      {children}
    </DataStoreProviderInner>
  );
}

interface UnknownDataStoreDialogProps {
  origin: DataStore["origin"];
}

function UnknownDataStoreDialog({ origin }: UnknownDataStoreDialogProps) {
  const [showForm, setShowForm] = useState(false);

  const createCustomConnection = useCreateCustomDataStore();

  const navigate = useNavigate();

  function handleReturnToHomeClick() {
    navigate(paths.makeIndexLocation(), { replace: true });
  }

  function handleCreateNewConnectionClick() {
    setShowForm(true);
  }

  function handleGoBackClick() {
    setShowForm(false);
  }

  let children;
  if (!showForm) {
    children = (
      <>
        <DialogTitle id="datastore-dialog-title">
          Unknown DataStore URL
        </DialogTitle>
        <DialogContent dividers>
          <Typography paragraph>
            Studio couldn't find a DataStore connection for the following URL:
          </Typography>
          <Box
            component="pre"
            sx={{
              overflowX: "auto",
              bgcolor: "grey.800",
              p: 1.5,
              borderRadius: 1,
            }}
          >
            {origin}
          </Box>
          <Typography paragraph>
            If you don't recognize this DataStore's URL, you can return to
            Studio's home page.
          </Typography>
          <Typography paragraph>
            If you intended to connect to this DataStore, you can create a new
            custom connection.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReturnToHomeClick}>Return to Home</Button>
          <Button onClick={handleCreateNewConnectionClick}>
            Create New Connection
          </Button>
        </DialogActions>
      </>
    );
  } else {
    const formId = "new-connection-dialog-form";

    children = (
      <>
        <DialogTitle id="datastore-dialog-title">
          Create New Connection
        </DialogTitle>
        <DialogContent dividers>
          <Typography paragraph>
            Provide a name for the new DataStore connection. Studio will
            remember this connection in the future.
          </Typography>
          <CustomConnectionForm
            onSubmit={createCustomConnection.mutateAsync}
            formId={formId}
            origin={origin}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGoBackClick}>Go Back</Button>
          <LoadingButton
            type="submit"
            form={formId}
            variant="contained"
            disableElevation
            loading={createCustomConnection.isLoading}
          >
            Create Connection
          </LoadingButton>
        </DialogActions>
      </>
    );
  }

  return (
    <Dialog aria-labelledby="datastore-dialog-title" fullWidth open>
      {children}
    </Dialog>
  );
}
