/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  CreatePresignedURLRequest,
  CreatePresignedURLRequestFromJSON,
  CreatePresignedURLRequestToJSON,
  CreatePresignedURLResponse,
  CreatePresignedURLResponseFromJSON,
  CreatePresignedURLResponseToJSON,
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  RecordCreateRequest,
  RecordCreateRequestFromJSON,
  RecordCreateRequestToJSON,
  RecordFetchResponse,
  RecordFetchResponseFromJSON,
  RecordFetchResponseToJSON,
  RecordListResponse,
  RecordListResponseFromJSON,
  RecordListResponseToJSON,
  RecordUpdateRequest,
  RecordUpdateRequestFromJSON,
  RecordUpdateRequestToJSON,
  TopicCreateRequest,
  TopicCreateRequestFromJSON,
  TopicCreateRequestToJSON,
  TopicFetchResponse,
  TopicFetchResponseFromJSON,
  TopicFetchResponseToJSON,
  TopicListResponse,
  TopicListResponseFromJSON,
  TopicListResponseToJSON,
  TopicUpdateRequest,
  TopicUpdateRequestFromJSON,
  TopicUpdateRequestToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface CreateRecordRequest {
  topicId: string;
  recordCreateRequest: RecordCreateRequest;
}

export interface CreateRecordPresignedUrlRequest {
  topicId: string;
  timestamp: number;
  createPresignedURLRequest: CreatePresignedURLRequest;
}

export interface CreateTopicRequest {
  topicCreateRequest: TopicCreateRequest;
}

export interface CreateTopicPresignedUrlRequest {
  topicId: string;
  createPresignedURLRequest: CreatePresignedURLRequest;
}

export interface DeleteRecordRequest {
  topicId: string;
  timestamp: number;
}

export interface DeleteTopicRequest {
  topicId: string;
}

export interface GetRecordRequest {
  topicId: string;
  timestamp: number;
}

export interface GetTopicRequest {
  topicId: string;
}

export interface ListRecordsRequest {
  topicId: string;
  offset?: number;
  limit?: number;
  order?: string;
  sort?: string;
  logId?: string;
  ingestionId?: string;
  errored?: boolean;
  archived?: boolean;
  dataFilter?: any;
  contextFilter?: any;
  frequency?: number;
  timestampGt?: number;
  timestampLt?: number;
  timestampGte?: number;
  timestampLte?: number;
  lengthGte?: number;
  lengthLte?: number;
  dataLengthGte?: number;
  dataLengthLte?: number;
  offsetGte?: number;
  offsetLte?: number;
  dataOffsetGte?: number;
  dataOffsetLte?: number;
  nanosecondGte?: number;
  nanosecondLte?: number;
  includeImage?: boolean;
  includeBytes?: boolean;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  createdAtLte?: Date;
  createdAtGte?: Date;
  updatedAtLte?: Date;
  updatedAtGte?: Date;
  deletedAtLte?: Date;
  deletedAtGte?: Date;
}

export interface ListTopicsRequest {
  offset?: number;
  limit?: number;
  order?: string;
  sort?: string;
  logId?: string;
  groupId?: string;
  messageTypeId?: string;
  associatedTopicId?: string;
  ingestionId?: string;
  locked?: boolean;
  name?: string;
  nameLike?: string;
  contextFilter?: any;
  typeName?: string;
  typeNameLike?: string;
  typeEncoding?: string;
  typeEncodingLike?: string;
  typeData?: string;
  typeDataLike?: string;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  updatedByNull?: boolean;
  deletedByNull?: boolean;
  updatedAtNull?: boolean;
  deletedAtNull?: boolean;
  createdAtLte?: Date;
  updatedAtLte?: Date;
  deletedAtLte?: Date;
  createdAtGte?: Date;
  updatedAtGte?: Date;
  deletedAtGte?: Date;
}

export interface UpdateRecordRequest {
  topicId: string;
  timestamp: number;
  recordUpdateRequest: RecordUpdateRequest;
}

export interface UpdateTopicRequest {
  topicId: string;
  topicUpdateRequest: TopicUpdateRequest;
}

/**
 *
 */
export class TopicApi extends runtime.BaseAPI {
  /**
   * Required role: [editor, owner, admin]
   * Create Record
   */
  async createRecordRaw(
    this: TopicApi,
    requestParameters: CreateRecordRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<RecordFetchResponse>> {
    if (
      requestParameters.topicId === null ||
      requestParameters.topicId === undefined
    ) {
      throw new runtime.RequiredError(
        "topicId",
        "Required parameter requestParameters.topicId was null or undefined when calling createRecord."
      );
    }

    if (
      requestParameters.recordCreateRequest === null ||
      requestParameters.recordCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "recordCreateRequest",
        "Required parameter requestParameters.recordCreateRequest was null or undefined when calling createRecord."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}/records`.replace(
          `{${"topic_id"}}`,
          encodeURIComponent(String(requestParameters.topicId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: RecordCreateRequestToJSON(requestParameters.recordCreateRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RecordFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Required role: [editor, owner, admin]
   * Create Record
   */
  async createRecord(
    this: TopicApi,
    requestParameters: CreateRecordRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<RecordFetchResponse> {
    const response = await this.createRecordRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create Record Presigned Url
   */
  async createRecordPresignedUrlRaw(
    this: TopicApi,
    requestParameters: CreateRecordPresignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreatePresignedURLResponse>> {
    if (
      requestParameters.topicId === null ||
      requestParameters.topicId === undefined
    ) {
      throw new runtime.RequiredError(
        "topicId",
        "Required parameter requestParameters.topicId was null or undefined when calling createRecordPresignedUrl."
      );
    }

    if (
      requestParameters.timestamp === null ||
      requestParameters.timestamp === undefined
    ) {
      throw new runtime.RequiredError(
        "timestamp",
        "Required parameter requestParameters.timestamp was null or undefined when calling createRecordPresignedUrl."
      );
    }

    if (
      requestParameters.createPresignedURLRequest === null ||
      requestParameters.createPresignedURLRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "createPresignedURLRequest",
        "Required parameter requestParameters.createPresignedURLRequest was null or undefined when calling createRecordPresignedUrl."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}/records/{timestamp}/presignedUrls`
          .replace(
            `{${"topic_id"}}`,
            encodeURIComponent(String(requestParameters.topicId))
          )
          .replace(
            `{${"timestamp"}}`,
            encodeURIComponent(String(requestParameters.timestamp))
          ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreatePresignedURLRequestToJSON(
          requestParameters.createPresignedURLRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreatePresignedURLResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create Record Presigned Url
   */
  async createRecordPresignedUrl(
    this: TopicApi,
    requestParameters: CreateRecordPresignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreatePresignedURLResponse> {
    const response = await this.createRecordPresignedUrlRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create Topic
   */
  async createTopicRaw(
    this: TopicApi,
    requestParameters: CreateTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TopicFetchResponse>> {
    if (
      requestParameters.topicCreateRequest === null ||
      requestParameters.topicCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "topicCreateRequest",
        "Required parameter requestParameters.topicCreateRequest was null or undefined when calling createTopic."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/topics`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: TopicCreateRequestToJSON(requestParameters.topicCreateRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TopicFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create Topic
   */
  async createTopic(
    this: TopicApi,
    requestParameters: CreateTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TopicFetchResponse> {
    const response = await this.createTopicRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create Topic Presigned Url
   */
  async createTopicPresignedUrlRaw(
    this: TopicApi,
    requestParameters: CreateTopicPresignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreatePresignedURLResponse>> {
    if (
      requestParameters.topicId === null ||
      requestParameters.topicId === undefined
    ) {
      throw new runtime.RequiredError(
        "topicId",
        "Required parameter requestParameters.topicId was null or undefined when calling createTopicPresignedUrl."
      );
    }

    if (
      requestParameters.createPresignedURLRequest === null ||
      requestParameters.createPresignedURLRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "createPresignedURLRequest",
        "Required parameter requestParameters.createPresignedURLRequest was null or undefined when calling createTopicPresignedUrl."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}/presignedUrls`.replace(
          `{${"topic_id"}}`,
          encodeURIComponent(String(requestParameters.topicId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreatePresignedURLRequestToJSON(
          requestParameters.createPresignedURLRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreatePresignedURLResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create Topic Presigned Url
   */
  async createTopicPresignedUrl(
    this: TopicApi,
    requestParameters: CreateTopicPresignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreatePresignedURLResponse> {
    const response = await this.createTopicPresignedUrlRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Delete Record
   */
  async deleteRecordRaw(
    this: TopicApi,
    requestParameters: DeleteRecordRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.topicId === null ||
      requestParameters.topicId === undefined
    ) {
      throw new runtime.RequiredError(
        "topicId",
        "Required parameter requestParameters.topicId was null or undefined when calling deleteRecord."
      );
    }

    if (
      requestParameters.timestamp === null ||
      requestParameters.timestamp === undefined
    ) {
      throw new runtime.RequiredError(
        "timestamp",
        "Required parameter requestParameters.timestamp was null or undefined when calling deleteRecord."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}/records/{timestamp}`
          .replace(
            `{${"topic_id"}}`,
            encodeURIComponent(String(requestParameters.topicId))
          )
          .replace(
            `{${"timestamp"}}`,
            encodeURIComponent(String(requestParameters.timestamp))
          ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Record
   */
  async deleteRecord(
    this: TopicApi,
    requestParameters: DeleteRecordRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<void> {
    await this.deleteRecordRaw(requestParameters, initOverrides);
  }

  /**
   * Delete Topic
   */
  async deleteTopicRaw(
    this: TopicApi,
    requestParameters: DeleteTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.topicId === null ||
      requestParameters.topicId === undefined
    ) {
      throw new runtime.RequiredError(
        "topicId",
        "Required parameter requestParameters.topicId was null or undefined when calling deleteTopic."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}`.replace(
          `{${"topic_id"}}`,
          encodeURIComponent(String(requestParameters.topicId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Topic
   */
  async deleteTopic(
    this: TopicApi,
    requestParameters: DeleteTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<void> {
    await this.deleteTopicRaw(requestParameters, initOverrides);
  }

  /**
   * Get Record
   */
  async getRecordRaw(
    this: TopicApi,
    requestParameters: GetRecordRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<RecordFetchResponse>> {
    if (
      requestParameters.topicId === null ||
      requestParameters.topicId === undefined
    ) {
      throw new runtime.RequiredError(
        "topicId",
        "Required parameter requestParameters.topicId was null or undefined when calling getRecord."
      );
    }

    if (
      requestParameters.timestamp === null ||
      requestParameters.timestamp === undefined
    ) {
      throw new runtime.RequiredError(
        "timestamp",
        "Required parameter requestParameters.timestamp was null or undefined when calling getRecord."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}/records/{timestamp}`
          .replace(
            `{${"topic_id"}}`,
            encodeURIComponent(String(requestParameters.topicId))
          )
          .replace(
            `{${"timestamp"}}`,
            encodeURIComponent(String(requestParameters.timestamp))
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RecordFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Record
   */
  async getRecord(
    this: TopicApi,
    requestParameters: GetRecordRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<RecordFetchResponse> {
    const response = await this.getRecordRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Topic
   */
  async getTopicRaw(
    this: TopicApi,
    requestParameters: GetTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TopicFetchResponse>> {
    if (
      requestParameters.topicId === null ||
      requestParameters.topicId === undefined
    ) {
      throw new runtime.RequiredError(
        "topicId",
        "Required parameter requestParameters.topicId was null or undefined when calling getTopic."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}`.replace(
          `{${"topic_id"}}`,
          encodeURIComponent(String(requestParameters.topicId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TopicFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Topic
   */
  async getTopic(
    this: TopicApi,
    requestParameters: GetTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TopicFetchResponse> {
    const response = await this.getTopicRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * List Records
   */
  async listRecordsRaw(
    this: TopicApi,
    requestParameters: ListRecordsRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<RecordListResponse>> {
    if (
      requestParameters.topicId === null ||
      requestParameters.topicId === undefined
    ) {
      throw new runtime.RequiredError(
        "topicId",
        "Required parameter requestParameters.topicId was null or undefined when calling listRecords."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.logId !== undefined) {
      queryParameters["log_id"] = requestParameters.logId;
    }

    if (requestParameters.ingestionId !== undefined) {
      queryParameters["ingestion_id"] = requestParameters.ingestionId;
    }

    if (requestParameters.errored !== undefined) {
      queryParameters["errored"] = requestParameters.errored;
    }

    if (requestParameters.archived !== undefined) {
      queryParameters["archived"] = requestParameters.archived;
    }

    if (requestParameters.dataFilter !== undefined) {
      queryParameters["data_filter"] = requestParameters.dataFilter;
    }

    if (requestParameters.contextFilter !== undefined) {
      queryParameters["context_filter"] = requestParameters.contextFilter;
    }

    if (requestParameters.frequency !== undefined) {
      queryParameters["frequency"] = requestParameters.frequency;
    }

    if (requestParameters.timestampGt !== undefined) {
      queryParameters["timestamp_gt"] = requestParameters.timestampGt;
    }

    if (requestParameters.timestampLt !== undefined) {
      queryParameters["timestamp_lt"] = requestParameters.timestampLt;
    }

    if (requestParameters.timestampGte !== undefined) {
      queryParameters["timestamp_gte"] = requestParameters.timestampGte;
    }

    if (requestParameters.timestampLte !== undefined) {
      queryParameters["timestamp_lte"] = requestParameters.timestampLte;
    }

    if (requestParameters.lengthGte !== undefined) {
      queryParameters["length_gte"] = requestParameters.lengthGte;
    }

    if (requestParameters.lengthLte !== undefined) {
      queryParameters["length_lte"] = requestParameters.lengthLte;
    }

    if (requestParameters.dataLengthGte !== undefined) {
      queryParameters["data_length_gte"] = requestParameters.dataLengthGte;
    }

    if (requestParameters.dataLengthLte !== undefined) {
      queryParameters["data_length_lte"] = requestParameters.dataLengthLte;
    }

    if (requestParameters.offsetGte !== undefined) {
      queryParameters["offset_gte"] = requestParameters.offsetGte;
    }

    if (requestParameters.offsetLte !== undefined) {
      queryParameters["offset_lte"] = requestParameters.offsetLte;
    }

    if (requestParameters.dataOffsetGte !== undefined) {
      queryParameters["data_offset_gte"] = requestParameters.dataOffsetGte;
    }

    if (requestParameters.dataOffsetLte !== undefined) {
      queryParameters["data_offset_lte"] = requestParameters.dataOffsetLte;
    }

    if (requestParameters.nanosecondGte !== undefined) {
      queryParameters["nanosecond_gte"] = requestParameters.nanosecondGte;
    }

    if (requestParameters.nanosecondLte !== undefined) {
      queryParameters["nanosecond_lte"] = requestParameters.nanosecondLte;
    }

    if (requestParameters.includeImage !== undefined) {
      queryParameters["include_image"] = requestParameters.includeImage;
    }

    if (requestParameters.includeBytes !== undefined) {
      queryParameters["include_bytes"] = requestParameters.includeBytes;
    }

    if (requestParameters.createdBy !== undefined) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy !== undefined) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy !== undefined) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.createdAtLte !== undefined) {
      queryParameters["created_at_lte"] = (
        requestParameters.createdAtLte as any
      ).toISOString();
    }

    if (requestParameters.createdAtGte !== undefined) {
      queryParameters["created_at_gte"] = (
        requestParameters.createdAtGte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtLte !== undefined) {
      queryParameters["updated_at_lte"] = (
        requestParameters.updatedAtLte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtGte !== undefined) {
      queryParameters["updated_at_gte"] = (
        requestParameters.updatedAtGte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtLte !== undefined) {
      queryParameters["deleted_at_lte"] = (
        requestParameters.deletedAtLte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtGte !== undefined) {
      queryParameters["deleted_at_gte"] = (
        requestParameters.deletedAtGte as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}/records`.replace(
          `{${"topic_id"}}`,
          encodeURIComponent(String(requestParameters.topicId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RecordListResponseFromJSON(jsonValue)
    );
  }

  /**
   * List Records
   */
  async listRecords(
    this: TopicApi,
    requestParameters: ListRecordsRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<RecordListResponse> {
    const response = await this.listRecordsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * List Topics
   */
  async listTopicsRaw(
    this: TopicApi,
    requestParameters: ListTopicsRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TopicListResponse>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.logId !== undefined) {
      queryParameters["log_id"] = requestParameters.logId;
    }

    if (requestParameters.groupId !== undefined) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.messageTypeId !== undefined) {
      queryParameters["message_type_id"] = requestParameters.messageTypeId;
    }

    if (requestParameters.associatedTopicId !== undefined) {
      queryParameters["associated_topic_id"] =
        requestParameters.associatedTopicId;
    }

    if (requestParameters.ingestionId !== undefined) {
      queryParameters["ingestion_id"] = requestParameters.ingestionId;
    }

    if (requestParameters.locked !== undefined) {
      queryParameters["locked"] = requestParameters.locked;
    }

    if (requestParameters.name !== undefined) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike !== undefined) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.contextFilter !== undefined) {
      queryParameters["context_filter"] = requestParameters.contextFilter;
    }

    if (requestParameters.typeName !== undefined) {
      queryParameters["type_name"] = requestParameters.typeName;
    }

    if (requestParameters.typeNameLike !== undefined) {
      queryParameters["type_name_like"] = requestParameters.typeNameLike;
    }

    if (requestParameters.typeEncoding !== undefined) {
      queryParameters["type_encoding"] = requestParameters.typeEncoding;
    }

    if (requestParameters.typeEncodingLike !== undefined) {
      queryParameters["type_encoding_like"] =
        requestParameters.typeEncodingLike;
    }

    if (requestParameters.typeData !== undefined) {
      queryParameters["type_data"] = requestParameters.typeData;
    }

    if (requestParameters.typeDataLike !== undefined) {
      queryParameters["type_data_like"] = requestParameters.typeDataLike;
    }

    if (requestParameters.createdBy !== undefined) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy !== undefined) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy !== undefined) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull !== undefined) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull !== undefined) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull !== undefined) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull !== undefined) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte !== undefined) {
      queryParameters["created_at_lte"] = (
        requestParameters.createdAtLte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtLte !== undefined) {
      queryParameters["updated_at_lte"] = (
        requestParameters.updatedAtLte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtLte !== undefined) {
      queryParameters["deleted_at_lte"] = (
        requestParameters.deletedAtLte as any
      ).toISOString();
    }

    if (requestParameters.createdAtGte !== undefined) {
      queryParameters["created_at_gte"] = (
        requestParameters.createdAtGte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtGte !== undefined) {
      queryParameters["updated_at_gte"] = (
        requestParameters.updatedAtGte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtGte !== undefined) {
      queryParameters["deleted_at_gte"] = (
        requestParameters.deletedAtGte as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/topics`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TopicListResponseFromJSON(jsonValue)
    );
  }

  /**
   * List Topics
   */
  async listTopics(
    this: TopicApi,
    requestParameters: ListTopicsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TopicListResponse> {
    const response = await this.listTopicsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update Record
   */
  async updateRecordRaw(
    this: TopicApi,
    requestParameters: UpdateRecordRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<RecordFetchResponse>> {
    if (
      requestParameters.topicId === null ||
      requestParameters.topicId === undefined
    ) {
      throw new runtime.RequiredError(
        "topicId",
        "Required parameter requestParameters.topicId was null or undefined when calling updateRecord."
      );
    }

    if (
      requestParameters.timestamp === null ||
      requestParameters.timestamp === undefined
    ) {
      throw new runtime.RequiredError(
        "timestamp",
        "Required parameter requestParameters.timestamp was null or undefined when calling updateRecord."
      );
    }

    if (
      requestParameters.recordUpdateRequest === null ||
      requestParameters.recordUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "recordUpdateRequest",
        "Required parameter requestParameters.recordUpdateRequest was null or undefined when calling updateRecord."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}/records/{timestamp}`
          .replace(
            `{${"topic_id"}}`,
            encodeURIComponent(String(requestParameters.topicId))
          )
          .replace(
            `{${"timestamp"}}`,
            encodeURIComponent(String(requestParameters.timestamp))
          ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: RecordUpdateRequestToJSON(requestParameters.recordUpdateRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RecordFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Record
   */
  async updateRecord(
    this: TopicApi,
    requestParameters: UpdateRecordRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<RecordFetchResponse> {
    const response = await this.updateRecordRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Topic
   */
  async updateTopicRaw(
    this: TopicApi,
    requestParameters: UpdateTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TopicFetchResponse>> {
    if (
      requestParameters.topicId === null ||
      requestParameters.topicId === undefined
    ) {
      throw new runtime.RequiredError(
        "topicId",
        "Required parameter requestParameters.topicId was null or undefined when calling updateTopic."
      );
    }

    if (
      requestParameters.topicUpdateRequest === null ||
      requestParameters.topicUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "topicUpdateRequest",
        "Required parameter requestParameters.topicUpdateRequest was null or undefined when calling updateTopic."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/topics/{topic_id}`.replace(
          `{${"topic_id"}}`,
          encodeURIComponent(String(requestParameters.topicId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: TopicUpdateRequestToJSON(requestParameters.topicUpdateRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TopicFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Topic
   */
  async updateTopic(
    this: TopicApi,
    requestParameters: UpdateTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TopicFetchResponse> {
    const response = await this.updateTopicRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
