import type { UserFetchResponse } from "../../services/datastore";
import type { ResolvedKeyFactory } from "../../types";
import type { UseDataStoreQueryOptions } from "../datastores";
import { useDataStoreQuery, useDataStoreQueryKey } from "../datastores";

export function useMeKeys() {
  const baseKey = useDataStoreQueryKey(["me"] as const);

  const factory = {
    all: baseKey,
    details: () => [...factory.all, "details"] as const,
    detail: () => [...factory.details()] as const,
  } as const;

  return factory;
}

export type MeKeys = ResolvedKeyFactory<typeof useMeKeys>;

export function useCurrentUser<TData = UserFetchResponse>(
  options?: UseDataStoreQueryOptions<
    UserFetchResponse,
    unknown,
    TData,
    MeKeys["detail"]
  >
) {
  return useDataStoreQuery({
    queryKey: useMeKeys().detail(),
    queryFn(context, { meApi }) {
      return meApi.getMe(context);
    },
    ...options,
  });
}
