import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { NumberField, omitNullFields } from "../../../components/Form";
import { TopicSelect } from "../../../components/Form/TopicSelect";
import { useStudioForm } from "../../../components/Form/hooks";
import { useCreateExtractionTopic } from "../../../domain/crud";
import { makeExtractionTopicLocation, useStudioNavigate } from "../../../paths";
import type { Extraction } from "../../../services/datastore";
import { createExtractionTopicSchema } from "./validation";

export interface CreateExtractionTopicFormProps {
  extraction: Extraction;
}

export function CreateExtractionTopicForm({
  extraction,
}: CreateExtractionTopicFormProps) {
  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const createExtractionTopic = useCreateExtractionTopic(extraction.id);

  const { control, handleSubmit } = useStudioForm({
    schema: createExtractionTopicSchema,
    defaultValues: {
      topicId: null,
      startTime: null,
      endTime: null,
      frequency: null,
    },
    onSubmit(values) {
      createExtractionTopic.mutate(omitNullFields(values), {
        onSuccess(response) {
          navigate(
            makeExtractionTopicLocation(extraction.id, response.data.id)
          );
        },
        onError() {
          enqueueSnackbar("Unable to create extraction topic", {
            variant: "error",
          });
        },
      });
    },
  });

  return (
    <Card>
      <CardContent>
        <Grid component="form" container onSubmit={handleSubmit} spacing={2}>
          <Grid item xs={6}>
            <TopicSelect
              control={control}
              name="topicId"
              label="Topic"
              logId={extraction.logId}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberField control={control} name="frequency" label="Frequency" />
          </Grid>
          <Grid item xs={6}>
            <NumberField
              control={control}
              name="startTime"
              label="Start Time"
            />
          </Grid>
          <Grid item xs={6}>
            <NumberField control={control} name="endTime" label="End Time" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={createExtractionTopic.isLoading}
              type="submit"
              variant="contained"
            >
              Create
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
