import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { CheckboxField, TextField } from "../../../components/Form";
import { useStudioForm } from "../../../components/Form/hooks";
import { useCreateUser } from "../../../domain/crud";
import { makeUserLocation, useStudioNavigate } from "../../../paths";
import { createUserSchema } from "./validation";

export default function UserForm() {
  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const createUser = useCreateUser();

  const { control, handleSubmit } = useStudioForm({
    schema: createUserSchema,
    defaultValues: {
      username: null,
      isAdmin: false,
    },
    onSubmit(values) {
      createUser.mutate(values, {
        onSuccess(response) {
          navigate(makeUserLocation(response.data.id));
        },
        onError() {
          enqueueSnackbar("Unable to create user", { variant: "error" });
        },
      });
    },
  });

  return (
    <Card>
      <CardContent>
        <Grid
          alignItems="center"
          component="form"
          container
          onSubmit={handleSubmit}
          spacing={2}
        >
          <Grid item xs={4}>
            <TextField control={control} name="username" />
          </Grid>
          <Grid item xs={3}>
            <CheckboxField control={control} name="isAdmin" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={createUser.isLoading}
              type="submit"
              variant="contained"
            >
              Create
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
