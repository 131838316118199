import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useLogKeys } from "../../domain/crud";
import type { DataStoreMutationFunction } from "../../domain/datastores";
import { useDataStoreMutation } from "../../domain/datastores";
import type { Group, Log, LogFetchResponse } from "../../services/datastore";
import { ResponseError } from "../../services/datastore";
import type { UpdateEvent } from "./MultipartUpload";
import MultipartUpload from "./MultipartUpload";

export class UploadResponseError {
  public type: UploadErrorType;
  public responseError: ResponseError;

  constructor(type: UploadErrorType, responseError: ResponseError) {
    this.type = type;
    this.responseError = responseError;
  }
}

export type UploadErrorType = "name:duplicate";

export interface UseUploadLogArgs {
  name: Log["name"];
  groupId: Group["id"];
  file: File;
}

export interface MultipartUploadStatus {
  isUploading: boolean;
  progress: number;
  isComplete: boolean;
}

export function useUploadLog() {
  const [uploadStatus, setUploadStatus] = useState<MultipartUploadStatus>({
    isUploading: false,
    progress: 0,
    isComplete: false,
  });

  const logKeys = useLogKeys();

  const queryClient = useQueryClient();

  const mutationFn: DataStoreMutationFunction<
    LogFetchResponse,
    UseUploadLogArgs
  > = async function mutationFn(
    { name, groupId, file },
    { logApi, ingestionApi }
  ) {
    let newLog;
    try {
      newLog = await logApi.createLog({
        logCreateRequest: { name, groupId },
      });
    } catch (e) {
      if (e instanceof ResponseError && e.response.status === 409) {
        throw new UploadResponseError("name:duplicate", e);
      }

      throw e;
    }

    const newIngestion = await ingestionApi.createIngestion({
      ingestionCreateRequest: {
        logId: newLog.data.id,
        name: file.name,
        format: "ros",
      },
    });

    const upload = new MultipartUpload({
      ingestionApi,
      file,
      ingestionId: newIngestion.data.id,
      onUpdate(event) {
        setUploadStatus((prevStatus) => ({
          ...prevStatus,
          ...updateEventToUploadStatus(event),
        }));
      },
    });

    const { bucket, key } = await upload.start();

    await ingestionApi.updateIngestion({
      ingestionId: newIngestion.data.id,
      ingestionUpdateRequest: {
        s3Bucket: bucket,
        s3Key: key,
        queued: true,
      },
    });

    return newLog;
  };

  const mutation = useDataStoreMutation({
    mutationFn,
    onSuccess(response) {
      queryClient.setQueryData(logKeys.detail(response.data.id), response);
    },
  });

  return { ...mutation, uploadStatus };
}

function updateEventToUploadStatus(
  updateEvent: UpdateEvent
): Partial<MultipartUploadStatus> | undefined {
  switch (updateEvent.type) {
    case "created":
      return { isUploading: true };
    case "progress":
      return { progress: updateEvent.percent };
    case "uploaded":
      return { progress: 1 };
    case "completed":
      return { isComplete: true };
    default:
      return undefined;
  }
}
