import React, { useEffect, useState } from "react";
import {
  Dashboard,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Pause,
  PlayArrow,
  Replay,
  Share,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Slider,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import invariant from "invariant";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { PlaySpeed, SquareWave } from "mdi-material-ui";
import { useSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard";
import { QueryRenderer } from "../../../components/QueryRenderer";
import { formatTimestamp } from "../../../utils";
import {
  useFormatPlaybackTimestamp,
  usePlaybackSettings,
  usePlaybackSource,
} from "../PlaybackProvider";
import { intervalToTimeRange } from "../hooks/utils";
import type { LayoutProfile } from "../panels";
import {
  loadLayout,
  useLayoutProfiles,
  usePanelLayoutContext,
} from "../panels";
import { usePlaybackTimer, usePlaybackTimerPause } from "../playback";
import type { SeekAction } from "../playbackReducer";
import {
  nextFrame,
  pause,
  play,
  previousFrame,
  restart,
  seek,
  setRange,
  tick,
} from "../playbackReducer";
import { useTaggedRecordTimestamps } from "../tags";
import type { TimeRange, TimestepValue } from "../types";
import { PlaybackSpeed, Timestep } from "../types";
import ProfileDialog from "./ProfileDialog";

const ControlsSection = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr auto 1fr",
  gridTemplateAreas: '"left center right"',
});

const LeftControls = styled("div")({
  gridArea: "left",
  display: "flex",
  alignItems: "center",
});

const CenterControls = styled("div")({
  gridArea: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const RightControls = styled("div")({
  gridArea: "right",
  display: "flex",
  justifyContent: "right",
  alignItems: "center",
});

function offsetMsForTimestep(timestep: TimestepValue) {
  return timestep === Timestep.Second ? 1_000 : 100;
}

export default function PlaybackController() {
  const layoutProfilesQuery = useLayoutProfiles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const playbackSettings = usePlaybackSettings();
  const playbackSource = usePlaybackSource();

  const panelLayout = usePanelLayoutContext();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const profilesMenuState = usePopupState({
    variant: "popover",
    popupId: "profiles-menu",
  });
  const speedMenuState = usePopupState({
    variant: "popover",
    popupId: "speed-menu",
  });
  const stepMenuStep = usePopupState({
    variant: "popover",
    popupId: "step-menu",
  });

  const { enqueueSnackbar } = useSnackbar();

  const offsetMs = offsetMsForTimestep(playbackSettings.timestep);

  const { dispatch } = playbackSource;

  const playbackTimer = usePlaybackTimer();

  const [isSeeking, setIsSeeking] = useState(false);
  usePlaybackTimerPause(isSeeking);

  useEffect(
    function managePlaybackTimer() {
      if (!playbackSource.isPlaying) {
        return;
      }

      playbackTimer.set({
        onTick() {
          dispatch(tick());
        },
        speed: playbackSettings.speed,
        timestep: playbackSettings.timestep,
      });

      return () => {
        playbackTimer.clear();
      };
    },
    [
      playbackSource.isPlaying,
      playbackTimer,
      dispatch,
      playbackSettings.speed,
      playbackSettings.timestep,
    ]
  );

  const taggedRecordTimestampsQuery = useTaggedRecordTimestamps();

  function handleLayoutSelect(layoutProfile: LayoutProfile) {
    return function onClick() {
      panelLayout.dispatch(loadLayout(layoutProfile.layout));

      profilesMenuState.close();
    };
  }

  function handleDialogOpen() {
    setDialogOpen(true);

    profilesMenuState.close();
  }

  const isAtStart =
    playbackSource.timestampMs === playbackSource.bounds?.startTimeMs;
  const isAtEnd =
    playbackSource.timestampMs === playbackSource.bounds?.endTimeMs;

  let playbackControl;
  if (playbackSource.isPlaying) {
    playbackControl = (
      <Tooltip title="Pause">
        <span>
          <IconButton
            disabled={playbackSource.inRangeMode}
            aria-label="Pause log playback"
            onClick={() => playbackSource.dispatch(pause())}
            size="large"
          >
            <Pause />
          </IconButton>
        </span>
      </Tooltip>
    );
  } else if (!playbackSource.isLoading && isAtEnd) {
    playbackControl = (
      <Tooltip title="Replay">
        <span>
          <IconButton
            disabled={playbackSource.inRangeMode}
            aria-label="Replay log from beginning"
            onClick={() => playbackSource.dispatch(restart())}
            size="large"
          >
            <Replay />
          </IconButton>
        </span>
      </Tooltip>
    );
  } else {
    playbackControl = (
      <Tooltip title="Play">
        <span>
          <IconButton
            disabled={playbackSource.isLoading || playbackSource.inRangeMode}
            aria-label="Start or resume log playback"
            onClick={() => playbackSource.dispatch(play())}
            size="large"
          >
            <PlayArrow />
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  function formatVerboseTimestamp(value: number) {
    return formatTimestamp(value, {
      precision: 1,
      relativeToMs: playbackSource.bounds?.startTimeMs,
      colonNotation: false,
      separateMilliseconds: true,
      verbose: true,
    });
  }

  let shareableLink: string = "";
  if (!playbackSource.isLoading) {
    const currentUrl = new URL(window.location.href);

    // Note: this keeps existing search parameters. Maybe we don't want
    // that but currently there's nothing important kept in there
    currentUrl.searchParams.set("t", playbackSource.timestampMs.toString());

    shareableLink = currentUrl.toString();
  }

  return (
    <Box
      sx={{
        mt: "auto",
        borderTop: 1,
        borderTopColor: "divider",
        p: 3,
        "& .MuiSkeleton-root": {
          display: "inline-block",
        },
      }}
    >
      <Box
        sx={{
          mx: 2,
          position: "relative",
        }}
      >
        <Slider
          sx={{
            "& :is(.MuiSlider-track, .MuiSlider-thumb)": {
              transition: "none",
            },
            "& .MuiSlider-thumb": {
              // Custom marks are positioned over the rest of the slider,
              // specifically the padding area so it doesn't steal click events
              // meant for the mark. However, the thumb still needs to appear
              // over the custom marks.
              // Since custom marks also use a z-index on hover to appear over
              // neighboring marks, the thumb's z-index must ensure it still
              // appears over hovered custom markers.
              zIndex: 2,
            },
          }}
          disabled={playbackSource.isLoading}
          getAriaLabel={(index) => {
            if (!playbackSource.inRangeMode) {
              return "playback time";
            } else if (index === 0) {
              return "extraction time range start";
            } else {
              return "extraction time range end";
            }
          }}
          getAriaValueText={(value) => {
            if (playbackSource.isLoading) {
              return "loading playback time";
            } else if (!playbackSource.inRangeMode) {
              return `${formatVerboseTimestamp(
                value
              )} of ${formatVerboseTimestamp(playbackSource.bounds.endTimeMs)}`;
            } else {
              return formatVerboseTimestamp(value);
            }
          }}
          valueLabelDisplay={playbackSource.inRangeMode ? "on" : "auto"}
          valueLabelFormat={formatPlaybackTimestamp}
          min={playbackSource.bounds?.startTimeMs ?? 0}
          max={playbackSource.bounds?.endTimeMs ?? 0}
          step={offsetMs}
          onChange={(e, value, thumbIndex) => {
            setIsSeeking(true);

            if (playbackSource.inRangeMode) {
              invariant(
                Array.isArray(value),
                "Expected a 2-tuple in range-select mode"
              );

              playbackSource.dispatch(
                setRange(intervalToTimeRange(value as [number, number]))
              );
              playbackSource.dispatch(seek(value[thumbIndex]));
            } else {
              invariant(
                typeof value === "number",
                "Expected a number in playback mode"
              );

              playbackSource.dispatch(seek(value));
            }
          }}
          onChangeCommitted={() => {
            setIsSeeking(false);
          }}
          value={
            playbackSource.inRangeMode
              ? [
                  playbackSource.range?.startTimeMs ?? 0,
                  playbackSource.range?.endTimeMs ?? 0,
                ]
              : playbackSource.timestampMs ?? 0
          }
        />
        {taggedRecordTimestampsQuery.isFetching && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              translate: "0 -75%",
              left: 0,
              width: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <CircularProgress size="1rem" />
            <Typography>Loading tags...</Typography>
          </Box>
        )}
        {!playbackSource.isLoading &&
          taggedRecordTimestampsQuery.isSuccess &&
          taggedRecordTimestampsQuery.data.map((timestampMs) => (
            <Mark
              key={timestampMs}
              timestampMs={timestampMs}
              playbackBounds={playbackSource.bounds}
              dispatch={dispatch}
            />
          ))}
      </Box>
      <ControlsSection>
        <LeftControls>
          <Tooltip title="Previous">
            <span>
              <IconButton
                disabled={
                  isAtStart ||
                  playbackSource.isLoading ||
                  playbackSource.inRangeMode
                }
                aria-label="Move backward to previous timestamp"
                onClick={() => playbackSource.dispatch(previousFrame())}
                size="large"
              >
                <SkipPrevious />
              </IconButton>
            </span>
          </Tooltip>
          {playbackControl}
          <Tooltip title="Next">
            <span>
              <IconButton
                disabled={
                  isAtEnd ||
                  playbackSource.isLoading ||
                  playbackSource.inRangeMode
                }
                aria-label="Move forward to next timestamp"
                onClick={() => playbackSource.dispatch(nextFrame())}
                size="large"
              >
                <SkipNext />
              </IconButton>
            </span>
          </Tooltip>
          <Box sx={{ "& pre": { m: 0, display: "inline" } }}>
            <pre>
              {playbackSource.isLoading
                ? "--"
                : formatPlaybackTimestamp(playbackSource.timestampMs)}
            </pre>
            {" / "}
            <pre>
              {playbackSource.isLoading
                ? "--"
                : formatPlaybackTimestamp(playbackSource.bounds.endTimeMs)}
            </pre>
          </Box>
        </LeftControls>
        {!playbackSource.isLoading && (
          <QueryRenderer
            query={taggedRecordTimestampsQuery}
            success={(timestamps) => {
              // Timestamps are assumed to be sorted in ascending order
              const previousTagTimestampMs = timestamps.findLast(
                (timestampMs) => timestampMs < playbackSource.timestampMs
              );
              const nextTagTimestampMs = timestamps.find(
                (timestampMs) => timestampMs > playbackSource.timestampMs
              );

              function makeTagSeekHandler(timestampMs: number | undefined) {
                return function handleTagSeek() {
                  if (timestampMs === undefined) {
                    return;
                  }

                  dispatch(seek(timestampMs));
                };
              }

              return (
                <CenterControls>
                  <Tooltip title="Previous tag">
                    <span>
                      <IconButton
                        size="large"
                        disabled={previousTagTimestampMs === undefined}
                        onClick={makeTagSeekHandler(previousTagTimestampMs)}
                      >
                        <KeyboardDoubleArrowLeft />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Next tag">
                    <span>
                      <IconButton
                        size="large"
                        disabled={nextTagTimestampMs === undefined}
                        onClick={makeTagSeekHandler(nextTagTimestampMs)}
                      >
                        <KeyboardDoubleArrowRight />
                      </IconButton>
                    </span>
                  </Tooltip>
                </CenterControls>
              );
            }}
          />
        )}
        <RightControls>
          <Box display="inline-block">
            <Tooltip title="Layout profiles">
              <span>
                <IconButton
                  disabled={
                    playbackSource.isLoading || !layoutProfilesQuery.isSuccess
                  }
                  size="large"
                  {...bindTrigger(profilesMenuState)}
                >
                  <Dashboard />
                </IconButton>
              </span>
            </Tooltip>
            <Menu {...bindMenu(profilesMenuState)}>
              {layoutProfilesQuery.data?.map((profile) => (
                <MenuItem
                  key={profile.name}
                  onClick={handleLayoutSelect(profile)}
                >
                  {profile.name}
                </MenuItem>
              ))}
              {(layoutProfilesQuery.data?.length ?? 0) > 0 && (
                <Divider component="li" sx={{ my: 1 }} />
              )}
              <MenuItem onClick={handleDialogOpen}>Manage Profiles...</MenuItem>
            </Menu>
            <ProfileDialog open={dialogOpen} setOpen={setDialogOpen} />
            <Tooltip title="Copy shareable link">
              <span>
                <CopyToClipboard
                  text={shareableLink}
                  onCopy={(_, result) => {
                    if (result) {
                      enqueueSnackbar("Link copied", {
                        variant: "success",
                      });
                    } else {
                      enqueueSnackbar("Unable to copy link", {
                        variant: "error",
                      });
                    }
                  }}
                >
                  <IconButton
                    disabled={playbackSource.isLoading}
                    aria-label="Copy shareable link"
                    size="large"
                  >
                    <Share />
                  </IconButton>
                </CopyToClipboard>
              </span>
            </Tooltip>
            <Tooltip title="Playback speed">
              <span>
                <IconButton
                  disabled={playbackSource.isLoading}
                  aria-label="Open playback speed menu"
                  size="large"
                  {...bindTrigger(speedMenuState)}
                >
                  <PlaySpeed />
                </IconButton>
              </span>
            </Tooltip>
            <Menu {...bindMenu(speedMenuState)}>
              <MenuItem
                selected={playbackSettings.speed === PlaybackSpeed.TimesOne}
                onClick={() =>
                  playbackSettings.setSpeed(PlaybackSpeed.TimesOne)
                }
              >
                1x
              </MenuItem>
              <MenuItem
                selected={playbackSettings.speed === PlaybackSpeed.TimesTwo}
                onClick={() =>
                  playbackSettings.setSpeed(PlaybackSpeed.TimesTwo)
                }
              >
                2x
              </MenuItem>
              <MenuItem
                selected={playbackSettings.speed === PlaybackSpeed.TimesFive}
                onClick={() =>
                  playbackSettings.setSpeed(PlaybackSpeed.TimesFive)
                }
              >
                5x
              </MenuItem>
              <MenuItem
                selected={playbackSettings.speed === PlaybackSpeed.TimesTen}
                onClick={() =>
                  playbackSettings.setSpeed(PlaybackSpeed.TimesTen)
                }
              >
                10x
              </MenuItem>
            </Menu>
            <Tooltip title="Timestep">
              <span>
                <IconButton
                  disabled={playbackSource.isLoading}
                  aria-label="Open timestep control menu"
                  size="large"
                  {...bindTrigger(stepMenuStep)}
                >
                  <SquareWave />
                </IconButton>
              </span>
            </Tooltip>
            <Menu {...bindMenu(stepMenuStep)}>
              <MenuItem
                selected={playbackSettings.timestep === Timestep.Second}
                onClick={() => playbackSettings.setTimestep(Timestep.Second)}
              >
                1 second
              </MenuItem>
              <MenuItem
                selected={playbackSettings.timestep === Timestep.Decisecond}
                onClick={() =>
                  playbackSettings.setTimestep(Timestep.Decisecond)
                }
              >
                0.1 seconds
              </MenuItem>
            </Menu>
          </Box>
        </RightControls>
      </ControlsSection>
    </Box>
  );
}

function Mark({
  timestampMs,
  playbackBounds,
  dispatch,
}: {
  timestampMs: number;
  playbackBounds: TimeRange;
  dispatch: (action: SeekAction) => void;
}) {
  const leftOffsetPercentage =
    ((timestampMs - playbackBounds.startTimeMs) /
      (playbackBounds.endTimeMs - playbackBounds.startTimeMs)) *
    100;

  function handleClick() {
    dispatch(seek(timestampMs));
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: `${leftOffsetPercentage}%`,
        translate: "-50% calc(-50% - 2px)",
        width: 4,
        height: 20,
        borderRadius: 2,
        bgcolor: "secondary.main",
        cursor: "pointer",
        // Grow slightly on hover and appear over top of any neighboring marks
        "&:hover": {
          zIndex: 1,
          scale: "1.1",
          transition: (theme) => theme.transitions.create(["scale"]),
        },
        // Increase area to help clicking and hovering
        "&::before": {
          content: '" "',
          position: "absolute",
          top: -6,
          bottom: -1,
          left: -2,
          right: -2,
          zIndex: -1,
        },
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: "50%",
          width: 10,
          height: 10,
          borderRadius: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "secondary.main",
          boxShadow: 1,
        }}
      />
    </Box>
  );
}
