/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface APIKeyUpdateRequest
 */
export interface APIKeyUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof APIKeyUpdateRequest
   */
  name?: string;
  /**
   *
   * @type {Date}
   * @memberof APIKeyUpdateRequest
   */
  deletedAt?: Date;
}

export function APIKeyUpdateRequestFromJSON(json: any): APIKeyUpdateRequest {
  return APIKeyUpdateRequestFromJSONTyped(json, false);
}

export function APIKeyUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): APIKeyUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    deletedAt: !exists(json, "deleted_at")
      ? undefined
      : new Date(json["deleted_at"]),
  };
}

export function APIKeyUpdateRequestToJSON(
  value?: APIKeyUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    deleted_at:
      value.deletedAt === undefined ? undefined : value.deletedAt.toISOString(),
  };
}
