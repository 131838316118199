import type { ElementOf } from "ts-essentials";
import { z } from "zod";
import {
  boolean,
  logFormat,
  name,
  optionalNumber,
  optionalText,
  uuid,
} from "../../../components/Form";
import {
  filterBoolean,
  filterDate,
  filterLogFormat,
  filterNumber,
  filterProcessStatus,
  filterText,
  filterUuid,
} from "../../../components/Table";
import type { Ingestion } from "../../../services/datastore";

export const listIngestionsSchema = z.object({
  name: filterText,
  nameLike: filterText,
  logId: filterUuid,
  status: filterProcessStatus,
  format: filterLogFormat,
  s3Bucket: filterText,
  s3Key: filterText,
  s3KeyPrefix: filterText,
  noteLike: filterText,
  startOffsetGte: filterNumber,
  startOffsetLte: filterNumber,
  startOffsetNull: filterBoolean,
  endOffsetGte: filterNumber,
  endOffsetLte: filterNumber,
  endOffsetNull: filterBoolean,
  sizeGte: filterNumber,
  sizeLte: filterNumber,
  sizeNull: filterBoolean,
  progressGte: filterNumber,
  progressLte: filterNumber,
  progressNull: filterBoolean,
  processing: filterBoolean,
  queued: filterBoolean,
  cancelled: filterBoolean,
  errored: filterBoolean,
  archived: filterBoolean,
  completed: filterBoolean,
  createdBy: filterUuid,
  createdAtGte: filterDate,
  createdAtLte: filterDate,
  updatedBy: filterUuid,
  updatedByNull: filterBoolean,
  updatedAtGte: filterDate,
  updatedAtLte: filterDate,
  updatedAtNull: filterBoolean,
  deletedBy: filterUuid,
  deletedByNull: filterBoolean,
  deletedAtGte: filterDate,
  deletedAtLte: filterDate,
  deletedAtNull: filterBoolean,
});

export type ListIngestionsFormValues = z.infer<typeof listIngestionsSchema>;

export const createIngestionSchema = z.object({
  name: name.nullable(),
  logId: uuid,
  s3Bucket: optionalText,
  s3Key: optionalText,
  startOffset: optionalNumber,
  endOffset: optionalNumber,
  note: optionalText,
  format: logFormat,
  queued: boolean,
});

export type CreateIngestionFormValues = z.infer<typeof createIngestionSchema>;

export const EDITABLE_INGESTION_FIELDS = [
  "name",
  "s3Bucket",
  "s3Key",
  "startOffset",
  "endOffset",
  "note",
  "format",
  "queued",
  "processing",
  "errored",
  "cancelled",
  "archived",
  "status",
  "size",
] as const;

export type EditIngestionFormValues = Pick<
  Ingestion,
  ElementOf<typeof EDITABLE_INGESTION_FIELDS>
>;

export const editIngestionSchema = z.object({
  name,
  s3Bucket: optionalText,
  s3Key: optionalText,
  size: optionalNumber,
  startOffset: optionalNumber,
  endOffset: optionalNumber,
  note: optionalText,
  format: logFormat,
  queued: boolean,
  processing: boolean,
  errored: boolean,
  cancelled: boolean,
  archived: boolean,
});
