import type { UseQueryResult } from "@tanstack/react-query";
import { find } from "lodash";
import type {
  CustomDataStore,
  DataStore,
  PlatformDataStore,
} from "../../../models";
import {
  CustomDataStoreOrigin,
  PlatformDataStoreOrigin,
} from "../../../models";
import type { UseLogrusQueryResult } from "../../../services/logrus";
import type { Maybe } from "../../../types";

export interface ProcessOriginParamParameters {
  originParam: Maybe<string>;
  platformDataStoresQuery: UseLogrusQueryResult<PlatformDataStore[]>;
  customDataStoresQuery: UseQueryResult<CustomDataStore[]>;
}

export type ProcessOriginParamResult =
  | { status: "invalid:malformed"; value: string }
  | { status: "loading" }
  | { status: "loaded:no-connection" }
  | { status: "loaded:connection-found"; dataStore: DataStore }
  | { status: "unknown:platform"; origin: string }
  | { status: "unknown:custom"; origin: string };

export function processOriginParam({
  originParam,
  platformDataStoresQuery,
  customDataStoresQuery,
}: ProcessOriginParamParameters): ProcessOriginParamResult {
  // Would accept the following query strings:
  // 1. "?" (or nothing at all)
  // 2. "?url"
  // 3. "?url="
  if (!originParam) {
    return { status: "loaded:no-connection" };
  }

  const platformParseResult = PlatformDataStoreOrigin.safeParse(originParam);
  if (platformParseResult.success) {
    const { data: origin } = platformParseResult;

    if (!platformDataStoresQuery.canRun) {
      return { status: "unknown:platform", origin };
    }

    if (platformDataStoresQuery.isLoading) {
      return { status: "loading" };
    }

    const { data: platformDataStores = [] } = platformDataStoresQuery;

    const dataStore = find(platformDataStores, { origin });

    if (dataStore === undefined) {
      return { status: "unknown:platform", origin };
    }

    return { status: "loaded:connection-found", dataStore };
  }

  const customParseResult = CustomDataStoreOrigin.safeParse(originParam);
  if (customParseResult.success) {
    const { data: origin } = customParseResult;

    if (customDataStoresQuery.isLoading) {
      return { status: "loading" };
    }

    const { data: customDataStores = [] } = customDataStoresQuery;

    const dataStore = find(customDataStores, { origin });

    if (dataStore === undefined) {
      return { status: "unknown:custom", origin };
    }

    return { status: "loaded:connection-found", dataStore };
  }

  return { status: "invalid:malformed", value: originParam };
}
