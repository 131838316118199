import React, { useState } from "react";
import {
  Add,
  NoPhotography,
  Rotate90DegreesCcw,
  Rotate90DegreesCw,
  SettingsApplications,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItemButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { minutesToMilliseconds, secondsToMilliseconds } from "date-fns";
import invariant from "invariant";
import { range } from "lodash";
import { TimerSand } from "mdi-material-ui";
import { useSnackbar } from "notistack";
import Center from "../../../../components/Center";
import Error from "../../../../components/Error";
import Loading from "../../../../components/Loading";
import { useUploadPreviewImage } from "../../../../domain/crud";
import { useBlobSource } from "../../../../hooks";
import type { Log, Topic } from "../../../../services/datastore";
import type { Maybe } from "../../../../types";
import { usePlaybackSettings, usePlaybackSource } from "../../PlaybackProvider";
import {
  useCurrentWindowRecord,
  useFirstTopicRecord,
  useLogThumbnails,
  usePlayerConfig,
  useRecordWindow,
  useUpdatePanelBuffering,
} from "../../hooks";
import type { UseRecordWindowOptions } from "../../hooks/useRecordWindow";
import type { InitializedPanelNode } from "../../panels";
import {
  rotateImage,
  RotationDirection,
  toggleImageControls,
  usePanelLayoutContext,
} from "../../panels";
import { seek } from "../../playbackReducer";
import { contextTagsSchema } from "../../schemas";
import { useTagActions, useTagSelection } from "../../tags";
import type { TimestepValue } from "../../types";
import { SampleFrequency, Timestep } from "../../types";
import PanelHeader from "../PanelHeader";
import PanelLayout from "../PanelLayout";
import PlaceholderOverlay from "../PlaceholderOverlay";

interface ImageVisualizationProps {
  panel: InitializedPanelNode;
  topic: Topic;
}

const useRecordWindowOptionsMap = new Map<
  TimestepValue,
  Pick<
    UseRecordWindowOptions,
    | "sampleFrequency"
    | "bufferBehindMs"
    | "bufferAheadMs"
    | "windowSizeMs"
    | "chunkSizeMs"
  >
>([
  [
    Timestep.Second,
    {
      sampleFrequency: SampleFrequency.Second,
      // 1 chunk behind
      bufferBehindMs: secondsToMilliseconds(30),
      // 7 chunks ahead
      bufferAheadMs: minutesToMilliseconds(3.5),
      windowSizeMs: secondsToMilliseconds(30),
      // One chunk corresponds to a max of 30 images
      chunkSizeMs: secondsToMilliseconds(30),
    },
  ],
  [
    Timestep.Decisecond,
    {
      sampleFrequency: SampleFrequency.Decisecond,
      // 1 chunk behind
      bufferBehindMs: secondsToMilliseconds(3),
      // 7 chunks ahead
      bufferAheadMs: secondsToMilliseconds(21),
      windowSizeMs: secondsToMilliseconds(3),
      // One chunk corresponds to a max of 30 images
      chunkSizeMs: secondsToMilliseconds(3),
    },
  ],
]);

export default function ImageVisualization({
  panel,
  topic,
}: ImageVisualizationProps) {
  const { dispatch } = usePanelLayoutContext();

  const [showThumbnailDialog, setShowThumbnailDialog] = useState(false);
  const { logId } = usePlayerConfig();

  const topicTagsParseResult = contextTagsSchema.safeParse(topic.context);
  const tagSelection = useTagSelection();
  const { selectTag, clearSelectedTag } = useTagActions();

  function handleSelectedTagChange(_: unknown, newValue: string | null) {
    if (newValue === null) {
      clearSelectedTag();
    } else {
      selectTag({ tag: newValue, topicId: topic.id });
    }
  }

  const playbackSettings = usePlaybackSettings();
  const playbackSource = usePlaybackSource();

  const firstMessageQuery = useFirstTopicRecord(topic.id);

  const windowQuery = useRecordWindow({
    topicId: topic.id,
    includeImage: true,
    ...useRecordWindowOptionsMap.get(playbackSettings.timestep)!,
  });
  useUpdatePanelBuffering(
    windowQuery.status === "idle" || windowQuery.status === "loading"
  );
  const currentImg = useCurrentWindowRecord(
    windowQuery,
    playbackSettings.timestep === Timestep.Second ? 2_000 : 200
  );
  const currentImgRef = useBlobSource(currentImg?.image);

  function handleTogglePanelActions() {
    dispatch(toggleImageControls(panel.id));
  }

  function handleRotate(direction: RotationDirection) {
    return function onRotate() {
      dispatch(rotateImage(panel.id, direction));
    };
  }

  function handleOpenThumbnailDialog() {
    setShowThumbnailDialog(true);
  }

  let rootContent;
  if (windowQuery.data === undefined || playbackSource.isLoading) {
    rootContent = <Loading type="circular" />;
  } else if (windowQuery.status === "error") {
    // TODO: Do something else if record window has error and placeholder data?
    rootContent = (
      <Error>
        <Typography variant="h5" component="p" color="error">
          An error occurred. Unable to get images
        </Typography>
      </Error>
    );
  } else {
    const firstMessage = firstMessageQuery.data;

    let imageContent;
    if (
      firstMessage !== undefined &&
      playbackSource.timestampMs < firstMessage.timestampMs
    ) {
      imageContent = (
        <Center>
          <TimerSand fontSize="large" />
          <Typography variant="h5" component="p">
            No recent image
          </Typography>
          <Button
            color="primary"
            variant="outlined"
            onClick={() =>
              playbackSource.dispatch(seek(firstMessage.timestampMs))
            }
          >
            Skip to First Message
          </Button>
        </Center>
      );
    } else {
      if (currentImg === undefined) {
        imageContent = (
          <Center>
            <TimerSand fontSize="large" />
            <Typography variant="h5" component="p">
              No recent image
            </Typography>
          </Center>
        );
      } else {
        imageContent = (
          <>
            <img alt="" ref={currentImgRef} />
            {panel.showImageControls && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  height: 1,
                  right: 0,
                  width: "min(80%, 300px)",
                  p: 2,
                  bgcolor: (theme) =>
                    alpha(
                      theme.palette.mode === "light"
                        ? theme.palette.grey["200"]
                        : theme.palette.grey["500"],
                      0.5
                    ),
                  "@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)":
                    {
                      backdropFilter: "blur(6px)",
                    },
                }}
              >
                <Typography align="center" fontWeight={600} paragraph>
                  Panel Actions
                </Typography>
                <Typography fontWeight={600}>Rotate Image</Typography>
                <ButtonGroup
                  fullWidth
                  disableElevation
                  variant="contained"
                  color="primary"
                >
                  <Button
                    startIcon={<Rotate90DegreesCcw />}
                    onClick={handleRotate(RotationDirection.Left)}
                  >
                    Left
                  </Button>
                  <Button
                    startIcon={<Rotate90DegreesCw />}
                    onClick={handleRotate(RotationDirection.Right)}
                  >
                    Right
                  </Button>
                </ButtonGroup>
                <Divider sx={{ my: 2 }} />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={currentImg === undefined}
                  onClick={handleOpenThumbnailDialog}
                >
                  Set as Thumbnail...
                </Button>
                <Divider sx={{ my: 2 }} />
                <Autocomplete
                  disabled={!topicTagsParseResult.success}
                  value={tagSelection?.tag ?? null}
                  onChange={handleSelectedTagChange}
                  options={
                    topicTagsParseResult.success
                      ? topicTagsParseResult.data
                      : []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tags"
                      error={!topicTagsParseResult.success}
                      helperText={
                        !topicTagsParseResult.success
                          ? "Error loading tags"
                          : " "
                      }
                    />
                  )}
                />
              </Box>
            )}
            <PlaceholderOverlay windowQuery={windowQuery} />
          </>
        );
      }
    }

    rootContent = (
      <Box
        sx={{
          height: 1,
          width: 1,
          overflow: "hidden",
          position: "relative",
          "& img": {
            height: "inherit",
            width: "inherit",
            objectFit: "contain",
            transform: `rotate(${panel.imageRotationDeg}deg)`,
          },
        }}
      >
        {imageContent}
      </Box>
    );
  }

  return (
    <>
      <PanelLayout
        header={
          <PanelHeader
            actions={
              <Tooltip title="Panel actions">
                <IconButton size="small" onClick={handleTogglePanelActions}>
                  <SettingsApplications />
                </IconButton>
              </Tooltip>
            }
          />
        }
      >
        {rootContent}
      </PanelLayout>
      <ThumbnailDialog
        open={showThumbnailDialog}
        setOpen={setShowThumbnailDialog}
        // TODO: Don't use non-null assertion
        logId={logId!}
        src={currentImg?.image}
      />
    </>
  );
}

interface ThumbnailDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  logId: Log["id"];
  src: Maybe<Blob>;
}

const NUM_THUMBNAILS = 3;

function ThumbnailDialog({ open, setOpen, logId, src }: ThumbnailDialogProps) {
  const [selectedSlot, setSelectedSlot] = useState<number | null>(null);
  const uploadPreviewImage = useUploadPreviewImage(logId);

  const thumbnailRef = useBlobSource(src);

  const effectiveOpen = open && src != null;

  const thumbnailsQuery = useLogThumbnails();
  const { enqueueSnackbar } = useSnackbar();

  function handleClose() {
    if (uploadPreviewImage.isLoading) {
      return;
    }

    setOpen(false);
  }

  function handleUpload() {
    invariant(src != null, "No image source to upload");
    invariant(selectedSlot !== null, "No slot selected");
    invariant(
      thumbnailsQuery.isError || thumbnailsQuery.isSuccess,
      "Thumbnails not loaded yet"
    );

    uploadPreviewImage.mutate(
      { image: src, index: selectedSlot },
      {
        onSuccess() {
          enqueueSnackbar("Thumbnail uploaded", { variant: "success" });
          setSelectedSlot(null);
        },
        onError() {
          enqueueSnackbar("Couldn't upload thumbnail", { variant: "error" });
        },
      }
    );
  }

  return (
    <Dialog
      aria-labelledby="thumbnail-dialog-title"
      fullWidth
      maxWidth="md"
      open={effectiveOpen}
      onClose={handleClose}
    >
      <DialogTitle id="thumbnail-dialog-title">
        Set Image as Thumbnail
      </DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            "& img": {
              my: 1,
              mx: "auto",
              maxWidth: 1,
              objectFit: "contain",
            },
          }}
        >
          <Typography variant="h6" component="h3" gutterBottom>
            New Thumbnail
          </Typography>
          <img ref={thumbnailRef} alt="" height={300} width="auto" />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography variant="h6" component="h3" gutterBottom>
            Select Thumbnail Slot
          </Typography>
          <List
            sx={{
              display: "flex",
              flexDirection: "row",
              width: 1,
              overflowX: "auto",
              "& .MuiListItemButton-root": {
                flex: "1 0 250px",
                flexDirection: "column",
              },
              "& .thumbnail-container": {
                height: 200,
                width: 1,
                "& img": {
                  height: 1,
                  width: 1,
                  objectFit: "contain",
                },
              },
            }}
          >
            {range(NUM_THUMBNAILS).map((index) => (
              <ListItemButton
                key={index}
                selected={selectedSlot === index}
                onClick={() => setSelectedSlot(index)}
                disabled={thumbnailsQuery.isLoading}
              >
                <Box className="thumbnail-container">
                  <ThumbnailImage index={index} />
                </Box>
                <Typography variant="h6" component="p" align="center">
                  {index + 1}
                </Typography>
              </ListItemButton>
            ))}
          </List>
        </Box>
        {selectedSlot !== null &&
          thumbnailsQuery.data?.[selectedSlot].blob !== null && (
            <Typography>
              Setting the thumbnail in this slot will overwrite the existing
              thumbnail.
            </Typography>
          )}
        {selectedSlot !== null &&
          thumbnailsQuery.data?.[selectedSlot].success === false && (
            <Typography>
              There is a thumbnail in this slot but it couldn't be loaded. It
              will be overwritten.
            </Typography>
          )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          sx={{ my: 1 }}
          color="primary"
          variant="contained"
          loading={uploadPreviewImage.isLoading}
          disabled={selectedSlot === null}
          onClick={handleUpload}
        >
          Set as Thumbnail
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

interface ThumbnailImageProps {
  index: number;
}

function ThumbnailImage({ index }: ThumbnailImageProps) {
  const thumbnailsQuery = useLogThumbnails();
  const thumbnail = thumbnailsQuery.data?.[index];
  const imgRef = useBlobSource(thumbnail?.blob);

  if (thumbnailsQuery.isLoading) {
    return <Skeleton variant="rectangular" sx={{ height: 1, width: 1 }} />;
  }

  if (thumbnailsQuery.isError || thumbnail?.success === false) {
    return (
      <Box
        height={1}
        width={1}
        bgcolor="grey.800"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <NoPhotography fontSize="large" color="error" />
        <Typography>Couldn't load thumbnail</Typography>
      </Box>
    );
  }

  if (thumbnail?.success && thumbnail.blob === null) {
    return (
      <Box
        height={1}
        width={1}
        bgcolor="grey.800"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Add fontSize="large" />
        <Typography>Empty slot</Typography>
      </Box>
    );
  }

  return <img alt="" ref={imgRef} />;
}
