import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  IngestionSelect,
  LogSelect,
  omitNullFields,
  TextField,
} from "../../../components/Form";
import { useStudioForm } from "../../../components/Form/hooks";
import { useCreateTopic } from "../../../domain/crud";
import { makeTopicLocation, useStudioNavigate } from "../../../paths";
import { createTopicSchema } from "./validation";

export default function TopicForm() {
  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const createTopic = useCreateTopic();

  const { control, handleSubmit } = useStudioForm({
    schema: createTopicSchema,
    defaultValues: {
      name: null,
      logId: null,
      ingestionId: null,
    },
    onSubmit(values) {
      createTopic.mutate(omitNullFields(values), {
        onSuccess(response) {
          navigate(makeTopicLocation(response.data.id));
        },
        onError() {
          enqueueSnackbar("Unable to create topic", { variant: "error" });
        },
      });
    },
  });

  return (
    <Card>
      <CardContent>
        <Grid component="form" container onSubmit={handleSubmit} spacing={2}>
          <Grid item xs={3}>
            <TextField control={control} name="name" />
          </Grid>
          <Grid item xs={3}>
            <LogSelect control={control} name="logId" label="Log ID" />
          </Grid>
          <Grid item xs={3}>
            <IngestionSelect
              control={control}
              name="ingestionId"
              label="Ingestion ID"
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={createTopic.isLoading}
              type="submit"
              variant="contained"
            >
              Create
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
