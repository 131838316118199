import { MenuItem, TextField } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import type { BaseFieldProps, Option } from "./types";

export interface SelectFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string | null>
> extends BaseFieldProps<TFieldValues, TName> {
  options: Option[];
}

export function SelectField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string | null>
>({ control, name, label, options }: SelectFieldProps<TFieldValues, TName>) {
  const {
    field: { value, onChange, ...field },
    fieldState,
  } = useController({ control, name });

  return (
    <TextField
      select
      fullWidth
      {...field}
      label={label}
      error={fieldState.error !== undefined}
      helperText={fieldState.error?.message ?? " "}
      value={value ?? ""}
      onChange={(e) => {
        const rawValue = e.target.value;

        if (rawValue === "") {
          onChange(null);
        } else {
          onChange(rawValue);
        }
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
