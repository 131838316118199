import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { pick } from "lodash";
import { useSnackbar } from "notistack";
import {
  CheckboxField,
  getChangedFields,
  TextField,
} from "../../../components/Form";
import { useStudioForm } from "../../../components/Form/hooks";
import { useUpdateUser } from "../../../domain/crud";
import {
  makeUserLocation,
  useStudioNavigate,
  useUserParams,
} from "../../../paths";
import type { User } from "../../../services/datastore";
import { EDITABLE_FIELDS, editUserSchema } from "./validation";

interface UserFormProps {
  user: User;
}

export default function UserForm({ user }: UserFormProps) {
  const { userId } = useUserParams();

  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const updateUser = useUpdateUser(userId);

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useStudioForm({
    schema: editUserSchema,
    defaultValues: pick(user, EDITABLE_FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateUser.mutate(changedFields, {
        onSuccess() {
          enqueueSnackbar("User updated", { variant: "success" });

          navigate(makeUserLocation(userId));
        },
        onError() {
          enqueueSnackbar("Failed to update User", { variant: "error" });
        },
      });
    },
  });

  return (
    <Card>
      <CardContent>
        <Grid
          alignItems="center"
          component="form"
          name="editUser"
          container
          onSubmit={handleSubmit}
          spacing={2}
        >
          <Grid item xs={4}>
            <TextField control={control} name="username" />
          </Grid>
          <Grid item xs={3}>
            <CheckboxField control={control} name="isAdmin" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={updateUser.isLoading}
              type="submit"
              variant="contained"
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
