import { z } from "zod";
import { useLocalStorage } from "../../hooks";
import { getFriendlyResourceKey, toggleArrayElement } from "../../utils";
import { makeCellRenderer } from "./cells";
import type { AccessorColumn, Column, InternalColumn } from "./types";

const VISIBLE_COLUMNS_STORAGE_KEY_PREFIX = "column-visibility";
const visibleColumnsSchema = z.array(z.string());

export function useColumnVisibility<TResource>(
  key: string,
  columns: ReadonlyArray<InternalColumn<TResource>>
) {
  const [visibleColumnsHeaders, setVisibleColumnsHeaders] = useLocalStorage(
    `${VISIBLE_COLUMNS_STORAGE_KEY_PREFIX}.${key}`,
    columns.flatMap((column) => {
      if (column.defaultHidden) {
        return [];
      } else {
        return [column.header];
      }
    }),
    (value) => visibleColumnsSchema.parse(value)
  );

  return {
    visibleColumns: columns.filter(({ header }) =>
      visibleColumnsHeaders.includes(header)
    ),
    toggleColumnVisibility(column: InternalColumn<any>) {
      setVisibleColumnsHeaders(
        toggleArrayElement(visibleColumnsHeaders, column.header)
      );
    },
  };
}

/**
 * Return a list of sort keys or accessors from sortable columns. Useful to
 * derive a list of allowable columns for validation.
 */
export function getSortableFields<TResource>(
  columns: ReadonlyArray<Column<TResource>>
): Array<string & keyof TResource> {
  // Equivalent to filtering and mapping
  return columns.flatMap((column) => {
    const sortKey = getColumnSortKey(column);

    return sortKey !== undefined ? [sortKey] : [];
  });
}

export function makeInternalColumn<TResource>(
  column: Column<TResource>
): InternalColumn<TResource> {
  const { defaultHidden = false } = column;

  const sortKey = getColumnSortKey(column);

  if (isAccessorColumn(column)) {
    const align = getColumnAlignment(column);

    return {
      align,
      defaultHidden,
      header: column.header ?? getFriendlyResourceKey(column.accessor),
      sortKey,
      renderCell: makeCellRenderer(column),
    };
  } else {
    return {
      align: column.align,
      defaultHidden,
      header: column.header,
      sortKey,
      renderCell: column.renderCell,
    };
  }
}

function getColumnAlignment(
  column: AccessorColumn<any>
): InternalColumn<any>["align"] {
  if (column.align !== undefined) {
    return column.align;
  } else if (column.dataType === "number" || column.dataType === "bytes") {
    return "right";
  } else {
    return undefined;
  }
}

/**
 * Return the sorting key for the given column if one is available.
 *
 * If the column has a `sortKey` field, it is returned. If the column has
 * an `accessor` field, it is returned only if `isSortable === true`. Otherwise,
 * the column is not considered to have a sorting key.
 */
function getColumnSortKey<TResource>(
  column: Column<TResource>
): (string & keyof TResource) | undefined {
  if (isAccessorColumn(column) && column.isSortable) {
    return column.accessor;
  } else if ("sortKey" in column && column.sortKey !== undefined) {
    return column.sortKey;
  } else {
    return undefined;
  }
}

function isAccessorColumn<TResource>(
  column: Column<TResource>
): column is AccessorColumn<TResource> {
  return "accessor" in column;
}
