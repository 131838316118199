import React from "react";
import { MenuItem, TextField } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import type { BaseFieldProps } from "./types";

export interface BooleanFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, boolean | null>
> extends BaseFieldProps<TFieldValues, TName> {
  label: string;
}

export function BooleanField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, boolean | null>
>({ control, name, label }: BooleanFieldProps<TFieldValues, TName>) {
  const {
    field: { value, onChange },
    fieldState,
  } = useController({ control, name });

  const stringifiedValue = value === null ? "" : String(value);

  return (
    <TextField
      select
      fullWidth
      label={label}
      value={stringifiedValue}
      onChange={(e) => {
        const rawValue = e.target.value;

        onChange(rawValue === "" ? null : rawValue === "true");
      }}
      error={fieldState.error !== undefined}
      helperText={fieldState.error?.message ?? " "}
    >
      <MenuItem value="">Either</MenuItem>
      <MenuItem value="true">True</MenuItem>
      <MenuItem value="false">False</MenuItem>
    </TextField>
  );
}
