import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { pick } from "lodash";
import { useSnackbar } from "notistack";
import { getChangedFields, NumberField } from "../../../components/Form";
import { useStudioForm } from "../../../components/Form/hooks";
import { useUpdateExtractionTopic } from "../../../domain/crud";
import {
  makeExtractionTopicLocation,
  useExtractionTopicParams,
  useStudioNavigate,
} from "../../../paths";
import type { ExtractionTopic } from "../../../services/datastore";
import {
  EDITABLE_EXTRACTION_TOPIC_FIELDS,
  editExtractionTopicSchema,
} from "./validation";

export interface TopicEditFormProps {
  extractionTopic: ExtractionTopic;
}

export function TopicEditForm({ extractionTopic }: TopicEditFormProps) {
  const { extractionId, topicId } = useExtractionTopicParams();

  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const updateExtractionTopic = useUpdateExtractionTopic(extractionId, topicId);

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useStudioForm({
    schema: editExtractionTopicSchema,
    defaultValues: pick(extractionTopic, EDITABLE_EXTRACTION_TOPIC_FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      // @ts-ignore OpenAPI issue with optional vs. nullable
      updateExtractionTopic.mutate(changedFields, {
        onSuccess() {
          enqueueSnackbar("Extraction topic updated", { variant: "success" });

          navigate(makeExtractionTopicLocation(extractionId, topicId));
        },
        onError() {
          enqueueSnackbar("Could not update extraction topic", {
            variant: "error",
          });
        },
      });
    },
  });

  return (
    <Card>
      <CardContent>
        <Grid component="form" container onSubmit={handleSubmit} spacing={2}>
          <Grid item xs={6}>
            <NumberField
              control={control}
              name="startTime"
              label="Start time"
            />
          </Grid>
          <Grid item xs={6}>
            <NumberField control={control} name="endTime" label="End time" />
          </Grid>
          <Grid item xs={6}>
            <NumberField control={control} name="frequency" label="Frequency" />
          </Grid>
          <Grid item xs={6}>
            <NumberField control={control} name="size" label="Size" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={updateExtractionTopic.isLoading}
              type="submit"
              variant="contained"
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
