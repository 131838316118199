import { omitBy } from "lodash";
import { z } from "zod";
import { LogFormat, ProcessStatus, UserRole } from "../../services/datastore";
import { createErrorMap } from "./utils";

// Create a new type where any nullable field is made non-nullable but optional.
// Fields that cannot hold a `null` value are left as-is
type PartialNullFields<TValues extends object> = {
  [Key in keyof TValues]: null extends TValues[Key]
    ? NonNullable<TValues[Key]> | undefined
    : TValues[Key];
};

export const boolean = z.boolean({
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (issue.received === z.ZodParsedType.null) {
        return "Field is required";
      } else {
        return "Unexpected value";
      }
    },
  }),
});

export const requiredText = z.string({
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (issue.received === z.ZodParsedType.null) {
        return "Field is required";
      } else {
        return "Expected text";
      }
    },
  }),
});

export const optionalText = requiredText.nullable();

export const optionalNumber = z
  .number({
    errorMap: createErrorMap({
      invalid_type: "Expected a number",
      not_finite: "Expected a number",
    }),
  })
  .finite()
  .nullable();

export const name = z.string({
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (issue.received === z.ZodParsedType.null) {
        return "Field is required";
      }
    },
  }),
});

export const uuid = z
  .string({
    errorMap: createErrorMap({
      invalid_type(issue) {
        if (issue.received === z.ZodParsedType.null) {
          return "Field is required";
        } else {
          return "Unexpected input";
        }
      },
      invalid_string(issue) {
        if (issue.validation === "uuid") {
          return "Must be a UUID";
        }
      },
    }),
  })
  .uuid();

export const logFormat = z.nativeEnum(LogFormat, {
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (issue.received === z.ZodParsedType.null) {
        return "Field is required";
      } else {
        return "Unexpected value";
      }
    },
    invalid_enum_value: "Unexpected value",
  }),
});

export const processStatus = z.nativeEnum(ProcessStatus, {
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (issue.received === z.ZodParsedType.null) {
        return "Field is required";
      } else {
        return "Unexpected value";
      }
    },
    invalid_enum_value: "Unexpected value",
  }),
});

export const userRole = z.nativeEnum(UserRole, {
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (issue.received === z.ZodParsedType.null) {
        return "Field is required";
      } else {
        return "Unexpected value";
      }
    },
    invalid_enum_value: "Unexpected value",
  }),
});

// Helper for SDK operations where empty fields are optional but not nullable
export function omitNullFields<TValues extends object>(
  values: TValues
): PartialNullFields<TValues> {
  return omitBy(
    values,
    (value) => value === null
  ) as PartialNullFields<TValues>;
}
