import { Stack, styled } from "@mui/material";

// TS autocomplete doesn't work correctly if just doing
// `export default styled(...) as ...`
const Center = styled(Stack)({
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
}) as typeof Stack;

export default Center;
