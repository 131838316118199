import type { ChipProps } from "@mui/material";
import { Chip } from "@mui/material";
import prettyMilliseconds from "pretty-ms";
import Time from "../../../components/Time";
import type { Log } from "../../../services/datastore";
import { sToMs } from "../../../utils";
import type { DerivedIngestionStatus } from "../../../utils/logs";
import {
  calculateLogBoundsInMs,
  deriveIngestionStatus,
} from "../../../utils/logs";

export function renderRecorded(log: Log) {
  return log.startTime === null ? (
    "-"
  ) : (
    <Time date={new Date(sToMs(log.startTime))} />
  );
}

export function renderDuration(log: Log) {
  const { startTimeMs, endTimeMs } = calculateLogBoundsInMs(log);

  return startTimeMs === null || endTimeMs === null
    ? "-"
    : prettyMilliseconds(endTimeMs - startTimeMs);
}

export function renderIngestionStatusChip(log: Log) {
  const chipPropsMap = new Map<DerivedIngestionStatus, ChipProps>([
    ["empty", { label: "No Ingestions", color: "default" }],
    ["error", { label: "Errored", color: "error" }],
    ["processing", { label: "Processing", color: "info" }],
    ["complete", { label: "Complete", color: "success" }],
    ["unknown", { label: "Unknown", color: "warning" }],
  ]);

  const derivedStatus = deriveIngestionStatus(log);

  return <Chip {...chipPropsMap.get(derivedStatus)!} />;
}

export type LogMarker = {
  id: Log["id"];
  name: Log["name"];
  coordinates: {
    longitude: number;
    latitude: number;
  };
};

export function calculateLogMarkers(logs: Log[]): LogMarker[] {
  return logs
    .map((log): LogMarker | null => {
      if (
        log.context === null ||
        !("sample_coordinates" in log.context) ||
        log.context.sample_coordinates == null
      ) {
        return null;
      }

      const { name, longitude, latitude } = log.context
        .sample_coordinates as any;

      return {
        id: log.id,
        name: name ?? log.name,
        coordinates: {
          longitude,
          latitude,
        },
      };
    })
    .filter((value): value is LogMarker => value !== null);
}
