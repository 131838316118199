import type { UseQueryOptions } from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { find, some, without } from "lodash";
import type { StrictOmit } from "ts-essentials";
import type { DataStore } from "../../../models";
import { CustomDataStore, CustomDataStoreOrigin } from "../../../models";
import { useOriginParam } from "../providers/hooks";
import { queryKeyFactory } from "./common";
import { CUSTOM_DATASTORES_STORAGE_KEY } from "./constants";

export interface CustomDataStoresList {
  data: CustomDataStore[];
}

export type UseCreateCustomDataStoreRequest = Pick<
  CustomDataStore,
  "name" | "origin"
>;

export function useCustomDataStores<TData = CustomDataStoresList>(
  options?: StrictOmit<
    UseQueryOptions<
      CustomDataStoresList,
      unknown,
      TData,
      ReturnType<typeof queryKeyFactory.custom.list>
    >,
    "networkMode" | "initialData"
  >
) {
  return useQuery(
    queryKeyFactory.custom.list(),
    () => Promise.resolve({ data: readFromStorage() }),
    {
      ...options,
      networkMode: "always",
      initialData() {
        // Will ensure query is always initialized in the "success" state.
        // Eventually the actual query function may reach out to a back-end
        // and local storage will just be used for quick local access.
        return { data: readFromStorage() };
      },
    }
  );
}

export function useCreateCustomDataStore() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ name, origin }: UseCreateCustomDataStoreRequest) => {
      const customDataStores = readFromStorage();

      if (some(customDataStores, { origin })) {
        throw new Error(
          `Connection already exists for DataStore with origin "${origin}"`
        );
      }

      const newDataStoreShape: CustomDataStore = {
        name,
        origin,
        type: "custom",
      };

      // Last line to ensure all schema constraints are met
      const newDataStore = CustomDataStore.parse(newDataStoreShape);

      writeToStorage([...customDataStores, newDataStore]);

      return newDataStore;
    },
    {
      onSuccess() {
        return queryClient.invalidateQueries(queryKeyFactory.custom.lists());
      },
    }
  );
}

export function useDeleteCustomDataStore() {
  const [, setOriginParam] = useOriginParam();

  const queryClient = useQueryClient();

  return useMutation(
    async (origin: DataStore["origin"]) => {
      const customDataStores = readFromStorage();

      const dataStoreToDelete = find(customDataStores, { origin });

      if (dataStoreToDelete === undefined) {
        throw new Error(
          `No connection exists for DataStore with origin "${origin}"`
        );
      }

      writeToStorage(without(customDataStores, dataStoreToDelete));
    },
    {
      onSuccess(_, origin) {
        setOriginParam((currentOriginParam) => {
          // Don't need to check if the current origin param is a Platform:
          // it's guaranteed not to equal the deleted connection's origin
          const paramParseResult =
            CustomDataStoreOrigin.safeParse(currentOriginParam);

          if (paramParseResult.success && paramParseResult.data === origin) {
            // The origin of the custom connection just deleted corresponds to
            // the current origin query param meaning the user deleted the
            // active connection. Return `null` here to clear that query param.
            return null;
          }

          // Return the existing param to bail out of the state update
          return currentOriginParam;
        });

        return queryClient.invalidateQueries(queryKeyFactory.custom.lists());
      },
    }
  );
}

const CustomDataStoreArray = CustomDataStore.array();

function readFromStorage(): CustomDataStore[] {
  try {
    const storedValue = localStorage.getItem(CUSTOM_DATASTORES_STORAGE_KEY);

    if (storedValue === null) {
      return [];
    }

    return CustomDataStoreArray.parse(JSON.parse(storedValue));
  } catch {
    return [];
  }
}

function writeToStorage(dataStores: CustomDataStore[]): void {
  try {
    localStorage.setItem(
      CUSTOM_DATASTORES_STORAGE_KEY,
      JSON.stringify(dataStores)
    );
  } catch {
    /* do nothing */
  }
}
