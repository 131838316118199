import React from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { minutesToMilliseconds, secondsToMilliseconds } from "date-fns";
import invariant from "invariant";
import { TimerSand } from "mdi-material-ui";
import {
  CartesianGrid,
  Cell,
  ReferenceLine,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import type { ElementOf } from "ts-essentials";
import useResizeObserver from "use-resize-observer";
import Center from "../../../../components/Center";
import Error from "../../../../components/Error";
import JsonTree from "../../../../components/JsonTree";
import Loading from "../../../../components/Loading";
import type { Topic } from "../../../../services/datastore";
import {
  useFormatPlaybackTimestamp,
  usePlaybackSource,
} from "../../PlaybackProvider";
import {
  useCurrentWindowRecord,
  useFirstTopicRecord,
  usePlayerLog,
  useRecordWindow,
  useUpdatePanelBuffering,
} from "../../hooks";
import { timeRangeToInterval } from "../../hooks/utils";
import type { InitializedPanelNode } from "../../panels";
import {
  addChartField,
  chooseVisualization,
  MAX_CHART_FIELDS,
  removeChartField,
  usePanelLayoutContext,
} from "../../panels";
import { seek } from "../../playbackReducer";
import { SampleFrequency } from "../../types";
import PanelHeader from "../PanelHeader";
import PanelLayout from "../PanelLayout";
import PlaceholderOverlay from "../PlaceholderOverlay";
import { calculateWindowTicks } from "../utils";

interface TimelineVisualizationProps {
  panel: InitializedPanelNode;
  topic: Topic;
}

type Field = ElementOf<InitializedPanelNode["fields"]>;

export default function TimelineVisualization({
  panel,
  topic,
}: TimelineVisualizationProps) {
  const { dispatch } = usePanelLayoutContext();

  const theme = useTheme();

  const { ref, width } = useResizeObserver();

  const logQuery = usePlayerLog();

  const firstMessageQuery = useFirstTopicRecord(topic.id);

  const playbackSource = usePlaybackSource();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const windowQuery = useRecordWindow({
    topicId: topic.id,
    sampleFrequency: SampleFrequency.Decisecond,
    bufferBehindMs: secondsToMilliseconds(30),
    bufferAheadMs: minutesToMilliseconds(1.5),
    windowSizeMs: secondsToMilliseconds(30),
    chunkSizeMs: secondsToMilliseconds(10),
  });
  useUpdatePanelBuffering(
    windowQuery.status === "idle" || windowQuery.status === "loading"
  );
  const currentRecord = useCurrentWindowRecord(windowQuery);

  const { fields } = panel;

  function selectField(field: Field) {
    invariant(firstMessageQuery.data !== undefined, "No defined message");

    dispatch(
      addChartField(panel.id, field, firstMessageQuery.data.messageData)
    );
  }

  function removeField(field: Field) {
    dispatch(removeChartField(panel.id, field));
  }

  function handleGraph() {
    dispatch(chooseVisualization(panel.id, "chart"));
  }

  let content;
  if (
    logQuery.isLoading ||
    windowQuery.data === undefined ||
    playbackSource.isLoading
  ) {
    content = <Loading type="circular" />;
  } else if (logQuery.isError || windowQuery.status === "error") {
    // TODO: Do something else if record window has error and placeholder data?
    content = (
      <Error>
        <Typography variant="h5" component="p" color="error">
          An error occurred. Couldn't get timeline data
        </Typography>
      </Error>
    );
  } else {
    const { timestampMs, dispatch } = playbackSource;

    let body: React.ReactNode;
    if (currentRecord === undefined) {
      body = (
        <Center>
          <TimerSand fontSize="large" />
          <Typography variant="h5" component="p">
            No recent message
          </Typography>
          {firstMessageQuery.data !== undefined &&
            timestampMs < firstMessageQuery.data.timestampMs && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() =>
                  dispatch(seek(firstMessageQuery.data!.timestampMs))
                }
              >
                Skip to First Message
              </Button>
            )}
        </Center>
      );
    } else {
      let selection: React.ReactNode;
      if (fields.length > 0) {
        selection = (
          <Stack direction="row" spacing={1}>
            {fields.map((field) => (
              <Chip
                key={field}
                label={field}
                onDelete={() => removeField(field)}
              />
            ))}
          </Stack>
        );
      } else {
        selection = (
          <Typography paragraph>
            <i>No fields selected</i>
          </Typography>
        );
      }

      body = (
        <Box px={2}>
          <Box mb={2}>
            <Typography paragraph>
              {fields.length} / {MAX_CHART_FIELDS} fields selected
            </Typography>
            <Box mb={2}>{selection}</Box>
            <Button
              disabled={fields.length === 0}
              color="primary"
              variant="contained"
              onClick={handleGraph}
            >
              Graph Fields
            </Button>
          </Box>
          <JsonTree src={currentRecord.messageData} onSelect={selectField} />
        </Box>
      );
    }

    content = width !== undefined && (
      <Stack
        spacing={2}
        height={1}
        width={1}
        overflow="hidden"
        position="relative"
      >
        <ScatterChart
          height={75}
          width={width}
          data={windowQuery.data}
          // Hide the start and end grid lines that I can't seem to get rid of
          margin={{ left: -1, right: -1 }}
        >
          <CartesianGrid horizontal={false} strokeDasharray="6 3" />
          <XAxis
            dataKey="timestampMs"
            type="number"
            ticks={calculateWindowTicks(
              windowQuery.recordWindow,
              secondsToMilliseconds(5)
            )}
            tickLine={false}
            tickFormatter={formatPlaybackTimestamp}
            orientation="top"
            domain={timeRangeToInterval(windowQuery.recordWindow)}
          />
          <YAxis
            hide
            domain={[0, 1]}
            dataKey={(record) => (record === currentRecord ? 0.8 : 0.4)}
          />
          {/* This is apparently the way you have to change the point size */}
          <ZAxis range={[150, 150]} />
          <Scatter isAnimationActive={false}>
            {windowQuery.data.map((record) => (
              <Cell
                key={record.timestampMs}
                stroke="white"
                strokeWidth={1}
                fill={record === currentRecord ? "tomato" : "darkgrey"}
              />
            ))}
          </Scatter>
          <ReferenceLine
            x={playbackSource.timestampMs}
            strokeWidth={2}
            stroke={theme.palette.grey["600"]}
          />
        </ScatterChart>
        <Divider />
        <Box sx={{ flex: 1, minHeight: 0, overflowY: "auto" }}>{body}</Box>
        <PlaceholderOverlay windowQuery={windowQuery} />
      </Stack>
    );
  }

  return (
    <PanelLayout header={<PanelHeader />} contentRef={ref}>
      {content}
    </PanelLayout>
  );
}
