import type { ElementOf } from "ts-essentials";
import { z } from "zod";
import {
  boolean,
  name,
  optionalNumber,
  optionalText,
  processStatus,
  uuid,
} from "../../../components/Form";
import {
  filterBoolean,
  filterDate,
  filterLogFormat,
  filterNumber,
  filterProcessStatus,
  filterText,
  filterUuid,
} from "../../../components/Table";
import type { Extraction, ExtractionTopic } from "../../../services/datastore";

export const listExtractionsSchema = z.object({
  name: filterText,
  nameLike: filterText,
  logId: filterUuid,
  status: filterProcessStatus,
  format: filterLogFormat,
  s3Bucket: filterText,
  s3Key: filterText,
  s3KeyPrefix: filterText,
  noteLike: filterText,
  sizeGte: filterNumber,
  sizeLte: filterNumber,
  sizeNull: filterBoolean,
  progressGte: filterNumber,
  progressLte: filterNumber,
  progressNull: filterBoolean,
  processing: filterBoolean,
  queued: filterBoolean,
  cancelled: filterBoolean,
  errored: filterBoolean,
  archived: filterBoolean,
  completed: filterBoolean,
  createdBy: filterUuid,
  createdAtGte: filterDate,
  createdAtLte: filterDate,
  updatedBy: filterUuid,
  updatedByNull: filterBoolean,
  updatedAtGte: filterDate,
  updatedAtLte: filterDate,
  updatedAtNull: filterBoolean,
  deletedBy: filterUuid,
  deletedByNull: filterBoolean,
  deletedAtGte: filterDate,
  deletedAtLte: filterDate,
  deletedAtNull: filterBoolean,
});

export type ListExtractionsFormValues = z.infer<typeof listExtractionsSchema>;

export const createExtractionSchema = z.object({
  logId: uuid,
  name: optionalText,
  note: optionalText,
});

export type CreateExtractionFormValues = z.infer<typeof createExtractionSchema>;

export const EDITABLE_EXTRACTION_FIELDS = [
  "name",
  "s3Bucket",
  "s3Key",
  "note",
  "queued",
  "progress",
  "processing",
  "errored",
  "cancelled",
  "archived",
  "status",
  "size",
] as const;

export type EditExtractionFormValues = Pick<
  Extraction,
  ElementOf<typeof EDITABLE_EXTRACTION_FIELDS>
>;

export const editExtractionSchema = z.object({
  name,
  note: optionalText,
  size: optionalNumber,
  s3Key: optionalText,
  s3Bucket: optionalText,
  status: processStatus,
  progress: optionalNumber,
  queued: boolean,
  processing: boolean,
  errored: boolean,
  cancelled: boolean,
  archived: boolean,
});

export const listExtractionTopicsSchema = z.object({
  startTimeGte: filterNumber,
  startTimeLte: filterNumber,
  startTimeNull: filterBoolean,
  endTimeGte: filterNumber,
  endTimeLte: filterNumber,
  endTimeNull: filterBoolean,
  createdBy: filterUuid,
  createdAtGte: filterDate,
  createdAtLte: filterDate,
  updatedBy: filterUuid,
  updatedAtGte: filterDate,
  updatedAtLte: filterDate,
  deletedBy: filterUuid,
  deletedAtGte: filterDate,
  deletedAtLte: filterDate,
});

export type ListExtractionTopicsFormValues = z.infer<
  typeof listExtractionTopicsSchema
>;

export const createExtractionTopicSchema = z.object({
  topicId: uuid,
  startTime: optionalNumber,
  endTime: optionalNumber,
  frequency: optionalNumber,
});

export type CreateExtractionTopicFormValues = z.infer<
  typeof createExtractionTopicSchema
>;

export const EDITABLE_EXTRACTION_TOPIC_FIELDS = [
  "startTime",
  "endTime",
  "frequency",
  "size",
] as const;

export type EditExtractionTopicFormValues = Pick<
  ExtractionTopic,
  ElementOf<typeof EDITABLE_EXTRACTION_TOPIC_FIELDS>
>;

export const editExtractionTopicSchema = z.object({
  startTime: optionalNumber,
  endTime: optionalNumber,
  frequency: optionalNumber,
  size: optionalNumber,
});
