import React from "react";
import { DoNotDisturb, Person } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { find } from "lodash";
import { ErrorBoundary } from "react-error-boundary";
import Center from "../../../../components/Center";
import Error from "../../../../components/Error";
import Loading from "../../../../components/Loading";
import { AuthGuard } from "../../../../domain/auth";
import { usePlayerTopics } from "../../hooks";
import { usePanelContext, VisualizationType } from "../../panels";
import PanelHeader from "../PanelHeader";
import PanelLayout from "../PanelLayout";
import ChartVisualization from "./ChartVisualization";
import ImageVisualization from "./ImageVisualization";
import type { MapVisualizationProps } from "./MapVisualization";
import TimelineVisualization from "./TimelineVisualization";
import TopicSelector from "./TopicSelector";

const MapPanel = React.lazy(() => import("./MapVisualization"));

export default function VisualizationSwitch() {
  const panel = usePanelContext();

  const topicsQuery = usePlayerTopics();

  if (topicsQuery.isSuccess) {
    if (!panel.isInitialized) {
      return <TopicSelector panelId={panel.id} topics={topicsQuery.data} />;
    } else {
      const topic = find(topicsQuery.data, { name: panel.topicName });

      if (topic === undefined) {
        return (
          <PanelLayout header={<PanelHeader />}>
            <Error icon={<DoNotDisturb fontSize="large" color="error" />}>
              <Typography variant="h5" component="p">
                <code>{panel.topicName}</code> is not in this log
              </Typography>
            </Error>
          </PanelLayout>
        );
      } else if (panel.visualization === VisualizationType.Timeline) {
        return <TimelineVisualization panel={panel} topic={topic} />;
      } else if (panel.visualization === VisualizationType.Chart) {
        return <ChartVisualization panel={panel} topic={topic} />;
      } else if (panel.visualization === VisualizationType.Image) {
        return <ImageVisualization panel={panel} topic={topic} />;
      } else if (panel.visualization === VisualizationType.Map) {
        return (
          <AuthGuard
            fallback={
              <PanelLayout header={<PanelHeader />}>
                <Center>
                  <Person fontSize="large" />
                  <Typography variant="h6" component="p">
                    Sign in to use the map panel
                  </Typography>
                </Center>
              </PanelLayout>
            }
          >
            {renderLazyMapPanel({ panel, topic })}
          </AuthGuard>
        );
      } else {
        return null;
      }
    }
  } else if (topicsQuery.isError) {
    return (
      <Error>
        <Typography variant="h5" component="p" color="error">
          Unable to fetch topics
        </Typography>
      </Error>
    );
  } else {
    return (
      <Loading type="circular">
        <Typography variant="h5" component="p">
          Loading topics...
        </Typography>
      </Loading>
    );
  }
}

function renderLazyMapPanel(props: MapVisualizationProps) {
  return (
    <ErrorBoundary
      fallback={renderPanelFallback(
        <Error>
          <Typography variant="h5" component="p">
            Could not initialize map view
          </Typography>
        </Error>
      )}
    >
      <React.Suspense
        fallback={renderPanelFallback(<Loading type="circular" />)}
      >
        <MapPanel {...props} />
      </React.Suspense>
    </ErrorBoundary>
  );
}

function renderPanelFallback(children: React.ReactNode) {
  return <PanelLayout header={<PanelHeader />}>{children}</PanelLayout>;
}
