import { secondsToMilliseconds } from "date-fns";
import invariant from "invariant";
import { floor } from "lodash";
import { SortDirection } from "../../../components/Table";
import { circumventPagination } from "../../../domain/crud";
import {
  useDataStoreQuery,
  useDataStoreQueryKey,
} from "../../../domain/datastores";
import type { ListRecordsRequest } from "../../../services/datastore";
import { usePlayerConfig } from "../hooks";
import { useTagSelection } from "./store";

export function useTaggedRecordTimestamps() {
  const { logId } = usePlayerConfig();

  const selection = useTagSelection();

  const isTagSelected = selection !== null;

  return useDataStoreQuery({
    queryKey: useDataStoreQueryKey(["tagged-records", logId, selection]),
    queryFn(context, { topicApi }) {
      invariant(isTagSelected, "No tag selected");

      const request: ListRecordsRequest = {
        topicId: selection.topicId,
        sort: SortDirection.Asc,
        order: "timestamp",
        contextFilter: JSON.stringify([
          {
            var: "tags",
            op: "contains",
            val: selection.tag,
          },
        ]),
      };

      return circumventPagination(
        topicApi.listRecords.bind(topicApi),
        100,
        request,
        context,
        // Fetch at most 500 records for now
        5
      );
    },
    enabled: isTagSelected,
    select({ data }) {
      return data.map(({ timestamp }) =>
        secondsToMilliseconds(floor(timestamp, 1))
      );
    },
  });
}
