import React from "react";
import { TableCell } from "@mui/material";
import { z } from "zod";
import type { User } from "../../services/datastore";
import {
  formatBytes,
  formatDate,
  formatNumber,
  formatTimestamp,
} from "../../utils/formats";
import { UserInfo } from "../ResourceInfo";
import type { AccessorColumn, CellRenderer, ColumnDataType } from "./types";

export interface DatetimeCellProps {
  datetime: Date | null;
}

export function DatetimeCell({ datetime }: DatetimeCellProps) {
  return (
    <TableCell>
      {datetime === null ? (
        "-"
      ) : (
        <span title={datetime.toLocaleString()}>{datetime.toUTCString()}</span>
      )}
    </TableCell>
  );
}

export interface UserCellProps {
  userId: User["id"] | null;
}

export function UserCell({ userId }: UserCellProps) {
  return (
    <TableCell>
      {userId === null ? "-" : <UserInfo userId={userId} />}
    </TableCell>
  );
}

export function makeCellRenderer<TResource>(
  column: AccessorColumn<TResource>
): CellRenderer<TResource> {
  return function renderCell(resource, props) {
    const cellData: unknown = resource[column.accessor];

    return (
      <TableCell {...props}>
        {cellData === null ? "-" : getCellContent(cellData, column.dataType)}
      </TableCell>
    );
  };
}

function getCellContent(
  cellData: unknown,
  dataType: ColumnDataType | undefined
): React.ReactNode {
  switch (dataType) {
    case undefined: {
      return cellData;
    }
    case "number": {
      return z.number().transform(formatNumber).parse(cellData);
    }
    case "timestamp": {
      const parsedData = z.number().parse(cellData);

      return (
        <span title={String(parsedData)}>{formatTimestamp(parsedData)}</span>
      );
    }
    case "datetime": {
      const parsedData = z.date().parse(cellData);

      return (
        <span title={formatDate(parsedData, "local")}>
          {formatDate(parsedData, "UTC")}
        </span>
      );
    }
    case "bytes": {
      const parsedData = z.number().parse(cellData);

      return formatBytes(parsedData);
    }
    case "percent": {
      return cellData === null ? "-" : `${Number(cellData) * 100}%`;
    }
    default: {
      const _exhaustiveCheck: never = dataType;
      throw new Error(`Unknown column data type: ${_exhaustiveCheck}`);
    }
  }
}
