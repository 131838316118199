import { useQueryClient } from "@tanstack/react-query";
import invariant from "invariant";
import type { StrictOmit } from "ts-essentials";
import type {
  ListUsersRequest,
  User,
  UserCreateRequest,
  UserFetchResponse,
  UserListResponse,
  UserUpdateRequest,
} from "../../services/datastore";
import type { ResolvedKeyFactory } from "../../types";
import type { UseDataStoreQueryOptions } from "../datastores";
import {
  useDataStoreMutation,
  useDataStoreQuery,
  useDataStoreQueryKey,
} from "../datastores";
import { getInitialDetailsData, mergeEnabledOption } from "./utils";

export function useUserKeys() {
  const baseKey = useDataStoreQueryKey(["users"] as const);

  const factory = {
    all: baseKey,
    lists: () => [...factory.all, "list"] as const,
    list: (request: ListUsersRequest) => [...factory.lists(), request] as const,
    details: () => [...factory.all, "details"] as const,
    detail: (userId: User["id"] | null) =>
      [...factory.details(), userId] as const,
  } as const;

  return factory;
}

export type UserKeys = ResolvedKeyFactory<typeof useUserKeys>;

export function useUsers<TData = UserListResponse>(
  request: ListUsersRequest,
  options?: UseDataStoreQueryOptions<
    UserListResponse,
    unknown,
    TData,
    UserKeys["list"]
  >
) {
  return useDataStoreQuery({
    queryKey: useUserKeys().list(request),
    queryFn(context, { userApi }) {
      return userApi.listUsers(request, context);
    },
    ...options,
  });
}

export function useUser<TData = UserFetchResponse>(
  userId: User["id"] | null,
  options?: StrictOmit<
    UseDataStoreQueryOptions<
      UserFetchResponse,
      unknown,
      TData,
      UserKeys["detail"]
    >,
    "initialData"
  >
) {
  const queryClient = useQueryClient();

  const userKeys = useUserKeys();

  const isUserIdValid = userId !== null;

  return useDataStoreQuery({
    queryKey: userKeys.detail(userId),
    queryFn(context, { userApi }) {
      invariant(isUserIdValid, "User ID cannot be null");

      return userApi.getUser({ userId }, context);
    },
    ...options,
    enabled: mergeEnabledOption(options, isUserIdValid),
    initialData() {
      return getInitialDetailsData(
        queryClient,
        userKeys.lists(),
        (user: User) => user.id === userId
      );
    },
  });
}

export function useCreateUser() {
  const userKeys = useUserKeys();

  const queryClient = useQueryClient();

  return useDataStoreMutation({
    mutationFn(request: UserCreateRequest, { userApi }) {
      return userApi.createUser({ userCreateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData<UserFetchResponse>(
        userKeys.detail(response.data.id),
        response
      );
    },
  });
}

export function useUpdateUser(userId: User["id"]) {
  const queryClient = useQueryClient();
  const userKeys = useUserKeys();

  return useDataStoreMutation({
    mutationFn(request: UserUpdateRequest, { userApi }) {
      return userApi.updateUser({ userId, userUpdateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData<UserFetchResponse>(
        userKeys.detail(response.data.id),
        response
      );
    },
  });
}

export function useDeleteUser(userId: User["id"]) {
  return useDataStoreMutation({
    mutationFn(_, { userApi }) {
      return userApi.deleteUser({ userId });
    },
  });
}
