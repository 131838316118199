import { useState } from "react";
import { Typography } from "@mui/material";
import { DeleteDialog } from "../../../components/DeleteDialog";
import {
  dateTimeFields,
  DetailsCard,
  half,
} from "../../../components/DetailsCard";
import type { ResourceField } from "../../../components/DetailsCard/types";
import { IngestionInfo, LogInfo } from "../../../components/ResourceInfo";
import { useDeleteTopic, useTopic } from "../../../domain/crud";
import {
  makeEditTopicLocation,
  makeTopicsLocation,
  useStudioNavigate,
  useTopicParams,
} from "../../../paths";
import type { Topic } from "../../../services/datastore";
import { getTopicTypeName } from "../../../utils";
import RecordsTable from "./RecordsTable";

const FIELDS: ReadonlyArray<ResourceField<Topic>> = [
  {
    col: half,
    label: "Name",
    renderField(topic) {
      return (
        <>
          {topic.name}
          <Typography variant="body2">{topic.id}</Typography>
        </>
      );
    },
  },
  {
    col: half,
    label: "Log",
    renderField(topic) {
      return <LogInfo logId={topic.logId} />;
    },
  },
  {
    col: half,
    label: "Message Type",
    renderField(topic) {
      return getTopicTypeName(topic) ?? "-";
    },
  },
  {
    col: half,
    label: "Ingestion",
    renderField(topic) {
      return topic.ingestionId === null ? (
        "-"
      ) : (
        <IngestionInfo ingestionId={topic.ingestionId} />
      );
    },
  },
  ...dateTimeFields,
];

export default function Details() {
  const { topicId } = useTopicParams();

  const topicQuery = useTopic(topicId, {
    select(response) {
      return response.data;
    },
  });

  const navigate = useStudioNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const deleteTopic = useDeleteTopic(topicId);

  function closeModal() {
    setShowDeleteDialog(false);
  }

  function openModal() {
    setShowDeleteDialog(true);
  }

  function onDelete() {
    navigate(makeTopicsLocation());
  }

  return (
    <>
      <DetailsCard
        editLocation={makeEditTopicLocation(topicId)}
        onDelete={openModal}
        query={topicQuery}
        fields={FIELDS}
      />
      <RecordsTable topicId={topicId} />
      <DeleteDialog
        open={showDeleteDialog}
        onClose={closeModal}
        resourceName="topic"
        resourceLoading={topicQuery.isLoading}
        mutation={deleteTopic}
        onDelete={onDelete}
      >
        {topicQuery.data?.name}
      </DeleteDialog>
    </>
  );
}
