import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { dateTimeFields, third } from "../../../components/DetailsCard";
import DetailsCard from "../../../components/DetailsCard/DetailsCard";
import type { ResourceField } from "../../../components/DetailsCard/types";
import {
  useApiKeys,
  useCurrentUser,
  useGroupAssociations,
} from "../../../domain/crud";
import { useIsConnected } from "../../../domain/datastores";
import type { User } from "../../../services/datastore";
import { selectData } from "../../../utils";
import APIKeysView from "./APIKeysView";
import GroupsView from "./GroupsView";

const FIELDS: ReadonlyArray<ResourceField<User>> = [
  { col: third, accessor: "username", dataType: "text" },
  { col: third, accessor: "id", dataType: "text" },
  { col: third, accessor: "isAdmin", dataType: "boolean" },
  ...dateTimeFields,
];

export default function MeView() {
  const isConnected = useIsConnected();

  const userQuery = useCurrentUser({ select: selectData });

  const userId = userQuery.data?.id;
  const enabled = userQuery.isSuccess;

  const apiKeysQuery = useApiKeys({ userId, limit: 100 }, { enabled });

  const groupAssociationsQuery = useGroupAssociations(
    { userId, limit: 100 },
    { enabled }
  );

  if (!isConnected) {
    return (
      <Box sx={{ display: "grid", placeItems: "center", mt: 8 }}>
        <Typography variant="h4" component="p">
          Connect to a DataStore to view your profile
        </Typography>
      </Box>
    );
  }

  return (
    <Stack spacing={4}>
      <DetailsCard query={userQuery} fields={FIELDS} />
      <APIKeysView apiKeysQuery={apiKeysQuery} userId={userId} />
      <GroupsView query={groupAssociationsQuery} />
    </Stack>
  );
}
