import React from "react";
import { Circle, CircleOutlined, Person } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import Center from "../../../components/Center";
import Error from "../../../components/Error";
import {
  useCustomDataStores,
  useDataStoreContext,
  usePlatformDataStores,
} from "../../../domain/datastores";
import type { DataStore } from "../../../models";
import { selectData } from "../../../utils";

export interface ListViewProps {
  selected: DataStore["origin"] | null;
  onSelect: (origin: DataStore["origin"]) => void;
  onCreate: () => void;
}

export default function ListView({
  selected,
  onSelect,
  onCreate,
}: ListViewProps) {
  const { current } = useDataStoreContext();
  const platformDataStoresQuery = usePlatformDataStores();
  const customDataStoresQuery = useCustomDataStores({ select: selectData });

  function renderListItem(dataStore: DataStore) {
    return (
      <ListItem key={dataStore.origin} disablePadding>
        <ListItemButton
          selected={dataStore.origin === selected}
          onClick={() => onSelect(dataStore.origin)}
        >
          <ListItemIcon>
            {dataStore.origin === current?.origin ? (
              <Circle color="success" />
            ) : (
              <CircleOutlined />
            )}
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "h6", component: "p" }}
            primary={dataStore.name}
            secondary={dataStore.origin}
          />
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <Card>
      <CardHeader
        title="Platform DataStores"
        titleTypographyProps={{ component: "h2" }}
      />
      <CardContent sx={{ pt: 0 }}>
        <List
          disablePadding
          sx={{
            "& .MuiListItem-root": {
              flexWrap: "wrap",
            },
          }}
        >
          {!platformDataStoresQuery.canRun ? (
            <Center>
              <Person fontSize="large" />
              <Typography variant="h6" component="p">
                Sign in to connect to Platform DataStores
              </Typography>
            </Center>
          ) : platformDataStoresQuery.isLoading ? (
            <Skeleton variant="rounded" height={78} />
          ) : platformDataStoresQuery.isError ? (
            <Error>
              <Typography variant="h6" component="p">
                Unable to load Platform DataStores
              </Typography>
            </Error>
          ) : platformDataStoresQuery.data.length === 0 ? (
            <ListItem>
              <ListItemText>
                You don't have access to any Platform DataStores
              </ListItemText>
            </ListItem>
          ) : (
            platformDataStoresQuery.data.map(renderListItem)
          )}
        </List>
      </CardContent>
      <Divider />
      <CardHeader
        title="Custom DataStores"
        titleTypographyProps={{ component: "h2" }}
        action={
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={onCreate}
          >
            Create Connection
          </Button>
        }
      />
      <CardContent sx={{ pt: 0 }}>
        <List
          disablePadding
          sx={{
            "& .MuiListItem-root": {
              flexWrap: "wrap",
            },
          }}
        >
          {customDataStoresQuery.data.length === 0 ? (
            <ListItem>
              <ListItemText>
                You haven't added any custom DataStore connections yet
              </ListItemText>
            </ListItem>
          ) : (
            customDataStoresQuery.data.map(renderListItem)
          )}
        </List>
      </CardContent>
    </Card>
  );
}
