/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface APIKeyCreateRequest
 */
export interface APIKeyCreateRequest {
  /**
   *
   * @type {string}
   * @memberof APIKeyCreateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof APIKeyCreateRequest
   */
  userId: string;
}

export function APIKeyCreateRequestFromJSON(json: any): APIKeyCreateRequest {
  return APIKeyCreateRequestFromJSONTyped(json, false);
}

export function APIKeyCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): APIKeyCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    userId: json["user_id"],
  };
}

export function APIKeyCreateRequestToJSON(
  value?: APIKeyCreateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    user_id: value.userId,
  };
}
