import type { Configuration } from "../../../services/datastore";
import type { DataStoreCacheKeyPrefix } from "./types";

export const baseCacheKeyPrefix = { $$api: "datastore" } as const;

export default function makeCacheKeyPrefix(
  basePath: Configuration["basePath"] | null
): DataStoreCacheKeyPrefix {
  return { ...baseCacheKeyPrefix, basePath } as DataStoreCacheKeyPrefix;
}
