import { useState } from "react";
import { GroupRemove } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import Center from "../../../components/Center";
import Error from "../../../components/Error";
import Loading from "../../../components/Loading";
import { QueryRenderer } from "../../../components/QueryRenderer";
import { GroupInfo } from "../../../components/ResourceInfo";
import {
  useDeleteGroupAssociation,
  useGroupAssociations,
} from "../../../domain/crud";
import { useUserParams } from "../../../paths";
import type { GroupAssociation } from "../../../services/datastore";
import { AddToGroupDialog } from "./AddToGroupDialog";

export function GroupManagement() {
  const [showDialog, setShowDialog] = useState(false);

  const { userId } = useUserParams();

  const groupAssociationsQuery = useGroupAssociations({ userId, limit: 100 });

  function handleOpenDialog() {
    setShowDialog(true);
  }

  function handleCloseDialog() {
    setShowDialog(false);
  }

  return (
    <Card sx={{ mt: 4 }}>
      <CardHeader
        title="Group Management"
        titleTypographyProps={{ component: "h2" }}
        action={
          groupAssociationsQuery.isSuccess && (
            <Button variant="contained" onClick={handleOpenDialog}>
              Add to Group
            </Button>
          )
        }
      />
      <CardContent>
        <QueryRenderer
          query={groupAssociationsQuery}
          loading={<Loading type="circular" />}
          error={
            <Error>
              <Typography variant="h5" component="p">
                Unable to loads user's groups
              </Typography>
            </Error>
          }
          success={({ data }) => (
            <>
              {data.length === 0 ? (
                <Center>
                  <Typography
                    variant="h6"
                    component="p"
                    sx={{ fontStyle: "italic", my: 4 }}
                  >
                    User is not a member of any groups
                  </Typography>
                </Center>
              ) : (
                <List>
                  {data.map((groupAssociation) => (
                    <GroupMembership
                      key={groupAssociation.id}
                      groupAssociation={groupAssociation}
                    />
                  ))}
                </List>
              )}
              <AddToGroupDialog
                userId={userId}
                open={showDialog}
                onClose={handleCloseDialog}
                groupAssociations={data}
              />
            </>
          )}
        />
      </CardContent>
    </Card>
  );
}

function GroupMembership({
  groupAssociation,
}: {
  groupAssociation: GroupAssociation;
}) {
  const { groupId } = groupAssociation;

  const deleteGroupAssociation = useDeleteGroupAssociation(groupAssociation.id);

  function handleDelete() {
    deleteGroupAssociation.mutate();
  }

  return (
    <ListItem
      secondaryAction={
        <Tooltip title="Remove user from this group">
          <span>
            <IconButton
              disabled={deleteGroupAssociation.isLoading}
              onClick={handleDelete}
            >
              <GroupRemove />
            </IconButton>
          </span>
        </Tooltip>
      }
    >
      <GroupInfo
        groupId={groupId}
        chip={<Chip label={groupAssociation.role} size="small" />}
      />
    </ListItem>
  );
}
