import type { ElementOf } from "ts-essentials";
import { z } from "zod";
import { name } from "../../../components/Form";
import {
  filterBoolean,
  filterDate,
  filterText,
  filterUuid,
} from "../../../components/Table";
import type { Group } from "../../../services/datastore";

export const listGroupsSchema = z.object({
  name: filterText,
  nameLike: filterText,
  createdBy: filterUuid,
  createdAtGte: filterDate,
  createdAtLte: filterDate,
  updatedBy: filterUuid,
  updatedByNull: filterBoolean,
  updatedAtGte: filterDate,
  updatedAtLte: filterDate,
  updatedAtNull: filterBoolean,
  deletedBy: filterUuid,
  deletedByNull: filterBoolean,
  deletedAtGte: filterDate,
  deletedAtLte: filterDate,
  deletedAtNull: filterBoolean,
});

export type ListGroupsFormValues = z.infer<typeof listGroupsSchema>;

export const createGroupSchema = z.object({ name });

export type CreateGroupFormValues = z.infer<typeof createGroupSchema>;

export const EDITABLE_FIELDS = ["name"] as const;

export type EditGroupFormValues = Pick<
  Group,
  ElementOf<typeof EDITABLE_FIELDS>
>;

export const editFormSchema = z.object({ name });
