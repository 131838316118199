/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface Topic
 */
export interface Topic {
  /**
   *
   * @type {string}
   * @memberof Topic
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof Topic
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof Topic
   */
  updatedAt: Date | null;
  /**
   *
   * @type {Date}
   * @memberof Topic
   */
  deletedAt: Date | null;
  /**
   *
   * @type {string}
   * @memberof Topic
   */
  createdBy: string | null;
  /**
   *
   * @type {string}
   * @memberof Topic
   */
  updatedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof Topic
   */
  deletedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof Topic
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Topic
   */
  logId: string;
  /**
   *
   * @type {string}
   * @memberof Topic
   */
  messageTypeId: string | null;
  /**
   *
   * @type {string}
   * @memberof Topic
   */
  ingestionId: string | null;
  /**
   *
   * @type {string}
   * @memberof Topic
   */
  associatedTopicId: string | null;
  /**
   *
   * @type {string}
   * @memberof Topic
   */
  messageTypeName: string | null;
  /**
   *
   * @type {number}
   * @memberof Topic
   */
  startTime: number | null;
  /**
   *
   * @type {number}
   * @memberof Topic
   */
  endTime: number | null;
  /**
   *
   * @type {number}
   * @memberof Topic
   */
  recordSize: number | null;
  /**
   *
   * @type {number}
   * @memberof Topic
   */
  recordCount: number | null;
  /**
   *
   * @type {object}
   * @memberof Topic
   */
  context: object | null;
  /**
   *
   * @type {boolean}
   * @memberof Topic
   */
  locked: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Topic
   */
  typeName: string | null;
  /**
   *
   * @type {string}
   * @memberof Topic
   */
  typeEncoding: string | null;
  /**
   *
   * @type {string}
   * @memberof Topic
   */
  typeData: string | null;
  /**
   *
   * @type {object}
   * @memberof Topic
   */
  typeSchema: object | null;
}

export function TopicFromJSON(json: any): Topic {
  return TopicFromJSONTyped(json, false);
}

export function TopicFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Topic {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    name: json["name"],
    logId: json["log_id"],
    messageTypeId: json["message_type_id"],
    ingestionId: json["ingestion_id"],
    associatedTopicId: json["associated_topic_id"],
    messageTypeName: json["message_type_name"],
    startTime: json["start_time"],
    endTime: json["end_time"],
    recordSize: json["record_size"],
    recordCount: json["record_count"],
    context: json["context"],
    locked: json["locked"],
    typeName: json["type_name"],
    typeEncoding: json["type_encoding"],
    typeData: json["type_data"],
    typeSchema: json["type_schema"],
  };
}

export function TopicToJSON(value?: Topic | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    name: value.name,
    log_id: value.logId,
    message_type_id: value.messageTypeId,
    ingestion_id: value.ingestionId,
    associated_topic_id: value.associatedTopicId,
    message_type_name: value.messageTypeName,
    start_time: value.startTime,
    end_time: value.endTime,
    record_size: value.recordSize,
    record_count: value.recordCount,
    context: value.context,
    locked: value.locked,
    type_name: value.typeName,
    type_encoding: value.typeEncoding,
    type_data: value.typeData,
    type_schema: value.typeSchema,
  };
}
