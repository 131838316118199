import type { DataStore } from "../../models";

export function isOriginSecure(origin: DataStore["origin"]): boolean {
  return new URL(origin).protocol === "https:";
}

export function isOriginMixedContent(origin: DataStore["origin"]): boolean {
  // Used to know if the browser is likely to block the connection due to it
  // being classified as "mixed content." This logic is a bit too aggressive.
  // There are several cases where this will return `true` but the browser
  // won't treat it as mixed content:
  //   1. Studio is running locally (localhost) and connecting to a local
  //      DataStore (localhost). Browsers treat apps running on localhost as if
  //      they were a secure context
  //   2. Studio is deployed (HTTPS) but the origin is a loopback address
  //      (localhost). Aside from Safari, browsers won't consider connections
  //      to non-secure loopback addresses in a secure context as mixed content
  return window.isSecureContext && !isOriginSecure(origin);
}
