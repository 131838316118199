import type { ElementOf } from "ts-essentials";
import { z } from "zod";
import { boolean } from "../../../components/Form";
import {
  filterBoolean,
  filterDate,
  filterText,
  filterUuid,
} from "../../../components/Table";
import type { User } from "../../../services/datastore";

export const listUsersSchema = z.object({
  username: filterText,
  usernameLike: filterText,
  createdBy: filterUuid,
  createdAtGte: filterDate,
  createdAtLte: filterDate,
  updatedBy: filterUuid,
  updatedByNull: filterBoolean,
  updatedAtGte: filterDate,
  updatedAtLte: filterDate,
  updatedAtNull: filterBoolean,
  deletedBy: filterUuid,
  deletedByNull: filterBoolean,
  deletedAtGte: filterDate,
  deletedAtLte: filterDate,
  deletedAtNull: filterBoolean,
});

export type ListUsersFormValues = z.infer<typeof listUsersSchema>;

const username = z.string({ invalid_type_error: "Username is required" });

export const createUserSchema = z.object({
  username,
  isAdmin: boolean,
});

export type CreateUserFormValues = z.infer<typeof createUserSchema>;

export const EDITABLE_FIELDS = ["username", "isAdmin"] as const;

export type EditUserFormValues = Pick<User, ElementOf<typeof EDITABLE_FIELDS>>;

export const editUserSchema = z.object({
  username,
  isAdmin: boolean,
});
