import { UserCell } from ".";
import type { DateTimeFields } from "../DetailsCard/constants";
import type { Column } from "./types";

export const dateTimeColumns: ReadonlyArray<Column<DateTimeFields>> = [
  {
    accessor: "createdAt",
    dataType: "datetime",
    isSortable: true,
  },
  {
    header: "Created By",
    renderCell(resource) {
      return <UserCell userId={resource.createdBy} />;
    },
  },
  {
    accessor: "updatedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    header: "Updated By",
    defaultHidden: true,
    renderCell(resource) {
      return <UserCell userId={resource.updatedBy} />;
    },
  },
  {
    accessor: "deletedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  {
    header: "Deleted By",
    defaultHidden: true,
    renderCell(resource) {
      return <UserCell userId={resource.deletedBy} />;
    },
  },
];
