import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { pick } from "lodash";
import { useSnackbar } from "notistack";
import { getChangedFields, TextField } from "../../../components/Form";
import { useStudioForm } from "../../../components/Form/hooks";
import { useUpdateTopic } from "../../../domain/crud";
import {
  makeTopicLocation,
  useStudioNavigate,
  useTopicParams,
} from "../../../paths";
import type { Topic } from "../../../services/datastore";
import { EDITABLE_TOPIC_FIELDS, editTopicSchema } from "./validation";

interface TopicFormProps {
  topic: Topic;
}

export default function TopicForm({ topic }: TopicFormProps) {
  const { topicId } = useTopicParams();

  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const updateTopic = useUpdateTopic(topicId);

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useStudioForm({
    schema: editTopicSchema,
    defaultValues: pick(topic, EDITABLE_TOPIC_FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateTopic.mutate(changedFields, {
        onSuccess() {
          enqueueSnackbar("Topic updated", { variant: "success" });

          navigate(makeTopicLocation(topicId));
        },
        onError() {
          enqueueSnackbar("Failed to update Topic", { variant: "error" });
        },
      });
    },
  });

  return (
    <Card>
      <CardContent>
        <Grid
          component="form"
          name="editTopic"
          container
          onSubmit={handleSubmit}
          spacing={2}
        >
          <Grid item xs={3}>
            <TextField control={control} name="name" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={updateTopic.isLoading}
              type="submit"
              variant="contained"
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
