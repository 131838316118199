import { useQueryClient } from "@tanstack/react-query";
import invariant from "invariant";
import type { StrictOmit } from "ts-essentials";
import type {
  Extraction,
  ExtractionCreateRequest,
  ExtractionFetchResponse,
  ExtractionListResponse,
  ExtractionTopic,
  ExtractionTopicCreateRequest,
  ExtractionTopicFetchResponse,
  ExtractionTopicListResponse,
  ExtractionTopicUpdateRequest,
  ExtractionUpdateRequest,
  ListExtractionsRequest,
  ListExtractionTopicsRequest,
} from "../../services/datastore";
import type { Maybe, ResolvedKeyFactory } from "../../types";
import { selectData } from "../../utils";
import { useHasPermission } from "../auth";
import type {
  DataStoreMutationFunction,
  UseDataStoreQueryOptions,
} from "../datastores";
import {
  useDataStoreMutation,
  useDataStoreQuery,
  useDataStoreQueryKey,
} from "../datastores";
import { useLog } from "./logs";
import { getInitialDetailsData } from "./utils";

export function useExtractionKeys() {
  const baseQuery = useDataStoreQueryKey(["extractions"] as const);

  const factory = {
    all: baseQuery,
    lists: () => [...factory.all, "list"] as const,
    list: (request: ListExtractionsRequest) =>
      [...factory.lists(), request] as const,
    details: () => [...factory.all, "details"] as const,
    detail: (extractionId: Extraction["id"]) =>
      [...factory.details(), extractionId] as const,
    presignedUrl: (extractionId: Extraction["id"]) =>
      [...factory.detail(extractionId), "presigned-url"] as const,
  } as const;

  return factory;
}

export type ExtractionKeys = ResolvedKeyFactory<typeof useExtractionKeys>;

export function useExtractions<TData = ExtractionListResponse>(
  request: ListExtractionsRequest,
  options?: UseDataStoreQueryOptions<
    ExtractionListResponse,
    unknown,
    TData,
    ExtractionKeys["list"]
  >
) {
  return useDataStoreQuery({
    queryKey: useExtractionKeys().list(request),
    queryFn(context, { extractionApi }) {
      return extractionApi.listExtractions(request, context);
    },
    ...options,
  });
}

export function useExtraction<TData = ExtractionFetchResponse>(
  extractionId: Extraction["id"],
  options?: Omit<
    UseDataStoreQueryOptions<
      ExtractionFetchResponse,
      unknown,
      TData,
      ExtractionKeys["detail"]
    >,
    "initialData"
  >
) {
  const queryClient = useQueryClient();

  const extractionKeys = useExtractionKeys();

  return useDataStoreQuery({
    queryKey: extractionKeys.detail(extractionId),
    queryFn(context, { extractionApi }) {
      return extractionApi.getExtraction({ extractionId }, context);
    },
    ...options,
    initialData() {
      return getInitialDetailsData(
        queryClient,
        extractionKeys.lists(),
        (extraction: Extraction) => extraction.id === extractionId
      );
    },
  });
}

export function useCreateExtraction() {
  const extractionKeys = useExtractionKeys();

  const queryClient = useQueryClient();

  return useDataStoreMutation({
    mutationFn(request: ExtractionCreateRequest, { extractionApi }) {
      return extractionApi.createExtraction({
        extractionCreateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.setQueryData<ExtractionFetchResponse>(
        extractionKeys.detail(response.data.id),
        response
      );
    },
  });
}

export function useUpdateExtraction(extractionId: Extraction["id"]) {
  const extractionKeys = useExtractionKeys();

  const extractionQuery = useExtraction(extractionId, { select: selectData });
  const logQuery = useLog(extractionQuery.data?.logId, { select: selectData });
  const hasPermission = useHasPermission(logQuery, ["editor", "owner"]);

  const queryClient = useQueryClient();

  const mutationFn: DataStoreMutationFunction<
    ExtractionFetchResponse,
    ExtractionUpdateRequest
  > = function mutationFn(request, { extractionApi }) {
    invariant(
      hasPermission,
      "User does not have permission to update extraction"
    );

    return extractionApi.updateExtraction({
      extractionId,
      extractionUpdateRequest: request,
    });
  };

  return {
    hasPermission,
    ...useDataStoreMutation({
      mutationFn,
      onSuccess(response) {
        queryClient.setQueryData<ExtractionFetchResponse>(
          extractionKeys.detail(response.data.id),
          response
        );
      },
    }),
  };
}

export function useDeleteExtraction(extractionId: Extraction["id"]) {
  return useDataStoreMutation({
    mutationFn(_, { extractionApi }) {
      return extractionApi.deleteExtraction({ extractionId });
    },
  });
}

export function useExtractionPresignedUrl(
  extractionId: Extraction["id"],
  status: Extraction["status"],
  key: Extraction["s3Key"]
) {
  const extractionKeys = useExtractionKeys();

  return useDataStoreQuery({
    enabled: extractionId != null && status === "complete" && key !== null,
    queryKey: extractionKeys.presignedUrl(extractionId),
    queryFn(_, { extractionApi }) {
      invariant(status === "complete", "Extraction is not complete");
      invariant(key !== null, "S3 key is missing");

      return extractionApi.createExtractionPresignedUrl({
        extractionId,
        createPresignedURLRequest: {
          method: "get_object",
          params: { key },
        },
      });
    },
  });
}

export function useExtractionTopicKeys() {
  const extractionKeys = useExtractionKeys();

  const factory = {
    all: (extractionId: Maybe<Extraction["id"]>) =>
      [...extractionKeys.all, extractionId, "topics"] as const,
    lists: (extractionId: Extraction["id"]) =>
      [...factory.all(extractionId), "list"] as const,
    list: (
      extractionId: Extraction["id"],
      request: StrictOmit<ListExtractionTopicsRequest, "extractionId">
    ) => [...factory.lists(extractionId), request] as const,
    details: (extractionId: Maybe<Extraction["id"]>) =>
      [...factory.all(extractionId), "details"] as const,
    detail: (
      extractionId: Maybe<Extraction["id"]>,
      extractionTopicId: Maybe<ExtractionTopic["id"]>
    ) => [...factory.details(extractionId), extractionTopicId] as const,
  } as const;

  return factory;
}

export type ExtractionTopicKeys = ResolvedKeyFactory<
  typeof useExtractionTopicKeys
>;

export function useExtractionTopics<TData = ExtractionTopicListResponse>(
  extractionId: Extraction["id"],
  request: StrictOmit<ListExtractionTopicsRequest, "extractionId">,
  options?: UseDataStoreQueryOptions<
    ExtractionTopicListResponse,
    unknown,
    TData,
    ExtractionTopicKeys["list"]
  >
) {
  return useDataStoreQuery({
    queryKey: useExtractionTopicKeys().list(extractionId, request),
    queryFn(context, { extractionApi }) {
      return extractionApi.listExtractionTopics(
        { extractionId, ...request },
        context
      );
    },
    ...options,
  });
}

export function useExtractionTopic<TData = ExtractionTopicFetchResponse>(
  extractionId: Extraction["id"],
  extractionTopicId: ExtractionTopic["id"],
  options?: StrictOmit<
    UseDataStoreQueryOptions<
      ExtractionTopicFetchResponse,
      unknown,
      TData,
      ExtractionTopicKeys["detail"]
    >,
    "initialData"
  >
) {
  const queryClient = useQueryClient();

  const extractionTopicKeys = useExtractionTopicKeys();

  return useDataStoreQuery({
    queryKey: extractionTopicKeys.detail(extractionId, extractionTopicId),
    queryFn(context, { extractionApi }) {
      return extractionApi.getExtractionTopic(
        { extractionId, extractionTopicId },
        context
      );
    },
    ...options,
    initialData() {
      return getInitialDetailsData(
        queryClient,
        extractionTopicKeys.lists(extractionId),
        (extractionTopic: ExtractionTopic) =>
          extractionTopic.extractionId === extractionId &&
          extractionTopic.id === extractionTopicId
      );
    },
  });
}

export function useCreateExtractionTopic(extractionId: Extraction["id"]) {
  const extractionTopicKeys = useExtractionTopicKeys();

  const queryClient = useQueryClient();

  return useDataStoreMutation({
    mutationFn(request: ExtractionTopicCreateRequest, { extractionApi }) {
      return extractionApi.createExtractionTopic({
        extractionId,
        extractionTopicCreateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.setQueryData<ExtractionTopicFetchResponse>(
        extractionTopicKeys.detail(extractionId, response.data.id),
        response
      );
    },
  });
}

export function useUpdateExtractionTopic(
  extractionId: Extraction["id"],
  extractionTopicId: ExtractionTopic["id"]
) {
  return useDataStoreMutation({
    mutationFn(request: ExtractionTopicUpdateRequest, { extractionApi }) {
      return extractionApi.updateExtractionTopic({
        extractionId,
        extractionTopicId,
        extractionTopicUpdateRequest: request,
      });
    },
  });
}

export function useDeleteExtractionTopic(
  extractionId: Maybe<Extraction["id"]>,
  extractionTopicId: Maybe<ExtractionTopic["id"]>
) {
  return useDataStoreMutation({
    mutationFn(_, { extractionApi }) {
      invariant(extractionId != null, "Extraction ID must be defined");
      invariant(extractionTopicId != null, "Topic ID must be defined");

      return extractionApi.deleteExtractionTopic({
        extractionId,
        extractionTopicId,
      });
    },
  });
}
