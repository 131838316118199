import React, { useState } from "react";
import { TextField } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import type { BaseFieldProps } from "./types";

export function NumberField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, number | null>
>({ control, name, label }: BaseFieldProps<TFieldValues, TName>) {
  // RHF expects a number or `null`. If the user types "x" for example, the
  // input should display "x" but `NaN` should be passed to RHF. A separate
  // state variable is therefore necessary for the input to continue displaying
  // what the user typed in while giving RHF values of the correct type.
  const [displayedValue, setDisplayedValue] = useState<string>();

  const {
    field: { value, onChange, ...field },
    fieldState,
  } = useController({ control, name });

  return (
    <TextField
      fullWidth
      label={label}
      InputProps={{
        // Not recommended to use `type="number"` for inputs since they
        // have awful UX. Instead, this makes virtual keyboards on mobile
        // show number keys, while validation will prevent non-numbers
        // from being submitted
        inputMode: "decimal",
      }}
      error={fieldState.error !== undefined}
      helperText={fieldState.error?.message ?? " "}
      {...field}
      value={getFinalDisplayValue(displayedValue, value)}
      onChange={(e) => {
        const rawValue = e.target.value.trim();

        setDisplayedValue(rawValue);

        if (rawValue === "") {
          onChange(null);
        } else {
          onChange(Number(rawValue));
        }
      }}
    />
  );
}

function getFinalDisplayValue(
  displayValue: string | undefined,
  storedValue: number | null
): string {
  if (displayValue !== undefined) {
    return displayValue;
  } else if (storedValue === null) {
    return "";
  } else {
    return String(storedValue);
  }
}
