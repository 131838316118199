import { z } from "zod";
import {
  boolean,
  createErrorMap,
  logFormat,
  optionalNumber,
  optionalText,
  processStatus,
  uuid,
} from "../Form";

export const filterText = optionalText.default(null);

export const filterUuid = uuid.nullable().default(null);

export const filterNumber = z.preprocess((arg) => {
  if (arg == null) {
    return arg;
  } else {
    return Number(arg);
  }
}, optionalNumber.default(null));

export const filterBoolean = z.preprocess((arg) => {
  if (arg === "1") {
    return true;
  } else if (arg === "0") {
    return false;
  } else {
    return arg;
  }
}, boolean.nullable().default(null));

export const filterDate = z.coerce
  .date({
    errorMap: createErrorMap({
      invalid_type: "Expected a date",
      invalid_date: "Invalid date",
    }),
  })
  .nullable()
  .default(null);

export const filterLogFormat = logFormat.nullable().default(null);

export const filterProcessStatus = processStatus.nullable().default(null);
