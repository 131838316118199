import { Auth } from "aws-amplify";
import invariant from "invariant";
import { DataStoreName, PlatformDataStore } from "../../models";
import { Configuration } from "../datastore";
import type { LogrusClient } from "./types";

const DataStoreNameArray = DataStoreName.array();

export default class AmplifyLogrusClient implements LogrusClient {
  // Name of the field in the access token where the Cognito groups
  // can be accessed.
  readonly #groupsFieldName: string;
  readonly #platformOriginSuffix: string;

  constructor(groupsFieldName: string, platformOriginSuffix: string) {
    this.#groupsFieldName = groupsFieldName;
    this.#platformOriginSuffix = platformOriginSuffix;
  }

  async #getDataStoreNames(): Promise<DataStoreName[]> {
    let session;
    try {
      session = await Auth.currentSession();
    } catch {
      throw new Error("User is not authenticated");
    }

    const accessToken = session.getAccessToken();
    const payloadValue: unknown = accessToken.payload[this.#groupsFieldName];

    const namesParseResult = DataStoreNameArray.safeParse(payloadValue);

    invariant(namesParseResult.success, "Could not get DataStore names");

    return namesParseResult.data;
  }

  #buildPlatformDataStore(name: DataStoreName): PlatformDataStore {
    const { origin } = new URL(`https://${name}${this.#platformOriginSuffix}`);

    const dataStoreParseResult = PlatformDataStore.safeParse({
      name,
      origin,
      type: "platform",
    });

    invariant(dataStoreParseResult.success, "Invalid DataStore");

    return dataStoreParseResult.data;
  }

  async listPlatformDataStores(): Promise<PlatformDataStore[]> {
    const names = await this.#getDataStoreNames();

    return names.map(this.#buildPlatformDataStore.bind(this));
  }

  async isUserAuthenticated(): Promise<boolean> {
    try {
      await Auth.currentSession();
    } catch {
      return false;
    }

    return true;
  }

  buildPlatformConfiguration(dataStore: PlatformDataStore): Configuration {
    invariant(
      PlatformDataStore.safeParse(dataStore).success,
      "Expected a Platform DataStore"
    );

    return new Configuration({
      basePath: dataStore.origin,
      // Purposefully setting the ID token as if it were the access token
      accessToken: getAmplifyIdToken,
    });
  }
}

async function getAmplifyIdToken(): Promise<string> {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
}
