import { useState } from "react";
import { DeleteDialog } from "../../../components/DeleteDialog";
import {
  dateTimeFields,
  DetailsCard,
  full,
  half,
} from "../../../components/DetailsCard";
import type { ResourceField } from "../../../components/DetailsCard/types";
import { ExtractionInfo, TopicInfo } from "../../../components/ResourceInfo";
import {
  useDeleteExtractionTopic,
  useExtractionTopic,
} from "../../../domain/crud";
import {
  makeEditExtractionTopicLocation,
  makeExtractionLocation,
  useExtractionTopicParams,
  useStudioNavigate,
} from "../../../paths";
import type { ExtractionTopic } from "../../../services/datastore";

const FIELDS: ReadonlyArray<ResourceField<ExtractionTopic>> = [
  {
    col: full,
    accessor: "id",
    label: "Extraction Topic ID",
    dataType: "text",
  },
  {
    col: half,
    label: "Extraction",
    renderField(extractionTopic) {
      return <ExtractionInfo extractionId={extractionTopic.extractionId} />;
    },
  },
  {
    col: half,
    label: "Topic",
    renderField(extractionTopic) {
      return <TopicInfo topicId={extractionTopic.topicId} />;
    },
  },
  {
    col: half,
    accessor: "startTime",
    dataType: "timestamp",
  },
  {
    col: half,
    accessor: "endTime",
    dataType: "timestamp",
  },
  {
    col: half,
    accessor: "frequency",
    dataType: "text",
  },
  {
    col: half,
    accessor: "size",
    dataType: "bytes",
  },
  ...dateTimeFields,
];

export default function Details() {
  const { extractionId, topicId } = useExtractionTopicParams();

  const extractionTopicQuery = useExtractionTopic(extractionId, topicId, {
    select(response) {
      return response.data;
    },
  });

  const navigate = useStudioNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const deleteExtractionTopic = useDeleteExtractionTopic(extractionId, topicId);

  function closeModal() {
    setShowDeleteDialog(false);
  }

  function openModal() {
    setShowDeleteDialog(true);
  }

  function onDelete() {
    navigate(makeExtractionLocation(extractionId));
  }

  return (
    <>
      <DetailsCard
        editLocation={makeEditExtractionTopicLocation(extractionId, topicId)}
        onDelete={openModal}
        query={extractionTopicQuery}
        fields={FIELDS}
      />
      <DeleteDialog
        open={showDeleteDialog}
        onClose={closeModal}
        resourceName="extraction topic"
        resourceLoading={extractionTopicQuery.isLoading}
        mutation={deleteExtractionTopic}
        onDelete={onDelete}
      >
        {extractionTopicQuery.data?.id}
      </DeleteDialog>
    </>
  );
}
