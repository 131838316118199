import type { Maybe } from "../types";
import { formatBytes } from "../utils/formats";

export interface BytesProps {
  value: Maybe<number>;
}

export function Bytes({ value }: BytesProps) {
  return <>{value == null ? "-" : formatBytes(value)}</>;
}
