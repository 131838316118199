import { useQueryClient } from "@tanstack/react-query";
import invariant from "invariant";
import type { StrictOmit } from "ts-essentials";
import type {
  Ingestion,
  IngestionCreateRequest,
  IngestionFetchResponse,
  IngestionListResponse,
  IngestionUpdateRequest,
  ListIngestionsRequest,
} from "../../services/datastore";
import type { ResolvedKeyFactory } from "../../types";
import type { UseDataStoreQueryOptions } from "../datastores";
import {
  useDataStoreMutation,
  useDataStoreQuery,
  useDataStoreQueryKey,
} from "../datastores";
import { getInitialDetailsData, mergeEnabledOption } from "./utils";

export function useIngestionKeys() {
  const baseKey = useDataStoreQueryKey(["ingestions"] as const);

  const factory = {
    all: baseKey,
    lists: () => [...factory.all, "list"] as const,
    list: (request: ListIngestionsRequest) =>
      [...factory.lists(), request] as const,
    details: () => [...factory.all, "details"] as const,
    detail: (ingestionId: Ingestion["id"] | null) =>
      [...factory.details(), ingestionId] as const,
  } as const;

  return factory;
}

export type IngestionKeys = ResolvedKeyFactory<typeof useIngestionKeys>;

export function useIngestions<TData = IngestionListResponse>(
  request: ListIngestionsRequest,
  options?: UseDataStoreQueryOptions<
    IngestionListResponse,
    unknown,
    TData,
    IngestionKeys["list"]
  >
) {
  return useDataStoreQuery({
    queryKey: useIngestionKeys().list(request),
    queryFn(context, { ingestionApi }) {
      return ingestionApi.listIngestions(request, context);
    },
    ...options,
  });
}

export function useIngestion<TData = IngestionFetchResponse>(
  ingestionId: Ingestion["id"] | null,
  options?: StrictOmit<
    UseDataStoreQueryOptions<
      IngestionFetchResponse,
      unknown,
      TData,
      IngestionKeys["detail"]
    >,
    "initialData"
  >
) {
  const queryClient = useQueryClient();

  const ingestionKeys = useIngestionKeys();

  const isIngestionIdValid = ingestionId !== null;

  return useDataStoreQuery({
    queryKey: ingestionKeys.detail(ingestionId),
    queryFn(context, { ingestionApi }) {
      invariant(isIngestionIdValid, "Ingestion ID cannot be null");

      return ingestionApi.getIngestion({ ingestionId }, context);
    },
    ...options,
    enabled: mergeEnabledOption(options, isIngestionIdValid),
    initialData() {
      if (!isIngestionIdValid) {
        return;
      }

      return getInitialDetailsData(
        queryClient,
        ingestionKeys.lists(),
        (ingestion: Ingestion) => ingestion.id === ingestionId
      );
    },
  });
}

export function useCreateIngestion() {
  const queryClient = useQueryClient();
  const ingestionKeys = useIngestionKeys();

  return useDataStoreMutation({
    mutationFn(request: IngestionCreateRequest, { ingestionApi }) {
      return ingestionApi.createIngestion({ ingestionCreateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData<IngestionFetchResponse>(
        ingestionKeys.detail(response.data.id),
        response
      );
    },
  });
}

export function useUpdateIngestion(ingestionId: Ingestion["id"]) {
  const queryClient = useQueryClient();
  const ingestionKeys = useIngestionKeys();

  return useDataStoreMutation({
    mutationFn(request: IngestionUpdateRequest, { ingestionApi }) {
      return ingestionApi.updateIngestion({
        ingestionId,
        ingestionUpdateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.setQueryData<IngestionFetchResponse>(
        ingestionKeys.detail(response.data.id),
        response
      );
    },
  });
}

export function useDeleteIngestion(ingestionId: Ingestion["id"]) {
  return useDataStoreMutation({
    mutationFn(_, { ingestionApi }) {
      return ingestionApi.deleteIngestion({ ingestionId });
    },
  });
}
