import React, { useCallback, useMemo, useState } from "react";
import { clamp } from "lodash";
import { useImmerReducer } from "use-immer";
import { createSafeContext } from "../../contexts";
import type { Maybe } from "../../types";
import { formatTimestamp } from "../../utils";
import { usePlayerConfig, usePlayerLog } from "./hooks";
import type { BasePlaybackSource, PlaybackSource } from "./playbackReducer";
import { initialState, makeReducer } from "./playbackReducer";
import type { PlaybackSpeedValue, TimeRange, TimestepValue } from "./types";
import { PlaybackSpeed, Timestep } from "./types";

export type DisplayFormat = "elapsed" | "original";

export interface PlaybackSettings {
  displayFormat: DisplayFormat;
  setDisplayFormat: (displayFormat: DisplayFormat) => void;
  speed: PlaybackSpeedValue;
  setSpeed: (speed: PlaybackSpeedValue) => void;
  timestep: TimestepValue;
  setTimestep: (timestep: TimestepValue) => void;
}

export const [usePlaybackSettings, PlaybackSettingsContext] =
  createSafeContext<PlaybackSettings>("PlaybackSettings");

export interface PlaybackSettingsProviderProps {
  children: React.ReactNode;
}

export function PlaybackSettingsProvider({
  children,
}: PlaybackSettingsProviderProps) {
  const [displayFormat, setDisplayFormat] = useState<DisplayFormat>("elapsed");
  const [speed, setSpeed] = useState<PlaybackSpeedValue>(
    PlaybackSpeed.TimesTen
  );
  const [timestep, setTimestep] = useState<TimestepValue>(Timestep.Second);

  const contextValue: PlaybackSettings = useMemo(
    () => ({
      displayFormat,
      setDisplayFormat,
      speed,
      setSpeed,
      timestep,
      setTimestep,
    }),
    [displayFormat, speed, timestep]
  );

  return (
    <PlaybackSettingsContext.Provider value={contextValue}>
      {children}
    </PlaybackSettingsContext.Provider>
  );
}

export const [usePlaybackSource, PlaybackSourceContext] =
  createSafeContext<PlaybackSource>("PlaybackSource");

export function useFormatPlaybackTimestamp() {
  const playbackSettings = usePlaybackSettings();
  const playbackSource = usePlaybackSource();

  const lowerBoundMs = playbackSource.bounds?.startTimeMs;

  return useCallback(
    (timestampMs: number) =>
      playbackSettings.displayFormat === "elapsed"
        ? formatTimestamp(timestampMs, {
            precision: 1,
            relativeToMs: lowerBoundMs,
          })
        : (timestampMs / 1_000).toFixed(1),
    [playbackSettings.displayFormat, lowerBoundMs]
  );
}

export interface PlaybackSourceProviderProps {
  /**
   * UTC timestamps representing the upper and lower playback bounds in
   * milliseconds. All timestamps will be clamped to within these bounds.
   * Conceptually, the lower bound can be considered t = 0 and can be used for
   * displaying UTC timestamps relative to how long after this time they
   * occurred. Set this value to undefined if it needs to be fetched
   * asynchronously. Trying to perform playback operations while this is
   * undefined will result in an error.
   */
  bounds?: TimeRange;
  /**
   * A UTC timestamp in milliseconds representing the default time at which
   * playback should start. Useful if loading a time from an external source
   * like a URL query param. If not given the default time will be the
   * lower playback bound. Will be clamped within `bounds` before being
   * passed through the context
   */
  initialTimeMs?: Maybe<number>;
  children: React.ReactNode;
}

export function PlaybackSourceProvider({
  bounds,
  initialTimeMs,
  children,
}: PlaybackSourceProviderProps) {
  const clampedInitialTimeMs =
    bounds !== undefined
      ? // Since initial time comes from an outside source like a URL, there's
        // no guarantee it's within playback bounds
        clamp(
          initialTimeMs ?? bounds.startTimeMs,
          bounds.startTimeMs,
          bounds.endTimeMs
        )
      : undefined;

  const playbackSettings = usePlaybackSettings();

  const [playbackSourceState, dispatch] = useImmerReducer(
    makeReducer(bounds, clampedInitialTimeMs, playbackSettings.timestep),
    initialState
  );

  const {
    timestampMs: effectiveTimestampMs = clampedInitialTimeMs,
    range: effectiveRange = bounds,
  } = playbackSourceState;

  const isLoading = bounds === undefined || effectiveTimestampMs === undefined;

  const value: BasePlaybackSource = {
    isLoading,
    bounds: isLoading ? undefined : bounds,
    mode: playbackSourceState.status === "range-mode" ? "range" : "single",
    inRangeMode: playbackSourceState.status === "range-mode",
    isPlaying: playbackSourceState.status === "playing",
    range: isLoading ? undefined : effectiveRange,
    timestampMs: isLoading ? undefined : effectiveTimestampMs,
    dispatch,
  };

  return (
    <PlaybackSourceContext.Provider value={value as PlaybackSource}>
      {children}
    </PlaybackSourceContext.Provider>
  );
}

export function LogPlaybackSourceProvider({
  children,
}: PlaybackSourceProviderProps) {
  const { logId, initialTimeMs } = usePlayerConfig();
  const playerLogQuery = usePlayerLog();

  let bounds: TimeRange | undefined = undefined;
  if (
    playerLogQuery.isSuccess &&
    playerLogQuery.data.startTimeMs !== null &&
    playerLogQuery.data.endTimeMs !== null
  ) {
    bounds = {
      startTimeMs: playerLogQuery.data.startTimeMs,
      endTimeMs: playerLogQuery.data.endTimeMs,
    };
  }

  return (
    <PlaybackSourceProvider
      key={logId}
      bounds={bounds}
      initialTimeMs={initialTimeMs}
    >
      {children}
    </PlaybackSourceProvider>
  );
}
