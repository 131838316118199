import type { QueryFunction } from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import invariant from "invariant";
import { useDataStoreClients } from "./hooks";
import type {
  DataStoreQueryKey,
  UseDataStoreMutationFullOptions,
  UseDataStoreMutationResult,
  UseDataStoreQueryFullOptions,
  UseDataStoreQueryOptions,
  UseDataStoreQueryResult,
} from "./types";

/**
 * Generic utility for building the options for a DataStore query. Could be
 * kept internal if a custom `useDataStoreQueries` hook was exported instead.
 */
export function useBuildDataStoreQuery() {
  const clients = useDataStoreClients();

  const canRun = clients !== null;

  function assertBasePathsMatch(dataStoreQueryKey: DataStoreQueryKey) {
    // The DataStore base path in the query key must match the base path of the
    // API clients (or both must be null if there's no DataStore connection).
    // Since the query builder below doesn't add the query key prefix itself,
    // this check ensures calling code didn't make a mistake.
    const [{ basePath: queryKeyBasePath }] = dataStoreQueryKey;

    if (clients === null) {
      invariant(queryKeyBasePath === null, "Query key prefix mismatch");
    } else {
      invariant(
        clients.basePath === queryKeyBasePath,
        "Query key prefix mismatch"
      );
    }
  }

  return {
    // `canRun` flag is the same for all queries made using the returned builder
    canRun,
    buildDataStoreQuery<
      TQueryFnData = unknown,
      TError = unknown,
      TData = TQueryFnData,
      TQueryKey extends DataStoreQueryKey = DataStoreQueryKey
    >(
      initialOptions: UseDataStoreQueryFullOptions<
        TQueryFnData,
        TError,
        TData,
        TQueryKey
      >
    ): UseDataStoreQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
      queryFn: QueryFunction<TQueryFnData, TQueryKey>;
    } {
      assertBasePathsMatch(initialOptions.queryKey);

      const {
        enabled: initialEnabled = true,
        queryFn: userQueryFn,
        ...remainingOptions
      } = initialOptions;

      const enabled = initialEnabled && canRun;

      return {
        ...remainingOptions,
        queryFn(context) {
          invariant(canRun, "No DataStore connection");

          return userQueryFn(context, clients);
        },
        enabled,
      };
    },
  };
}

/**
 * Custom wrapper around {@link useQuery}. Enforces query keys are
 * {@link DataStoreQueryKey} types and passes configured API clients to
 * provided query function.
 */
export function useDataStoreQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends DataStoreQueryKey = DataStoreQueryKey
>(
  initialOptions: UseDataStoreQueryFullOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryKey
  >
): UseDataStoreQueryResult<TData, TError> {
  const { canRun, buildDataStoreQuery } = useBuildDataStoreQuery();

  // Though not currently used, react-query provides a tracking feature where
  // it will only re-render if a property you read during render changes. I'd
  // eventually like to use that, so object spread syntax can't be used here as
  // it would effectively defeat the tracked feature by "reading" every property
  // on the result.
  return Object.defineProperty(
    useQuery(buildDataStoreQuery(initialOptions)),
    "canRun",
    { value: canRun, writable: false }
  ) as UseDataStoreQueryResult<TData, TError>;
}

/**
 * Custom wrapper around {@link useMutation}. Passes configured API clients to
 * provided mutation function.
 */
export function useDataStoreMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  initialOptions: UseDataStoreMutationFullOptions<
    TData,
    TError,
    TVariables,
    TContext
  >
): UseDataStoreMutationResult<TData, TError, TVariables, TContext> {
  const clients = useDataStoreClients();

  const canRun = clients !== null;

  const { mutationFn: userMutationFn, ...remainingOptions } = initialOptions;

  // Unlike queries, mutations don't have a tracking feature so it's perfectly
  // fine to use spread syntax here.
  return {
    ...useMutation({
      ...remainingOptions,
      mutationFn(variables) {
        invariant(canRun, "Mutation cannot run");

        return userMutationFn(variables, clients);
      },
    }),
    canRun,
  };
}
