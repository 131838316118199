import { Settings } from "@mui/icons-material";
import { Box, Container } from "@mui/material";
import GlobalNavigation from "../../components/GlobalNavigation";
import Header from "../../components/Header";
import Helmet from "../../components/Helmet";
import Layout, {
  LayoutStateProvider,
  SidebarSwitch,
  SideSheetTrigger,
} from "../../components/Layout";
import NoDataStoreAlert from "../../components/NoDataStoreAlert";
import SettingsDrawer from "../../components/SettingsDrawer";
import LogForm from "./components/CreateForm";

export default function NewLog() {
  return (
    <>
      <Helmet>
        <title>New Log</title>
      </Helmet>
      <LayoutStateProvider>
        <Layout
          header={
            <Header
              title="New Log"
              actions={
                <SideSheetTrigger
                  title="Settings"
                  sidebarId="settings"
                  icon={<Settings />}
                />
              }
            />
          }
          globalNavigation={<GlobalNavigation />}
          sideSheet={
            <SidebarSwitch
              config={[
                {
                  id: "settings",
                  element: <SettingsDrawer />,
                },
              ]}
            />
          }
        >
          <Box sx={{ flexGrow: 1, minWidth: 0, overflowY: "auto", py: 4 }}>
            <Container fixed>
              <NoDataStoreAlert sx={{ mb: 4 }} />
              <LogForm />
            </Container>
          </Box>
        </Layout>
      </LayoutStateProvider>
    </>
  );
}
