import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { LogSelect, omitNullFields, TextField } from "../../../components/Form";
import { useStudioForm } from "../../../components/Form/hooks";
import { useCreateExtraction } from "../../../domain/crud";
import { makeExtractionLocation, useStudioNavigate } from "../../../paths";
import { createExtractionSchema } from "./validation";

export default function ExtractionForm() {
  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const createExtraction = useCreateExtraction();

  const { control, handleSubmit } = useStudioForm({
    schema: createExtractionSchema,
    defaultValues: {
      name: null,
      note: null,
      logId: null,
    },
    onSubmit(values) {
      createExtraction.mutate(omitNullFields(values), {
        onSuccess(response) {
          navigate(makeExtractionLocation(response.data.id));
        },
        onError() {
          enqueueSnackbar("Unable to create extraction", { variant: "error" });
        },
      });
    },
  });

  return (
    <Card>
      <CardContent>
        <Grid component="form" container onSubmit={handleSubmit} spacing={2}>
          <Grid item xs={3}>
            <LogSelect control={control} name="logId" label="Log" />
          </Grid>
          <Grid item xs={3}>
            <TextField control={control} name="name" />
          </Grid>
          <Grid item xs={3}>
            <TextField control={control} name="note" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={createExtraction.isLoading}
              type="submit"
              variant="contained"
            >
              Create
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
