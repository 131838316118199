import { useQueryClient } from "@tanstack/react-query";
import invariant from "invariant";
import type { StrictOmit } from "ts-essentials";
import type {
  ListRecordsRequest,
  ListTopicsRequest,
  Record,
  RecordCreateRequest,
  RecordFetchResponse,
  RecordListResponse,
  RecordUpdateRequest,
  Topic,
  TopicCreateRequest,
  TopicFetchResponse,
  TopicListResponse,
  TopicUpdateRequest,
} from "../../services/datastore";
import type { ResolvedKeyFactory } from "../../types";
import type { UseDataStoreQueryOptions } from "../datastores";
import {
  useDataStoreMutation,
  useDataStoreQuery,
  useDataStoreQueryKey,
} from "../datastores";
import { getInitialDetailsData, mergeEnabledOption } from "./utils";

export function useTopicKeys() {
  const baseKey = useDataStoreQueryKey(["topics"] as const);
  const factory = {
    all: baseKey,
    lists: () => [...factory.all, "list"] as const,
    list: (request: ListTopicsRequest) =>
      [...factory.lists(), request] as const,
    details: () => [...factory.all, "details"] as const,
    detail: (topicId: Topic["id"] | null) =>
      [...factory.details(), topicId] as const,
  } as const;

  return factory;
}

export type TopicKeys = ResolvedKeyFactory<typeof useTopicKeys>;

export function useTopics<TData = TopicListResponse>(
  request: ListTopicsRequest,
  options?: UseDataStoreQueryOptions<
    TopicListResponse,
    unknown,
    TData,
    TopicKeys["list"]
  >
) {
  return useDataStoreQuery({
    queryKey: useTopicKeys().list(request),
    queryFn(context, { topicApi }) {
      return topicApi.listTopics(request, context);
    },
    ...options,
  });
}

export function useTopic<TData = TopicFetchResponse>(
  topicId: Topic["id"] | null,
  options?: StrictOmit<
    UseDataStoreQueryOptions<
      TopicFetchResponse,
      unknown,
      TData,
      TopicKeys["detail"]
    >,
    "initialData"
  >
) {
  const queryClient = useQueryClient();

  const topicKeys = useTopicKeys();

  const isTopicIdValid = topicId !== null;

  return useDataStoreQuery({
    queryKey: topicKeys.detail(topicId),
    queryFn(context, { topicApi }) {
      invariant(isTopicIdValid, "Topic ID cannot be null");

      return topicApi.getTopic({ topicId }, context);
    },
    ...options,
    enabled: mergeEnabledOption(options, isTopicIdValid),
    initialData() {
      return getInitialDetailsData(
        queryClient,
        topicKeys.lists(),
        (topic: Topic) => topic.id === topicId
      );
    },
  });
}

export function useCreateTopic() {
  const topicKeys = useTopicKeys();

  const queryClient = useQueryClient();

  return useDataStoreMutation({
    mutationFn(request: TopicCreateRequest, { topicApi }) {
      return topicApi.createTopic({ topicCreateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData<TopicFetchResponse>(
        topicKeys.detail(response.data.id),
        response
      );
    },
  });
}

export function useUpdateTopic(topicId: Topic["id"]) {
  const queryClient = useQueryClient();
  const topicKeys = useTopicKeys();

  return useDataStoreMutation({
    mutationFn(request: TopicUpdateRequest, { topicApi }) {
      return topicApi.updateTopic({ topicId, topicUpdateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData<TopicFetchResponse>(
        topicKeys.detail(response.data.id),
        response
      );
    },
  });
}

export function useDeleteTopic(topicId: Topic["id"]) {
  return useDataStoreMutation({
    mutationFn(_, { topicApi }) {
      return topicApi.deleteTopic({ topicId });
    },
  });
}

export function useRecordKeys() {
  const topicKeys = useTopicKeys();

  const factory = {
    all: (topicId: Topic["id"]) =>
      [...topicKeys.all, topicId, "records"] as const,
    lists: (topicId: Topic["id"]) => [...factory.all(topicId), "list"] as const,
    list: (
      topicId: Topic["id"],
      request: StrictOmit<ListRecordsRequest, "topicId">
    ) => [...factory.lists(topicId), request] as const,
    details: (topicId: Topic["id"]) =>
      [...factory.all(topicId), "details"] as const,
    detail: (topicId: Topic["id"], timestamp: Record["timestamp"]) =>
      [...factory.details(topicId), timestamp] as const,
  } as const;

  return factory;
}

export type RecordKeys = ResolvedKeyFactory<typeof useRecordKeys>;

export function useRecords<TData = RecordListResponse>(
  topicId: Topic["id"],
  request: StrictOmit<ListRecordsRequest, "topicId">,
  options?: UseDataStoreQueryOptions<
    RecordListResponse,
    unknown,
    TData,
    RecordKeys["list"]
  >
) {
  return useDataStoreQuery({
    queryKey: useRecordKeys().list(topicId, request),
    queryFn(context, { topicApi }) {
      return topicApi.listRecords({ topicId, ...request }, context);
    },
    ...options,
  });
}

export function useRecord<TData = RecordFetchResponse>(
  topicId: Topic["id"],
  timestamp: Record["timestamp"],
  options?: StrictOmit<
    UseDataStoreQueryOptions<
      RecordFetchResponse,
      unknown,
      TData,
      RecordKeys["detail"]
    >,
    "initialData"
  >
) {
  const queryClient = useQueryClient();

  const recordKeys = useRecordKeys();

  return useDataStoreQuery({
    queryKey: recordKeys.detail(topicId, timestamp),
    queryFn(context, { topicApi }) {
      return topicApi.getRecord({ topicId, timestamp }, context);
    },
    ...options,
    initialData() {
      return getInitialDetailsData(
        queryClient,
        recordKeys.lists(topicId),
        (record: Record) =>
          record.topicId === topicId && record.timestamp === timestamp
      );
    },
  });
}

export function useCreateRecord(topicId: Topic["id"]) {
  const recordKeys = useRecordKeys();

  const queryClient = useQueryClient();

  return useDataStoreMutation({
    mutationFn(request: RecordCreateRequest, { topicApi }) {
      return topicApi.createRecord({ topicId, recordCreateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData<RecordFetchResponse>(
        recordKeys.detail(topicId, response.data.timestamp),
        response
      );
    },
  });
}

export function useUpdateRecord(
  topicId: Topic["id"],
  timestamp: Record["timestamp"]
) {
  return useDataStoreMutation({
    mutationFn(request: RecordUpdateRequest, { topicApi }) {
      return topicApi.updateRecord({
        topicId,
        timestamp,
        recordUpdateRequest: request,
      });
    },
  });
}

export function useDeleteRecord(
  topicId: Topic["id"],
  timestamp: Record["timestamp"]
) {
  return useDataStoreMutation({
    mutationFn(_, { topicApi }) {
      return topicApi.deleteRecord({ topicId, timestamp });
    },
  });
}
