import React, { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Error } from "@mui/icons-material";
import { Alert, Box, InputAdornment, TextField } from "@mui/material";
import { some } from "lodash";
import { PencilLock } from "mdi-material-ui";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";
import {
  isOriginMixedContent,
  isOriginSecure,
  useCustomDataStores,
} from "../../domain/datastores";
import type { DataStore } from "../../models";
import { CustomDataStore } from "../../models";
import { selectData } from "../../utils";
import IconicTypography from "../IconicTypography";

type FormValues = z.infer<typeof ValidationSchema>;
// Makes TypeScript happy
type FormValuesWithServerError = FormValues & { server: void };

export type CustomConnectionFormSubmitHandler = (
  values: FormValues
) => Promise<CustomDataStore>;

export interface CustomConnectionFormProps {
  onSubmit: CustomConnectionFormSubmitHandler;
  formId?: HTMLFormElement["id"];
  origin?: DataStore["origin"];
}

export default function CustomConnectionForm({
  onSubmit: userOnSubmit,
  formId,
  origin,
}: CustomConnectionFormProps) {
  const [isReadOnly] = useState(origin !== undefined);

  const { data: customDataStores } = useCustomDataStores({
    select: selectData,
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormValuesWithServerError>({
    defaultValues: {
      name: "",
      origin: origin ?? "",
    },
    resolver: makeResolver(customDataStores),
  });

  const showInsecureWarning = isReadOnly && !isOriginSecure(origin!);

  const onSubmit: SubmitHandler<FormValuesWithServerError> =
    async function onSubmit({ name, origin }) {
      try {
        await userOnSubmit({ name, origin });
      } catch (e) {
        setError("server", {
          type: "custom",
          message: "An unexpected error occurred",
        });
      }
    };

  return (
    <Box id={formId} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="origin"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="URL"
            fullWidth
            margin="dense"
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message || " "}
            {...(isReadOnly && {
              InputProps: {
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <PencilLock />
                  </InputAdornment>
                ),
              },
            })}
          />
        )}
      />
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="Name"
            fullWidth
            margin="dense"
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message || " "}
          />
        )}
      />
      {showInsecureWarning && (
        <Alert variant="filled" severity="warning">
          Connection will be unencrypted.
          {isOriginMixedContent(origin!) && (
            <>
              {" "}
              Additionally, your browser will likely block the connection if the
              URL does not resolve to a loopback address.
            </>
          )}
        </Alert>
      )}
      {errors.server?.message !== undefined && (
        <IconicTypography icon={<Error color="error" />}>
          {errors.server.message}
        </IconicTypography>
      )}
    </Box>
  );
}

function makeUniquenessRefinement(dataStores: CustomDataStore[]) {
  return function isOriginUnique(origin: DataStore["origin"]): boolean {
    return !some(dataStores, { origin });
  };
}

const ValidationSchema = CustomDataStore.pick({ name: true, origin: true });

// zod doesn't allow passing dynamic arguments to refinements at parse time, in
// this case the current user's DataStores. The workaround is to create the
// schema on each render, closing over the DataStores.
function makeResolver(dataStores: CustomDataStore[]) {
  const ExtendedSchema = ValidationSchema.extend({
    origin: ValidationSchema.shape.origin.refine(
      makeUniquenessRefinement(dataStores),
      { message: "An existing connection has this origin" }
    ),
  });

  return zodResolver(ExtendedSchema);
}
