import { useState } from "react";
import { Typography } from "@mui/material";
import { DeleteDialog } from "../../../components/DeleteDialog";
import { dateTimeFields, half } from "../../../components/DetailsCard";
import DetailsCard from "../../../components/DetailsCard/DetailsCard";
import type { ResourceField } from "../../../components/DetailsCard/types";
import { AdminGuard } from "../../../domain/auth";
import { useDeleteUser, useUser } from "../../../domain/crud";
import {
  makeEditUserLocation,
  makeUsersLocation,
  useStudioNavigate,
  useUserParams,
} from "../../../paths";
import type { User } from "../../../services/datastore";
import { GroupManagement } from "./GroupManagement";

const FIELDS: ReadonlyArray<ResourceField<User>> = [
  {
    col: half,
    label: "Username",
    renderField(user) {
      return (
        <>
          {user.username}
          <Typography variant="body2">{user.id}</Typography>
        </>
      );
    },
  },
  { col: half, accessor: "isAdmin", dataType: "boolean" },
  ...dateTimeFields,
];

export default function Details() {
  const { userId } = useUserParams();

  const userQuery = useUser(userId, {
    select(response) {
      return response.data;
    },
  });

  const navigate = useStudioNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const deleteUser = useDeleteUser(userId);

  function closeModal() {
    setShowDeleteDialog(false);
  }

  function openModal() {
    setShowDeleteDialog(true);
  }

  function handleDelete() {
    navigate(makeUsersLocation());
  }

  return (
    <>
      <DetailsCard
        editLocation={makeEditUserLocation(userId)}
        onDelete={openModal}
        query={userQuery}
        fields={FIELDS}
      />
      <AdminGuard>
        <GroupManagement />
      </AdminGuard>
      <DeleteDialog
        open={showDeleteDialog}
        onClose={closeModal}
        resourceName="user"
        resourceLoading={userQuery.isLoading}
        mutation={deleteUser}
        onDelete={handleDelete}
      >
        {userQuery.data?.username}
      </DeleteDialog>
    </>
  );
}
