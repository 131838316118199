import { useLog } from "../../../domain/crud";
import type { LogFetchResponse } from "../../../services/datastore";
import { calculateLogBoundsInMs } from "../../../utils/logs";
import type { PlayerLog } from "../types";
import usePlayerConfig from "./usePlayerConfig";

export default function usePlayerLog() {
  const { logId } = usePlayerConfig();

  return useLog(logId, { select: selectPlayerLog });
}

function selectPlayerLog({ data }: LogFetchResponse): PlayerLog {
  return {
    ...data,
    ...calculateLogBoundsInMs(data),
  };
}
