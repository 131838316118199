import { useCallback } from "react";
import { omit, snakeCase } from "lodash";
import { useSearchParams } from "react-router-dom";
import type { ValueOf } from "type-fest";
import { z } from "zod";
import { getSortableFields } from "./columns";
import type { Column } from "./types";

export interface PaginationModel {
  limit: number;
  offset: number;
}

export interface ResourceTableModel extends PaginationModel {
  sort: SortDirection;
  order: string;
}

export const LIMIT_OPTIONS = [5, 10, 25, 50, 100];

export const SortDirection = {
  Asc: "asc",
  Desc: "desc",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SortDirection = ValueOf<typeof SortDirection>;

const orderKeys = new Map([
  ["md5", "md5"],
  ["s3Key", "s3_key"],
  ["s3Bucket", "s3_bucket"],
]);

/**
 * Perform common preparation tasks on a list request's parameters.
 *
 * Tasks performed:
 * 1. The `order` field's value is converted to snake_case
 */
export function prepareListRequest<TRequest extends { order: string }>(
  request: TRequest
): TRequest {
  return {
    ...request,
    order: orderKeys.get(request.order) ?? snakeCase(request.order),
  };
}

const number = z.coerce.number();

export function makeRequestSchema<TFilterSchema extends z.AnyZodObject>(
  columns: ReadonlyArray<Column<any>>,
  filterSchema: TFilterSchema
): z.ZodType<
  z.output<TFilterSchema> & ResourceTableModel,
  z.ZodTypeDef,
  z.input<TFilterSchema> & Partial<ResourceTableModel>
> {
  const sortableFields = getSortableFields(columns);

  const baseSchema = z
    .object({
      sort: z.nativeEnum(SortDirection).default(SortDirection.Desc),
      order: z
        .string()
        .refine((value) => sortableFields.includes(value as any))
        // This isn't type-safe but all resource (as of now) have
        // a `createdAt` field
        .default("createdAt"),
      limit: number
        .refine((value) => LIMIT_OPTIONS.includes(value))
        .default(LIMIT_OPTIONS[1]),
      offset: number.nonnegative().int().default(0),
    }) // Check if `offset` is a multiple of `limit`
    .refine(({ limit, offset }) => offset % limit === 0);

  return filterSchema.and(baseSchema);
}

export function useSearchRequest<TRequestSchema extends z.ZodTypeAny>(
  requestSchema: TRequestSchema
): [
  z.infer<TRequestSchema>,
  (updates: Partial<z.infer<TRequestSchema>>) => void
] {
  const [searchParams, setSearchParams] = useSearchParams();

  // TODO: This will throw during render if something is wrong
  const request = requestSchema.parse(Object.fromEntries(searchParams));

  const setRequest = useCallback(
    (updates: Partial<z.infer<TRequestSchema>>) => {
      setSearchParams((prevSearchParams) => {
        const updatedSearchParams = new URLSearchParams(prevSearchParams);

        // The offset should always reset when the filters, page size or
        // sort parameters change
        if (!("offset" in updates)) {
          updatedSearchParams.set("offset", "0");
        }

        Object.entries(updates).forEach(([key, value]: [string, unknown]) => {
          if (value === null) {
            updatedSearchParams.delete(key);
          } else {
            let serializedValue: string;
            if (value instanceof Date) {
              serializedValue = value.toISOString();
            } else if (typeof value === "boolean") {
              serializedValue = value ? "1" : "0";
            } else {
              serializedValue = String(value);
            }

            updatedSearchParams.set(key, serializedValue);
          }
        });

        return updatedSearchParams;
      });
    },
    [setSearchParams]
  );

  return [request, setRequest];
}

export function withoutBaseTableModel<TRequest extends ResourceTableModel>(
  request: TRequest
): Omit<TRequest, keyof ResourceTableModel> {
  return omit(request, ["sort", "order", "limit", "offset"]);
}

export function getActiveFiltersCount(
  filters: Record<string, unknown>
): number {
  return Object.entries(filters).filter(([, value]) => value != null).length;
}
