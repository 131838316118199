import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { pick } from "lodash";
import { useSnackbar } from "notistack";
import {
  getChangedFields,
  NumberField,
  TextField,
} from "../../../components/Form";
import { useStudioForm } from "../../../components/Form/hooks";
import { useUpdateRecord } from "../../../domain/crud";
import {
  makeRecordLocation,
  useRecordParams,
  useStudioNavigate,
} from "../../../paths";
import type { Record } from "../../../services/datastore";
import { EDITABLE_RECORD_FIELDS, editRecordSchema } from "./validation";

export interface RecordEditFormProps {
  record: Record;
}

export function RecordEditForm({ record }: RecordEditFormProps) {
  const { topicId, timestamp } = useRecordParams();

  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const updateRecord = useUpdateRecord(topicId, timestamp);

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useStudioForm({
    schema: editRecordSchema,
    defaultValues: pick(record, EDITABLE_RECORD_FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      // @ts-ignore Issue with OpenAPI spec null vs. undefined
      updateRecord.mutate(changedFields, {
        onSuccess() {
          enqueueSnackbar("Record updated", { variant: "success" });

          navigate(makeRecordLocation(topicId, timestamp));
        },
        onError() {
          enqueueSnackbar("Could not update record", { variant: "error" });
        },
      });
    },
  });

  return (
    <Card>
      <CardContent>
        <Grid component="form" container onSubmit={handleSubmit} spacing={2}>
          <Grid item xs={6}>
            <NumberField control={control} name="offset" label="Offset" />
          </Grid>
          <Grid item xs={6}>
            <NumberField control={control} name="length" label="Length" />
          </Grid>
          <Grid item xs={6}>
            <NumberField
              control={control}
              name="dataOffset"
              label="Data Offset"
            />
          </Grid>
          <Grid item xs={6}>
            <NumberField
              control={control}
              name="dataLength"
              label="Data Length"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              control={control}
              name="chunkCompression"
              label="Chunk Compression"
            />
          </Grid>
          <Grid item xs={6}>
            <NumberField
              control={control}
              name="nanosecond"
              label="Nanosecond"
            />
          </Grid>
          <Grid item xs={6}>
            <NumberField
              control={control}
              name="chunkOffset"
              label="Chunk Offset"
            />
          </Grid>
          <Grid item xs={6}>
            <NumberField
              control={control}
              name="chunkLength"
              label="Chunk Length"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField control={control} name="s3Bucket" label="S3 Bucket" />
          </Grid>
          <Grid item xs={6}>
            <TextField control={control} name="s3Key" label="S3 Key" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={updateRecord.isLoading}
              type="submit"
              variant="contained"
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
