import { Button, Grid } from "@mui/material";
import {
  BooleanField,
  DateField,
  FuzzyField,
  GroupSelect,
  NumberField,
  TextField,
} from "../../../components/Form";
import { UserSelect } from "../../../components/Form/UserSelect";
import { useStudioForm } from "../../../components/Form/hooks";
import { GTE, LTE } from "../../../constants";
import type { ListLogsFormValues } from "./validation";
import { listLogsSchema } from "./validation";

export interface LogFiltersProps {
  values: ListLogsFormValues;
  setValues: (newValues: ListLogsFormValues) => void;
}

export function LogFilters({ values, setValues }: LogFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listLogsSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={3}>
          <FuzzyField
            control={control}
            exactName="name"
            fuzzyName="nameLike"
            label="Name"
          />
        </Grid>
        <Grid item xs={3}>
          <GroupSelect control={control} name="groupId" label="Group" />
        </Grid>
        <Grid item xs={3}>
          <BooleanField control={control} name="locked" label="Locked?" />
        </Grid>
        <Grid item xs={3}>
          <TextField control={control} name="noteLike" label="Note" />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <NumberField
            control={control}
            name="startTimeGte"
            label={`Start Time ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberField
            control={control}
            name="startTimeLte"
            label={`Start Time ${LTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="startTimeNull"
            label="Start Time Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <NumberField
            control={control}
            name="endTimeGte"
            label={`End Time ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberField
            control={control}
            name="endTimeLte"
            label={`End Time ${LTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="endTimeNull"
            label="End Time Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="createdBy" label="Created By" />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="createdAtGte"
            label={`Created At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="createdAtLte"
            label={`Created At ${LTE}`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="updatedBy" label="Updated By" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="updatedByNull"
            label="Updated By Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="updatedAtGte"
            label={`Updated At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="updatedAtLte"
            label={`Updated At ${LTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="updatedAtNull"
            label="Updated At Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="deletedBy" label="Deleted By" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="deletedByNull"
            label="Deleted By Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="deletedAtGte"
            label={`Deleted At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="deletedAtLte"
            label={`Deleted At ${LTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="deletedAtNull"
            label="Deleted At Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
