import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { enablePatches, enableMapSet } from "immer";
import { SnackbarProvider } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import DefaultErrorFallback from "./components/DefaultErrorFallback";
import GlobalLoadingFallback from "./components/GlobalLoadingFallback";
import { COGNITO_GROUPS_PROPERTY_NAME, getAppConfig } from "./config";
import "./css/styles.css";
import { DataStoreProvider } from "./domain/datastores";
import * as paths from "./paths";
import DarkModeProvider from "./providers/DarkModeProvider";
import ThemeProvider from "./providers/ThemeProvider";
import { AmplifyLogrusClient, LogrusProvider } from "./services/logrus";
import { ApiKey, ApiKeys, EditApiKey, NewApiKey } from "./views/ApiKeys";
import DataStores from "./views/DataStores";
import {
  EditExtraction,
  EditExtractionTopic,
  Extraction,
  Extractions,
  ExtractionTopic,
  NewExtraction,
} from "./views/Extractions";
import { NewExtractionTopic } from "./views/Extractions/NewExtractionTopic";
import { EditGroup, Group, Groups, NewGroup } from "./views/Groups";
import Home from "./views/Home";
import {
  EditIngestion,
  Ingestion,
  Ingestions,
  NewIngestion,
} from "./views/Ingestions";
import { EditLog, Log, Logs, NewLog } from "./views/Logs";
import Me from "./views/Me";
import NotFound from "./views/NotFound";
import Player from "./views/Player";
import { EditTopic, NewTopic, Record, Topic, Topics } from "./views/Topics";
import { EditRecord } from "./views/Topics/EditRecord";
import Upload from "./views/Upload";
import { EditUser, NewUser, User, Users } from "./views/Users";

enablePatches();
enableMapSet();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Preserve v3 behavior, opting out of smart query tracking (for now).
      // Smart tracking should be done on a case-by-case basis to avoid
      // unnoticed bugs
      notifyOnChangeProps: "all",
      // API clients use `fetch-retry` library to handle refetching
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      // Default staleTime of Infinity for all queries. We deal with too
      // much data to be refetching willy-nilly and I'd prefer to control
      // when refetching happens anyways
      staleTime: Infinity,
    },
  },
});

export default function App() {
  const [logrusClient] = useState(() => {
    const { apiDomainSuffix } = getAppConfig();

    return new AmplifyLogrusClient(
      COGNITO_GROUPS_PROPERTY_NAME,
      apiDomainSuffix
    );
  });

  return (
    <DarkModeProvider>
      <HelmetProvider>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ErrorBoundary FallbackComponent={DefaultErrorFallback}>
              <QueryClientProvider client={queryClient}>
                <Router>
                  <LogrusProvider
                    client={logrusClient}
                    loadingFallback={<GlobalLoadingFallback />}
                  >
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                      <DataStoreProvider>
                        <SnackbarProvider maxSnack={1}>
                          <Routes>
                            <Route
                              path={paths.DATASTORES}
                              element={<DataStores />}
                            />
                            <Route path={paths.PLAYER} element={<Player />} />
                            <Route path={paths.LOGS} element={<Logs />} />
                            <Route path={paths.LOG} element={<Log />} />
                            <Route path={paths.NEW_LOG} element={<NewLog />} />
                            <Route
                              path={paths.EDIT_LOG}
                              element={<EditLog />}
                            />
                            <Route path={paths.UPLOAD} element={<Upload />} />
                            <Route
                              path={paths.INGESTIONS}
                              element={<Ingestions />}
                            />
                            <Route
                              path={paths.INGESTION}
                              element={<Ingestion />}
                            />
                            <Route
                              path={paths.NEW_INGESTION}
                              element={<NewIngestion />}
                            />
                            <Route
                              path={paths.EDIT_INGESTION}
                              element={<EditIngestion />}
                            />
                            <Route
                              path={paths.EXTRACTIONS}
                              element={<Extractions />}
                            />
                            <Route
                              path={paths.EXTRACTION}
                              element={<Extraction />}
                            />
                            <Route
                              path={paths.NEW_EXTRACTION}
                              element={<NewExtraction />}
                            />
                            <Route
                              path={paths.EDIT_EXTRACTION}
                              element={<EditExtraction />}
                            />
                            <Route
                              path={paths.NEW_EXTRACTION_TOPIC}
                              element={<NewExtractionTopic />}
                            />
                            <Route
                              path={paths.EXTRACTION_TOPIC}
                              element={<ExtractionTopic />}
                            />
                            <Route
                              path={paths.EDIT_EXTRACTION_TOPIC}
                              element={<EditExtractionTopic />}
                            />
                            <Route path={paths.TOPICS} element={<Topics />} />
                            <Route path={paths.TOPIC} element={<Topic />} />
                            <Route
                              path={paths.NEW_TOPIC}
                              element={<NewTopic />}
                            />
                            <Route
                              path={paths.EDIT_TOPIC}
                              element={<EditTopic />}
                            />
                            <Route path={paths.RECORD} element={<Record />} />
                            <Route
                              path={paths.EDIT_RECORD}
                              element={<EditRecord />}
                            />
                            <Route path={paths.USERS} element={<Users />} />
                            <Route path={paths.USER} element={<User />} />
                            <Route
                              path={paths.NEW_USER}
                              element={<NewUser />}
                            />
                            <Route
                              path={paths.EDIT_USER}
                              element={<EditUser />}
                            />
                            <Route path={paths.GROUPS} element={<Groups />} />
                            <Route path={paths.GROUP} element={<Group />} />
                            <Route
                              path={paths.NEW_GROUP}
                              element={<NewGroup />}
                            />
                            <Route
                              path={paths.EDIT_GROUP}
                              element={<EditGroup />}
                            />
                            <Route
                              path={paths.API_KEYS}
                              element={<ApiKeys />}
                            />
                            <Route
                              path={paths.NEW_API_KEY}
                              element={<NewApiKey />}
                            />
                            <Route path={paths.API_KEY} element={<ApiKey />} />
                            <Route
                              path={paths.EDIT_API_KEY}
                              element={<EditApiKey />}
                            />
                            <Route path={paths.PROFILE} element={<Me />} />
                            <Route path={paths.INDEX} element={<Home />} />
                            <Route path="*" element={<NotFound />} />
                          </Routes>
                        </SnackbarProvider>
                      </DataStoreProvider>
                    </QueryParamProvider>
                  </LogrusProvider>
                </Router>
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
              </QueryClientProvider>
            </ErrorBoundary>
          </LocalizationProvider>
        </ThemeProvider>
      </HelmetProvider>
    </DarkModeProvider>
  );
}
