import React from "react";
import { useLogrus } from "../../services/logrus";

export interface AuthGuardProps {
  fallback?: React.ReactNode;
  children: React.ReactNode;
}

export default function AuthGuard({
  fallback = null,
  children,
}: AuthGuardProps) {
  const logrus = useLogrus();

  if (logrus.authStatus === "authenticated") {
    return <>{children}</>;
  }

  return <>{fallback}</>;
}
