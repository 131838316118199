/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  APIKeyCreateRequest,
  APIKeyCreateRequestFromJSON,
  APIKeyCreateRequestToJSON,
  APIKeyFetchResponse,
  APIKeyFetchResponseFromJSON,
  APIKeyFetchResponseToJSON,
  APIKeyListResponse,
  APIKeyListResponseFromJSON,
  APIKeyListResponseToJSON,
  APIKeyUpdateRequest,
  APIKeyUpdateRequestFromJSON,
  APIKeyUpdateRequestToJSON,
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface CreateApiKeyRequest {
  aPIKeyCreateRequest: APIKeyCreateRequest;
}

export interface DeleteApiKeyRequest {
  apiKeyId: string;
}

export interface GetApiKeyRequest {
  apiKeyId: string;
}

export interface ListApiKeysRequest {
  offset?: number;
  limit?: number;
  order?: string;
  sort?: string;
  userId?: string;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  updatedByNull?: boolean;
  deletedByNull?: boolean;
  updatedAtNull?: boolean;
  deletedAtNull?: boolean;
  createdAtLte?: Date;
  updatedAtLte?: Date;
  deletedAtLte?: Date;
  createdAtGte?: Date;
  updatedAtGte?: Date;
  deletedAtGte?: Date;
}

export interface UpdateApiKeyRequest {
  apiKeyId: string;
  aPIKeyUpdateRequest: APIKeyUpdateRequest;
}

/**
 *
 */
export class APIKeyApi extends runtime.BaseAPI {
  /**
   * Required role: [owner, admin]
   * Create Api Key
   */
  async createApiKeyRaw(
    this: APIKeyApi,
    requestParameters: CreateApiKeyRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<APIKeyFetchResponse>> {
    if (
      requestParameters.aPIKeyCreateRequest === null ||
      requestParameters.aPIKeyCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "aPIKeyCreateRequest",
        "Required parameter requestParameters.aPIKeyCreateRequest was null or undefined when calling createApiKey."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/apiKeys`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: APIKeyCreateRequestToJSON(requestParameters.aPIKeyCreateRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      APIKeyFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Required role: [owner, admin]
   * Create Api Key
   */
  async createApiKey(
    this: APIKeyApi,
    requestParameters: CreateApiKeyRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<APIKeyFetchResponse> {
    const response = await this.createApiKeyRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Required role: [owner, admin]
   * Delete Api Key
   */
  async deleteApiKeyRaw(
    this: APIKeyApi,
    requestParameters: DeleteApiKeyRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.apiKeyId === null ||
      requestParameters.apiKeyId === undefined
    ) {
      throw new runtime.RequiredError(
        "apiKeyId",
        "Required parameter requestParameters.apiKeyId was null or undefined when calling deleteApiKey."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/apiKeys/{api_key_id}`.replace(
          `{${"api_key_id"}}`,
          encodeURIComponent(String(requestParameters.apiKeyId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Required role: [owner, admin]
   * Delete Api Key
   */
  async deleteApiKey(
    this: APIKeyApi,
    requestParameters: DeleteApiKeyRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<void> {
    await this.deleteApiKeyRaw(requestParameters, initOverrides);
  }

  /**
   * Get Api Key
   */
  async getApiKeyRaw(
    this: APIKeyApi,
    requestParameters: GetApiKeyRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<APIKeyFetchResponse>> {
    if (
      requestParameters.apiKeyId === null ||
      requestParameters.apiKeyId === undefined
    ) {
      throw new runtime.RequiredError(
        "apiKeyId",
        "Required parameter requestParameters.apiKeyId was null or undefined when calling getApiKey."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/apiKeys/{api_key_id}`.replace(
          `{${"api_key_id"}}`,
          encodeURIComponent(String(requestParameters.apiKeyId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      APIKeyFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Api Key
   */
  async getApiKey(
    this: APIKeyApi,
    requestParameters: GetApiKeyRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<APIKeyFetchResponse> {
    const response = await this.getApiKeyRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * List Api Keys
   */
  async listApiKeysRaw(
    this: APIKeyApi,
    requestParameters: ListApiKeysRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<APIKeyListResponse>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.userId !== undefined) {
      queryParameters["user_id"] = requestParameters.userId;
    }

    if (requestParameters.createdBy !== undefined) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy !== undefined) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy !== undefined) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull !== undefined) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull !== undefined) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull !== undefined) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull !== undefined) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte !== undefined) {
      queryParameters["created_at_lte"] = (
        requestParameters.createdAtLte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtLte !== undefined) {
      queryParameters["updated_at_lte"] = (
        requestParameters.updatedAtLte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtLte !== undefined) {
      queryParameters["deleted_at_lte"] = (
        requestParameters.deletedAtLte as any
      ).toISOString();
    }

    if (requestParameters.createdAtGte !== undefined) {
      queryParameters["created_at_gte"] = (
        requestParameters.createdAtGte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtGte !== undefined) {
      queryParameters["updated_at_gte"] = (
        requestParameters.updatedAtGte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtGte !== undefined) {
      queryParameters["deleted_at_gte"] = (
        requestParameters.deletedAtGte as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/apiKeys`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      APIKeyListResponseFromJSON(jsonValue)
    );
  }

  /**
   * List Api Keys
   */
  async listApiKeys(
    this: APIKeyApi,
    requestParameters: ListApiKeysRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<APIKeyListResponse> {
    const response = await this.listApiKeysRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Api Key
   */
  async updateApiKeyRaw(
    this: APIKeyApi,
    requestParameters: UpdateApiKeyRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<APIKeyFetchResponse>> {
    if (
      requestParameters.apiKeyId === null ||
      requestParameters.apiKeyId === undefined
    ) {
      throw new runtime.RequiredError(
        "apiKeyId",
        "Required parameter requestParameters.apiKeyId was null or undefined when calling updateApiKey."
      );
    }

    if (
      requestParameters.aPIKeyUpdateRequest === null ||
      requestParameters.aPIKeyUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "aPIKeyUpdateRequest",
        "Required parameter requestParameters.aPIKeyUpdateRequest was null or undefined when calling updateApiKey."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/apiKeys/{api_key_id}`.replace(
          `{${"api_key_id"}}`,
          encodeURIComponent(String(requestParameters.apiKeyId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: APIKeyUpdateRequestToJSON(requestParameters.aPIKeyUpdateRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      APIKeyFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Api Key
   */
  async updateApiKey(
    this: APIKeyApi,
    requestParameters: UpdateApiKeyRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<APIKeyFetchResponse> {
    const response = await this.updateApiKeyRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
