import { useTopic, useTopics } from "../../domain/crud";
import type { Log } from "../../services/datastore";
import { makeResourceSelect } from "./makeResourceSelect";

type ExtraTopicSelectProps = {
  logId?: Log["id"];
};

export const TopicSelect = makeResourceSelect<ExtraTopicSelectProps>({
  useGet(uuid) {
    return useTopic(uuid, {
      select({ data: { id, name } }) {
        return {
          value: id,
          label: name,
        };
      },
    });
  },
  useSearch(text, { logId }) {
    return useTopics(
      {
        nameLike: text ?? "",
        limit: 5,
        sort: "asc",
        order: "name",
        logId,
      },
      {
        keepPreviousData: true,
        enabled: text !== null,
        select({ data }) {
          return data.map((topic) => ({
            value: topic.id,
            label: topic.name,
          }));
        },
      }
    );
  },
});
