import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { CopyableText } from "../../../components/CopyableText";
import { TextField } from "../../../components/Form";
import { UserSelect } from "../../../components/Form/UserSelect";
import { useStudioForm } from "../../../components/Form/hooks";
import { useCreateApiKey } from "../../../domain/crud";
import { makeApiKeysLocation, useStudioNavigate } from "../../../paths";
import { createApiKeySchema } from "./validation";

export function CreateForm() {
  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const createApiKey = useCreateApiKey();

  const { control, handleSubmit } = useStudioForm({
    schema: createApiKeySchema,
    defaultValues: {
      name: null,
      userId: null,
    },
    onSubmit(values) {
      createApiKey.mutate(values, {
        onError() {
          enqueueSnackbar("Could not create API key", { variant: "error" });
        },
      });
    },
  });

  function handleCloseDialog() {
    navigate(makeApiKeysLocation());
  }

  return (
    <>
      <Card>
        <CardContent>
          <Grid component="form" container onSubmit={handleSubmit} spacing={2}>
            <Grid item xs={6}>
              <TextField control={control} name="name" />
            </Grid>
            <Grid item xs={6}>
              <UserSelect control={control} name="userId" label="User" />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={createApiKey.isLoading}
                type="submit"
                variant="contained"
              >
                Create
              </LoadingButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {createApiKey.isSuccess && (
        <Dialog open maxWidth="xs" fullWidth>
          <DialogTitle>API Key Created</DialogTitle>
          <DialogContent>
            <Typography paragraph>
              Authenticating with this API key requires both its ID and secret
            </Typography>
            <CopyableText label="ID" text={createApiKey.data.data.id} />
            <CopyableText
              label="Secret"
              text={createApiKey.data.data.secret!}
            />
            <Typography
              paragraph
              variant="body2"
              sx={{ fontWeight: "bold", mt: 1 }}
            >
              This is the only time you will have access to this secret!
            </Typography>
            <Typography variant="body2">
              Closing this dialog will redirect you to the table page
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
