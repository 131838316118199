import React from "react";
import { useCurrentUser } from "../crud";

export type AdminGuardProps = {
  children: React.ReactNode;
};

export function AdminGuard({ children }: AdminGuardProps) {
  const isAdminQuery = useCurrentUser({
    select(response) {
      return response.data.isAdmin;
    },
  });

  if (isAdminQuery.isSuccess && isAdminQuery.data) {
    return <>{children}</>;
  }

  return null;
}
