import type { UseQueryOptions } from "@tanstack/react-query";
import { useQueries } from "@tanstack/react-query";
import { millisecondsToSeconds, secondsToMilliseconds } from "date-fns";
import invariant from "invariant";
import type { StrictOmit } from "ts-essentials";
import type { TopicKeys } from "../../domain/crud";
import {
  circumventPagination,
  mergeEnabledOption,
  useTopicKeys,
} from "../../domain/crud";
import type {
  DataStoreMutationFunction,
  UseDataStoreQueryOptions,
} from "../../domain/datastores";
import {
  useBuildDataStoreQuery,
  useDataStoreMutation,
  useDataStoreQuery,
  useDataStoreQueryKey,
} from "../../domain/datastores";
import type {
  ExtractionCreateRequest,
  ExtractionFetchResponse,
  ListRecordsRequest,
  ListTopicsRequest,
  Log,
  Record,
  RecordListResponse,
  TopicListResponse,
} from "../../services/datastore";
import type { ResolvedKeyFactory, SetMaybe } from "../../types";
import type { RecordListCache } from "./recordListCache";
import type { DraftExtractionTopic } from "./types";

const API_MAX_LIMIT = 100;

export type UseRecordQueriesRequest = SetMaybe<ListRecordsRequest, "topicId">;

export interface LocalRecord extends StrictOmit<Record, "imageUrl"> {
  image: Blob | null;
}

export type LocalRecordListResponse = StrictOmit<RecordListResponse, "data"> & {
  data: Array<LocalRecord>;
};

export interface UseRecordsQueriesOptions<TData = LocalRecordListResponse> {
  request: UseRecordQueriesRequest;
  options?: Pick<
    UseQueryOptions<LocalRecordListResponse, unknown, TData>,
    "select" | "enabled" | "meta" | "cacheTime"
  >;
  cache?: RecordListCache;
}

export interface UseCreateExtractionArgs {
  logId: Log["id"];
  draftExtractionTopics: DraftExtractionTopic[];
  name: ExtractionCreateRequest["name"];
}

export function usePlayerRecordKeys() {
  const baseKey = useDataStoreQueryKey(["player-records"] as const);

  const factory = {
    all: baseKey,
    lists: () => [...factory.all, "list"] as const,
    list: (request: UseRecordQueriesRequest) =>
      [...factory.lists(), request] as const,
  } as const;

  return factory;
}

export type PlayerRecordKeys = ResolvedKeyFactory<typeof usePlayerRecordKeys>;

export function useTopics<TData = TopicListResponse>(
  request: ListTopicsRequest,
  options?: UseDataStoreQueryOptions<
    TopicListResponse,
    unknown,
    TData,
    TopicKeys["list"]
  >
) {
  return useDataStoreQuery({
    queryKey: useTopicKeys().list(request),
    queryFn(context, { topicApi }) {
      return request.limit === -1
        ? circumventPagination(
            topicApi.listTopics.bind(topicApi),
            API_MAX_LIMIT,
            request,
            context
          )
        : topicApi.listTopics(request, context);
    },
    ...options,
  });
}

function isListRecordsRequest(
  request: UseRecordQueriesRequest
): request is ListRecordsRequest {
  return request.topicId != null;
}

export function useRecordsQueries<TData = LocalRecordListResponse>(
  queries: UseRecordsQueriesOptions<TData>[]
) {
  const { buildDataStoreQuery } = useBuildDataStoreQuery();

  const playerRecordKeys = usePlayerRecordKeys();

  return useQueries({
    queries: queries.map(({ request, options, cache }) =>
      buildDataStoreQuery({
        queryKey: playerRecordKeys.list(request),
        async queryFn(context, { topicApi }) {
          invariant(isListRecordsRequest(request), "Topic ID must be defined");

          const response = await topicApi.listRecords(request, context);

          const localRecordResponse = {
            ...response,
            data: await Promise.all(
              response.data.map(async (record) => ({
                ...record,
                image: await getLocalImage(record, context.signal),
              }))
            ),
          };

          cache?.set(request, localRecordResponse);

          return localRecordResponse;
        },
        staleTime: Infinity,
        cacheTime: secondsToMilliseconds(20),
        ...options,
        enabled: mergeEnabledOption(options, isListRecordsRequest(request)),
        initialData() {
          if (!isListRecordsRequest(request)) {
            return;
          }

          return cache?.get(request);
        },
      })
    ),
  });
}

export function useCreateExtraction() {
  const mutationFn: DataStoreMutationFunction<
    ExtractionFetchResponse,
    UseCreateExtractionArgs
  > = async function mutationFn(
    { logId, draftExtractionTopics, name },
    { extractionApi }
  ) {
    const newExtraction = await extractionApi.createExtraction({
      extractionCreateRequest: {
        logId,
        name,
      },
    });

    await Promise.all(
      draftExtractionTopics.map((draftExtractionTopic) =>
        extractionApi.createExtractionTopic({
          extractionId: newExtraction.data.id,
          extractionTopicCreateRequest: {
            topicId: draftExtractionTopic.topicId,
            startTime: millisecondsToSeconds(draftExtractionTopic.startTimeMs),
            endTime: millisecondsToSeconds(draftExtractionTopic.endTimeMs),
          },
        })
      )
    );

    return extractionApi.updateExtraction({
      extractionId: newExtraction.data.id,
      extractionUpdateRequest: {
        queued: true,
      },
    });
  };

  return useDataStoreMutation({ mutationFn });
}

// Utilities

async function getLocalImage(
  record: Record,
  signal: AbortSignal | undefined
): Promise<LocalRecord["image"]> {
  if (record.imageUrl === null) {
    return null;
  }

  let response;
  try {
    response = await fetch(record.imageUrl, { signal });
  } catch {
    return null;
  }

  if (!response.ok) {
    return null;
  }

  return response.blob();
}
