import React, { useEffect, useMemo, useState } from "react";
import type { StrictOmit } from "ts-essentials";
import { createSafeContext } from "../../contexts";
import type { LogrusClient } from "./types";

export type LogrusAuthStatus = "guest" | "authenticated";

export interface LogrusContextValue {
  authStatus: LogrusAuthStatus;
  client: LogrusClient;
}

export const [useLogrus, LogrusContext] =
  createSafeContext<LogrusContextValue>("Logrus");

export interface LogrusProviderProps {
  client: LogrusClient;
  loadingFallback?: React.ReactNode;
  children: React.ReactNode;
}

export default function LogrusProvider({
  client,
  loadingFallback = null,
  children,
}: LogrusProviderProps) {
  const [authStatus, setAuthStatus] = useState<LogrusAuthStatus | null>(null);

  useEffect(
    function determineAuthStatus() {
      (async function performStatusCheck() {
        setAuthStatus(
          (await client.isUserAuthenticated()) ? "authenticated" : "guest"
        );
      })();
    },
    [client]
  );

  const value = useMemo(() => ({ authStatus, client }), [authStatus, client]);

  if (isValidContextValue(value)) {
    return (
      <LogrusContext.Provider value={value}>{children}</LogrusContext.Provider>
    );
  } else {
    return <>{loadingFallback}</>;
  }
}

function isValidContextValue(
  value: StrictOmit<LogrusContextValue, "authStatus"> & {
    authStatus: LogrusAuthStatus | null;
  }
): value is LogrusContextValue {
  return value.authStatus !== null;
}
