import type { Topic } from "../../../services/datastore";
import { traverseTree } from "../../../utils";
import type { LayoutNode, PanelNode } from "./api";

/**
 * Performs a breadth-first traversal of the tree starting from the root,
 * calling the visitor function for each node as it is reached. To cancel
 * the traversal before every node is visited, the visitor can return
 * false; otherwise, the visitor should not return anything
 *
 * @param root the root of the tree to traverse
 * @param visitor a function to be called on each node found during the
 *        traversal. Can return false to stop traversal early
 */
export function walkLayoutTree(
  root: LayoutNode,
  visitor: (node: LayoutNode) => false | undefined | void
) {
  function getChildren(node: LayoutNode) {
    if (node.type === "container") {
      return [node.firstChild, node.secondChild];
    }
  }

  traverseTree(root, getChildren, visitor);
}

export function supportsImage(typeName: Topic["typeName"]) {
  return [
    "sensor_msgs/Image",
    "sensor_msgs/CompressedImage",
    "ark::image::Image",
  ].includes(typeName as any);
}

export function supportsMap(typeName: Topic["typeName"]) {
  return typeName === "sensor_msgs/NavSatFix";
}

export function iteratePanels(
  root: LayoutNode,
  visitor: (node: PanelNode) => false | undefined | void
): void {
  walkLayoutTree(root, (node) => {
    if (node.type !== "panel") {
      return;
    }

    return visitor(node);
  });
}
