import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { z } from "zod";
import {
  SelectField,
  userRole,
  userRoleOptions,
  uuid,
} from "../../../components/Form";
import { UserSelect } from "../../../components/Form/UserSelect";
import { useStudioForm } from "../../../components/Form/hooks";
import { useCreateGroupAssociation } from "../../../domain/crud";
import type { Group, GroupAssociation } from "../../../services/datastore";
import { UserRole } from "../../../services/datastore";

export interface AddUserDialogProps {
  groupId: Group["id"];
  open: boolean;
  onClose: () => void;
  groupAssociations: readonly GroupAssociation[];
}

const DEFAULT_VALUES = {
  userId: null,
  role: UserRole.Viewer,
};

export function AddUserDialog({
  groupId,
  open,
  onClose,
  groupAssociations,
}: AddUserDialogProps) {
  const schema = makeSchema(groupAssociations);

  const { enqueueSnackbar } = useSnackbar();

  const createGroupAssociation = useCreateGroupAssociation();

  const { control, handleSubmit, reset } = useStudioForm({
    schema: schema,
    defaultValues: DEFAULT_VALUES,
    onSubmit({ userId, role }) {
      createGroupAssociation.mutate(
        { groupId, userId, role },
        {
          onSuccess() {
            onClose();

            enqueueSnackbar("User added to group", { variant: "success" });
          },
          onError() {
            enqueueSnackbar("Unable to add user to group", {
              variant: "error",
            });
          },
        }
      );
    },
  });

  function handleClose() {
    if (createGroupAssociation.isLoading) {
      return;
    }

    onClose();
  }

  function handleExited() {
    createGroupAssociation.reset();
    reset(DEFAULT_VALUES);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      TransitionProps={{
        onExited: handleExited,
      }}
    >
      <DialogTitle>Add User to Group</DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Stack component="form" onSubmit={handleSubmit} spacing={2}>
          <UserSelect control={control} name="userId" label="User" />
          <SelectField
            control={control}
            name="role"
            label="Role"
            options={userRoleOptions}
          />
          <Stack direction="row" spacing={2} justifyContent="end">
            <Button
              disabled={createGroupAssociation.isLoading}
              onClick={handleClose}
            >
              Close
            </Button>
            <LoadingButton
              loading={createGroupAssociation.isLoading}
              type="submit"
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

function makeSchema(groupAssociations: readonly GroupAssociation[]) {
  return z.object({
    userId: uuid.refine(
      (value) =>
        !groupAssociations.some(
          (groupAssociation) => groupAssociation.userId === value
        ),
      "User is already in this group"
    ),
    role: userRole,
  });
}
