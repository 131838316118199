import type { ElementOf } from "ts-essentials";
import { z } from "zod";
import { optionalNumber, optionalText, uuid } from "../../../components/Form";
import {
  filterBoolean,
  filterDate,
  filterNumber,
  filterText,
  filterUuid,
} from "../../../components/Table";
import type { Record, Topic } from "../../../services/datastore";

const name = z.string({ invalid_type_error: "Name is required" });

export const listTopicsSchema = z.object({
  logId: filterUuid,
  groupId: filterUuid,
  ingestionId: filterUuid,
  locked: filterBoolean,
  name: filterText,
  nameLike: filterText,
  typeName: filterText,
  typeNameLike: filterText,
  typeEncoding: filterText,
  typeEncodingLike: filterText,
  createdBy: filterUuid,
  createdAtGte: filterDate,
  createdAtLte: filterDate,
  updatedBy: filterUuid,
  updatedByNull: filterBoolean,
  updatedAtGte: filterDate,
  updatedAtLte: filterDate,
  updatedAtNull: filterBoolean,
  deletedBy: filterUuid,
  deletedByNull: filterBoolean,
  deletedAtGte: filterDate,
  deletedAtLte: filterDate,
  deletedAtNull: filterBoolean,
});

export type ListTopicsFormValues = z.infer<typeof listTopicsSchema>;

export const createTopicSchema = z.object({
  name,
  logId: uuid,
  ingestionId: uuid.nullable(),
});

export type CreateTopicFormValues = z.infer<typeof createTopicSchema>;

export const EDITABLE_TOPIC_FIELDS = ["name"] as const;

export type EditTopicFormValues = Pick<
  Topic,
  ElementOf<typeof EDITABLE_TOPIC_FIELDS>
>;

export const editTopicSchema = z.object({
  name,
});

export const listRecordsSchema = z.object({
  ingestionId: filterUuid,
  errored: filterBoolean,
  archived: filterBoolean,
  lengthGte: filterNumber,
  lengthLte: filterNumber,
  dataLengthGte: filterNumber,
  dataLengthLte: filterNumber,
  offsetGte: filterNumber,
  offsetLte: filterNumber,
  dataOffsetGte: filterNumber,
  dataOffsetLte: filterNumber,
  createdBy: filterUuid,
  createdAtGte: filterDate,
  createdAtLte: filterDate,
  updatedBy: filterUuid,
  updatedAtGte: filterDate,
  updatedAtLte: filterDate,
  deletedBy: filterUuid,
  deletedAtGte: filterDate,
  deletedAtLte: filterDate,
});

export type ListRecordsFormValues = z.infer<typeof listRecordsSchema>;

export const EDITABLE_RECORD_FIELDS = [
  "offset",
  "length",
  "dataOffset",
  "dataLength",
  "chunkCompression",
  "chunkOffset",
  "chunkLength",
  "s3Bucket",
  "s3Key",
  "nanosecond",
] as const;

export type EditRecordFormValues = Pick<
  Record,
  ElementOf<typeof EDITABLE_RECORD_FIELDS>
>;

export const editRecordSchema = z.object({
  offset: optionalNumber,
  length: optionalNumber,
  dataOffset: optionalNumber,
  dataLength: optionalNumber,
  chunkCompression: optionalText,
  chunkOffset: optionalNumber,
  chunkLength: optionalNumber,
  s3Bucket: optionalText,
  s3Key: optionalText,
  nanosecond: optionalNumber,
});
