import { useCallback } from "react";
import queryString from "query-string";
import type {
  Location,
  NavigateOptions,
  ParamParseKey,
  Params,
} from "react-router-dom";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import type { DeepNonNullable } from "ts-essentials";
import type {
  OverrideProperties,
  RequireExactlyOne,
  SetReturnType,
} from "type-fest";
import { useCurrentDataStore } from "../domain/datastores";
import type {
  APIKey,
  Extraction,
  ExtractionTopic,
  Group,
  Ingestion,
  Log,
  Record,
  Topic,
  User,
} from "../services/datastore";

type ParamsShape<TPath extends string> = DeepNonNullable<
  Params<ParamParseKey<TPath>>
>;

/**
 * Creates a typed version of react-router's `useParams` hook for a specific
 * path.
 */
type UseTypedParams<TPath extends string> = SetReturnType<
  typeof useParams,
  ParamsShape<TPath>
>;

export const INDEX = "/" as const;

export interface IndexState {
  invalidOrigin?: string;
  unknownPlatformOrigin?: string;
  unmatchedPath?: string;
}

export function makeIndexLocation(
  state?: RequireExactlyOne<IndexState>
): Partial<Location> {
  return {
    pathname: INDEX,
    state,
  };
}

export const LOGS = "/logs" as const;

export function makeLogsLocation(): Partial<Location> {
  return {
    pathname: LOGS,
  };
}

export const LOG = `${LOGS}/:logId` as const;

export function makeLogLocation(logId: Log["id"]): Partial<Location> {
  return {
    pathname: generatePath(LOG, { logId }),
  };
}

export const useLogParams = useParams as UseTypedParams<typeof LOG>;

export const NEW_LOG = `${LOGS}/new` as const;

export function makeNewLogLocation(): Partial<Location> {
  return {
    pathname: generatePath(NEW_LOG),
  };
}

export const EDIT_LOG = `${LOG}/edit` as const;

export function makeEditLogLocation(logId: Log["id"]): Partial<Location> {
  return {
    pathname: generatePath(EDIT_LOG, { logId }),
  };
}

export const INGESTIONS = "/ingestions" as const;

export function makeIngestionsLocation(): Partial<Location> {
  return {
    pathname: INGESTIONS,
  };
}

export const INGESTION = `${INGESTIONS}/:ingestionId` as const;

export function makeIngestionLocation(
  ingestionId: Ingestion["id"]
): Partial<Location> {
  return {
    pathname: generatePath(INGESTION, { ingestionId }),
  };
}

export const useIngestionParams = useParams as UseTypedParams<typeof INGESTION>;

export const NEW_INGESTION = `${INGESTIONS}/new` as const;

export function makeNewIngestionLocation(): Partial<Location> {
  return {
    pathname: generatePath(NEW_INGESTION),
  };
}

export const EDIT_INGESTION = `${INGESTION}/edit` as const;

export function makeEditIngestionLocation(
  ingestionId: Ingestion["id"]
): Partial<Location> {
  return {
    pathname: generatePath(EDIT_INGESTION, { ingestionId }),
  };
}

export const TOPICS = "/topics" as const;

export function makeTopicsLocation(): Partial<Location> {
  return {
    pathname: TOPICS,
  };
}

export const TOPIC = `${TOPICS}/:topicId` as const;

export function makeTopicLocation(topicId: Topic["id"]): Partial<Location> {
  return {
    pathname: generatePath(TOPIC, { topicId }),
  };
}

export const useTopicParams = useParams as UseTypedParams<typeof TOPIC>;

export const NEW_TOPIC = `${TOPICS}/new` as const;

export function makeNewTopicLocation(): Partial<Location> {
  return {
    pathname: generatePath(NEW_TOPIC),
  };
}

export const EDIT_TOPIC = `${TOPIC}/edit` as const;

export function makeEditTopicLocation(topicId: Topic["id"]): Partial<Location> {
  return {
    pathname: generatePath(EDIT_TOPIC, { topicId }),
  };
}

export const RECORDS = `${TOPIC}/records` as const;

export function makeRecordsLocation(topicId: Topic["id"]): Partial<Location> {
  return {
    pathname: generatePath(RECORDS, { topicId }),
  };
}

export const useRecordsParams = useParams as UseTypedParams<typeof RECORDS>;

export const RECORD = `${RECORDS}/:timestamp` as const;

export function makeRecordLocation(
  topicId: Topic["id"],
  timestamp: Record["timestamp"]
): Partial<Location> {
  return {
    pathname: generatePath(RECORD, {
      topicId,
      timestamp: timestamp.toString(),
    }),
  };
}

export function useRecordParams(): OverrideProperties<
  ParamsShape<typeof RECORD>,
  { timestamp: number }
> {
  const { topicId, timestamp } = useParams() as ParamsShape<typeof RECORD>;

  return { topicId, timestamp: Number(timestamp) };
}

export const EDIT_RECORD = `${RECORD}/edit` as const;

export function makeEditRecordLocation(
  topicId: Topic["id"],
  timestamp: Record["timestamp"]
): Partial<Location> {
  return {
    pathname: generatePath(EDIT_RECORD, {
      topicId,
      timestamp: String(timestamp),
    }),
  };
}

export const UPLOAD = "/upload" as const;

export function makeUploadLocation(): Partial<Location> {
  return {
    pathname: UPLOAD,
  };
}

export const PLAYER = "/player" as const;

export type PlayerQuery = { logId?: Log["id"] };

export function makePlayerLocation(query?: PlayerQuery): Partial<Location> {
  return {
    pathname: PLAYER,
    search: makeSearchString(query),
  };
}

export const EXTRACTIONS = "/extractions" as const;

export function makeExtractionsLocation(): Partial<Location> {
  return {
    pathname: EXTRACTIONS,
  };
}

export const EXTRACTION = `${EXTRACTIONS}/:extractionId` as const;

export function makeExtractionLocation(
  extractionId: Extraction["id"]
): Partial<Location> {
  return {
    pathname: generatePath(EXTRACTION, { extractionId }),
  };
}

export const useExtractionParams = useParams as UseTypedParams<
  typeof EXTRACTION
>;

export const NEW_EXTRACTION = `${EXTRACTIONS}/new` as const;

export function makeNewExtractionLocation(): Partial<Location> {
  return {
    pathname: generatePath(NEW_EXTRACTION),
  };
}

export const EDIT_EXTRACTION = `${EXTRACTION}/edit` as const;

export function makeEditExtractionLocation(
  extractionId: Extraction["id"]
): Partial<Location> {
  return {
    pathname: generatePath(EDIT_EXTRACTION, { extractionId }),
  };
}

// Not exported since there isn't a stand-alone extraction topics list page
const EXTRACTION_TOPICS = `${EXTRACTION}/topics` as const;

export const NEW_EXTRACTION_TOPIC = `${EXTRACTION_TOPICS}/new` as const;

export function makeNewExtractionTopicLocation(
  extractionId: Extraction["id"]
): Partial<Location> {
  return {
    pathname: generatePath(NEW_EXTRACTION_TOPIC, { extractionId }),
  };
}

export const EXTRACTION_TOPIC = `${EXTRACTION_TOPICS}/:topicId` as const;

export function makeExtractionTopicLocation(
  extractionId: Extraction["id"],
  topicId: ExtractionTopic["id"]
): Partial<Location> {
  return {
    pathname: generatePath(EXTRACTION_TOPIC, { extractionId, topicId }),
  };
}

export const useExtractionTopicParams = useParams as UseTypedParams<
  typeof EXTRACTION_TOPIC
>;

export const EDIT_EXTRACTION_TOPIC = `${EXTRACTION_TOPIC}/edit` as const;

export function makeEditExtractionTopicLocation(
  extractionId: Extraction["id"],
  topicId: ExtractionTopic["id"]
): Partial<Location> {
  return {
    pathname: generatePath(EDIT_EXTRACTION_TOPIC, { extractionId, topicId }),
  };
}

export const DATASTORES = "/datastores" as const;

export function makeDataStoresLocation(): Partial<Location> {
  return {
    pathname: DATASTORES,
  };
}

export const PROFILE = "/profile" as const;

export function makeProfileLocation(): Partial<Location> {
  return {
    pathname: PROFILE,
  };
}
export const USERS = "/users" as const;

export function makeUsersLocation(): Partial<Location> {
  return {
    pathname: USERS,
  };
}

export const USER = `${USERS}/:userId` as const;

export function makeUserLocation(userId: User["id"]): Partial<Location> {
  return {
    pathname: generatePath(USER, { userId }),
  };
}

export const useUserParams = useParams as UseTypedParams<typeof USER>;

export const NEW_USER = `${USERS}/new` as const;

export function makeNewUserLocation(): Partial<Location> {
  return {
    pathname: generatePath(NEW_USER),
  };
}

export const EDIT_USER = `${USER}/edit` as const;

export function makeEditUserLocation(userId: User["id"]): Partial<Location> {
  return {
    pathname: generatePath(EDIT_USER, { userId }),
  };
}

export const GROUPS = "/groups" as const;

export function makeGroupsLocation(): Partial<Location> {
  return {
    pathname: GROUPS,
  };
}

export const GROUP = `${GROUPS}/:groupId` as const;

export function makeGroupLocation(groupId: Group["id"]): Partial<Location> {
  return {
    pathname: generatePath(GROUP, { groupId }),
  };
}

export const useGroupParams = useParams as UseTypedParams<typeof GROUP>;

export const NEW_GROUP = `${GROUPS}/new` as const;

export function makeNewGroupLocation(): Partial<Location> {
  return {
    pathname: generatePath(NEW_GROUP),
  };
}

export const EDIT_GROUP = `${GROUP}/edit` as const;

export function makeEditGroupLocation(groupId: Group["id"]): Partial<Location> {
  return {
    pathname: generatePath(EDIT_GROUP, { groupId }),
  };
}

export const API_KEYS = "/api-keys" as const;

export function makeApiKeysLocation(): Partial<Location> {
  return {
    pathname: generatePath(API_KEYS),
  };
}

export const NEW_API_KEY = `${API_KEYS}/new` as const;

export function makeNewApiKeyLocation(): Partial<Location> {
  return {
    pathname: generatePath(NEW_API_KEY),
  };
}

export const API_KEY = `${API_KEYS}/:apiKeyId` as const;

export function makeApiKeyLocation(apiKeyId: APIKey["id"]): Partial<Location> {
  return {
    pathname: generatePath(API_KEY, { apiKeyId }),
  };
}

export const EDIT_API_KEY = `${API_KEY}/edit` as const;

export function makeEditApiKeyLocation(
  apiKeyId: APIKey["id"]
): Partial<Location> {
  return {
    pathname: generatePath(EDIT_API_KEY, { apiKeyId }),
  };
}

export const useApiKeyParams = useParams as UseTypedParams<typeof API_KEY>;

function makeSearchString(query: any): string {
  const stringifiedQuery = queryString.stringify(query, {
    skipNull: true,
    skipEmptyString: true,
  });

  return `?${stringifiedQuery}`;
}

export function useMakeStudioLocation() {
  const dataStore = useCurrentDataStore();
  const url = dataStore?.origin;

  return useCallback(
    (location: Partial<Location>) => {
      const searchParams = new URLSearchParams(location.search);

      if (url !== undefined) {
        searchParams.set("url", url);
      }

      return {
        ...location,
        search: searchParams.toString(),
      };
    },
    [url]
  );
}

export function useStudioNavigate() {
  const makeStudioLocation = useMakeStudioLocation();

  const navigate = useNavigate();

  return useCallback(
    (location: Partial<Location>, options?: NavigateOptions) => {
      navigate(makeStudioLocation(location), options);
    },
    [makeStudioLocation, navigate]
  );
}
