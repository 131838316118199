import {
  APIKeyApi,
  Configuration,
  ExtractionApi,
  GroupApi,
  GroupAssociationApi,
  IngestionApi,
  LogApi,
  MeApi,
  MessageTypeApi,
  TopicApi,
  UserApi,
} from "../../../services/datastore";
import { useLogrus } from "../../../services/logrus";
import { useCurrentDataStore } from "../providers";
import type { DataStoreClients } from "./types";

/**
 * Using the current DataStore connection, create an object of configured
 * DataStore API clients or `null` if there is currently no connection.
 */
export function useDataStoreClients(): DataStoreClients | null {
  const dataStore = useCurrentDataStore();
  const logrus = useLogrus();

  if (dataStore === null) {
    return null;
  }

  let configuration;
  if (dataStore.type === "platform") {
    configuration = logrus.client.buildPlatformConfiguration(dataStore);
  } else {
    configuration = new Configuration({ basePath: dataStore.origin });
  }

  return {
    basePath: configuration.basePath,
    apiKeyApi: new APIKeyApi(configuration),
    extractionApi: new ExtractionApi(configuration),
    groupApi: new GroupApi(configuration),
    groupAssociationApi: new GroupAssociationApi(configuration),
    ingestionApi: new IngestionApi(configuration),
    logApi: new LogApi(configuration),
    meApi: new MeApi(configuration),
    messageTypeApi: new MessageTypeApi(configuration),
    topicApi: new TopicApi(configuration),
    userApi: new UserApi(configuration),
  };
}
