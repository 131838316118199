import type { Maybe } from "../types";
import { formatDate } from "../utils/formats";

export interface DatetimeProps {
  date: Maybe<Date>;
}

export function Datetime({ date }: DatetimeProps) {
  return date == null ? (
    <>-</>
  ) : (
    <span title={formatDate(date, "local")}>{formatDate(date, "UTC")}</span>
  );
}
