import { Card, CardContent, TableCell, Typography } from "@mui/material";
import type { z } from "zod";
import { omitNullFields } from "../../../components/Form";
import Link from "../../../components/Link";
import type { Column } from "../../../components/Table";
import {
  dateTimeColumns,
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  UserCell,
  useSearchRequest,
  withoutBaseTableModel,
} from "../../../components/Table";
import { useApiKeys } from "../../../domain/crud";
import { makeApiKeyLocation, makeNewApiKeyLocation } from "../../../paths";
import type { APIKey } from "../../../services/datastore";
import { ApiKeyFilters } from "./ApiKeyFilters";
import { listApiKeysSchema } from "./validation";

const COLUMNS: ReadonlyArray<Column<APIKey>> = [
  {
    header: "Name",
    renderCell(apiKey) {
      return (
        <TableCell>
          {apiKey.name}
          <Link to={makeApiKeyLocation(apiKey.id)}>
            <Typography variant="body2">{apiKey.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    header: "User",
    renderCell(apiKey) {
      return <UserCell userId={apiKey.userId} />;
    },
  },
  ...dateTimeColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listApiKeysSchema);

export function Table() {
  const [request, setRequest] = useSearchRequest(requestSchema);
  const searchQuery = useApiKeySearch(request);

  const filterValues = withoutBaseTableModel(request);

  return (
    <Card>
      <CardContent>
        <ResourceTable
          resourceName="API Key"
          resourceCreateLocation={makeNewApiKeyLocation()}
          getRowKey={(apiKey) => apiKey.id}
          columns={COLUMNS}
          searchQuery={searchQuery}
          tableModel={request}
          onTableModelChange={setRequest}
          filterSection={
            <ApiKeyFilters values={filterValues} setValues={setRequest} />
          }
          activeFilterCount={getActiveFiltersCount(filterValues)}
        />
      </CardContent>
    </Card>
  );
}

function useApiKeySearch(request: z.infer<typeof requestSchema>) {
  return useApiKeys(omitNullFields(prepareListRequest(request)), {
    keepPreviousData: true,
    cacheTime: 0,
  });
}
