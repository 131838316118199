import { Card, CardContent, TableCell, Typography } from "@mui/material";
import type { z } from "zod";
import { omitNullFields } from "../../../components/Form";
import Link from "../../../components/Link";
import { IngestionInfo, LogInfo } from "../../../components/ResourceInfo";
import type { Column } from "../../../components/Table";
import {
  dateTimeColumns,
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
} from "../../../components/Table";
import { useTopics } from "../../../domain/crud";
import { makeNewTopicLocation, makeTopicLocation } from "../../../paths";
import type { Topic } from "../../../services/datastore";
import { getTopicTypeName } from "../../../utils";
import { TopicFilters } from "./TopicFilters";
import { listTopicsSchema } from "./validation";

const COLUMNS: ReadonlyArray<Column<Topic>> = [
  {
    header: "Topic",
    renderCell(topic) {
      return (
        <TableCell>
          {topic.name}
          <Link to={makeTopicLocation(topic.id)}>
            <Typography variant="body2">{topic.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    header: "Log",
    renderCell(topic) {
      return (
        <TableCell>
          <LogInfo logId={topic.logId} />
        </TableCell>
      );
    },
  },
  {
    header: "Message Type",
    renderCell(topic) {
      return <TableCell>{getTopicTypeName(topic) ?? "-"}</TableCell>;
    },
  },
  {
    header: "Ingestion",
    renderCell(topic) {
      return (
        <TableCell>
          {topic.ingestionId === null ? (
            "-"
          ) : (
            <IngestionInfo ingestionId={topic.ingestionId} />
          )}
        </TableCell>
      );
    },
  },
  {
    accessor: "startTime",
    header: "Start Time",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "endTime",
    header: "End Time",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "recordSize",
    header: "Record Size",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "recordCount",
    header: "Record Count",
    dataType: "number",
    isSortable: true,
  },
  ...dateTimeColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listTopicsSchema);

export default function Table() {
  const [request, setRequest] = useSearchRequest(requestSchema);
  const searchQuery = useTopicSearch(request);

  const filterValues = withoutBaseTableModel(request);

  return (
    <Card>
      <CardContent>
        <ResourceTable
          resourceName="topic"
          resourceCreateLocation={makeNewTopicLocation()}
          getRowKey={(topic) => topic.id}
          columns={COLUMNS}
          searchQuery={searchQuery}
          tableModel={request}
          onTableModelChange={setRequest}
          filterSection={
            <TopicFilters values={filterValues} setValues={setRequest} />
          }
          activeFilterCount={getActiveFiltersCount(filterValues)}
        />
      </CardContent>
    </Card>
  );
}

function useTopicSearch(request: z.infer<typeof requestSchema>) {
  return useTopics(omitNullFields(prepareListRequest(request)), {
    keepPreviousData: true,
    cacheTime: 0,
  });
}
