import { fromUnixTime } from "date-fns";
import type { Maybe } from "../types";

export function sToMs(seconds: number): number;
export function sToMs(seconds: null | undefined): null;
export function sToMs(seconds: Maybe<number>): number | null;
export function sToMs(seconds: Maybe<number>): number | null {
  if (seconds == null) {
    return null;
  }

  return Number(fromUnixTime(seconds));
}
