import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import type { CustomConnectionFormSubmitHandler } from "../../../components/CustomConnectionForm";
import CustomConnectionForm from "../../../components/CustomConnectionForm";
import Details from "../../../components/Details";
import { useCreateCustomDataStore } from "../../../domain/datastores";
import type { CustomDataStore } from "../../../models";

export interface CreateViewProps {
  onClose: () => void;
  onCreated: (origin: CustomDataStore["origin"]) => void;
}

export default function CreateView({ onClose, onCreated }: CreateViewProps) {
  const createCustomConnection = useCreateCustomDataStore();

  const formId = "new-connection-card-form";

  const handleSubmit: CustomConnectionFormSubmitHandler =
    async function handleSubmit(values) {
      const newConnection = await createCustomConnection.mutateAsync(values);

      onCreated(newConnection.origin);

      return newConnection;
    };

  return (
    <Card>
      <CardHeader
        title="New Custom Connection"
        titleTypographyProps={{ component: "h2" }}
        action={
          <Tooltip title="Close">
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        <Typography paragraph>
          Using a custom connection, Studio can connect to local DataStores.
        </Typography>
        <Typography paragraph>
          Enter the DataStore's base URL and provide a name for the connection.
        </Typography>
        <CustomConnectionForm onSubmit={handleSubmit} formId={formId} />
        <LoadingButton
          type="submit"
          form={formId}
          variant="contained"
          color="primary"
          fullWidth
          disableElevation
          loading={createCustomConnection.isLoading}
        >
          Create Connection
        </LoadingButton>
        <Details sx={{ mt: 2 }}>
          <Details.Summary>
            How do I authenticate to my DataStore?
          </Details.Summary>
          <Details.Content>
            Studio does not yet support authentication for custom DataStore
            connections.
          </Details.Content>
        </Details>
        <Details sx={{ mt: 2 }}>
          <Details.Summary>
            How do I create a Platform connection?
          </Details.Summary>
          <Details.Content paragraph>
            Studio does not currently support creating Platform DataStore
            connections.
          </Details.Content>
          <Details.Content>
            Your organization's administrator will need to give you access,
            after which you will need to sign out and sign back in.
          </Details.Content>
        </Details>
      </CardContent>
    </Card>
  );
}
