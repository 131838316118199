import React from "react";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
} from "@mui/material";
import invariant from "invariant";
import { capitalize } from "lodash";
import { Dl, renderDlGroup } from "../../../components/DescriptionList";
import Details from "../../../components/Details";
import {
  isOriginMixedContent,
  isOriginSecure,
  useDataStoreContext,
  useDeleteCustomDataStore,
} from "../../../domain/datastores";
import type { DataStore } from "../../../models";

export interface DetailsViewProps {
  dataStore: DataStore;
  onClose: () => void;
}

export default function DetailsView({ dataStore, onClose }: DetailsViewProps) {
  const { current, connect, disconnect } = useDataStoreContext();

  const deleteCustomConnection = useDeleteCustomDataStore();

  function handleConnect() {
    connect(dataStore);
  }

  function handleDisconnect() {
    disconnect();
  }

  function renderConnection() {
    const isConnected = dataStore.origin === current?.origin;

    let button;
    if (isConnected) {
      button = (
        <Button
          color="success"
          variant="contained"
          disableElevation
          fullWidth
          onClick={handleDisconnect}
        >
          Disconnect
        </Button>
      );
    } else {
      button = (
        <Button
          color="primary"
          variant="contained"
          disableElevation
          fullWidth
          onClick={handleConnect}
        >
          Connect
        </Button>
      );
    }

    return (
      <>
        {button}
        {!isOriginSecure(dataStore.origin) && (
          <Alert sx={{ mt: 2 }} variant="filled" severity="warning">
            Connection is unencrypted.
            {isOriginMixedContent(dataStore.origin) && (
              <>
                {" "}
                Additionally, your browser will likely block the connection if
                the URL does not resolve to a loopback address.
              </>
            )}
          </Alert>
        )}
      </>
    );
  }

  function handleDelete() {
    invariant(
      dataStore.type === "custom",
      "Can only delete custom connections"
    );

    deleteCustomConnection.mutate(dataStore.origin);
  }

  return (
    <Card>
      <CardHeader
        title="Connection Details"
        titleTypographyProps={{ component: "h2" }}
        action={
          <Tooltip title="Close">
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        {renderConnection()}
        <Dl spacing={4} sx={{ mt: -2, "& dd": { wordBreak: "break-all" } }}>
          {renderDlGroup("Name", dataStore.name, { xs: 12 })}
          {renderDlGroup("URL", dataStore.origin, { xs: 12 })}
          {renderDlGroup("Type", capitalize(dataStore.type), { xs: 12 })}
        </Dl>
        {dataStore.type === "custom" && (
          <LoadingButton
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleDelete}
            loading={deleteCustomConnection.isLoading}
          >
            Delete Connection
          </LoadingButton>
        )}
        {dataStore.type === "platform" && (
          <Details sx={{ mt: 2 }}>
            <Details.Summary>
              How do I delete a Platform connection?
            </Details.Summary>
            <Details.Content>
              You cannot delete a Platform DataStore connection through Studio.
              Your organization's administrator will need to remove you from the
              DataStore.
            </Details.Content>
          </Details>
        )}
      </CardContent>
    </Card>
  );
}
