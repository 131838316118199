import React from "react";
import { useState } from "react";
import type { StoreApi } from "zustand";
import { createStore, useStore } from "zustand";
import { immer } from "zustand/middleware/immer";
import { createSafeContext } from "../../../contexts";
import type { Topic } from "../../../services/datastore";

type TagSelection = {
  tag: string;
  topicId: Topic["id"];
};

type TagStore = {
  selected: TagSelection | null;
  actions: {
    selectTag: (selection: TagSelection) => void;
    clearSelectedTag: () => void;
  };
};

function createTagStore() {
  return createStore<TagStore>()(
    immer((set) => {
      return {
        selected: null,
        actions: {
          selectTag(selection) {
            set((state) => {
              state.selected = selection;
            });
          },
          clearSelectedTag() {
            set((state) => {
              state.selected = null;
            });
          },
        },
      };
    })
  );
}

const [useTagStoreContext, TagStoreContext] =
  createSafeContext<StoreApi<TagStore>>("TagStore");

// Tag store must be scoped to a given log, meaning there can't be a single
// store for the entire app. Instead, the `<TagStoreProvider />` will create
// a fresh store whenever it mounts. This component should be rendered under
// the `<LogPlaybackSourceProvider />`
export function TagStoreProvider({ children }: { children: React.ReactNode }) {
  const [tagStore] = useState(createTagStore);

  return (
    <TagStoreContext.Provider value={tagStore}>
      {children}
    </TagStoreContext.Provider>
  );
}

export function useTagSelection() {
  const tagStore = useTagStoreContext();

  return useStore(tagStore, (store) => store.selected);
}

export function useTagActions() {
  const tagStore = useTagStoreContext();

  return useStore(tagStore, (store) => store.actions);
}
