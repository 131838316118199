import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { pick } from "lodash";
import { useSnackbar } from "notistack";
import { getChangedFields, TextField } from "../../../components/Form";
import { useStudioForm } from "../../../components/Form/hooks";
import { useUpdateApiKey } from "../../../domain/crud";
import {
  makeApiKeyLocation,
  useApiKeyParams,
  useStudioNavigate,
} from "../../../paths";
import type { APIKey } from "../../../services/datastore";
import { EDITABLE_API_KEY_FIELDS, editApiKeySchema } from "./validation";

export interface EditFormProps {
  apiKey: APIKey;
}

export function EditForm({ apiKey }: EditFormProps) {
  const { apiKeyId } = useApiKeyParams();

  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const updateApiKey = useUpdateApiKey(apiKeyId);

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useStudioForm({
    schema: editApiKeySchema,
    defaultValues: pick(apiKey, EDITABLE_API_KEY_FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateApiKey.mutate(changedFields, {
        onSuccess() {
          enqueueSnackbar("API key updated", { variant: "success" });

          navigate(makeApiKeyLocation(apiKeyId));
        },
        onError() {
          enqueueSnackbar("Could not updated API key", { variant: "error" });
        },
      });
    },
  });

  return (
    <Card>
      <CardContent>
        <Grid component="form" container onSubmit={handleSubmit} spacing={2}>
          <Grid item xs={3}>
            <TextField control={control} name="name" label="Name" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={updateApiKey.isLoading}
              type="submit"
              variant="contained"
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
