import { useState } from "react";
import { Typography } from "@mui/material";
import { DeleteDialog } from "../../../components/DeleteDialog";
import {
  dateTimeFields,
  DetailsCard,
  half,
} from "../../../components/DetailsCard";
import type { ResourceField } from "../../../components/DetailsCard/types";
import { useApiKey, useDeleteApiKey } from "../../../domain/crud";
import {
  makeApiKeysLocation,
  makeEditApiKeyLocation,
  useApiKeyParams,
  useStudioNavigate,
} from "../../../paths";
import type { APIKey } from "../../../services/datastore";
import { selectData } from "../../../utils";

const FIELDS: ReadonlyArray<ResourceField<APIKey>> = [
  {
    col: half,
    label: "Name",
    renderField(apiKey) {
      return (
        <>
          {apiKey.name}
          <Typography variant="body2">{apiKey.id}</Typography>
        </>
      );
    },
  },
  {
    col: half,
    accessor: "userId",
    label: "Owner",
    dataType: "user",
  },
  ...dateTimeFields,
];

export function Details() {
  const { apiKeyId } = useApiKeyParams();

  const apiKeyQuery = useApiKey(apiKeyId, { select: selectData });

  const navigate = useStudioNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const deleteApiKey = useDeleteApiKey(apiKeyId);

  function openDeleteDialog() {
    setShowDeleteDialog(true);
  }

  function closeDeleteDialog() {
    setShowDeleteDialog(false);
  }

  function handleDelete() {
    navigate(makeApiKeysLocation());
  }

  return (
    <>
      <DetailsCard
        editLocation={makeEditApiKeyLocation(apiKeyId)}
        onDelete={openDeleteDialog}
        query={apiKeyQuery}
        fields={FIELDS}
      />
      <DeleteDialog
        open={showDeleteDialog}
        onClose={closeDeleteDialog}
        resourceName="API key"
        resourceLoading={apiKeyQuery.isLoading}
        mutation={deleteApiKey}
        onDelete={handleDelete}
      >
        {apiKeyQuery.data?.name}
      </DeleteDialog>
    </>
  );
}
