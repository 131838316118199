import { useState } from "react";
import { DeleteDialog } from "../../../components/DeleteDialog";
import {
  dateTimeFields,
  DetailsCard,
  half,
} from "../../../components/DetailsCard";
import type { ResourceField } from "../../../components/DetailsCard/types";
import {
  IngestionInfo,
  LogInfo,
  TopicInfo,
} from "../../../components/ResourceInfo";
import { useDeleteRecord, useRecord } from "../../../domain/crud";
import {
  makeEditRecordLocation,
  makeTopicLocation,
  useRecordParams,
  useStudioNavigate,
} from "../../../paths";
import type { Record } from "../../../services/datastore";

const FIELDS: ReadonlyArray<ResourceField<Record>> = [
  {
    col: half,
    accessor: "timestamp",
    // A record's timestamp is part of its primary key so it should
    // be displayed as-is
    dataType: "text",
  },
  {
    col: half,
    label: "Topic",
    renderField(record) {
      return <TopicInfo topicId={record.topicId} />;
    },
  },
  {
    col: half,
    label: "Log",
    renderField(record) {
      return <LogInfo logId={record.logId} />;
    },
  },
  {
    col: half,
    label: "Ingestion",
    renderField(record) {
      return record.ingestionId === null ? (
        "-"
      ) : (
        <IngestionInfo ingestionId={record.ingestionId} />
      );
    },
  },
  {
    col: half,
    accessor: "offset",
    dataType: "bytes",
  },
  {
    col: half,
    accessor: "length",
    dataType: "bytes",
  },
  {
    col: half,
    accessor: "dataOffset",
    dataType: "bytes",
  },
  {
    col: half,
    accessor: "dataLength",
    dataType: "bytes",
  },
  {
    col: half,
    accessor: "chunkCompression",
    dataType: "text",
  },
  {
    col: half,
    accessor: "chunkOffset",
    dataType: "bytes",
  },
  {
    col: half,
    accessor: "chunkLength",
    dataType: "bytes",
  },
  {
    col: half,
    accessor: "s3Bucket",
    dataType: "text",
  },
  {
    col: half,
    accessor: "s3Key",
    dataType: "text",
  },
  {
    col: half,
    accessor: "format",
    dataType: "text",
  },
  {
    col: half,
    accessor: "messageData",
    dataType: "json",
  },
  {
    col: half,
    accessor: "imageUrl",
    dataType: "text",
  },
  {
    col: half,
    accessor: "bytesUrl",
    dataType: "text",
  },
  {
    col: half,
    accessor: "errored",
    dataType: "boolean",
  },
  {
    col: half,
    accessor: "archived",
    dataType: "boolean",
  },
  ...dateTimeFields,
];

export default function Details() {
  const { timestamp, topicId } = useRecordParams();

  const recordQuery = useRecord(topicId, Number(timestamp), {
    select(response) {
      return response.data;
    },
  });

  const navigate = useStudioNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const deleteRecord = useDeleteRecord(topicId, Number(timestamp));

  function closeModal() {
    setShowDeleteDialog(false);
  }

  function openModal() {
    setShowDeleteDialog(true);
  }

  function onDelete() {
    navigate(makeTopicLocation(topicId));
  }

  return (
    <>
      <DetailsCard
        editLocation={makeEditRecordLocation(topicId, timestamp)}
        onDelete={openModal}
        query={recordQuery}
        fields={FIELDS}
      />
      <DeleteDialog
        open={showDeleteDialog}
        onClose={closeModal}
        resourceName="record"
        resourceLoading={recordQuery.isLoading}
        mutation={deleteRecord}
        onDelete={onDelete}
      >
        {recordQuery.data?.timestamp}
      </DeleteDialog>
    </>
  );
}
