import { Button, Grid } from "@mui/material";
import { BooleanField, DateField } from "../../../components/Form";
import { UserSelect } from "../../../components/Form/UserSelect";
import { useStudioForm } from "../../../components/Form/hooks";
import { GTE, LTE } from "../../../constants";
import type { ListApiKeysFormValues } from "./validation";
import { listApiKeysSchema } from "./validation";

export interface ApiKeyFiltersProps {
  values: ListApiKeysFormValues;
  setValues: (newValues: ListApiKeysFormValues) => void;
}

export function ApiKeyFilters({ values, setValues }: ApiKeyFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listApiKeysSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={3}>
        <UserSelect control={control} name="userId" label="User" />
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="createdBy" label="Created By" />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="createdAtGte"
            label={`Created At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="createdAtLte"
            label={`Created At ${LTE}`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="updatedBy" label="Updated By" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="updatedByNull"
            label="Updated By Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="updatedAtGte"
            label={`Updated At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="updatedAtLte"
            label={`Updated At ${LTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="updatedAtNull"
            label="Updated At Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="deletedBy" label="Deleted By" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="deletedByNull"
            label="Deleted By Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="deletedAtGte"
            label={`Deleted At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="deletedAtLte"
            label={`Deleted At ${LTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="deletedAtNull"
            label="Deleted At Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
