import React from "react";
import { Settings } from "@mui/icons-material";
import { Skeleton, Stack, Typography } from "@mui/material";
import { FileCloud, ScissorsCutting } from "mdi-material-ui";
import AdvancedLayout from "../../components/AdvancedLayout";
import Center from "../../components/Center";
import GlobalNavigation from "../../components/GlobalNavigation";
import Header from "../../components/Header";
import Helmet from "../../components/Helmet";
import { LayoutStateProvider, SideSheetTrigger } from "../../components/Layout";
import {
  LogPlaybackSourceProvider,
  PlaybackSettingsProvider,
} from "./PlaybackProvider";
import { RecordListCacheProvider } from "./RecordListCacheProvider";
import PlaybackController from "./components/PlaybackController";
import TopicSection from "./components/TopicSection";
import { PlayerSidebar } from "./components/sidebar";
import { usePlayerConfig, usePlayerLog } from "./hooks";
import { PanelLayoutProvider } from "./panels";
import { PlaybackTimerProvider } from "./playback";
import { TagStoreProvider } from "./tags";

export default function Player() {
  const { logId } = usePlayerConfig();

  const logQuery = usePlayerLog();

  let content;
  if (logId == null) {
    content = (
      <Center>
        <Typography variant="h5" component="p" paragraph sx={{ px: 4 }}>
          To get started, search for a log in the side panel to the right
        </Typography>
      </Center>
    );
  } else if (
    logQuery.data?.startTimeMs === null ||
    logQuery.data?.endTimeMs === null
  ) {
    content = (
      <Center>
        <Typography variant="h5" component="p" paragraph>
          This log has no records to play. Choose a log with a defined start and
          end time.
        </Typography>
      </Center>
    );
  } else {
    content = <TopicSection />;
  }

  const title =
    logId === null ? (
      "Player"
    ) : logQuery.isSuccess ? (
      `Player - ${logQuery.data.name}`
    ) : (
      <>
        Player - <Skeleton width="15ch" sx={{ display: "inline-block" }} />
      </>
    );

  return (
    <>
      <Helmet>
        <title>Player</title>
      </Helmet>
      <LayoutStateProvider
        initialSideSheetState={logId === null ? "logs" : undefined}
      >
        <PanelLayoutProvider>
          <PlaybackSettingsProvider>
            <LogPlaybackSourceProvider>
              <TagStoreProvider>
                <PlaybackTimerProvider>
                  <RecordListCacheProvider>
                    <AdvancedLayout
                      header={
                        <Header
                          title={title}
                          actions={
                            <Stack direction="row" spacing={1}>
                              <SideSheetTrigger
                                title="Logs"
                                sidebarId="logs"
                                icon={<FileCloud />}
                              />
                              <SideSheetTrigger
                                title="Create an extraction"
                                sidebarId="extractions"
                                icon={<ScissorsCutting />}
                              />
                              <SideSheetTrigger
                                title="Settings"
                                sidebarId="settings"
                                icon={<Settings />}
                              />
                            </Stack>
                          }
                        />
                      }
                      globalNavigation={<GlobalNavigation />}
                      sideSheet={<PlayerSidebar />}
                      content={content}
                      controls={<PlaybackController />}
                    />
                  </RecordListCacheProvider>
                </PlaybackTimerProvider>
              </TagStoreProvider>
            </LogPlaybackSourceProvider>
          </PlaybackSettingsProvider>
        </PanelLayoutProvider>
      </LayoutStateProvider>
    </>
  );
}
