import { Error } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { CloudDownload } from "mdi-material-ui";
import IconicTypography from "../../../components/IconicTypography";
import { useExtractionPresignedUrl } from "../../../domain/crud";
import { ProcessStatus } from "../../../services/datastore";
import type { Extraction } from "../../../services/datastore";

interface DownloadProps {
  id: Extraction["id"];
  s3Key: Extraction["s3Key"];
  status: Extraction["status"];
}

export default function Download({ id, status, s3Key }: DownloadProps) {
  const presignedUrl = useExtractionPresignedUrl(id, status, s3Key);
  const loading =
    status === ProcessStatus.Complete &&
    s3Key !== null &&
    presignedUrl.isLoading;
  const disabled =
    status !== ProcessStatus.Complete ||
    s3Key === null ||
    !presignedUrl.isSuccess;
  return (
    <>
      <LoadingButton
        color="primary"
        disabled={disabled}
        href={presignedUrl.data?.url}
        loading={loading}
        startIcon={<CloudDownload />}
        sx={{ mt: 1 }}
        variant="contained"
      >
        Download Extraction
      </LoadingButton>
      {presignedUrl.isError && (
        <IconicTypography icon={<Error color="error" />}>
          Unable to get download link
        </IconicTypography>
      )}
    </>
  );
}
