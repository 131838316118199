import { Skeleton, Typography } from "@mui/material";
import { useLog } from "../../domain/crud";
import { makeLogLocation } from "../../paths";
import type { Log } from "../../services/datastore";
import Link from "../Link";

export interface LogInfoProps {
  logId: Log["id"];
}

export default function LogInfo({ logId }: LogInfoProps) {
  const logQuery = useLog(logId, {
    select(response) {
      return response.data.name;
    },
  });

  return (
    <>
      {logQuery.data ?? <Skeleton />}
      <Link to={makeLogLocation(logId)}>
        <Typography variant="body2">{logId}</Typography>
      </Link>
    </>
  );
}
