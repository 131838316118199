import { Button, Grid } from "@mui/material";
import {
  BooleanField,
  DateField,
  formatOptions,
  FuzzyField,
  LogSelect,
  NumberField,
  SelectField,
  statusOptions,
  TextField,
} from "../../../components/Form";
import { UserSelect } from "../../../components/Form/UserSelect";
import { useStudioForm } from "../../../components/Form/hooks";
import { GTE, LTE } from "../../../constants";
import type { ListIngestionsFormValues } from "./validation";
import { listIngestionsSchema } from "./validation";

export interface IngestionFiltersProps {
  values: ListIngestionsFormValues;
  setValues: (newValues: ListIngestionsFormValues) => void;
}

export function IngestionFilters({ values, setValues }: IngestionFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listIngestionsSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={3}>
        <FuzzyField
          control={control}
          exactName="name"
          fuzzyName="nameLike"
          label="Name"
        />
      </Grid>
      <Grid item xs={3}>
        <LogSelect control={control} name="logId" label="Log" />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          control={control}
          name="status"
          label="Status"
          options={statusOptions}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          control={control}
          name="format"
          label="Format"
          options={formatOptions}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField control={control} name="s3Bucket" label="S3 Bucket" />
      </Grid>
      <Grid item xs={3}>
        <FuzzyField
          control={control}
          exactName="s3Key"
          fuzzyName="s3KeyPrefix"
          label="S3 Key"
        />
      </Grid>
      <Grid item xs={3}>
        <TextField control={control} name="noteLike" label="Note" />
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <NumberField
            control={control}
            name="startOffsetGte"
            label={`Start Offset ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberField
            control={control}
            name="startOffsetLte"
            label={`Start Offset ${LTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="startOffsetNull"
            label="Start Offset Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <NumberField
            control={control}
            name="endOffsetGte"
            label={`End Offset ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberField
            control={control}
            name="endOffsetLte"
            label={`End Offset ${LTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="endOffsetNull"
            label="End Offset Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <NumberField
            control={control}
            name="progressGte"
            label={`Progress ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberField
            control={control}
            name="progressLte"
            label={`Progress ${LTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="progressNull"
            label="Progress Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={2}>
          <BooleanField
            control={control}
            name="processing"
            label="Processing?"
          />
        </Grid>
        <Grid item xs={2}>
          <BooleanField control={control} name="queued" label="Queued?" />
        </Grid>
        <Grid item xs={2}>
          <BooleanField control={control} name="cancelled" label="Cancelled?" />
        </Grid>
        <Grid item xs={2}>
          <BooleanField control={control} name="completed" label="Completed?" />
        </Grid>
        <Grid item xs={2}>
          <BooleanField control={control} name="errored" label="Errored?" />
        </Grid>
        <Grid item xs={2}>
          <BooleanField control={control} name="archived" label="Archived?" />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="createdBy" label="Created By" />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="createdAtGte"
            label={`Created At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="createdAtLte"
            label={`Created At ${LTE}`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="updatedBy" label="Updated By" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="updatedByNull"
            label="Updated By Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="updatedAtGte"
            label={`Updated At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="updatedAtLte"
            label={`Updated At ${LTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="updatedAtNull"
            label="Updated At Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="deletedBy" label="Deleted By" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="deletedByNull"
            label="Deleted By Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="deletedAtGte"
            label={`Deleted At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="deletedAtLte"
            label={`Deleted At ${LTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="deletedAtNull"
            label="Deleted At Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
