import type { ElementOf } from "ts-essentials";
import { z } from "zod";
import { name, uuid } from "../../../components/Form";
import {
  filterBoolean,
  filterDate,
  filterUuid,
} from "../../../components/Table";
import type { APIKey } from "../../../services/datastore";

export const listApiKeysSchema = z.object({
  userId: filterUuid,
  createdBy: filterUuid,
  createdAtGte: filterDate,
  createdAtLte: filterDate,
  updatedBy: filterUuid,
  updatedByNull: filterBoolean,
  updatedAtGte: filterDate,
  updatedAtLte: filterDate,
  updatedAtNull: filterBoolean,
  deletedBy: filterUuid,
  deletedByNull: filterBoolean,
  deletedAtGte: filterDate,
  deletedAtLte: filterDate,
  deletedAtNull: filterBoolean,
});

export type ListApiKeysFormValues = z.infer<typeof listApiKeysSchema>;

export const createApiKeySchema = z.object({
  name,
  userId: uuid,
});

export type CreateApiKeyFormValues = z.infer<typeof createApiKeySchema>;

export const EDITABLE_API_KEY_FIELDS = ["name"] as const;

export type EditApiKeyFormValues = Pick<
  APIKey,
  ElementOf<typeof EDITABLE_API_KEY_FIELDS>
>;

export const editApiKeySchema = z.object({ name });
