import { Card, CardContent, TableCell, Typography } from "@mui/material";
import type { z } from "zod";
import { omitNullFields } from "../../../components/Form";
import Link from "../../../components/Link";
import { LogInfo } from "../../../components/ResourceInfo";
import type { Column } from "../../../components/Table";
import {
  dateTimeColumns,
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
} from "../../../components/Table";
import { useIngestions } from "../../../domain/crud";
import {
  makeIngestionLocation,
  makeNewIngestionLocation,
} from "../../../paths";
import type { Ingestion } from "../../../services/datastore";
import { IngestionFilters } from "./IngestionFilters";
import { listIngestionsSchema } from "./validation";

const COLUMNS: ReadonlyArray<Column<Ingestion>> = [
  {
    header: "Ingestion",
    renderCell(ingestion) {
      return (
        <TableCell>
          {ingestion.name ?? "-"}
          <Link to={makeIngestionLocation(ingestion.id)}>
            <Typography variant="body2">{ingestion.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    header: "Log",
    renderCell(ingestion) {
      return (
        <TableCell>
          <LogInfo logId={ingestion.logId} />
        </TableCell>
      );
    },
  },
  {
    accessor: "status",
    align: "right",
    isSortable: true,
  },
  {
    accessor: "format",
    isSortable: true,
  },
  {
    accessor: "s3Bucket",
    header: "S3 Bucket",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "s3Key",
    header: "S3 Key",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "size",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "progress",
    dataType: "percent",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "note",
    isSortable: true,
  },
  {
    accessor: "startOffset",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "endOffset",
    dataType: "bytes",
    isSortable: true,
  },
  ...dateTimeColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listIngestionsSchema);

export default function Table() {
  const [request, setRequest] = useSearchRequest(requestSchema);
  const searchQuery = useIngestionSearch(request);

  const filterValues = withoutBaseTableModel(request);

  return (
    <Card>
      <CardContent>
        <ResourceTable
          resourceName="ingestion"
          resourceCreateLocation={makeNewIngestionLocation()}
          getRowKey={(ingestion) => ingestion.id}
          columns={COLUMNS}
          searchQuery={searchQuery}
          tableModel={request}
          onTableModelChange={setRequest}
          filterSection={
            <IngestionFilters values={filterValues} setValues={setRequest} />
          }
          activeFilterCount={getActiveFiltersCount(filterValues)}
        />
      </CardContent>
    </Card>
  );
}

function useIngestionSearch(request: z.infer<typeof requestSchema>) {
  return useIngestions(omitNullFields(prepareListRequest(request)), {
    keepPreviousData: true,
    cacheTime: 0,
  });
}
