import type { UseQueryResult } from "@tanstack/react-query";
import type { Group, UserRole } from "../../services/datastore";
import { selectData } from "../../utils";
import { useCurrentUser, useGroupAssociations } from "../crud";

export type GroupedResourceQuery = UseQueryResult<{ groupId: Group["id"] }>;

export function useHasPermission(
  groupedResourceQuery: GroupedResourceQuery,
  requiredRoles: UserRole[]
): boolean | undefined {
  const meQuery = useCurrentUser({ select: selectData });

  const userId = meQuery.data?.id;
  const isAdmin = meQuery.data?.isAdmin;

  const roleQuery = useGroupAssociations(
    { userId, groupId: groupedResourceQuery.data?.groupId },
    {
      select({ data }) {
        if (data.length !== 1) {
          throw new Error(
            `Expected a single group association but got ${data.length}`
          );
        }

        const [{ role }] = data;

        return role;
      },
      enabled:
        // Don't waste time fetching since admins always have permission
        isAdmin === false &&
        userId !== undefined &&
        groupedResourceQuery.isSuccess,
    }
  );

  if (isAdmin) {
    // Admins always have permission
    return true;
  }

  if (groupedResourceQuery.isError || roleQuery.isError) {
    // Optimistically assume the user would have permissions since something
    // prevented us from figuring out if they actually had permissions. If
    // they don't actually have permission the back-end will sort it out
    return true;
  }

  if (roleQuery.isSuccess) {
    return requiredRoles.includes(roleQuery.data);
  }

  // Don't know yet
  return undefined;
}
