import React, { useState } from "react";
import { Settings } from "@mui/icons-material";
import { Box, Container, Grid } from "@mui/material";
import { find } from "lodash";
import GlobalNavigation from "../../components/GlobalNavigation";
import Header from "../../components/Header";
import Helmet from "../../components/Helmet";
import Layout, {
  LayoutStateProvider,
  SidebarSwitch,
  SideSheetTrigger,
} from "../../components/Layout";
import SettingsDrawer from "../../components/SettingsDrawer";
import {
  useCustomDataStores,
  usePlatformDataStores,
} from "../../domain/datastores";
import type { CustomDataStore, DataStore } from "../../models";
import { selectData } from "../../utils";
import CreateView from "./components/CreateView";
import DetailsView from "./components/DetailsView";
import ListView from "./components/ListView";

export default function DataStores() {
  const [selected, setSelected] = useState<DataStore["origin"] | null>(null);
  const [creating, setCreating] = useState(false);

  const platformDataStoresQuery = usePlatformDataStores();
  const customDataStoresQuery = useCustomDataStores({ select: selectData });

  const allDataStores = [
    ...(platformDataStoresQuery.data ?? []),
    ...customDataStoresQuery.data,
  ];
  const selectedDataStore = find(allDataStores, { origin: selected as any });

  function handleSelect(origin: DataStore["origin"]) {
    setSelected(origin);
    setCreating(false);
  }

  function handleCreate() {
    setSelected(null);
    setCreating(true);
  }

  function handleCreated(origin: CustomDataStore["origin"]) {
    setSelected(origin);
    setCreating(false);
  }

  function optionallyRenderDetails() {
    if (selectedDataStore === undefined) {
      return;
    }

    return (
      <Grid item xs={12} lg={4}>
        <DetailsView
          dataStore={selectedDataStore}
          onClose={() => setSelected(null)}
        />
      </Grid>
    );
  }

  function optionallyRenderForm() {
    if (!creating) {
      return;
    }

    return (
      <Grid item xs={12} lg={4}>
        <CreateView
          onClose={() => setCreating(false)}
          onCreated={handleCreated}
        />
      </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title>DataStore Connections</title>
      </Helmet>
      <LayoutStateProvider>
        <Layout
          header={
            <Header
              title="Manage Your DataStore Connections"
              actions={
                <SideSheetTrigger
                  title="Settings"
                  sidebarId="settings"
                  icon={<Settings />}
                />
              }
            />
          }
          globalNavigation={<GlobalNavigation />}
          sideSheet={
            <SidebarSwitch
              config={[
                {
                  id: "settings",
                  element: <SettingsDrawer />,
                },
              ]}
            />
          }
        >
          <Box sx={{ flexGrow: 1, minWidth: 0, overflowY: "auto", py: 4 }}>
            <Container fixed>
              <Grid container spacing={4}>
                <Grid item xs={12} lg>
                  <ListView
                    selected={selected}
                    onSelect={handleSelect}
                    onCreate={handleCreate}
                  />
                </Grid>
                {optionallyRenderDetails()}
                {optionallyRenderForm()}
              </Grid>
            </Container>
          </Box>
        </Layout>
      </LayoutStateProvider>
    </>
  );
}
