import { Button, Grid } from "@mui/material";
import {
  BooleanField,
  DateField,
  IngestionSelect,
  NumberField,
} from "../../../components/Form";
import { UserSelect } from "../../../components/Form/UserSelect";
import { useStudioForm } from "../../../components/Form/hooks";
import { GTE, LTE } from "../../../constants";
import type { ListRecordsFormValues } from "./validation";
import { listRecordsSchema } from "./validation";

export interface RecordFiltersProps {
  values: ListRecordsFormValues;
  setValues: (newValues: ListRecordsFormValues) => void;
}

export function RecordFilters({ values, setValues }: RecordFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listRecordsSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={4}>
        <IngestionSelect
          control={control}
          name="ingestionId"
          label="Ingestion"
        />
      </Grid>
      <Grid item xs={4}>
        <BooleanField control={control} name="errored" label="Errored?" />
      </Grid>
      <Grid item xs={4}>
        <BooleanField control={control} name="archived" label="Archived?" />
      </Grid>
      <Grid item xs={3}>
        <NumberField
          control={control}
          name="lengthGte"
          label={`Length ${GTE}`}
        />
      </Grid>
      <Grid item xs={3}>
        <NumberField
          control={control}
          name="lengthLte"
          label={`Length ${LTE}`}
        />
      </Grid>
      <Grid item xs={3}>
        <NumberField
          control={control}
          name="dataLengthGte"
          label={`Data Length ${GTE}`}
        />
      </Grid>
      <Grid item xs={3}>
        <NumberField
          control={control}
          name="dataLengthLte"
          label={`Data Length ${LTE}`}
        />
      </Grid>
      <Grid item xs={3}>
        <NumberField
          control={control}
          name="offsetGte"
          label={`Offset ${GTE}`}
        />
      </Grid>
      <Grid item xs={3}>
        <NumberField
          control={control}
          name="offsetLte"
          label={`Offset ${LTE}`}
        />
      </Grid>
      <Grid item xs={3}>
        <NumberField
          control={control}
          name="dataOffsetGte"
          label={`Data Offset ${GTE}`}
        />
      </Grid>
      <Grid item xs={3}>
        <NumberField
          control={control}
          name="dataOffsetLte"
          label={`Data Offset ${LTE}`}
        />
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="createdBy" label="Created By" />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="createdAtGte"
            label={`Created At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="createdAtLte"
            label={`Created At ${LTE}`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="updatedBy" label="Updated By" />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="updatedAtGte"
            label={`Updated At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="updatedAtLte"
            label={`Updated At ${LTE}`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="deletedBy" label="Deleted By" />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="deletedAtGte"
            label={`Deleted At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="deletedAtLte"
            label={`Deleted At ${LTE}`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
