import { useState } from "react";
import { Typography } from "@mui/material";
import { DeleteDialog } from "../../../components/DeleteDialog";
import {
  dateTimeFields,
  DetailsCard,
  full,
} from "../../../components/DetailsCard";
import type { ResourceField } from "../../../components/DetailsCard/types";
import { AdminGuard } from "../../../domain/auth";
import { useDeleteGroup, useGroup } from "../../../domain/crud";
import {
  makeEditGroupLocation,
  makeGroupsLocation,
  useGroupParams,
  useStudioNavigate,
} from "../../../paths";
import type { Group } from "../../../services/datastore";
import { UserManagement } from "./UserManagement";

const FIELDS: ReadonlyArray<ResourceField<Group>> = [
  {
    col: full,
    label: "Name",
    renderField(group) {
      return (
        <>
          {group.name}
          <Typography variant="body2">{group.id}</Typography>
        </>
      );
    },
  },
  ...dateTimeFields,
];

export default function Details() {
  const { groupId } = useGroupParams();

  const groupQuery = useGroup(groupId, {
    select(response) {
      return response.data;
    },
  });

  const navigate = useStudioNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const deleteGroup = useDeleteGroup(groupId);

  function closeModal() {
    setShowDeleteDialog(false);
  }

  function openModal() {
    setShowDeleteDialog(true);
  }

  function handleDelete() {
    navigate(makeGroupsLocation());
  }

  return (
    <>
      <DetailsCard
        editLocation={makeEditGroupLocation(groupId)}
        onDelete={openModal}
        query={groupQuery}
        fields={FIELDS}
      />
      <AdminGuard>
        <UserManagement />
      </AdminGuard>
      <DeleteDialog
        open={showDeleteDialog}
        onClose={closeModal}
        resourceName="group"
        resourceLoading={groupQuery.isLoading}
        mutation={deleteGroup}
        onDelete={handleDelete}
      >
        {groupQuery.data?.name}
      </DeleteDialog>
    </>
  );
}
