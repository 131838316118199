import type {
  LogrusClient,
  UseLogrusQueryOptions,
} from "../../../services/logrus";
import { useLogrusQuery } from "../../../services/logrus";
import { queryKeyFactory } from "./common";

export type UsePlatformDataStoresResponse = Awaited<
  ReturnType<LogrusClient["listPlatformDataStores"]>
>;

export function usePlatformDataStores<TData = UsePlatformDataStoresResponse>(
  options?: UseLogrusQueryOptions<
    UsePlatformDataStoresResponse,
    unknown,
    TData,
    ReturnType<typeof queryKeyFactory.platform.list>
  >
) {
  return useLogrusQuery({
    queryKey: queryKeyFactory.platform.list(),
    queryFn(context, client) {
      return client.listPlatformDataStores();
    },
    ...options,
  });
}
