import { Skeleton, Typography } from "@mui/material";
import { useExtraction } from "../../domain/crud";
import { makeExtractionLocation } from "../../paths";
import type { Extraction } from "../../services/datastore";
import Link from "../Link";

export interface ExtractionInfoProps {
  extractionId: Extraction["id"];
}

export default function ExtractionInfo({ extractionId }: ExtractionInfoProps) {
  const extractionQuery = useExtraction(extractionId, {
    select(response) {
      return response.data.name;
    },
  });

  return (
    <>
      {extractionQuery.data ?? <Skeleton />}
      <Link to={makeExtractionLocation(extractionId)}>
        <Typography variant="body2">{extractionId}</Typography>
      </Link>
    </>
  );
}
