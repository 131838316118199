import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import * as paths from "../paths";

export default function NotFound() {
  const location = useLocation();

  const { state, ...to } = paths.makeIndexLocation({
    unmatchedPath: location.pathname,
  });

  return <Navigate replace to={to} state={state} />;
}
