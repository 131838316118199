import type { ValueOf } from "ts-essentials";
import type {
  ExtractionTopicCreateRequest,
  Log,
  Record,
} from "../../services/datastore";
import type { LocalRecord } from "./queries";

// Record down-sampling frequency. Units are records per second
export const SampleFrequency = {
  Second: 1,
  Decisecond: 10,
} as const;

export type SampleFrequencyValue = ValueOf<typeof SampleFrequency>;

export const PlaybackSpeed = {
  TimesOne: 1,
  TimesTwo: 2,
  TimesFive: 5,
  TimesTen: 10,
} as const;

export type PlaybackSpeedValue =
  typeof PlaybackSpeed[keyof typeof PlaybackSpeed];

export const Timestep = {
  Second: "second",
  Decisecond: "decisecond",
} as const;

export type TimestepValue = typeof Timestep[keyof typeof Timestep];

export interface TimeRange {
  startTimeMs: number;
  endTimeMs: number;
}

export interface PlayerLog extends Log {
  startTimeMs: Log["startTime"];
  endTimeMs: Log["endTime"];
}

export interface PlayerRecord extends LocalRecord {
  timestampMs: Record["timestamp"];
}

export type DraftExtractionTopic = Pick<
  ExtractionTopicCreateRequest,
  "topicId"
> &
  TimeRange;
