import { Button, Grid } from "@mui/material";
import {
  BooleanField,
  DateField,
  FuzzyField,
  IngestionSelect,
  LogSelect,
} from "../../../components/Form";
import { UserSelect } from "../../../components/Form/UserSelect";
import { useStudioForm } from "../../../components/Form/hooks";
import { GTE, LTE } from "../../../constants";
import type { ListTopicsFormValues } from "./validation";
import { listTopicsSchema } from "./validation";

export interface TopicFiltersProps {
  values: ListTopicsFormValues;
  setValues: (newValues: ListTopicsFormValues) => void;
}

export function TopicFilters({ values, setValues }: TopicFiltersProps) {
  const { control, handleSubmit } = useStudioForm({
    schema: listTopicsSchema,
    values,
    onSubmit: setValues,
  });

  return (
    <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
      <Grid item xs={4}>
        <FuzzyField
          control={control}
          exactName="name"
          fuzzyName="nameLike"
          label="Topic Name"
        />
      </Grid>
      <Grid item xs={4}>
        <FuzzyField
          control={control}
          exactName="typeName"
          fuzzyName="typeNameLike"
          label="Type Name"
        />
      </Grid>
      <Grid item xs={4}>
        <FuzzyField
          control={control}
          exactName="typeEncoding"
          fuzzyName="typeEncodingLike"
          label="Type Encoding"
        />
      </Grid>
      <Grid item xs={4}>
        <BooleanField control={control} name="locked" label="Locked?" />
      </Grid>
      <Grid item xs={4}>
        <LogSelect control={control} name="logId" label="Log" />
      </Grid>
      <Grid item xs={4}>
        <IngestionSelect
          control={control}
          name="ingestionId"
          label="Ingestion"
        />
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="createdBy" label="Created By" />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="createdAtGte"
            label={`Created At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="createdAtLte"
            label={`Created At ${LTE}`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="updatedBy" label="Updated By" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="updatedByNull"
            label="Updated By Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="updatedAtGte"
            label={`Updated At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="updatedAtLte"
            label={`Updated At ${LTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="updatedAtNull"
            label="Updated At Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <UserSelect control={control} name="deletedBy" label="Deleted By" />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="deletedByNull"
            label="Deleted By Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="deletedAtGte"
            label={`Deleted At ${GTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            control={control}
            name="deletedAtLte"
            label={`Deleted At ${LTE}`}
          />
        </Grid>
        <Grid item xs={4}>
          <BooleanField
            control={control}
            name="deletedAtNull"
            label="Deleted At Null?"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button type="submit" variant="contained">
          Filter
        </Button>
      </Grid>
    </Grid>
  );
}
