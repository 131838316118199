import { useState } from "react";
import { Button, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { DeleteDialog } from "../../../components/DeleteDialog";
import {
  dateTimeFields,
  DetailsCard,
  full,
  half,
} from "../../../components/DetailsCard";
import type { ResourceField } from "../../../components/DetailsCard/types";
import { GroupInfo } from "../../../components/ResourceInfo";
import { useDeleteLog, useLog } from "../../../domain/crud";
import {
  makeEditLogLocation,
  makeLogsLocation,
  makePlayerLocation,
  useLogParams,
  useMakeStudioLocation,
  useStudioNavigate,
} from "../../../paths";
import type { Log } from "../../../services/datastore";

const FIELDS: ReadonlyArray<ResourceField<Log>> = [
  {
    col: half,
    label: "Name",
    renderField(log) {
      return (
        <>
          {log.name}
          <Typography variant="body2">{log.id}</Typography>
        </>
      );
    },
  },
  {
    col: half,
    label: "Group",
    renderField(log) {
      return <GroupInfo groupId={log.groupId} />;
    },
  },
  { col: half, accessor: "startTime", dataType: "timestamp" },
  { col: half, accessor: "endTime", dataType: "timestamp" },
  { col: full, accessor: "note", dataType: "text" },
  ...dateTimeFields,
];

export default function Details() {
  const { logId } = useLogParams();

  const logQuery = useLog(logId, {
    select(response) {
      return response.data;
    },
  });

  const makeStudioLocation = useMakeStudioLocation();
  const navigate = useStudioNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const deleteLog = useDeleteLog(logId);

  function closeModal() {
    setShowDeleteDialog(false);
  }

  function openModal() {
    setShowDeleteDialog(true);
  }

  function handleDelete() {
    navigate(makeLogsLocation());
  }

  return (
    <>
      <DetailsCard
        startActions={
          <Button
            variant="contained"
            component={RouterLink}
            to={makeStudioLocation(makePlayerLocation({ logId }))}
          >
            Open in Player
          </Button>
        }
        editLocation={makeEditLogLocation(logId)}
        onDelete={openModal}
        query={logQuery}
        fields={FIELDS}
      />
      <DeleteDialog
        open={showDeleteDialog}
        onClose={closeModal}
        resourceName="log"
        resourceLoading={logQuery.isLoading}
        mutation={deleteLog}
        onDelete={handleDelete}
      >
        {logQuery.data?.name}
      </DeleteDialog>
    </>
  );
}
