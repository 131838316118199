/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const LogFormat = {
  Ros: "ros",
  Mls: "mls",
  Ark: "ark",
} as const;
export type LogFormat = typeof LogFormat[keyof typeof LogFormat];

export function LogFormatFromJSON(json: any): LogFormat {
  return LogFormatFromJSONTyped(json, false);
}

export function LogFormatFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LogFormat {
  return json as LogFormat;
}

export function LogFormatToJSON(value?: LogFormat | null): any {
  return value as any;
}
