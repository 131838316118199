import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import type { DeepNullable } from "ts-essentials";
import { z } from "zod";
import { CopyableText } from "../../../components/CopyableText";
import { requiredText, TextField } from "../../../components/Form";
import { useStudioForm } from "../../../components/Form/hooks";
import { useCreateApiKey } from "../../../domain/crud";

const schema = z.object({
  name: requiredText,
});

type FormValues = z.infer<typeof schema>;

const DEFAULT_VALUES: Readonly<DeepNullable<FormValues>> = {
  name: null,
};

export interface CreateDialogProps {
  open: boolean;
  onClose: VoidFunction;
  userId: string;
}

export default function CreateDialog({
  open,
  onClose,
  userId,
}: CreateDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const createApiKey = useCreateApiKey();

  // Close handler for the top-level <Dialog /> component's `onClose` prop.
  // Will be called when the user hits the escape key or clicks the backdrop.
  function handleDialogClose() {
    // User needs to manually hit "Close" once they've created an API key so
    // they don't accidentally close the dialog before copying their secret.
    if (createApiKey.isLoading || createApiKey.isSuccess) {
      return;
    }

    onClose();
  }

  // Close handler for buttons the user can click. Only restriction is to not
  // close the dialog while the API key is being created.
  function handleClose() {
    if (createApiKey.isLoading) {
      return;
    }

    onClose();
  }

  const { control, handleSubmit, reset } = useStudioForm({
    schema: schema,
    defaultValues: DEFAULT_VALUES,
    onSubmit(values: FormValues) {
      createApiKey.mutate(
        { userId, ...values },
        {
          onError() {
            enqueueSnackbar("Unable to create API key", { variant: "error" });
          },
        }
      );
    },
  });

  function handleCloseTransitionFinished() {
    // Once dialog closes for any reason, blow away any form and mutation state
    reset(DEFAULT_VALUES);
    createApiKey.reset();
  }

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={handleDialogClose}
      TransitionProps={{
        onExited: handleCloseTransitionFinished,
      }}
    >
      {createApiKey.isSuccess ? (
        <>
          <DialogTitle>Your API Key</DialogTitle>
          <DialogContent>
            <Typography paragraph>
              Authenticating with this API key requires both its ID and secret
            </Typography>
            <CopyableText label="ID" text={createApiKey.data.data.id} />
            <CopyableText
              label="Secret"
              text={createApiKey.data.data.secret!}
            />
            <Typography variant="body2" sx={{ fontWeight: "bold", mt: 1 }}>
              This is the only time you will have access to this secret!
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>New API Key</DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <TextField control={control} name="name" label="Name" />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={createApiKey.isLoading}
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={createApiKey.isLoading}
                variant="contained"
                type="submit"
              >
                Create
              </LoadingButton>
            </DialogActions>
          </form>
        </>
      )}
    </Dialog>
  );
}
