import type { SxProps } from "@mui/material";
import { Alert, AlertTitle, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useDataStoreContext } from "../domain/datastores";
import * as paths from "../paths";

export interface NoDataStoreAlertProps {
  sx?: SxProps;
}

export default function NoDataStoreAlert({ sx }: NoDataStoreAlertProps) {
  const { current } = useDataStoreContext();

  if (current !== null) {
    return null;
  }

  return (
    <Alert variant="filled" severity="info" sx={sx}>
      <AlertTitle>Not Connected</AlertTitle>
      You are not connected to a DataStore. To use Studio's features, connect to
      a DataStore from the{" "}
      <Link
        component={RouterLink}
        to={paths.makeDataStoresLocation()}
        color="inherit"
      >
        DataStore connection management page
      </Link>
      .
    </Alert>
  );
}
