import { useEffect, useState } from "react";
import { ContentCopy } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { useCopyToClipboard } from "../hooks";

export interface CopyableTextProps {
  text: string;
  label?: string;
}

export function CopyableText({ text, label }: CopyableTextProps) {
  const copyToClipboard = useCopyToClipboard();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  // When copy operation is successful, make the tooltip stay open for 3
  // seconds to show a success message
  useEffect(
    function setSuccessTooltipTimeout() {
      if (!copyToClipboard.isSuccess) {
        return;
      }

      const timeoutId = setTimeout(() => {
        setTooltipOpen(false);
      }, 3_000);

      return () => {
        clearTimeout(timeoutId);
      };
    },
    [copyToClipboard.isSuccess]
  );

  function handleOpenTooltip() {
    setTooltipOpen(true);
  }

  function handleCloseTooltip() {
    // Don't let the tooltip close when the success message is shown
    if (copyToClipboard.isSuccess) {
      return;
    }

    setTooltipOpen(false);
  }

  function handleTooltipTransitionExited() {
    if (!copyToClipboard.isSuccess) {
      return;
    }

    // When the tooltip finishes its exit transition after showing the success
    // message, reset the mutation to its idle state. The tooltip always shows
    // the success message while the mutation is successful, so resetting before
    // this point causes the default tooltip message to flicker on-screen
    // between the exit transition starting and finishing
    copyToClipboard.reset();
  }

  function handleCopy() {
    copyToClipboard.mutate(text, {
      onSuccess() {
        setTooltipOpen(true);
      },
    });
  }

  return (
    <TextField
      fullWidth
      label={label}
      value={text}
      margin="normal"
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip
              open={tooltipOpen}
              onOpen={handleOpenTooltip}
              onClose={handleCloseTooltip}
              title={
                copyToClipboard.isSuccess ? "Copied!" : "Copy to clipboard"
              }
              TransitionProps={{
                onExited: handleTooltipTransitionExited,
              }}
            >
              <span>
                <IconButton
                  disabled={copyToClipboard.isLoading}
                  onClick={handleCopy}
                  color={copyToClipboard.isSuccess ? "success" : undefined}
                >
                  <ContentCopy />
                </IconButton>
              </span>
            </Tooltip>
          </InputAdornment>
        ),
        sx: {
          "& input, & input": {
            fontFamily: "monospace",
            textOverflow: "ellipsis",
          },
        },
      }}
    />
  );
}
