import { Box, Card, CardContent, TableCell, Typography } from "@mui/material";
import type { z } from "zod";
import { omitNullFields } from "../../../components/Form";
import Link from "../../../components/Link";
import {
  IngestionInfo,
  LogInfo,
  TopicInfo,
} from "../../../components/ResourceInfo";
import type { Column } from "../../../components/Table";
import {
  dateTimeColumns,
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
} from "../../../components/Table";
import { useRecords } from "../../../domain/crud";
import { makeRecordLocation } from "../../../paths";
import type { Record } from "../../../services/datastore";
import { RecordFilters } from "./RecordFilters";
import { listRecordsSchema } from "./validation";

const COLUMNS: ReadonlyArray<Column<Record>> = [
  {
    header: "Timestamp",
    renderCell(record) {
      return (
        <TableCell>
          <Link to={makeRecordLocation(record.topicId, record.timestamp)}>
            <Typography variant="body2">{record.timestamp}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    header: "Topic",
    defaultHidden: true,
    renderCell(record) {
      return (
        <TableCell>
          <TopicInfo topicId={record.topicId} />
        </TableCell>
      );
    },
  },
  {
    header: "Log",
    defaultHidden: true,
    renderCell(record) {
      return (
        <TableCell>
          <LogInfo logId={record.logId} />
        </TableCell>
      );
    },
  },
  {
    header: "Ingestion",
    renderCell(record) {
      return (
        <TableCell>
          {record.ingestionId === null ? (
            "-"
          ) : (
            <IngestionInfo ingestionId={record.ingestionId} />
          )}
        </TableCell>
      );
    },
  },
  {
    accessor: "offset",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "length",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "dataOffset",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "dataLength",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "chunkCompression",
    isSortable: true,
  },
  {
    accessor: "chunkOffset",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "s3Bucket",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "s3Key",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "format",
    isSortable: true,
    defaultHidden: true,
  },
  ...dateTimeColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listRecordsSchema);

interface RecordsTableProps {
  topicId: string;
}

export default function RecordsTable({ topicId }: RecordsTableProps) {
  const [request, setRequest] = useSearchRequest(requestSchema);
  const searchQuery = useRecordSearch(topicId, request);

  const filterValues = withoutBaseTableModel(request);

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Records
      </Typography>
      <Card>
        <CardContent>
          <ResourceTable
            resourceName="record"
            getRowKey={(record) => record.timestamp}
            columns={COLUMNS}
            searchQuery={searchQuery}
            tableModel={request}
            onTableModelChange={setRequest}
            filterSection={
              <RecordFilters values={filterValues} setValues={setRequest} />
            }
            activeFilterCount={getActiveFiltersCount(filterValues)}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

function useRecordSearch(
  topicId: string,
  request: z.infer<typeof requestSchema>
) {
  return useRecords(topicId, omitNullFields(prepareListRequest(request)), {
    keepPreviousData: true,
    cacheTime: 0,
  });
}
