import React from "react";
import { DarkMode, Error as ErrorIcon, LightMode } from "@mui/icons-material";
import type { PaletteMode } from "@mui/material";
import {
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useCurrentUser } from "../domain/crud";
import { useDataStoreContext } from "../domain/datastores";
import { useDarkMode } from "../providers/DarkModeProvider";
import { selectData } from "../utils";
import { Dl, renderDlGroup } from "./DescriptionList";
import DrawerHeader from "./DrawerHeader";

export interface SettingsDrawerProps {
  children?: React.ReactNode;
}

export default function SettingsDrawer({ children }: SettingsDrawerProps) {
  const connectionContext = useDataStoreContext();
  const currentUserQuery = useCurrentUser({ select: selectData });

  const { mode, setMode } = useDarkMode();

  function handleModeChange(
    e: React.MouseEvent<HTMLElement>,
    newMode: PaletteMode | null
  ) {
    if (newMode !== null) {
      setMode(newMode);
    }
  }

  return (
    <>
      <DrawerHeader title="Settings" />
      <Stack spacing={2}>
        <div>
          <Typography variant="h6" component="p">
            Current DataStore Connection
          </Typography>
          {connectionContext.current !== null ? (
            <Dl spacing={3}>
              {renderDlGroup("URL", connectionContext.current.origin ?? "-", {
                xs: 12,
              })}
              {renderDlGroup("Name", connectionContext.current.name ?? "-", {
                xs: 12,
              })}
              {renderDlGroup(
                "Username",
                currentUserQuery.isSuccess ? (
                  currentUserQuery.data.username
                ) : currentUserQuery.isError ? (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <ErrorIcon color="error" />
                    <Typography paragraph>
                      You're signed in but your account information couldn't be
                      retrieved.
                    </Typography>
                  </Stack>
                ) : (
                  "Fetching your account information..."
                ),
                { xs: 12 }
              )}
            </Dl>
          ) : (
            <Typography>Not connected to a DataStore</Typography>
          )}
        </div>
        <div>
          <Typography variant="h6" component="p" id="color-mode-title">
            Color Mode
          </Typography>
          <ToggleButtonGroup
            sx={{
              "& .MuiSvgIcon-root": {
                mr: 1,
              },
            }}
            aria-labelledby="color-mode-title"
            value={mode}
            onChange={handleModeChange}
            exclusive
            color="primary"
            fullWidth
          >
            <ToggleButton value="light">
              <LightMode />
              Light
            </ToggleButton>
            <ToggleButton value="dark">
              <DarkMode />
              Dark
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {children !== undefined && (
          <div>
            <Divider sx={{ my: 2 }} />
            {children}
          </div>
        )}
      </Stack>
    </>
  );
}
