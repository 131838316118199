export const queryKeyFactory = {
  platform: {
    all: ["$$logrus", "platform-datastores"] as const,
    lists: () => [...queryKeyFactory.platform.all, "list"] as const,
    list: () => [...queryKeyFactory.platform.lists()] as const,
  },
  custom: {
    all: ["custom-datastores"] as const,
    lists: () => [...queryKeyFactory.custom.all, "list"] as const,
    list: () => [...queryKeyFactory.custom.lists()] as const,
  },
};
