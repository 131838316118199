import { sortedIndexBy, sortedLastIndexBy } from "lodash";
import type { Topic } from "../../../services/datastore";
import type { Maybe } from "../../../types";
import { usePlaybackSource } from "../PlaybackProvider";
import type { PlayerRecord, SampleFrequencyValue, TimeRange } from "../types";
import type { UseRecordChunksResult } from "./useRecordChunks";
import useRecordChunks from "./useRecordChunks";
import { calculateRecordWindow } from "./utils";

export interface UseRecordWindowOptions {
  topicId?: Maybe<Topic["id"]>;
  enabled?: boolean;
  windowSizeMs: number;
  sampleFrequency?: SampleFrequencyValue;
  includeImage?: boolean;
  bufferBehindMs?: number;
  bufferAheadMs?: number;
  chunkSizeMs: number;
}

export default function useRecordWindow({
  topicId,
  enabled = true,
  windowSizeMs,
  sampleFrequency,
  includeImage = false,
  bufferBehindMs,
  bufferAheadMs,
  chunkSizeMs,
}: UseRecordWindowOptions) {
  const playbackSource = usePlaybackSource();

  let recordWindow: TimeRange | undefined = undefined;
  if (!playbackSource.isLoading) {
    recordWindow = calculateRecordWindow(
      windowSizeMs,
      playbackSource.timestampMs,
      playbackSource.bounds
    );
  }

  const result = useRecordChunks({
    topicId,
    enabled: enabled && recordWindow !== undefined,
    playerBounds: playbackSource.bounds,
    recordWindow,
    sampleFrequency,
    includeImage,
    bufferBehindMs,
    bufferAheadMs,
    chunkSizeMs,
  });

  return sliceResult(result);
}

function sliceResult(result: UseRecordChunksResult): UseRecordChunksResult {
  if (result.data === undefined) {
    return result;
  }

  if (result.recordWindow === undefined) {
    return result;
  }

  const lowerIndex = sortedIndexBy(
    result.data,
    { timestampMs: result.recordWindow.startTimeMs } as PlayerRecord,
    "timestampMs"
  );
  const upperIndex = sortedLastIndexBy(
    result.data,
    { timestampMs: result.recordWindow.endTimeMs } as PlayerRecord,
    "timestampMs"
  );

  return {
    ...result,
    data: result.data.slice(lowerIndex, upperIndex),
  };
}
