import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { pick } from "lodash";
import { useSnackbar } from "notistack";
import { getChangedFields, TextField } from "../../../components/Form";
import { useStudioForm } from "../../../components/Form/hooks";
import { useUpdateGroup } from "../../../domain/crud";
import {
  makeGroupLocation,
  useGroupParams,
  useStudioNavigate,
} from "../../../paths";
import type { Group } from "../../../services/datastore";
import { EDITABLE_FIELDS, editFormSchema } from "./validation";

interface GroupFormProps {
  group: Group;
}

export default function GroupForm({ group }: GroupFormProps) {
  const { groupId } = useGroupParams();

  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const updateGroup = useUpdateGroup(groupId);

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useStudioForm({
    schema: editFormSchema,
    defaultValues: pick(group, EDITABLE_FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      updateGroup.mutate(changedFields, {
        onSuccess() {
          enqueueSnackbar("Group updated", { variant: "success" });

          navigate(makeGroupLocation(groupId));
        },
        onError() {
          enqueueSnackbar("Could not update group", { variant: "error" });
        },
      });
    },
  });

  return (
    <Card>
      <CardContent>
        <Grid
          component="form"
          name="editGroup"
          container
          onSubmit={handleSubmit}
          spacing={2}
        >
          <Grid item xs={3}>
            <TextField control={control} name="name" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={updateGroup.isLoading}
              type="submit"
              variant="contained"
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
