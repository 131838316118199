import { useQueryClient } from "@tanstack/react-query";
import type { StrictOmit } from "ts-essentials";
import type {
  APIKey,
  APIKeyCreateRequest,
  APIKeyFetchResponse,
  APIKeyListResponse,
  APIKeyUpdateRequest,
  ListApiKeysRequest,
} from "../../services/datastore";
import type { ResolvedKeyFactory } from "../../types";
import type { UseDataStoreQueryOptions } from "../datastores";
import {
  useDataStoreMutation,
  useDataStoreQuery,
  useDataStoreQueryKey,
} from "../datastores";
import { getInitialDetailsData } from "./utils";

export function useApiKeyKeys() {
  const baseKey = useDataStoreQueryKey(["api-keys"] as const);

  const factory = {
    all: baseKey,
    lists: () => [...factory.all, "list"] as const,
    list: (request: ListApiKeysRequest) =>
      [...factory.lists(), request] as const,
    details: () => [...factory.all, "details"] as const,
    detail: (apiKeyId: APIKey["id"]) =>
      [...factory.details(), apiKeyId] as const,
  } as const;

  return factory;
}

export type ApiKeyKeys = ResolvedKeyFactory<typeof useApiKeyKeys>;

export function useApiKeys<TData = APIKeyListResponse>(
  request: ListApiKeysRequest,
  options?: UseDataStoreQueryOptions<
    APIKeyListResponse,
    unknown,
    TData,
    ApiKeyKeys["list"]
  >
) {
  return useDataStoreQuery({
    queryKey: useApiKeyKeys().list(request),
    queryFn(context, { apiKeyApi }) {
      return apiKeyApi.listApiKeys(request, context);
    },
    ...options,
  });
}

export function useApiKey<TData = APIKeyFetchResponse>(
  apiKeyId: APIKey["id"],
  options?: StrictOmit<
    UseDataStoreQueryOptions<
      APIKeyFetchResponse,
      unknown,
      TData,
      ApiKeyKeys["detail"]
    >,
    "initialData"
  >
) {
  const queryClient = useQueryClient();

  const apiKeyKeys = useApiKeyKeys();

  return useDataStoreQuery({
    queryKey: apiKeyKeys.detail(apiKeyId),
    queryFn(context, { apiKeyApi }) {
      return apiKeyApi.getApiKey({ apiKeyId }, context);
    },
    ...options,
    initialData() {
      return getInitialDetailsData(
        queryClient,
        apiKeyKeys.lists(),
        (apiKey: APIKey) => apiKey.id === apiKeyId
      );
    },
  });
}

export function useCreateApiKey() {
  const queryClient = useQueryClient();

  const apiKeyKeys = useApiKeyKeys();

  return useDataStoreMutation({
    mutationFn(request: APIKeyCreateRequest, { apiKeyApi }) {
      return apiKeyApi.createApiKey({ aPIKeyCreateRequest: request });
    },
    // The returned API key contains a populated `secret` field. The only time
    // a user should ever be able to see that value is when they create the API
    // key (i.e. as a result of this mutation). Along with setting the mutation
    // cache time to 0, don't store the API key in the query cache either!
    cacheTime: 0,
    onSuccess() {
      queryClient.invalidateQueries(apiKeyKeys.lists());
    },
  });
}

export function useUpdateApiKey(apiKeyId: APIKey["id"]) {
  const queryClient = useQueryClient();

  const apiKeyKeys = useApiKeyKeys();

  return useDataStoreMutation({
    mutationFn(request: APIKeyUpdateRequest, { apiKeyApi }) {
      return apiKeyApi.updateApiKey({ apiKeyId, aPIKeyUpdateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData<APIKeyFetchResponse>(
        apiKeyKeys.detail(response.data.id),
        response
      );
    },
  });
}

export function useDeleteApiKey(apiKeyId: APIKey["id"]) {
  return useDataStoreMutation({
    mutationFn(_, { apiKeyApi }) {
      return apiKeyApi.deleteApiKey({ apiKeyId });
    },
  });
}
