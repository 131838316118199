/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import {
  APIKey,
  APIKeyFromJSON,
  APIKeyFromJSONTyped,
  APIKeyToJSON,
} from "./APIKey";

/**
 *
 * @export
 * @interface APIKeyListResponse
 */
export interface APIKeyListResponse {
  /**
   *
   * @type {number}
   * @memberof APIKeyListResponse
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof APIKeyListResponse
   */
  limit: number;
  /**
   *
   * @type {string}
   * @memberof APIKeyListResponse
   */
  order: string;
  /**
   *
   * @type {string}
   * @memberof APIKeyListResponse
   */
  sort: string;
  /**
   *
   * @type {number}
   * @memberof APIKeyListResponse
   */
  count: number;
  /**
   *
   * @type {Array<APIKey>}
   * @memberof APIKeyListResponse
   */
  data: Array<APIKey>;
}

export function APIKeyListResponseFromJSON(json: any): APIKeyListResponse {
  return APIKeyListResponseFromJSONTyped(json, false);
}

export function APIKeyListResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): APIKeyListResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    offset: json["offset"],
    limit: json["limit"],
    order: json["order"],
    sort: json["sort"],
    count: json["count"],
    data: (json["data"] as Array<any>).map(APIKeyFromJSON),
  };
}

export function APIKeyListResponseToJSON(
  value?: APIKeyListResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    offset: value.offset,
    limit: value.limit,
    order: value.order,
    sort: value.sort,
    count: value.count,
    data: (value.data as Array<any>).map(APIKeyToJSON),
  };
}
