import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { pick } from "lodash";
import { useSnackbar } from "notistack";
import {
  CheckboxField,
  getChangedFields,
  NumberField,
  SelectField,
  statusOptions,
  TextField,
} from "../../../components/Form";
import { useStudioForm } from "../../../components/Form/hooks";
import { useUpdateExtraction } from "../../../domain/crud";
import {
  makeExtractionLocation,
  useExtractionParams,
  useStudioNavigate,
} from "../../../paths";
import type { Extraction } from "../../../services/datastore";
import { EDITABLE_EXTRACTION_FIELDS, editExtractionSchema } from "./validation";

interface ExtractionFormProps {
  extraction: Extraction;
}

export default function ExtractionForm({ extraction }: ExtractionFormProps) {
  const { extractionId } = useExtractionParams();

  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const updateExtraction = useUpdateExtraction(extractionId);

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useStudioForm({
    schema: editExtractionSchema,
    defaultValues: pick(extraction, EDITABLE_EXTRACTION_FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      // @ts-ignore OpenAPI issue with optional vs. nullable
      updateExtraction.mutate(changedFields, {
        onSuccess() {
          enqueueSnackbar("Extraction updated", { variant: "success" });

          navigate(makeExtractionLocation(extractionId));
        },
        onError() {
          enqueueSnackbar("Could not update extraction", { variant: "error" });
        },
      });
    },
  });

  return (
    <Card>
      <CardContent>
        <Grid
          component="form"
          name="editExtraction"
          container
          onSubmit={handleSubmit}
          spacing={2}
        >
          <Grid item xs={4}>
            <TextField control={control} name="name" />
          </Grid>
          <Grid item xs={4}>
            <TextField control={control} name="s3Bucket" />
          </Grid>
          <Grid item xs={4}>
            <TextField control={control} name="s3Key" />
          </Grid>
          <Grid item xs={2}>
            <NumberField control={control} name="size" label="Size" />
          </Grid>
          <Grid item xs={2}>
            <SelectField
              control={control}
              name="status"
              label="Status"
              options={statusOptions}
            />
          </Grid>
          <Grid item xs={2}>
            <NumberField control={control} name="progress" label="Progress" />
          </Grid>
          <Grid item xs={6}>
            <TextField control={control} name="note" />
          </Grid>
          <Grid item xs={2}>
            <CheckboxField control={control} name="queued" />
          </Grid>
          <Grid item xs={2}>
            <CheckboxField control={control} name="processing" />
          </Grid>
          <Grid item xs={2}>
            <CheckboxField control={control} name="errored" />
          </Grid>
          <Grid item xs={2}>
            <CheckboxField control={control} name="cancelled" />
          </Grid>
          <Grid item xs={2}>
            <CheckboxField control={control} name="archived" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={updateExtraction.isLoading}
              type="submit"
              variant="contained"
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
