import { useQuery } from "@tanstack/react-query";
import invariant from "invariant";
import { useLogrus } from "./provider";
import type {
  LogrusQueryKey,
  UseLogrusQueryFullOptions,
  UseLogrusQueryResult,
} from "./types";

export function useLogrusQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends LogrusQueryKey = LogrusQueryKey
>(
  options: UseLogrusQueryFullOptions<TQueryFnData, TError, TData, TQueryKey>
): UseLogrusQueryResult<TData, TError> {
  const logrus = useLogrus();

  const {
    enabled: initialEnabled = true,
    queryFn: userQueryFn,
    ...remainingOptions
  } = options;

  const canRun = logrus.authStatus === "authenticated";

  const enabled = initialEnabled && canRun;

  return Object.defineProperty(
    useQuery({
      ...remainingOptions,
      queryFn(context) {
        invariant(canRun, "User is not authenticated");

        return userQueryFn(context, logrus.client);
      },
      enabled,
    }),
    "canRun",
    { value: canRun, writable: false }
  ) as UseLogrusQueryResult<TData, TError>;
}
