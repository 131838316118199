import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { pick } from "lodash";
import { useSnackbar } from "notistack";
import {
  getChangedFields,
  GroupSelect,
  NumberField,
  TextField,
} from "../../../components/Form";
import { useStudioForm } from "../../../components/Form/hooks";
import { useUpdateLog } from "../../../domain/crud";
import {
  makeLogLocation,
  useLogParams,
  useStudioNavigate,
} from "../../../paths";
import type { Log } from "../../../services/datastore";
import { EDITABLE_LOG_FIELDS, editLogSchema } from "./validation";

interface LogFormProps {
  log: Log;
}

export default function LogForm({ log }: LogFormProps) {
  const { logId } = useLogParams();

  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const updateLog = useUpdateLog(logId);

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useStudioForm({
    schema: editLogSchema,
    defaultValues: pick(log, EDITABLE_LOG_FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      // @ts-ignore OpenAPI issue with optional vs. nullable
      updateLog.mutate(changedFields, {
        onSuccess() {
          enqueueSnackbar("Log updated", { variant: "success" });

          navigate(makeLogLocation(logId));
        },
        onError() {
          enqueueSnackbar("Could not update log", { variant: "error" });
        },
      });
    },
  });

  return (
    <Card>
      <CardContent>
        <Grid
          component="form"
          name="editLog"
          container
          onSubmit={handleSubmit}
          spacing={2}
        >
          <Grid item xs={4}>
            <GroupSelect control={control} name="groupId" />
          </Grid>
          <Grid item xs={4}>
            <TextField control={control} name="name" />
          </Grid>
          <Grid item xs={4}>
            <TextField control={control} name="note" />
          </Grid>
          <Grid item xs={4}>
            <NumberField
              control={control}
              name="startTime"
              label="Start Time"
            />
          </Grid>
          <Grid item xs={4}>
            <NumberField control={control} name="endTime" label="End Time" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={updateLog.isLoading}
              type="submit"
              variant="contained"
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
