import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import type { UseMutationResult } from "@tanstack/react-query";
import invariant from "invariant";
import { capitalize, startCase } from "lodash";
import { useSnackbar } from "notistack";

export interface DeleteDialogProps {
  open: boolean;
  onClose: VoidFunction;
  resourceName: string;
  resourceLoading?: boolean;
  mutation: UseMutationResult<void, unknown, void>;
  onDelete: VoidFunction;
  children: React.ReactNode;
}

export function DeleteDialog({
  open,
  onClose,
  resourceName,
  resourceLoading = false,
  mutation,
  onDelete,
  children,
}: DeleteDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  function handleClose() {
    if (mutation.isLoading) {
      return;
    }

    onClose();
  }

  function handleDelete() {
    invariant(!resourceLoading, "Resource hasn't loaded yet");

    mutation.mutate(undefined, {
      onSuccess() {
        enqueueSnackbar(`${capitalize(resourceName)} deleted`, {
          variant: "success",
        });

        onDelete();
        onClose();
      },
      onError() {
        enqueueSnackbar(`Could not delete ${resourceName}`, {
          variant: "error",
        });
      },
    });
  }

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      open={!resourceLoading && open}
    >
      <DialogTitle>Delete {startCase(resourceName)}?</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="secondary"
          disabled={mutation.isLoading}
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={mutation.isLoading}
          onClick={handleDelete}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
