import React from "react";
import {
  Album,
  CloudDownload,
  CloudUpload,
  CloudUploadOutlined,
  Group,
  Key,
  People,
  Person,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { Database, FileCloud } from "mdi-material-ui";
import type { Location } from "react-router-dom";
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom";
import { AdminGuard, AuthGuard } from "../domain/auth";
import { DOCS_ORIGIN } from "../links";
import * as paths from "../paths";
import { useMakeStudioLocation } from "../paths";
import { useLogrus } from "../services/logrus";
import {
  ScreenConfiguration,
  useLayoutStateContext,
  useScreenConfiguration,
} from "./Layout";
import Logo from "./Logo";

function useIsActivePath() {
  const location = useLocation();

  return function isActivePath(path: string, end = false): boolean {
    return matchPath({ path, end }, location.pathname) !== null;
  };
}

export default function GlobalNavigation() {
  const { isGlobalNavigationOpen } = useLayoutStateContext();
  const screenConfiguration = useScreenConfiguration();

  const isActivePath = useIsActivePath();

  const useMiniDrawerStyles = !(
    screenConfiguration === ScreenConfiguration.Mobile || isGlobalNavigationOpen
  );

  const makeStudioLocation = useMakeStudioLocation();

  function renderLink(
    icon: React.ReactNode,
    text: string,
    path: string,
    to: Partial<Location>
  ) {
    const isSelected = isActivePath(path);

    return (
      <ListItem component="div" disablePadding>
        <ListItemButton
          component={RouterLink}
          to={makeStudioLocation(to)}
          selected={isSelected}
          sx={{
            ...(useMiniDrawerStyles && {
              px: 0,
              justifyContent: "center",
            }),
          }}
        >
          {useMiniDrawerStyles ? (
            <Tooltip title={text} placement="right">
              <span>{icon}</span>
            </Tooltip>
          ) : (
            <>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  variant: "body2",
                  fontWeight: isSelected ? 600 : undefined,
                }}
              >
                {text}
              </ListItemText>
            </>
          )}
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <Stack
      sx={{
        flexGrow: 1,
        minHeight: 0,
        overflowY: "auto",
      }}
    >
      <List
        component="nav"
        aria-label="global"
        sx={{
          "& .MuiListItemButton-root": {
            my: 0.5,
            borderRadius: "8px",
          },
        }}
      >
        <ListItem
          component="div"
          disablePadding
          sx={{
            justifyContent: "center",
            "& .homepage-link": {
              display: "flex",
              justifyContent: "center",
            },
            "& .logqs-logo": {
              display: "block",
              width: "auto",
              height: 40,
              mb: 2,
            },
          }}
        >
          {isActivePath(paths.INDEX, true) ? (
            <Logo alt="LogQS logo" icon={useMiniDrawerStyles} />
          ) : (
            <RouterLink
              className="homepage-link"
              to={makeStudioLocation(paths.makeIndexLocation())}
            >
              <Logo alt="Homepage" icon={useMiniDrawerStyles} />
            </RouterLink>
          )}
        </ListItem>
        {renderLink(
          <FileCloud />,
          "Logs",
          paths.LOGS,
          paths.makeLogsLocation()
        )}
        {renderLink(
          <CloudUpload />,
          "Upload",
          paths.UPLOAD,
          paths.makeUploadLocation()
        )}
        {renderLink(
          <Album />,
          "Player",
          paths.PLAYER,
          paths.makePlayerLocation()
        )}
        <Divider sx={{ my: 1 }} />
        {renderLink(
          <CloudDownload />,
          "Topics",
          paths.TOPICS,
          paths.makeTopicsLocation()
        )}
        {renderLink(
          <CloudUploadOutlined />,
          "Ingestions",
          paths.INGESTIONS,
          paths.makeIngestionsLocation()
        )}
        {renderLink(
          <CloudDownload />,
          "Extractions",
          paths.EXTRACTIONS,
          paths.makeExtractionsLocation()
        )}
        <Divider sx={{ my: 1 }} />
        {renderLink(
          <Database />,
          "DataStores",
          paths.DATASTORES,
          paths.makeDataStoresLocation()
        )}
        <AuthGuard>
          {renderLink(
            <Person />,
            "Profile",
            paths.PROFILE,
            paths.makeProfileLocation()
          )}
        </AuthGuard>
        <AdminGuard>
          {renderLink(
            <People />,
            "Users",
            paths.USERS,
            paths.makeUsersLocation()
          )}
          {renderLink(
            <Group />,
            "Groups",
            paths.GROUPS,
            paths.makeGroupsLocation()
          )}
          {renderLink(
            <Key />,
            "API Keys",
            paths.API_KEYS,
            paths.makeApiKeysLocation()
          )}
        </AdminGuard>
      </List>
      {!useMiniDrawerStyles && (
        <Stack spacing={4} mt="auto" alignItems="center">
          <Link href={DOCS_ORIGIN} underline="none" fontWeight="bold">
            Docs
          </Link>
          <AuthAction />
        </Stack>
      )}
    </Stack>
  );
}

function AuthAction() {
  const { authStatus } = useLogrus();

  if (authStatus === "authenticated") {
    return (
      <Link
        component="button"
        variant="body1"
        onClick={() => Auth.signOut()}
        underline="none"
        fontWeight="bold"
      >
        Sign Out
      </Link>
    );
  } else {
    return (
      <Button
        fullWidth
        disableElevation
        color="primary"
        variant="contained"
        onClick={() => Auth.federatedSignIn()}
      >
        Sign In
      </Button>
    );
  }
}
