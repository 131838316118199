import { Lock, LockOpen } from "@mui/icons-material";
import { Card, CardContent, TableCell, Typography } from "@mui/material";
import type { z } from "zod";
import { omitNullFields } from "../../../components/Form";
import Link from "../../../components/Link";
import { GroupInfo } from "../../../components/ResourceInfo";
import type { Column } from "../../../components/Table";
import {
  dateTimeColumns,
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
} from "../../../components/Table";
import { useLogs } from "../../../domain/crud";
import { makeLogLocation, makeNewLogLocation } from "../../../paths";
import type { Log } from "../../../services/datastore";
import { LogFilters } from "./LogFilters";
import { listLogsSchema } from "./validation";

const COLUMNS: ReadonlyArray<Column<Log>> = [
  {
    header: "Log",
    sortKey: "name",
    renderCell(log) {
      return (
        <TableCell>
          {log.name}
          <Link to={makeLogLocation(log.id)}>
            <Typography variant="body2">{log.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "startTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "endTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "recordSize",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "recordCount",
    dataType: "number",
    isSortable: true,
  },
  {
    header: "Locked",
    defaultHidden: true,
    renderCell(log) {
      return (
        <TableCell>
          {log.locked === null ? "-" : log.locked ? <Lock /> : <LockOpen />}
        </TableCell>
      );
    },
  },
  {
    header: "Group",
    renderCell(log) {
      return (
        <TableCell>
          <GroupInfo groupId={log.groupId} />
        </TableCell>
      );
    },
  },
  ...dateTimeColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listLogsSchema);

export default function Table() {
  const [request, setRequest] = useSearchRequest(requestSchema);
  const searchQuery = useLogSearch(request);

  const filterValues = withoutBaseTableModel(request);

  return (
    <Card>
      <CardContent>
        <ResourceTable
          resourceName="log"
          resourceCreateLocation={makeNewLogLocation()}
          getRowKey={(log) => log.id}
          columns={COLUMNS}
          searchQuery={searchQuery}
          tableModel={request}
          onTableModelChange={setRequest}
          filterSection={
            <LogFilters values={filterValues} setValues={setRequest} />
          }
          activeFilterCount={getActiveFiltersCount(filterValues)}
        />
      </CardContent>
    </Card>
  );
}

function useLogSearch(request: z.infer<typeof requestSchema>) {
  return useLogs(omitNullFields(prepareListRequest(request)), {
    keepPreviousData: true,
    cacheTime: 0,
  });
}
