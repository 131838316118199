import React from "react";
import type { JsonObject } from "type-fest";
import { z } from "zod";
import { assertNever, getFriendlyResourceKey } from "../../utils";
import { Boolean } from "../Boolean";
import { Bytes } from "../Bytes";
import { Datetime } from "../Datetime";
import { renderDlGroup } from "../DescriptionList";
import JsonTree from "../JsonTree";
import { UserInfo } from "../ResourceInfo";
import { Timestamp } from "../Timestamp";
import type { FieldType } from "./types";
import type { AccessorResourceField, ResourceField } from "./types";

const booleanField = z.boolean().nullable();

const bytesField = z.number().nullable();

const datetimeField = z.date().nullable();

const jsonField = z.unknown().refine((value): value is JsonObject | null => {
  return typeof value === "object";
});

const percentField = z.number().nullable();

// It's fine to coerce non-strings into strings for text fields
const textField = z.coerce.string().nullable();

const timestampField = z.number().nullable();

const userField = z.string().uuid().nullable();

export function renderField<TResource>(
  resource: TResource,
  field: ResourceField<TResource>
) {
  const label = isAccessorField(field)
    ? field.label ?? getFriendlyResourceKey(field.accessor)
    : field.label;

  const content = isAccessorField(field)
    ? getAccessorFieldContent(resource[field.accessor], field.dataType)
    : field.renderField(resource);

  return (
    <React.Fragment key={label}>
      {renderDlGroup(label, content, field.col)}
    </React.Fragment>
  );
}

function getAccessorFieldContent(
  fieldData: unknown,
  fieldType: FieldType
): React.ReactNode {
  switch (fieldType) {
    case "boolean": {
      return <Boolean value={booleanField.parse(fieldData)} />;
    }
    case "bytes": {
      return <Bytes value={bytesField.parse(fieldData)} />;
    }
    case "datetime": {
      return <Datetime date={datetimeField.parse(fieldData)} />;
    }
    case "json": {
      return <JsonTree src={jsonField.parse(fieldData)} onSelect={() => {}} />;
    }
    case "percent": {
      const parsedValue = percentField.parse(fieldData);

      return parsedValue === null ? "-" : `${parsedValue * 100}%`;
    }
    case "text": {
      const parsedValued = textField.parse(fieldData);

      return parsedValued ?? "-";
    }
    case "timestamp": {
      return <Timestamp date={timestampField.parse(fieldData)} />;
    }
    case "user": {
      const parsedValue = userField.parse(fieldData);

      return parsedValue === null ? "-" : <UserInfo userId={parsedValue} />;
    }
    default: {
      assertNever(fieldType);
    }
  }
}

function isAccessorField<TResource>(
  resourceField: ResourceField<TResource>
): resourceField is AccessorResourceField<TResource> {
  return "accessor" in resourceField;
}
