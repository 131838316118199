import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  CheckboxField,
  formatOptions,
  LogSelect,
  NumberField,
  omitNullFields,
  SelectField,
  TextField,
} from "../../../components/Form";
import { useStudioForm } from "../../../components/Form/hooks";
import { useCreateIngestion } from "../../../domain/crud";
import { makeIngestionLocation, useStudioNavigate } from "../../../paths";
import { createIngestionSchema } from "./validation";

export default function IngestionForm() {
  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const createIngestion = useCreateIngestion();

  const { control, handleSubmit } = useStudioForm({
    schema: createIngestionSchema,
    defaultValues: {
      name: null,
      logId: null,
      s3Bucket: null,
      s3Key: null,
      startOffset: null,
      endOffset: null,
      note: null,
      format: null,
      queued: false,
    },
    onSubmit(values) {
      createIngestion.mutate(omitNullFields(values), {
        onSuccess(response) {
          navigate(makeIngestionLocation(response.data.id));
        },
        onError() {
          enqueueSnackbar("Unable to create ingestion", { variant: "error" });
        },
      });
    },
  });

  return (
    <Card>
      <CardContent>
        <Grid component="form" container onSubmit={handleSubmit} spacing={2}>
          <Grid item xs={3}>
            <TextField control={control} name="name" />
          </Grid>
          <Grid item xs={3}>
            <LogSelect control={control} name="logId" label="Log" />
          </Grid>
          <Grid item xs={3}>
            <TextField control={control} name="s3Bucket" />
          </Grid>
          <Grid item xs={3}>
            <TextField control={control} name="s3Key" />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              name="startOffset"
              label="Start Offset"
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              name="endOffset"
              label="End Offset"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField control={control} name="note" />
          </Grid>
          <Grid item xs={2}>
            <SelectField
              control={control}
              name="format"
              label="Format"
              options={formatOptions}
            />
          </Grid>
          <Grid item xs={2}>
            <CheckboxField control={control} name="queued" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={createIngestion.isLoading}
              type="submit"
              variant="contained"
            >
              Create
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
