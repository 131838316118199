import { useState } from "react";
import { Typography } from "@mui/material";
import { DeleteDialog } from "../../../components/DeleteDialog";
import {
  dateTimeFields,
  DetailsCard,
  half,
  third,
} from "../../../components/DetailsCard";
import type { ResourceField } from "../../../components/DetailsCard/types";
import { LogInfo } from "../../../components/ResourceInfo";
import { useDeleteIngestion, useIngestion } from "../../../domain/crud";
import {
  makeEditIngestionLocation,
  makeIngestionsLocation,
  useIngestionParams,
  useStudioNavigate,
} from "../../../paths";
import type { Ingestion } from "../../../services/datastore";

const FIELDS: ReadonlyArray<ResourceField<Ingestion>> = [
  {
    col: half,
    label: "Name",
    renderField(ingestion) {
      return (
        <>
          {ingestion.name ?? "-"}
          <Typography variant="body2">{ingestion.id}</Typography>
        </>
      );
    },
  },
  {
    col: half,
    label: "Log",
    renderField(ingestion) {
      return <LogInfo logId={ingestion.logId} />;
    },
  },
  { col: half, accessor: "s3Bucket", dataType: "text" },
  { col: half, accessor: "s3Key", dataType: "text" },
  { col: half, accessor: "status", dataType: "text" },
  { col: half, accessor: "note", dataType: "text" },
  { col: half, accessor: "startOffset", dataType: "bytes" },
  { col: half, accessor: "endOffset", dataType: "bytes" },
  { col: half, accessor: "size", dataType: "bytes" },
  { col: half, accessor: "progress", dataType: "percent" },
  { col: third, accessor: "format", dataType: "text" },
  { col: third, accessor: "meta", dataType: "json" },
  { col: third, accessor: "error", dataType: "json" },
  ...dateTimeFields,
  { col: third, accessor: "queued", dataType: "boolean" },
  { col: third, accessor: "processing", dataType: "boolean" },
  { col: third, accessor: "errored", dataType: "boolean" },
  { col: third, accessor: "archived", dataType: "boolean" },
];

export default function Details() {
  const { ingestionId } = useIngestionParams();

  const ingestionQuery = useIngestion(ingestionId, {
    select(response) {
      return response.data;
    },
  });

  const navigate = useStudioNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const deleteIngestion = useDeleteIngestion(ingestionId);

  function closeModal() {
    setShowDeleteDialog(false);
  }

  function openModal() {
    setShowDeleteDialog(true);
  }

  function handleDelete() {
    navigate(makeIngestionsLocation());
  }

  return (
    <>
      <DetailsCard
        editLocation={makeEditIngestionLocation(ingestionId)}
        onDelete={openModal}
        query={ingestionQuery}
        fields={FIELDS}
      />
      <DeleteDialog
        open={showDeleteDialog}
        onClose={closeModal}
        resourceName="ingestion"
        resourceLoading={ingestionQuery.isLoading}
        mutation={deleteIngestion}
        onDelete={handleDelete}
      >
        {ingestionQuery.data?.name ?? ingestionId}
      </DeleteDialog>
    </>
  );
}
