import { LogFormat, ProcessStatus, UserRole } from "../../services/datastore";

export const formatOptions = Object.values(LogFormat).map((format) => ({
  value: format,
  label: format,
}));

export const statusOptions = Object.values(ProcessStatus).map((status) => ({
  value: status,
  label: status,
}));

export const userRoleOptions = Object.values(UserRole).map((role) => ({
  value: role,
  label: role,
}));
