import { Card, CardContent, TableCell, Typography } from "@mui/material";
import type { z } from "zod";
import { Boolean } from "../../../components/Boolean";
import { omitNullFields } from "../../../components/Form";
import Link from "../../../components/Link";
import type { Column } from "../../../components/Table";
import {
  dateTimeColumns,
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
} from "../../../components/Table";
import { useUsers } from "../../../domain/crud";
import { makeNewUserLocation, makeUserLocation } from "../../../paths";
import type { User } from "../../../services/datastore";
import { UserFilters } from "./UserFilters";
import { listUsersSchema } from "./validation";

const COLUMNS: ReadonlyArray<Column<User>> = [
  {
    header: "Username",
    sortKey: "username",
    renderCell(user) {
      return (
        <TableCell>
          {user.username}
          <Link to={makeUserLocation(user.id)}>
            <Typography variant="body2">{user.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    header: "Is Admin?",
    renderCell(user) {
      return (
        <TableCell>
          <Boolean value={user.isAdmin} />
        </TableCell>
      );
    },
  },
  ...dateTimeColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listUsersSchema);

export default function Table() {
  const [request, setRequest] = useSearchRequest(requestSchema);
  const searchQuery = useUserSearch(request);

  const filterValues = withoutBaseTableModel(request);

  return (
    <Card>
      <CardContent>
        <ResourceTable
          resourceName="user"
          resourceCreateLocation={makeNewUserLocation()}
          getRowKey={(user) => user.id}
          columns={COLUMNS}
          searchQuery={searchQuery}
          tableModel={request}
          onTableModelChange={setRequest}
          filterSection={
            <UserFilters values={filterValues} setValues={setRequest} />
          }
          activeFilterCount={getActiveFiltersCount(filterValues)}
        />
      </CardContent>
    </Card>
  );
}

function useUserSearch(request: z.infer<typeof requestSchema>) {
  return useUsers(omitNullFields(prepareListRequest(request)), {
    keepPreviousData: true,
    cacheTime: 0,
  });
}
