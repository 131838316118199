import { useQueryClient } from "@tanstack/react-query";
import { secondsToMilliseconds } from "date-fns";
import invariant from "invariant";
import { range } from "lodash";
import type { StrictOmit } from "ts-essentials";
import type {
  ListLogsRequest,
  Log,
  LogCreateRequest,
  LogFetchResponse,
  LogListResponse,
  LogUpdateRequest,
} from "../../services/datastore";
import type { Maybe, ResolvedKeyFactory } from "../../types";
import type {
  DataStoreMutationFunction,
  UseDataStoreQueryOptions,
} from "../datastores";
import {
  useDataStoreMutation,
  useDataStoreQuery,
  useDataStoreQueryKey,
} from "../datastores";
import { getInitialDetailsData, mergeEnabledOption } from "./utils";

export type PreviewImageResult =
  | { success: true; blob: Blob | null }
  | { success: false; blob: null };

export interface PreviewImageUpload {
  image: Blob;
  index: number;
}

export function useLogKeys() {
  const baseQuery = useDataStoreQueryKey(["logs"] as const);

  const factory = {
    all: baseQuery,
    lists: () => [...factory.all, "list"] as const,
    list: (request: ListLogsRequest) => [...factory.lists(), request] as const,
    details: () => [...factory.all, "details"] as const,
    detail: (logId: Maybe<Log["id"]>) => [...factory.details(), logId] as const,
    previewImages: (logId: Maybe<Log["id"]>) =>
      [...factory.all, "preview-images", logId] as const,
  } as const;

  return factory;
}

export type LogKeys = ResolvedKeyFactory<typeof useLogKeys>;

export function useLogs<TData = LogListResponse>(
  request: ListLogsRequest,
  options?: UseDataStoreQueryOptions<
    LogListResponse,
    unknown,
    TData,
    LogKeys["list"]
  >
) {
  return useDataStoreQuery({
    queryKey: useLogKeys().list(request),
    queryFn(context, { logApi }) {
      return logApi.listLogs(request, context);
    },
    ...options,
  });
}

export function useLog<TData = LogFetchResponse>(
  logId: Maybe<Log["id"]>,
  options?: StrictOmit<
    UseDataStoreQueryOptions<
      LogFetchResponse,
      unknown,
      TData,
      LogKeys["detail"]
    >,
    "initialData"
  >
) {
  const queryClient = useQueryClient();

  const logKeys = useLogKeys();

  return useDataStoreQuery({
    queryKey: logKeys.detail(logId),
    queryFn(context, { logApi }) {
      invariant(logId != null, "Log ID must be defined");

      return logApi.getLog({ logId }, context);
    },
    ...options,
    enabled: logId != null && (options?.enabled ?? true),
    initialData() {
      return getInitialDetailsData(
        queryClient,
        logKeys.lists(),
        (log: Log) => log.id === logId
      );
    },
  });
}

export function useCreateLog() {
  const logKeys = useLogKeys();

  const queryClient = useQueryClient();

  return useDataStoreMutation({
    mutationFn(request: LogCreateRequest, { logApi }) {
      return logApi.createLog({ logCreateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData<LogFetchResponse>(
        logKeys.detail(response.data.id),
        response
      );
    },
  });
}

export function useUpdateLog(logId: Log["id"]) {
  const queryClient = useQueryClient();
  const logKeys = useLogKeys();

  return useDataStoreMutation({
    mutationFn(request: LogUpdateRequest, { logApi }) {
      return logApi.updateLog({ logId, logUpdateRequest: request });
    },
    onSuccess(response) {
      queryClient.setQueryData<LogFetchResponse>(
        logKeys.detail(response.data.id),
        response
      );
    },
  });
}

export function useDeleteLog(logId: Log["id"]) {
  return useDataStoreMutation({
    mutationFn(_, { logApi }) {
      return logApi.deleteLog({ logId });
    },
  });
}

export function usePreviewImages<TData = PreviewImageResult[]>(
  logId: Maybe<Log["id"]>,
  numThumbnails: number,
  options?: StrictOmit<
    UseDataStoreQueryOptions<
      PreviewImageResult[],
      unknown,
      TData,
      LogKeys["previewImages"]
    >,
    "staleTime" | "cacheTime"
  >
) {
  return useDataStoreQuery({
    queryKey: useLogKeys().previewImages(logId),
    async queryFn(context, { logApi }) {
      invariant(logId != null, "Log ID must be defined");

      // Creating a pre-signed URL is essentially an idempotent request
      // so in this specific situation - getting pre-signed URLs to fetch
      // images - it's safe and advantageous to use a query over a mutation
      const presignedUrlResponses = await Promise.all(
        range(numThumbnails).map((index) =>
          logApi.createLogPresignedUrl({
            logId,
            createPresignedURLRequest: {
              method: "get_object",
              params: {
                key: `preview_${index}.webp`,
              },
            },
          })
        )
      );

      const fetchedImagePromises = await Promise.allSettled(
        presignedUrlResponses.map(({ url }) => fetchImageBlob(url))
      );

      const previews: PreviewImageResult[] = fetchedImagePromises.map(
        (promiseResult) => {
          if (promiseResult.status === "fulfilled") {
            return { success: true, blob: promiseResult.value };
          }

          return { success: false, blob: null };
        }
      );

      return previews;
    },
    ...options,
    staleTime: Infinity,
    cacheTime: secondsToMilliseconds(20),
    enabled: mergeEnabledOption(options, logId != null),
  });
}

export function useUploadPreviewImage(logId: Log["id"]) {
  const logKeys = useLogKeys();

  const queryClient = useQueryClient();

  const mutationFn: DataStoreMutationFunction<void, PreviewImageUpload> =
    async function mutationFn({ image, index }, { logApi }) {
      const presignedUploadUrl = await logApi.createLogPresignedUrl({
        logId,
        createPresignedURLRequest: {
          method: "put_object",
          params: {
            key: `preview_${index}.webp`,
          },
        },
      });

      const uploadResponse = await fetch(presignedUploadUrl.url, {
        method: "PUT",
        body: image,
      });

      if (!uploadResponse.ok) {
        throw uploadResponse;
      }
    };

  return useDataStoreMutation({
    mutationFn,
    onSuccess() {
      return queryClient.invalidateQueries(logKeys.previewImages(logId));
    },
  });
}

// Utilities

async function fetchImageBlob(url: string): Promise<Blob | null> {
  const response = await fetch(url);

  if (response.ok) {
    return response.blob();
  }

  if (response.status === 404) {
    // In this context, a 404 just means there was no preview image
    // at the given index, so it shouldn't be treated as a fetching error
    // even though there's no Blob to show
    return null;
  }

  throw response;
}
