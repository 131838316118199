import React from "react";
import { Link as MuiLink } from "@mui/material";
import type { Location } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { useMakeStudioLocation } from "../paths";

export interface LinkProps {
  to: Partial<Location>;
  children: React.ReactNode;
}

export default function Link({ to, children }: LinkProps) {
  const makeStudioLocation = useMakeStudioLocation();

  return (
    <MuiLink component={RouterLink} to={makeStudioLocation(to)}>
      {children}
    </MuiLink>
  );
}
