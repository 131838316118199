import { Checkbox, FormControlLabel } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import { getFriendlyResourceKey } from "../../utils";
import type { BaseFieldProps } from "./types";

export function CheckboxField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, boolean | null>
>({ control, name, label }: BaseFieldProps<TFieldValues, TName>) {
  const { field } = useController({ control, name });

  return (
    <FormControlLabel
      control={<Checkbox checked={field.value} {...field} />}
      label={label ?? getFriendlyResourceKey(name)}
    />
  );
}
