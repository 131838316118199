import React, { useRef, useState } from "react";
import { Person } from "@mui/icons-material";
import type { ButtonBaseActions, ButtonBaseProps } from "@mui/material";
import {
  Box,
  List,
  ListItem as MuiListItem,
  ListItemButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { matchSorter } from "match-sorter";
import { ErrorBoundary } from "react-error-boundary";
import { Link as RouterLink } from "react-router-dom";
import useResizeObserver from "use-resize-observer";
import Center from "../../../components/Center";
import Error from "../../../components/Error";
import Loading from "../../../components/Loading";
import NoDataStoreAlert from "../../../components/NoDataStoreAlert";
import { AuthGuard } from "../../../domain/auth";
import { useLogs } from "../../../domain/crud";
import { makePlayerLocation, useMakeStudioLocation } from "../../../paths";
import type { Log } from "../../../services/datastore";
import {
  calculateLogMarkers,
  renderIngestionStatusChip,
  renderRecorded,
} from "./utils";

const LazyMapView = React.lazy(() => import("./LazyMapView"));

export function Map() {
  const { ref, width, height } = useResizeObserver();

  const [searchValue, setSearchValue] = useState("");
  const searchQuery = useLogs(
    {
      limit: 500,
      sort: "desc",
      order: "start_time",
      startTimeNull: false,
      contextFilter: JSON.stringify([
        {
          var: "sample_coordinates.longitude",
          op: "gt",
          val: -180,
        },
      ]),
    },
    {
      select(response) {
        const filteredLogs =
          searchValue === ""
            ? response.data
            : matchSorter(response.data, searchValue, {
                keys: ["name"],
                threshold: matchSorter.rankings.CONTAINS,
              });

        return {
          logs: filteredLogs,
          logMarkers: calculateLogMarkers(response.data),
        };
      },
    }
  );

  const { makeRefCallback, focusLog } = useLogFocus();

  function handleSearchValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
  }

  if (!searchQuery.canRun) {
    return (
      <NoDataStoreAlert sx={{ mx: "auto", my: 4, alignSelf: "baseline" }} />
    );
  }

  if (searchQuery.isError) {
    return (
      <Error>
        <Typography variant="h5" component="p">
          Error fetching logs
        </Typography>
      </Error>
    );
  }

  if (searchQuery.isLoading) {
    return <Loading type="circular" />;
  }

  return (
    <Box sx={{ height: 1, width: 1, position: "relative" }} ref={ref}>
      <AuthGuard
        fallback={
          <Center>
            <Person fontSize="large" />
            <Typography variant="h5" component="p">
              Maps only available for signed-in users
            </Typography>
          </Center>
        }
      >
        <ErrorBoundary
          fallback={
            <Center>
              <Typography variant="h5" component="p">
                Error initializing map
              </Typography>
            </Center>
          }
        >
          <React.Suspense fallback={null}>
            <LazyMapView
              width={width}
              height={height}
              logMarkers={searchQuery.data.logMarkers}
              onLogClick={focusLog}
            />
          </React.Suspense>
        </ErrorBoundary>
        <Box
          sx={{
            position: "absolute",
            inset: (theme) => theme.spacing(1),
            pointerEvents: "none",
            p: 1,
          }}
        >
          <Paper
            sx={{
              width: "min(40ch, 100%)",
              pointerEvents: "auto",
              p: 2,
            }}
          >
            <TextField
              label="Search"
              fullWidth
              value={searchValue}
              onChange={handleSearchValueChange}
            />
            <List disablePadding sx={{ maxHeight: 400, overflowY: "auto" }}>
              {searchQuery.data.logs.map((log) => (
                <ListItem
                  key={log.id}
                  log={log}
                  actionRef={makeRefCallback(log.id)}
                />
              ))}
            </List>
          </Paper>
        </Box>
      </AuthGuard>
    </Box>
  );
}

interface ListItemProps {
  log: Log;
  actionRef: ButtonBaseProps["action"];
}

function ListItem({ log, actionRef }: ListItemProps) {
  const makeStudioLocation = useMakeStudioLocation();

  return (
    <MuiListItem disablePadding>
      <ListItemButton
        action={actionRef}
        component={RouterLink}
        to={makeStudioLocation(makePlayerLocation({ logId: log.id }))}
        disableTouchRipple
        sx={{ flexDirection: "column" }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          width={1}
          mb={2}
          sx={{ wordBreak: "break-all" }}
        >
          <Typography variant="body1" component="p" sx={{ fontWeight: "bold" }}>
            {log.name}
          </Typography>
          {renderIngestionStatusChip(log)}
        </Stack>
        <Typography
          variant="body2"
          sx={{ alignSelf: "baseline", color: "text.secondary" }}
        >
          {renderRecorded(log)}
        </Typography>
      </ListItemButton>
    </MuiListItem>
  );
}

function useLogFocus() {
  const mapRef = useRef(
    // TODO: Rename component to avoid shadowing the global Map constructor
    new window.Map<Log["id"], ButtonBaseActions>()
  );

  return {
    makeRefCallback(logId: Log["id"]): React.RefCallback<ButtonBaseActions> {
      return function refCallback(actions) {
        if (actions === null) {
          mapRef.current.delete(logId);
        } else {
          mapRef.current.set(logId, actions);
        }
      };
    },
    focusLog(logId: Log["id"]) {
      mapRef.current.get(logId)?.focusVisible();
    },
  };
}
