import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import Center from "../../../components/Center";
import Error from "../../../components/Error";
import Loading from "../../../components/Loading";
import { QueryRenderer } from "../../../components/QueryRenderer";
import { GroupInfo } from "../../../components/ResourceInfo";
import type { GroupAssociationListResponse } from "../../../services/datastore";

export interface GroupsViewProps {
  query: UseQueryResult<GroupAssociationListResponse>;
}

export default function GroupsView({ query }: GroupsViewProps) {
  return (
    <Card>
      <CardHeader title="Groups" titleTypographyProps={{ component: "h2" }} />
      <CardContent>
        <QueryRenderer
          query={query}
          loading={<Loading type="circular" />}
          error={
            <Error>
              <Typography variant="h5" component="p">
                Unable to load your groups
              </Typography>
            </Error>
          }
          success={({ data }) =>
            data.length === 0 ? (
              <Center>
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ fontStyle: "italic", my: 4 }}
                >
                  You're not a member of any groups
                </Typography>
              </Center>
            ) : (
              <List>
                {data.map((groupAssociation) => (
                  <ListItem key={groupAssociation.id}>
                    <GroupInfo
                      groupId={groupAssociation.groupId}
                      chip={<Chip label={groupAssociation.role} size="small" />}
                    />
                  </ListItem>
                ))}
              </List>
            )
          }
        />
      </CardContent>
    </Card>
  );
}
