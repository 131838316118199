import { useState } from "react";
import { Typography } from "@mui/material";
import { DeleteDialog } from "../../../components/DeleteDialog";
import {
  dateTimeFields,
  DetailsCard,
  half,
  third,
} from "../../../components/DetailsCard";
import type { ResourceField } from "../../../components/DetailsCard/types";
import { LogInfo } from "../../../components/ResourceInfo";
import { useDeleteExtraction, useExtraction } from "../../../domain/crud";
import {
  makeEditExtractionLocation,
  makeExtractionsLocation,
  useExtractionParams,
  useStudioNavigate,
} from "../../../paths";
import type { Extraction } from "../../../services/datastore";
import Download from "./Download";
import TopicsTable from "./TopicsTable";

const FIELDS: ReadonlyArray<ResourceField<Extraction>> = [
  {
    col: half,
    label: "Name",
    renderField(extraction) {
      return (
        <>
          {extraction.name}
          <Typography variant="body2">{extraction.id}</Typography>
        </>
      );
    },
  },
  {
    col: half,
    label: "Download",
    renderField(extraction) {
      return (
        <Download
          id={extraction.id}
          s3Key={extraction.s3Key}
          status={extraction.status}
        />
      );
    },
  },
  {
    col: half,
    label: "Log",
    renderField(extraction) {
      return <LogInfo logId={extraction.logId} />;
    },
  },
  { col: half, accessor: "note", dataType: "text" },
  { col: half, accessor: "s3Bucket", dataType: "text" },
  { col: half, accessor: "s3Key", dataType: "text" },
  { col: half, accessor: "status", dataType: "text" },
  { col: half, accessor: "size", dataType: "bytes" },
  { col: third, accessor: "progress", dataType: "percent" },
  { col: third, accessor: "error", dataType: "json" },
  { col: third, accessor: "format", dataType: "text" },
  ...dateTimeFields,
];

export default function Details() {
  const { extractionId } = useExtractionParams();

  const extractionQuery = useExtraction(extractionId, {
    select(response) {
      return response.data;
    },
  });

  const navigate = useStudioNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const deleteExtraction = useDeleteExtraction(extractionId);

  function closeModal() {
    setShowDeleteDialog(false);
  }

  function openModal() {
    setShowDeleteDialog(true);
  }

  function handleDelete() {
    navigate(makeExtractionsLocation());
  }

  return (
    <>
      <DetailsCard
        editLocation={makeEditExtractionLocation(extractionId)}
        onDelete={openModal}
        query={extractionQuery}
        fields={FIELDS}
      />
      <TopicsTable extractionId={extractionId} />
      <DeleteDialog
        open={showDeleteDialog}
        onClose={closeModal}
        resourceName="extraction"
        resourceLoading={extractionQuery.isLoading}
        mutation={deleteExtraction}
        onDelete={handleDelete}
      >
        {extractionQuery.data?.name ?? extractionId}
      </DeleteDialog>
    </>
  );
}
