import { startCase } from "lodash";

const specialKeys = new Map([
  ["md5", "md5"],
  ["s3Key", "S3 Key"],
  ["s3Bucket", "S3 Bucket"],
]);

export default function getFriendlyResourceKey(key: string) {
  return specialKeys.get(key) ?? startCase(key);
}
