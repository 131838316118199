import { TextField as TextInput } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import { getFriendlyResourceKey } from "../../utils";
import type { BaseFieldProps } from "./types";

export function TextField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string | null>
>({ control, label, name }: BaseFieldProps<TFieldValues, TName>) {
  const {
    field: { value, onChange, ...field },
    fieldState,
  } = useController({ control, name });

  return (
    <TextInput
      fullWidth
      label={label ?? getFriendlyResourceKey(name)}
      error={fieldState.error !== undefined}
      helperText={fieldState.error?.message ?? " "}
      {...field}
      value={value ?? ""}
      onChange={(e) => {
        if (e.target.value === "") {
          onChange(null);
        } else {
          onChange(e.target.value);
        }
      }}
    />
  );
}
