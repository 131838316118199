import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Grid } from "@mui/material";
import { pick } from "lodash";
import { useSnackbar } from "notistack";
import {
  CheckboxField,
  formatOptions,
  getChangedFields,
  NumberField,
  SelectField,
  TextField,
} from "../../../components/Form";
import { useStudioForm } from "../../../components/Form/hooks";
import { useUpdateIngestion } from "../../../domain/crud";
import {
  makeIngestionLocation,
  useIngestionParams,
  useStudioNavigate,
} from "../../../paths";
import type { Ingestion } from "../../../services/datastore";
import { EDITABLE_INGESTION_FIELDS, editIngestionSchema } from "./validation";

interface IngestionFormProps {
  ingestion: Ingestion;
}

export default function IngestionForm({ ingestion }: IngestionFormProps) {
  const { ingestionId } = useIngestionParams();

  const navigate = useStudioNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const updateIngestion = useUpdateIngestion(ingestionId);

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useStudioForm({
    schema: editIngestionSchema,
    defaultValues: pick(ingestion, EDITABLE_INGESTION_FIELDS),
    onSubmit(values) {
      const changedFields = getChangedFields(values, dirtyFields);

      // @ts-ignore OpenAPI issue with optional vs. nullable
      updateIngestion.mutate(changedFields, {
        onSuccess() {
          enqueueSnackbar("Ingestion updated", { variant: "success" });

          navigate(makeIngestionLocation(ingestionId));
        },
        onError() {
          enqueueSnackbar("Failed to update Ingestion", { variant: "error" });
        },
      });
    },
  });

  return (
    <Card>
      <CardContent>
        <Grid
          component="form"
          name="editIngestion"
          container
          onSubmit={handleSubmit}
          spacing={2}
        >
          <Grid item xs={3}>
            <TextField control={control} name="name" />
          </Grid>
          <Grid item xs={3}>
            <TextField control={control} name="s3Bucket" />
          </Grid>
          <Grid item xs={3}>
            <TextField control={control} name="s3Key" />
          </Grid>
          <Grid item xs={3}>
            <NumberField control={control} name="size" label="Size" />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              name="startOffset"
              label="Start Offset"
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              name="endOffset"
              label="End Offset"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField control={control} name="note" />
          </Grid>
          <Grid item xs={2}>
            <SelectField
              control={control}
              name="format"
              label="Format"
              options={formatOptions}
            />
          </Grid>
          <Grid item xs={2}>
            <CheckboxField control={control} name="queued" />
          </Grid>
          <Grid item xs={2}>
            <CheckboxField control={control} name="processing" />
          </Grid>
          <Grid item xs={2}>
            <CheckboxField control={control} name="errored" />
          </Grid>
          <Grid item xs={2}>
            <CheckboxField control={control} name="cancelled" />
          </Grid>
          <Grid item xs={2}>
            <CheckboxField control={control} name="archived" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={updateIngestion.isLoading}
              type="submit"
              variant="contained"
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
