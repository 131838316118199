import React from "react";
import { Skeleton, Stack, Typography } from "@mui/material";
import { useUser } from "../../domain/crud";
import { makeUserLocation } from "../../paths";
import type { User } from "../../services/datastore";
import Link from "../Link";

export interface UserInfoProps {
  userId: User["id"];
  chip?: React.ReactNode;
}

export default function UserInfo({ userId, chip }: UserInfoProps) {
  const usernameQuery = useUser(userId, {
    select(response) {
      return response.data.username;
    },
  });

  return (
    <Stack>
      {usernameQuery.isSuccess ? (
        <Stack direction="row" spacing={1} alignItems="center">
          <span>{usernameQuery.data}</span>
          {chip}
        </Stack>
      ) : (
        <Skeleton />
      )}
      <Link to={makeUserLocation(userId)}>
        <Typography variant="body2">{userId}</Typography>
      </Link>
    </Stack>
  );
}
