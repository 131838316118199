import type { ElementOf } from "ts-essentials";
import { z } from "zod";
import {
  name,
  optionalNumber,
  optionalText,
  uuid,
} from "../../../components/Form";
import {
  filterBoolean,
  filterDate,
  filterNumber,
  filterText,
  filterUuid,
} from "../../../components/Table";
import type { Log } from "../../../services/datastore";

export const listLogsSchema = z.object({
  name: filterText,
  nameLike: filterText,
  groupId: filterUuid,
  locked: filterBoolean,
  noteLike: filterText,
  startTimeGte: filterNumber,
  startTimeLte: filterNumber,
  startTimeNull: filterBoolean,
  endTimeGte: filterNumber,
  endTimeLte: filterNumber,
  endTimeNull: filterBoolean,
  createdBy: filterUuid,
  createdAtGte: filterDate,
  createdAtLte: filterDate,
  updatedBy: filterUuid,
  updatedByNull: filterBoolean,
  updatedAtGte: filterDate,
  updatedAtLte: filterDate,
  updatedAtNull: filterBoolean,
  deletedBy: filterUuid,
  deletedByNull: filterBoolean,
  deletedAtGte: filterDate,
  deletedAtLte: filterDate,
  deletedAtNull: filterBoolean,
});

export type ListLogsFormValues = z.infer<typeof listLogsSchema>;

export const createLogSchema = z.object({
  name,
  groupId: uuid,
  note: optionalText,
});

export type CreateLogFormValues = z.infer<typeof createLogSchema>;

export const EDITABLE_LOG_FIELDS = [
  "name",
  "groupId",
  "note",
  "startTime",
  "endTime",
] as const;

export type EditLogFormValues = Pick<
  Log,
  ElementOf<typeof EDITABLE_LOG_FIELDS>
>;

export const editLogSchema = z.object({
  name,
  groupId: uuid,
  note: optionalText,
  endTime: optionalNumber,
  startTime: optionalNumber,
});
