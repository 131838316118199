import type { User } from "../../services/datastore";
import type { AccessorResourceField } from "./types";

export const full = {
  xs: 12,
};

export const half = {
  xs: 6,
};

export const third = {
  xs: 4,
};

/*
 * There's nothing special about the 'User' type here
 * just didn't want to redefine the types by hand, if we generalize
 * the dateTime types in the future, we can just use that
 */
export type DateTimeFields = Pick<
  User,
  | "createdAt"
  | "createdBy"
  | "deletedAt"
  | "deletedBy"
  | "updatedAt"
  | "updatedBy"
>;

export const dateTimeFields: AccessorResourceField<DateTimeFields>[] = [
  { col: third, accessor: "createdAt", dataType: "datetime" },
  { col: third, accessor: "updatedAt", dataType: "datetime" },
  { col: third, accessor: "deletedAt", dataType: "datetime" },
  { col: third, accessor: "createdBy", dataType: "user" },
  { col: third, accessor: "updatedBy", dataType: "user" },
  { col: third, accessor: "deletedBy", dataType: "user" },
];
