/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface APIKey
 */
export interface APIKey {
  /**
   *
   * @type {string}
   * @memberof APIKey
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof APIKey
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof APIKey
   */
  updatedAt: Date | null;
  /**
   *
   * @type {Date}
   * @memberof APIKey
   */
  deletedAt: Date | null;
  /**
   *
   * @type {string}
   * @memberof APIKey
   */
  createdBy: string | null;
  /**
   *
   * @type {string}
   * @memberof APIKey
   */
  updatedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof APIKey
   */
  deletedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof APIKey
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof APIKey
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof APIKey
   */
  secret: string | null;
}

export function APIKeyFromJSON(json: any): APIKey {
  return APIKeyFromJSONTyped(json, false);
}

export function APIKeyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): APIKey {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    name: json["name"],
    userId: json["user_id"],
    secret: json["secret"],
  };
}

export function APIKeyToJSON(value?: APIKey | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    name: value.name,
    user_id: value.userId,
    secret: value.secret,
  };
}
