import React, { useEffect, useRef } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import type { ChipProps } from "@mui/material";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { fromUnixTime } from "date-fns";
import invariant from "invariant";
import { DatabaseAlert } from "mdi-material-ui";
import prettyMilliseconds from "pretty-ms";
import Center from "../../../../components/Center";
import { Dl, renderDlGroup } from "../../../../components/DescriptionList";
import DrawerHeader from "../../../../components/DrawerHeader";
import Error from "../../../../components/Error";
import NoDataStoreAlert from "../../../../components/NoDataStoreAlert";
import Time from "../../../../components/Time";
import { useLogs } from "../../../../domain/crud";
import { useIsConnected } from "../../../../domain/datastores";
import { useDebouncedValue, useSessionStorage } from "../../../../hooks";
import type { Log } from "../../../../services/datastore";
import type { DerivedIngestionStatus } from "../../../../utils/logs";
import {
  calculateLogBoundsInMs,
  deriveIngestionStatus,
} from "../../../../utils/logs";
import { usePlayerConfig, usePlayerLog } from "../../hooks";

type RefetchReason = "input" | "previous" | "next";

const limit = 10;

const SEARCH_QUERY_STORAGE_KEY = "log-drawer-search-query";
const SEARCH_OFFSET_STORAGE_KEY = "log-drawer-search-offset";

export default function LogDrawer() {
  const isConnected = useIsConnected();

  const { logId, setLogId } = usePlayerConfig();
  const currentLogQuery = usePlayerLog();

  const refetchReasonRef = useRef<RefetchReason>();
  const searchHeaderRef = useRef<HTMLParagraphElement | null>(null);

  const [search, setSearch] = useSessionStorage(SEARCH_QUERY_STORAGE_KEY, "");
  const [offset, setOffset] = useSessionStorage(SEARCH_OFFSET_STORAGE_KEY, 0);
  const debouncedSearch = useDebouncedValue(search, 200);
  const searchQuery = useLogs(
    {
      nameLike: debouncedSearch,
      offset,
      limit,
      sort: "desc",
      order: "start_time",
      startTimeNull: false,
      endTimeNull: false,
    },
    {
      keepPreviousData: true,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  useEffect(
    function scrollToTopOfSearchOnPageChange() {
      if (
        searchQuery.data !== undefined &&
        (refetchReasonRef.current === "previous" ||
          refetchReasonRef.current === "next")
      ) {
        searchHeaderRef.current?.scrollIntoView(true);
      }
    },
    [searchQuery.data]
  );

  const isPaginationDisabled =
    !searchQuery.isSuccess || searchQuery.data.count === 0;

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    refetchReasonRef.current = "input";

    setSearch(e.target.value);
  }

  function makePaginationHandler(direction: "previous" | "next") {
    return function handlePaginationChange() {
      refetchReasonRef.current = direction;

      const change = direction === "previous" ? -limit : limit;
      setOffset(offset + change);
    };
  }

  function causedRefetch(reason: RefetchReason) {
    return searchQuery.isFetching && refetchReasonRef.current === reason;
  }

  return (
    <>
      <DrawerHeader title="Logs" />
      <NoDataStoreAlert sx={{ mb: 2 }} />
      <Stack spacing={2}>
        <div>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" component="p">
              Current Log
            </Typography>
          </Stack>
          {logId == null ? (
            <Typography paragraph>
              No log selected. Search for one below
            </Typography>
          ) : currentLogQuery.isSuccess ? (
            <Dl
              spacing={3}
              sx={{
                mt: 0,
                "& .MuiGrid-root:first-child": {
                  pt: 0,
                },
              }}
            >
              {renderDlGroup("Name", currentLogQuery.data.name, { xs: 12 })}
              {renderDlGroup(
                "Start Time",
                currentLogQuery.data.startTime === null ? (
                  "--"
                ) : (
                  <Time date={fromUnixTime(currentLogQuery.data.startTime)} />
                ),
                { xs: 12 }
              )}
              {renderDlGroup(
                "Duration",
                currentLogQuery.data.startTimeMs === null ||
                  currentLogQuery.data.endTimeMs === null
                  ? "--"
                  : renderLogDuration(currentLogQuery.data),
                { xs: 12 }
              )}
              {renderDlGroup(
                "Ingestion Status",
                renderLogIngestionStatusChip(currentLogQuery.data)
              )}
            </Dl>
          ) : currentLogQuery.isError ? (
            <Typography paragraph>
              An error occurred trying to load the log.
            </Typography>
          ) : (
            <Typography paragraph>Loading the log...</Typography>
          )}
        </div>
        <Divider />
        <div>
          <Typography
            ref={searchHeaderRef}
            variant="h6"
            component="p"
            gutterBottom
          >
            Search Logs
          </Typography>
          <Typography paragraph>
            Select a log from the search results below to play its records and
            create extractions of its topics
          </Typography>
          <TextField
            label="Search for logs"
            fullWidth
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: causedRefetch("input") ? (
                <CircularProgress size="1.5rem" />
              ) : undefined,
            }}
          />
          {!isConnected ? (
            <div>
              <Center sx={{ my: 5 }}>
                <DatabaseAlert fontSize="large" />
                <Typography>Connect to a DataStore to see its logs</Typography>
              </Center>
            </div>
          ) : searchQuery.isError ? (
            <Box my={3}>
              <Error>
                <Typography>An error occurred searching for logs</Typography>
              </Error>
            </Box>
          ) : (
            <>
              <List>
                {searchQuery.isSuccess && searchQuery.data.count === 0 ? (
                  <ListItem>No logs matched the search</ListItem>
                ) : searchQuery.isSuccess ? (
                  searchQuery.data.data.map((log) => {
                    const hasDefinedBounds =
                      log.startTime !== null && log.endTime !== null;

                    return (
                      <ListItem key={log.id} disablePadding>
                        <ListItemButton
                          selected={log.id === logId}
                          role={undefined}
                          onClick={() => setLogId(log.id)}
                        >
                          <ListItemText
                            disableTypography
                            secondary={
                              hasDefinedBounds ? (
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  {...(hasDefinedBounds
                                    ? { fontWeight: "bold" }
                                    : { fontStyle: "italic" })}
                                >
                                  {renderLogDuration(log)} &bull;{" "}
                                  <Time date={fromUnixTime(log.startTime!)} />
                                </Typography>
                              ) : (
                                "--"
                              )
                            }
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              width={1}
                              sx={{ wordBreak: "break-all" }}
                            >
                              <Typography fontWeight="bold">
                                {log.name}
                              </Typography>
                              {renderLogIngestionStatusChip(log, "small")}
                            </Stack>
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    );
                  })
                ) : (
                  <ListItem>Fetching logs...</ListItem>
                )}
              </List>
              <Stack direction="row" justifyContent="space-between">
                <LoadingButton
                  loading={causedRefetch("previous")}
                  startIcon={<ChevronLeft />}
                  disabled={isPaginationDisabled || offset === 0}
                  onClick={makePaginationHandler("previous")}
                >
                  Previous
                </LoadingButton>
                <LoadingButton
                  loading={causedRefetch("next")}
                  endIcon={<ChevronRight />}
                  disabled={
                    isPaginationDisabled ||
                    offset + limit >= searchQuery.data.count
                  }
                  onClick={makePaginationHandler("next")}
                >
                  Next
                </LoadingButton>
              </Stack>
            </>
          )}
        </div>
      </Stack>
    </>
  );
}

function renderLogDuration(log: Log) {
  const { startTimeMs, endTimeMs } = calculateLogBoundsInMs(log);

  invariant(
    startTimeMs !== null && endTimeMs !== null,
    "Log must have defined bounds to render duration"
  );

  return prettyMilliseconds(endTimeMs - startTimeMs);
}

function renderLogIngestionStatusChip(log: Log, size?: ChipProps["size"]) {
  let chipPropsMap = new Map<DerivedIngestionStatus, ChipProps>([
    ["empty", { label: "No Ingestions", color: "default" }],
    ["error", { label: "Errored", color: "error" }],
    ["processing", { label: "Processing", color: "info" }],
    ["complete", { label: "Complete", color: "success" }],
    ["unknown", { label: "Unknown", color: "warning" }],
  ]);

  const derivedStatus = deriveIngestionStatus(log);

  return <Chip {...chipPropsMap.get(derivedStatus)!} size={size} />;
}
