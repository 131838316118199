import { useQueryClient } from "@tanstack/react-query";
import type { StrictOmit } from "ts-essentials";
import type {
  GroupAssociation,
  GroupAssociationCreateRequest,
  GroupAssociationFetchResponse,
  GroupAssociationListResponse,
  GroupAssociationUpdateRequest,
  ListGroupAssociationsRequest,
} from "../../services/datastore";
import type { ResolvedKeyFactory } from "../../types";
import type { UseDataStoreQueryOptions } from "../datastores";
import {
  useDataStoreMutation,
  useDataStoreQuery,
  useDataStoreQueryKey,
} from "../datastores";
import { getInitialDetailsData } from "./utils";

export function useGroupAssociationKeys() {
  const baseKey = useDataStoreQueryKey(["group-associations"] as const);

  const factory = {
    all: baseKey,
    lists: () => [...factory.all, "list"] as const,
    list: (request: ListGroupAssociationsRequest) =>
      [...factory.lists(), request] as const,
    details: () => [...factory.all, "details"] as const,
    detail: (groupAssociationId: GroupAssociation["id"]) =>
      [...factory.details(), groupAssociationId] as const,
  } as const;

  return factory;
}

export type GroupAssociationKeys = ResolvedKeyFactory<
  typeof useGroupAssociationKeys
>;

export function useGroupAssociations<TData = GroupAssociationListResponse>(
  request: ListGroupAssociationsRequest,
  options?: UseDataStoreQueryOptions<
    GroupAssociationListResponse,
    unknown,
    TData,
    GroupAssociationKeys["list"]
  >
) {
  return useDataStoreQuery({
    queryKey: useGroupAssociationKeys().list(request),
    queryFn(context, { groupAssociationApi }) {
      return groupAssociationApi.listGroupAssociations(request, context);
    },
    ...options,
  });
}

export function useGroupAssociation<TData = GroupAssociationFetchResponse>(
  groupAssociationId: GroupAssociation["id"],
  options?: StrictOmit<
    UseDataStoreQueryOptions<
      GroupAssociationFetchResponse,
      unknown,
      TData,
      GroupAssociationKeys["detail"]
    >,
    "initialData"
  >
) {
  const queryClient = useQueryClient();

  const groupAssociationKeys = useGroupAssociationKeys();

  return useDataStoreQuery({
    queryKey: groupAssociationKeys.detail(groupAssociationId),
    queryFn(context, { groupAssociationApi }) {
      return groupAssociationApi.getGroupAssociation(
        { groupAssociationId },
        context
      );
    },
    ...options,
    initialData() {
      return getInitialDetailsData(
        queryClient,
        groupAssociationKeys.lists(),
        (groupAssociation: GroupAssociation) =>
          groupAssociation.id === groupAssociationId
      );
    },
  });
}

export function useCreateGroupAssociation() {
  const groupAssociationKeys = useGroupAssociationKeys();

  const queryClient = useQueryClient();

  return useDataStoreMutation({
    mutationFn(
      request: GroupAssociationCreateRequest,
      { groupAssociationApi }
    ) {
      return groupAssociationApi.createGroupAssociation({
        groupAssociationCreateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.setQueryData<GroupAssociationFetchResponse>(
        groupAssociationKeys.detail(response.data.id),
        response
      );

      return Promise.allSettled([
        queryClient.invalidateQueries(groupAssociationKeys.lists()),
      ]);
    },
  });
}

export function useUpdateGroupAssociation(
  groupAssociationId: GroupAssociation["id"]
) {
  return useDataStoreMutation({
    mutationFn(
      request: GroupAssociationUpdateRequest,
      { groupAssociationApi }
    ) {
      return groupAssociationApi.updateGroupAssociation({
        groupAssociationId,
        groupAssociationUpdateRequest: request,
      });
    },
  });
}

export function useDeleteGroupAssociation(
  groupAssociationId: GroupAssociation["id"]
) {
  const groupAssociationKeys = useGroupAssociationKeys();

  const queryClient = useQueryClient();

  return useDataStoreMutation({
    mutationFn(_, { groupAssociationApi }) {
      return groupAssociationApi.deleteGroupAssociation({ groupAssociationId });
    },
    onSuccess() {
      return Promise.allSettled([
        queryClient.invalidateQueries(groupAssociationKeys.lists()),
      ]);
    },
  });
}
