import { secondsToMilliseconds } from "date-fns";
import { floor } from "lodash";
import type { Topic } from "../../../services/datastore";
import { useRecordsQueries } from "../queries";
import type { PlayerRecord } from "../types";

export default function useFirstTopicRecord(topicId: Topic["id"]) {
  const [query] = useRecordsQueries([
    {
      request: {
        topicId,
        limit: 1,
        sort: "asc",
        order: "timestamp",
        // API has an index on the `timestamp*` filters so the query
        // is sped up just by including this otherwise-pointless filter value
        timestampGte: 0,
      },
      options: {
        select(response): PlayerRecord | undefined {
          const [record] = response.data;

          if (record !== undefined) {
            return {
              ...record,
              timestampMs: secondsToMilliseconds(floor(record.timestamp, 1)),
            };
          }
        },
      },
    },
  ]);

  return query;
}
