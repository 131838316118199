import React from "react";
import { Delete, Edit, Refresh } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import type { Location } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { useMakeStudioLocation } from "../../paths";
import { Dl } from "../DescriptionList";
import Loading from "../Loading";
import { QueryRenderer } from "../QueryRenderer";
import { renderField } from "./fields";
import type { ResourceField } from "./types";

export interface DetailsCardProps<TResource> {
  startActions?: React.ReactNode;
  editLocation?: Partial<Location>;
  onDelete?: VoidFunction;
  query: UseQueryResult<TResource>;
  fields: ReadonlyArray<ResourceField<TResource>>;
}

export default function DetailsCard<TResource>({
  startActions,
  editLocation,
  onDelete,
  fields,
  query,
}: DetailsCardProps<TResource>) {
  const makeStudioLocation = useMakeStudioLocation();

  function handleRefresh() {
    query.refetch();
  }

  return (
    <Card>
      <CardHeader
        disableTypography
        title={
          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            {startActions}
            <Tooltip title="Refresh">
              <span>
                <IconButton onClick={handleRefresh} disabled={query.isLoading}>
                  <Refresh />
                </IconButton>
              </span>
            </Tooltip>
            {editLocation !== undefined && (
              <Tooltip title="Edit">
                <IconButton
                  component={RouterLink}
                  to={makeStudioLocation(editLocation)}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
            {onDelete !== undefined && (
              // Increase specificity for left margin
              <Box sx={{ "&&": { ml: "auto" } }}>
                <Tooltip title="Delete">
                  <span>
                    <IconButton
                      onClick={onDelete}
                      color="error"
                      disabled={!query.isSuccess}
                    >
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
          </Stack>
        }
      />
      <CardContent sx={{ position: "relative" }}>
        {query.isRefetching && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              transform: "translateY(-50%)",
              left: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2),
            }}
          />
        )}
        <QueryRenderer
          query={query}
          loading={<Loading type="circular" />}
          // TODO: Handle errors
          error={null}
          success={(resource) => (
            <Dl spacing={4} sx={{ "& dd": { wordBreak: "break-all" } }}>
              {fields.map((field) => renderField(resource, field))}
            </Dl>
          )}
        />
      </CardContent>
    </Card>
  );
}
