import type { RefCallback } from "react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NoPhotography } from "@mui/icons-material";
import {
  Box,
  Card as MuiCard,
  CardActionArea,
  CardContent,
  CardMedia,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { secondsToMilliseconds } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import { Dl, renderDlGroup } from "../../../components/DescriptionList";
import { usePreviewImages } from "../../../domain/crud";
import { useBlobSource } from "../../../hooks";
import { makePlayerLocation, useMakeStudioLocation } from "../../../paths";
import type { Log } from "../../../services/datastore";
import {
  renderDuration,
  renderIngestionStatusChip,
  renderRecorded,
} from "./utils";

export interface CardProps {
  log: Log;
}

export default function Card({ log }: CardProps) {
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);

  const makeStudioLocation = useMakeStudioLocation();

  const [shouldLoad, ref] = useShouldLoadImages();

  return (
    <MuiCard
      ref={ref}
      variant="outlined"
      sx={{
        width: 1,
        bgcolor: "inherit",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        // Required in Safari for an element with rounded corners to
        // hide its childrens' overflow
        isolation: "isolate",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <CardActionArea
        sx={{ flexGrow: 1 }}
        component={RouterLink}
        to={makeStudioLocation(makePlayerLocation({ logId: log.id }))}
        disableTouchRipple
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        <Thumbnail
          log={log}
          shouldLoad={shouldLoad}
          cycle={hovered || focused}
        />
        <CardContent>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            width={1}
            mb={2}
            sx={{ wordBreak: "break-all" }}
          >
            <Typography variant="h6" component="p" sx={{ fontWeight: "bold" }}>
              {log.name}
            </Typography>
            {renderIngestionStatusChip(log)}
          </Stack>
          <Dl spacing={4}>
            {renderDlGroup("Recorded", renderRecorded(log), {
              xs: 12,
              md: "auto",
            })}
            {renderDlGroup("Duration", renderDuration(log), {
              xs: 12,
              md: "auto",
            })}
          </Dl>
        </CardContent>
      </CardActionArea>
    </MuiCard>
  );
}

interface ThumbnailProps {
  log: Log;
  shouldLoad: boolean;
  cycle: boolean;
}

const NUM_THUMBNAILS = 3;

function Thumbnail({ log, shouldLoad, cycle }: ThumbnailProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const isLogPlayable = log.startTime !== null && log.endTime !== null;

  const previewImagesQuery = usePreviewImages(log.id, NUM_THUMBNAILS, {
    enabled: shouldLoad && isLogPlayable,
    select: (thumbnailResults) =>
      thumbnailResults.flatMap((result) =>
        result.blob !== null ? [result.blob] : []
      ),
  });

  const imgRef = useBlobSource(previewImagesQuery.data?.[currentImageIndex]);

  const numThumbnails = previewImagesQuery.data?.length;
  useEffect(
    function cycleThumbnails() {
      if (!cycle) {
        setCurrentImageIndex(0);
        return;
      }

      if (numThumbnails === undefined || numThumbnails === 0) {
        setCurrentImageIndex(0);
        return;
      }

      const intervalId = setInterval(
        () =>
          setCurrentImageIndex((currIndex) => (currIndex + 1) % numThumbnails),
        secondsToMilliseconds(1)
      );

      return () => {
        clearInterval(intervalId);
      };
    },
    [cycle, numThumbnails]
  );

  if (previewImagesQuery.isError || !isLogPlayable || numThumbnails === 0) {
    return (
      <Box
        height={200}
        width={1}
        bgcolor="grey.800"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <NoPhotography fontSize="large" />
      </Box>
    );
  }

  if (previewImagesQuery.isSuccess) {
    return (
      <>
        <CardMedia ref={imgRef} component="img" height={200} />
      </>
    );
  }

  return <Skeleton variant="rectangular" sx={{ height: 200, width: 1 }} />;
}

function useShouldLoadImages() {
  const [shouldLoad, setShouldLoad] = useState(false);

  const observedElementRef = useRef<HTMLElement | null>(null);

  const [observer] = useState(
    () =>
      new IntersectionObserver(
        (entries, observer) => {
          const [entry] = entries;

          if (entry.isIntersecting) {
            // Once lazy loading begins, the target can be unobserved as
            // we no longer care about its intersection status
            observer.unobserve(entry.target);
            observedElementRef.current = null;

            setShouldLoad(true);
          }
        },
        {
          root: document.querySelector("[data-scroll-root]"),
          rootMargin: "0px 0px 400px 0px",
        }
      )
  );

  const ref: RefCallback<HTMLElement | null> = useCallback(
    (element) => {
      if (observedElementRef.current !== null) {
        // Stop observing previous element to avoid memory leaks
        observer.unobserve(observedElementRef.current);
      }

      observedElementRef.current = element;

      if (element !== null) {
        observer.observe(element);
      }
    },
    [observer]
  );

  useEffect(
    function disconnectObserver() {
      return () => {
        // Extra safety measure to be really sure no memory leaks occur
        observer.disconnect();
      };
    },
    [observer]
  );

  return [shouldLoad, ref] as const;
}
