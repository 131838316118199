import { secondsToMilliseconds } from "date-fns";
import type { Topic } from "../../../services/datastore";
import type { Maybe } from "../../../types";
import type { TimeRange } from "../types";
import useRecordChunks from "./useRecordChunks";

interface UseOverviewOptions {
  topicId?: Maybe<Topic["id"]>;
  enabled?: boolean;
  playerBounds?: TimeRange;
}

export default function useTopicOverview({
  topicId,
  enabled = topicId != null,
  playerBounds,
}: UseOverviewOptions) {
  return useRecordChunks({
    topicId,
    enabled,
    chunkSizeMs: secondsToMilliseconds(100),
    playerBounds,
  });
}
