import prettyBytes from "pretty-bytes";

export function formatDate(date: Date, timezone: "UTC" | "local") {
  if (timezone === "UTC") {
    return date.toUTCString();
  }
  return date.toLocaleString();
}

export function formatTimestamp(timestamp: number) {
  const date = new Date(timestamp * 1000.0);
  return date.toUTCString();
}

export function formatBytes(bytes: number) {
  return prettyBytes(bytes);
}

const numberFormatter = new Intl.NumberFormat();

export function formatNumber(value: number) {
  return numberFormatter.format(value);
}
