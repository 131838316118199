import React from "react";
import { Grid, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { DatabaseAlert } from "mdi-material-ui";
import Center from "../../../components/Center";
import { useIsConnected } from "../../../domain/datastores";
import type { LogListResponse } from "../../../services/datastore";
import Card from "./Card";

export interface SearchResultsProps {
  searchQuery: UseQueryResult<LogListResponse>;
}

export default function SearchResults({ searchQuery }: SearchResultsProps) {
  const isConnected = useIsConnected();

  if (!isConnected) {
    return (
      <Center sx={{ py: 5 }}>
        <DatabaseAlert fontSize="large" />
        <Typography variant="h4" component="p">
          Connect to a DataStore to see its logs
        </Typography>
      </Center>
    );
  }

  if (searchQuery.isSuccess && searchQuery.data.count === 0) {
    return (
      <Typography align="center" variant="h4" component="p" my={4}>
        No logs matched the filters
      </Typography>
    );
  }

  if (searchQuery.isSuccess) {
    return (
      <Grid container spacing={3} mt={0} mb={3}>
        {searchQuery.data.data.map((log) => (
          <Grid
            key={log.id}
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="stretch"
          >
            <Card log={log} />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (searchQuery.isError) {
    return (
      <Typography align="center" variant="h4" component="p" my={4}>
        An error occurred searching for logs
      </Typography>
    );
  }

  return null;
}
