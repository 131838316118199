import type { QueryClient, UseQueryOptions } from "@tanstack/react-query";
import { map } from "lodash";
import type { DataStoreQueryKey } from "../datastores";

export interface ListResponse<TData> {
  offset: number;
  limit: number;
  order: string;
  sort: string;
  count: number;
  data: TData[];
}

export function mergeEnabledOption(
  options: UseQueryOptions<any, any, any, any> | undefined,
  enabledOverride: boolean
): boolean {
  return enabledOverride && (options?.enabled ?? true);
}

export async function circumventPagination<
  TRequest extends { limit?: number | undefined; offset?: number | undefined },
  TData
>(
  listItems: (
    request: TRequest,
    init: RequestInit
  ) => Promise<ListResponse<TData>>,
  requestLimit: number,
  request: TRequest,
  init: RequestInit,
  maxFetches = Infinity
): Promise<ListResponse<TData>> {
  const firstPageRequest = { ...request, limit: requestLimit, offset: 0 };
  const firstPageResponse = await listItems(firstPageRequest, init);

  if (firstPageResponse.count <= firstPageResponse.limit) {
    // No additional pages to fetch
    return {
      ...firstPageResponse,
      limit: -1,
    };
  }

  // More than `requestLimit` items exist for this request so the remaining
  // pages need to be fetched. Now that the exact count is known they
  // can be fetched in parallel. However, no more than `maxFetches` requests
  // should be made in total.
  const remainingPagesCount = Math.min(
    Math.ceil(
      (firstPageResponse.count - firstPageRequest.limit) /
        firstPageRequest.limit
    ),
    // One fetch has already been completed
    maxFetches - 1
  );
  const remainingPageRequests: Array<typeof firstPageRequest> = [];
  for (let page = 1; page <= remainingPagesCount; page++) {
    remainingPageRequests.push({
      ...firstPageRequest,
      offset: firstPageRequest.limit * page,
    });
  }

  const remainingPageResponses = await Promise.all(
    remainingPageRequests.map((pageRequest) => listItems(pageRequest, init))
  );

  const data = firstPageResponse.data.concat(
    ...map(remainingPageResponses, "data")
  );

  return {
    ...firstPageResponse,
    limit: -1,
    count: data.length,
    data,
  };
}

// Not fully type-safe. Lies to the query client about what the list queries'
// shapes are.
export function getInitialDetailsData<TResource>(
  queryClient: QueryClient,
  listQueryKey: DataStoreQueryKey,
  predicate: (resource: TResource) => boolean
): { data: TResource } | undefined {
  const listQueries = queryClient.getQueriesData<{ data: TResource[] }>({
    queryKey: listQueryKey,
    predicate: (query) => query.state.status === "success",
  });

  for (const [, query] of listQueries) {
    if (query === undefined) {
      continue;
    }

    for (const resource of query.data) {
      if (predicate(resource)) {
        return { data: resource };
      }
    }
  }
}
