import type { FieldNamesMarkedBoolean } from "react-hook-form";
import type { z } from "zod";

// Doesn't work for nested forms
export function getChangedFields<
  TFieldValues extends Record<
    string,
    number | string | Date | boolean | null | undefined
  >
>(
  values: TFieldValues,
  dirtyFields: FieldNamesMarkedBoolean<TFieldValues>
): Partial<TFieldValues> {
  const changedFields: Partial<TFieldValues> = {};

  for (const field in values) {
    if (dirtyFields[field]) {
      changedFields[field] = values[field];
    }
  }

  return changedFields;
}

type ErrorMapHandlers = Partial<{
  [Issue in z.ZodIssueOptionalMessage as Issue["code"]]:
    | string
    | ((issue: Issue) => string | undefined);
}>;

export function createErrorMap(handlers: ErrorMapHandlers): z.ZodErrorMap {
  return function errorMap(issue, ctx) {
    const handler = handlers[issue.code];

    let message: string | undefined = undefined;
    if (typeof handler === "string") {
      message = handler;
    } else if (typeof handler === "function") {
      message = handler(issue as any);
    }

    return { message: message ?? ctx.defaultError };
  };
}
