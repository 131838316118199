import { useEffect, useRef } from "react";
import type { UseQueryResult } from "@tanstack/react-query";
import { millisecondsToSeconds, secondsToMilliseconds } from "date-fns";
import { floor, map, some } from "lodash";
import type { Topic } from "../../../services/datastore";
import type { Maybe } from "../../../types";
import { useRecordListCache } from "../RecordListCacheProvider";
import type {
  LocalRecordListResponse,
  UseRecordsQueriesOptions,
} from "../queries";
import { useRecordsQueries } from "../queries";
import type { PlayerRecord, SampleFrequencyValue, TimeRange } from "../types";
import { SampleFrequency } from "../types";
import { calculateWindowChunks } from "./utils";

export interface UseChunksOptions {
  topicId?: Maybe<Topic["id"]>;
  enabled?: boolean;
  playerBounds?: TimeRange;
  recordWindow?: TimeRange;
  sampleFrequency?: SampleFrequencyValue;
  includeImage?: boolean;
  bufferBehindMs?: number;
  bufferAheadMs?: number;
  chunkSizeMs: number;
}

export interface UseRecordChunksIdleResult {
  status: "idle";
  isPlaceholderData: false;
  data: undefined;
  recordWindow: undefined;
}

export interface UseRecordChunksLoadingResult {
  status: "loading";
  isPlaceholderData: false;
  data: undefined;
  recordWindow: TimeRange;
}

export interface UseRecordChunksLoadingPlaceholderResult {
  status: "loading";
  isPlaceholderData: true;
  data: PlayerRecord[];
  recordWindow: TimeRange;
}

export interface UseRecordChunksErrorResult {
  status: "error";
  isPlaceholderData: false;
  data: undefined;
  recordWindow: TimeRange;
}

export interface UseRecordChunksErrorPlaceholderResult {
  status: "error";
  isPlaceholderData: true;
  data: PlayerRecord[];
  recordWindow: TimeRange;
}

export interface UseRecordChunksSuccessResult {
  status: "success";
  isPlaceholderData: false;
  data: PlayerRecord[];
  recordWindow: TimeRange;
}

export type UseRecordChunksResult =
  | UseRecordChunksIdleResult
  | UseRecordChunksLoadingResult
  | UseRecordChunksLoadingPlaceholderResult
  | UseRecordChunksErrorResult
  | UseRecordChunksErrorPlaceholderResult
  | UseRecordChunksSuccessResult;

// Exported for tests
export type ReducedResult =
  | Pick<UseQueryResult<PlayerRecord[]>, "status" | "data">
  | { status: "idle"; data: undefined };

interface LastSuccessful {
  recordWindow: NonNullable<UseChunksOptions["recordWindow"]>;
  queriesOptions: UseRecordsQueriesOptions<PlayerRecord[]>[];
}

export default function useRecordChunks({
  topicId,
  enabled = true,
  playerBounds,
  recordWindow = playerBounds,
  sampleFrequency = SampleFrequency.Second,
  includeImage = false,
  bufferBehindMs = 0,
  bufferAheadMs = 0,
  chunkSizeMs,
}: UseChunksOptions) {
  const cache = useRecordListCache();

  const windowChunks = calculateWindowChunks({
    chunkSizeMs,
    bufferAheadMs,
    bufferBehindMs,
    window: recordWindow,
    playerBounds,
  });

  function makeOptionsForChunk(
    chunk: TimeRange
  ): UseRecordsQueriesOptions<PlayerRecord[]> {
    return {
      request: {
        topicId,
        sort: "asc",
        order: "timestamp",
        limit: millisecondsToSeconds(chunkSizeMs) * sampleFrequency,
        timestampGte: millisecondsToSeconds(chunk.startTimeMs),
        timestampLt: millisecondsToSeconds(chunk.endTimeMs),
        frequency: sampleFrequency,
        includeImage,
      },
      options: {
        enabled,
        select: selectPlayerRecords,
        // Don't use react-query's cache if the responses are in the
        // custom cache
        ...(!includeImage && { cacheTime: 0 }),
      },

      ...(!includeImage && { cache }),
    };
  }

  const requiredQueriesOptions = windowChunks.required.map(makeOptionsForChunk);
  const requiredQueries = useRecordsQueries(requiredQueriesOptions);
  const currentResult = reduceChunkQueries(requiredQueries);

  // Results aren't used for buffered chunks. Just creating query observers so
  // react-query will fire off the requests if needed and won't evict the
  // responses from its cache.
  useRecordsQueries(windowChunks.bufferAhead.map(makeOptionsForChunk));
  useRecordsQueries(windowChunks.bufferBehind.map(makeOptionsForChunk));

  const lastSuccessfulRef = useRef<LastSuccessful>();
  useEffect(function saveLastSuccessfulData() {
    if (recordWindow === undefined) {
      return;
    }

    if (requiredQueriesOptions.length === 0) {
      return;
    }

    if (currentResult.status !== "success") {
      return;
    }

    lastSuccessfulRef.current = {
      recordWindow,
      queriesOptions: requiredQueriesOptions,
    };
  });

  const placeholderOptions =
    currentResult.status === "success"
      ? []
      : lastSuccessfulRef.current?.queriesOptions ?? [];
  const placeholderQueries = useRecordsQueries(placeholderOptions);
  const placeholderResult = reduceChunkQueries(placeholderQueries);

  return consolidateResults(
    currentResult,
    recordWindow,
    placeholderResult,
    lastSuccessfulRef.current?.recordWindow
  );
}

function selectPlayerRecords(
  response: LocalRecordListResponse
): PlayerRecord[] {
  return response.data.map((record) => ({
    ...record,
    timestampMs: secondsToMilliseconds(floor(record.timestamp, 1)),
  }));
}

// Exported for testing
export function reduceChunkQueries(
  requiredQueries: Array<UseQueryResult<PlayerRecord[]>>
): ReducedResult {
  if (requiredQueries.length === 0) {
    return {
      status: "idle",
      data: undefined,
    };
  }

  if (some(requiredQueries, { status: "error" })) {
    return {
      status: "error",
      data: undefined,
    };
  }

  if (some(requiredQueries, { status: "loading" })) {
    return {
      status: "loading",
      data: undefined,
    };
  }

  return {
    status: "success",
    data: ([] as PlayerRecord[]).concat(
      ...(map(requiredQueries, "data") as Array<PlayerRecord[]>)
    ),
  };
}

// Exported for testing
export function consolidateResults(
  currentResult: ReducedResult,
  currentRecordWindow: UseChunksOptions["recordWindow"],
  placeholderResult: ReducedResult | undefined,
  placeholderRecordWindow: UseChunksOptions["recordWindow"]
): UseRecordChunksResult {
  const isPlaceholderData =
    (currentResult.status === "loading" || currentResult.status === "error") &&
    placeholderResult !== undefined;

  return {
    status: currentResult.status,
    isPlaceholderData,
    data: isPlaceholderData ? placeholderResult.data : currentResult.data,
    recordWindow: isPlaceholderData
      ? placeholderRecordWindow
      : currentRecordWindow,
  } as UseRecordChunksResult;
}
