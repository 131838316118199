import { Skeleton, Typography } from "@mui/material";
import { useTopic } from "../../domain/crud";
import { makeTopicLocation } from "../../paths";
import type { Topic } from "../../services/datastore";
import Link from "../Link";

export interface TopicInfoProps {
  topicId: Topic["id"];
}

export default function TopicInfo({ topicId }: TopicInfoProps) {
  const topicQuery = useTopic(topicId, {
    select(response) {
      return response.data.name;
    },
  });

  return (
    <>
      {topicQuery.data ?? <Skeleton />}
      <Link to={makeTopicLocation(topicId)}>
        <Typography variant="body2">{topicId}</Typography>
      </Link>
    </>
  );
}
