import { Box, Card, CardContent, TableCell, Typography } from "@mui/material";
import type { z } from "zod";
import { omitNullFields } from "../../../components/Form";
import Link from "../../../components/Link";
import TopicInfo from "../../../components/ResourceInfo/TopicInfo";
import type { Column } from "../../../components/Table";
import {
  dateTimeColumns,
  getActiveFiltersCount,
  makeRequestSchema,
  prepareListRequest,
  ResourceTable,
  useSearchRequest,
  withoutBaseTableModel,
} from "../../../components/Table";
import { useExtractionTopics } from "../../../domain/crud";
import {
  makeExtractionTopicLocation,
  makeNewExtractionTopicLocation,
} from "../../../paths";
import type { ExtractionTopic } from "../../../services/datastore";
import { ExtractionTopicFilters } from "./ExtractionTopicFilters";
import { listExtractionTopicsSchema } from "./validation";

const COLUMNS: ReadonlyArray<Column<ExtractionTopic>> = [
  {
    header: "Extraction Topic ID",
    renderCell(topic) {
      return (
        <TableCell>
          <Link to={makeExtractionTopicLocation(topic.extractionId, topic.id)}>
            <Typography variant="body2">{topic.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    header: "Topic",
    renderCell(topic) {
      return (
        <TableCell>
          <TopicInfo topicId={topic.topicId} />
        </TableCell>
      );
    },
  },
  {
    accessor: "startTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "endTime",
    dataType: "timestamp",
    isSortable: true,
  },
  {
    accessor: "frequency",
    dataType: "number",
    isSortable: true,
  },
  ...dateTimeColumns,
];

const requestSchema = makeRequestSchema(COLUMNS, listExtractionTopicsSchema);

interface TopicsTableProps {
  extractionId: string;
}

export default function TopicsTable({ extractionId }: TopicsTableProps) {
  const [request, setRequest] = useSearchRequest(requestSchema);
  const searchQuery = useExtractionTopicsSearch(extractionId, request);

  const filterValues = withoutBaseTableModel(request);

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Extraction Topics
      </Typography>
      <Card>
        <CardContent>
          <ResourceTable
            resourceName="topic"
            resourceCreateLocation={makeNewExtractionTopicLocation(
              extractionId
            )}
            getRowKey={(topic) => `${topic.topicId}_${topic.createdAt}`}
            columns={COLUMNS}
            searchQuery={searchQuery}
            tableModel={request}
            onTableModelChange={setRequest}
            filterSection={
              <ExtractionTopicFilters
                values={filterValues}
                setValues={setRequest}
              />
            }
            activeFilterCount={getActiveFiltersCount(filterValues)}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

function useExtractionTopicsSearch(
  extractionId: string,
  request: z.infer<typeof requestSchema>
) {
  return useExtractionTopics(
    extractionId,
    omitNullFields(prepareListRequest(request)),
    {
      keepPreviousData: true,
      cacheTime: 0,
    }
  );
}
